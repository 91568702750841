import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as DateUtils from '../../../common/DateUtils'
import * as DataUtils from '../../../common/DataUtils'
import * as Constants from '../../../common/Constants'
import * as Storage from '../../../common/Storage'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import IIconButton from '../../controls/IIconButton'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import ICheckbox from '../../controls/ICheckbox'
import IImage from '../../controls/IImage'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import { initWorkingPeriodEmployee } from '../../../common/WorkingPeriod'
import Styles from '../../../common/Styles'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableHead from '../../controls/ITableHead'
import ITableRow from '../../controls/ITableRow'
import ITableFooter from '../../controls/ITableFooter'
import IPaper from '../../controls/IPaper'
import ISwitch from '../../controls/ISwitch'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogTitle from '../../controls/IDialogTitle'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import PhoneInput from '../../widgets/PhoneInput'
import TimePicker from '../../widgets/TimePicker'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import CancelIcon from '@material-ui/icons/Cancel'
import AddIcon from '@material-ui/icons/Add'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 850,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  },
  content: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  workingTime: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  }
}))

function TimesView({ props }) {
  Logger.log('TimesView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const [date, setDate] = useState({})
  const [items, setItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {

  }, [])

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {
      if (props.onTimesSelected) {
        props.onTimesSelected(date, items)
      }
    }
  }

  const handleDeleteTime = (item) => {
    if (items.length == 1) {
      showToast(props, TOAST_ERROR, Strings.mustHave1Time)
      return
    }
    var list = []
    for (let i in items) {
      if (items[i][Constants.ID] != item[Constants.ID]) {
        const newItem = { ...items[i] }
        newItem[Constants.ID] = StringUtils.getUniqueID()
        list.push(newItem)
      }
    }
    DataUtils.sortBy(list, Constants.ORDER)
    setItems(list)
  }

  const onAddTime = () => {
    const last = items[items.length - 1]
    const newItem = DateUtils.createHourOfDate(StringUtils.getUniqueID(), last[Constants.ORDER] + 1,
      last[Constants.END] + 1, last[Constants.END] + 1 + Constants.WORKING_STEP)
    const list = [...items, newItem]
    setItems(list)
  }

  props.onClickTime = (item) => {
    setDate(item)
    setOpenDialog(true)
    const hours = item[Constants.HOURS] || []
    if (hours.length > 0) {
      DataUtils.sortBy(hours, Constants.ORDER)
      setItems(hours)
    } else {
      setItems(DateUtils.createDefaultHoursOfDate())
    }
  }

  props.onTimeChanged = (item, hr, min, extra) => {
    var list = []
    for (let i in items) {
      const value = items[i]
      if (value[Constants.ID] == item[Constants.ID]) {
        const number = DateUtils.getNumberFromHourMinute({ hour: hr, minute: min })
        if (extra == 'start') {
          list.push(DateUtils.createHourOfDate(StringUtils.getUniqueID(), value[Constants.ORDER], number, value[Constants.END]))
        } else {
          list.push(DateUtils.createHourOfDate(StringUtils.getUniqueID(), value[Constants.ORDER], value[Constants.START], number))
        }
      } else {
        list.push(value)
      }
    }
    DataUtils.sortBy(list, Constants.ORDER)
    setItems(list)
  }

  const classes = useStyles()

  return <IDialog open={openDialog} maxWidth='sm' fullWidth={true} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title'>
    <IDialogTitle id='alert-dialog-title'>{DateUtils.getDayByNumber(date[Constants.DATE_OF_WEEK])}</IDialogTitle>
    <IDialogContent>
      <ITableContainer component={IPaper} className={classes.listSection}>
        <ITable className={classes.table} aria-label='customized table'>
          <ITableHead>
            <ITableRow>
              <ITableCell>{Strings.from}</ITableCell>
              <ITableCell>{Strings.to}</ITableCell>
              <ITableCell></ITableCell>
            </ITableRow>
          </ITableHead>
          <ITableBody>
            {items.map((row, index) => (
              <ITableRow key={row[Constants.ID]} >
                <ITableCell component='th' scope='row'>
                  <TimePicker props={props} item={row} select={row[Constants.START]} extra='start' />
                </ITableCell>
                <ITableCell align='center'>
                  <TimePicker props={props} item={row} select={row[Constants.END]} extra='end' />
                </ITableCell>
                <ITableCell align='center'>
                  <IIconButton onClick={() => handleDeleteTime(row)}><CancelIcon /></IIconButton>
                </ITableCell>
              </ITableRow>
            ))}
          </ITableBody>
          <ITableFooter>
            <ITableRow>
              <ITableCell colSpan={3}>
                <AddButton startIcon={<AddIcon />} onClick={() => onAddTime()}>{Strings.addTime}</AddButton>
              </ITableCell>
            </ITableRow>
          </ITableFooter>
        </ITable>
      </ITableContainer>
    </IDialogContent>
    <IDialogActions>
      <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
      <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
    </IDialogActions>
  </IDialog>
}

function ServicesView({ props }) {
  Logger.log('ServicesView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const [items, setItems] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [isSelectAll, setIsSelectAll] = useState(true)

  useEffect(() => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId)
    ref.once('value').then(response => {
      setLoading(props, false)
      const list = []
      const val = response.val()
      if (val) {
        for (let id in val) {
          list.push(val[id])
        }
      }
      DataUtils.sortBy(list, Constants.ORDER)
      setItems(list)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {
      var list = []
      for (let i in checkedItems) {
        for (let j in items) {
          if (checkedItems[i] == items[j][Constants.ID]) {
            list.push(items[j])
            break
          }
        }
      }
      if (props.onServicesSelected) {
        props.onServicesSelected(list)
      }
    }
  }

  const handleChange = (item) => {
    const index = checkedItems.indexOf(item[Constants.ID])
    let list = []
    if (index >= 0) {
      for (let i in checkedItems) {
        if (index != i) {
          list.push(checkedItems[i])
        }
      }
    } else {
      list.push(...checkedItems)
      list.push(item[Constants.ID])
    }
    setCheckedItems(list)
    setIsSelectAll(list.length != items.length)
  }

  const handleToggleAll = () => {
    let list = []
    if (checkedItems.length != items.length) {
      for (let i in items) {
        list.push(items[i][Constants.ID])
      }
    }
    setCheckedItems(list)
    setIsSelectAll(list.length != items.length)
  }

  props.onAddServices = () => {
    setOpenDialog(true)
  }

  const classes = useStyles()

  return <IDialog open={openDialog} maxWidth='sm' fullWidth={true} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title' >
    <IDialogContent>
      <IDialogTitle id='alert-dialog-title'>{Strings.services}</IDialogTitle>
      <ITableContainer component={IPaper} className={classes.listSection}>
        <ITable>
          <ITableBody>
            {items.map(item => (
              <ITableRow key={item[Constants.ID]}>
                <ITableCell size='small' width={Styles.smallImageSize}>
                  <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.TITLE]} />
                </ITableCell>
                <ITableCell><b>{item[Constants.TITLE]}</b></ITableCell>
                <ITableCell align='right'>
                  <ICheckbox checked={checkedItems.indexOf(item[Constants.ID]) >= 0} onChange={(event) => handleChange(item)} value='secondary' color='primary' />
                </ITableCell>
              </ITableRow>
            ))}
          </ITableBody>
        </ITable>
      </ITableContainer>
      <IDialogActions>
        <IButton onClick={() => handleToggleAll(false)}>{isSelectAll ? Strings.selectAll : Strings.deselectAll}</IButton>
        <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
        <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
      </IDialogActions>
    </IDialogContent>
  </IDialog>
}

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const refTimer = useRef()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [image, setImage] = useState()
  const [src, setSrc] = useState('')
  const [dates, setDates] = useState(DateUtils.genarateDateOfWeek())
  const [services, setServices] = useState([])

  useEffect(() => {
    if (refTimer.current) {
      clearTimeout(refTimer.current)
    }
  }, [])

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (!StringUtils.isValidUploadImage(file.name)) {
        setImage(null)
        setSrc('')
        showToast(props, TOAST_ERROR, Strings.errorWrongFormat + StringUtils.arrayToString(Constants.SUPPORT_IMAGE_TYPES))
        return
      }
      setImage(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function (e) {
        setSrc(reader.result)
      }.bind(this)
    }
  }

  const postSave = async () => {
    if (StringUtils.stringEmpty(email)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired + ': ' + Strings.email)
      return
    }
    if (!StringUtils.validateEmail(email)) {
      showToast(props, TOAST_ERROR, Strings.errorEmailInvalid)
      return
    }
    if (StringUtils.stringEmpty(firstName)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired + ': ' + Strings.firstName)
      return
    }
    if (StringUtils.stringEmpty(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired + ': ' + Strings.phone)
      return
    }
    if (!StringUtils.validatePhone(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorPhoneInvalid)
      return
    }
    
    setLoading(props, true)

    // get store's info
    let storeName = ''
    const refStore = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
    const storeResponse = await refStore.once('value')
    if (storeResponse) {
      const val = storeResponse.val()
      if (val) {
        storeName = val[Constants.NAME]
      }
    }

    const password = Constants.EMPLOYEE_DEFAULT_PASSWORD
    const message = `Dear ${firstName},<br/><br/>
    You have been added as an employee for ${storeName}.<br/><br/>
    You have the following account on www.NextNails.Care for login. Please change your default password after login.<br/><br/>
    Username: ${email}<br/>
    Password: ${password}<br/><br/>
    NextNails.Care is the simplest and most productive experience for the services industry.<br/>Welcome to NextNails.Care.<br/><br/>
    We appreciate your participation in our scheduling software platform.<br/><br/>
    Sincerely,<br/><br/>
    NextNails.Care`

    let id = ''
    const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_STORE_CREATE_EMPLOYEE)
    try {
      const httpsResponse = await httpsCallable({ userId, storeId, email, password, message })
      if (httpsResponse && httpsResponse.data) {
        if (httpsResponse.data.message) {
          setLoading(props, false)
          showToast(props, TOAST_ERROR, httpsResponse.data.message)
          return
        }
        id = httpsResponse.data.uid
      }
    } catch (err) {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      return
    }

    let updates = {}

    if (image) {
      const refStorage = firebase.storageRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + id + '/' + image.name)
      const downloadURL = await firebase.uploadFile(refStorage, image, 0, 1, (index, length, bytesTransferred, totalBytes) => {
        //updateProgress(Math.ceil(bytesTransferred / totalBytes * 100))
      })
      if (downloadURL) {
        updates[Constants.IMAGE] = downloadURL
      }
    }

    updates[Constants.ID] = id
    updates[Constants.USER_ID] = userId
    updates[Constants.STORE_ID] = storeId
    updates[Constants.EMAIL] = email
    updates[Constants.FIRST_NAME] = firstName
    updates[Constants.LAST_NAME] = lastName
    updates[Constants.PHONE] = phone
    updates[Constants.ADDRESS] = address
    updates[Constants.ROLE] = Constants.ROLE_EMPLOYEE_STORE
    updates[Constants.WORKING_PERIOD] = dates
    updates[Constants.DATE_MODIFIED] = Date.now()
    updates[Constants.DATE_ADDED] = Date.now()
    const ref = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + id)
    await ref.update(updates)

    // update services
    for (let i in services) {
      let service = services[i]
      const refService = firebase.databaseRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId + '/' + service[Constants.ID])
      const responseService = await refService.once('value')
      if (responseService) {
        const valService = responseService.val()
        if (valService) {
          const employeeIds = valService[Constants.EMPLOYEE_IDS] || []
          employeeIds.push(id)
          updates = {}
          updates[Constants.EMPLOYEE_IDS] = employeeIds
          updates[Constants.DATE_MODIFIED] = Date.now()
          await refService.update(updates)
        }
      }
    }

    // update store working hours    
    let storeWorkingPeriod = initWorkingPeriodEmployee()
    // get all employees
    let allDates = [...dates]
    const refEmployees = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId)
    const employeesResponse = await refEmployees.once('value')
    if (employeesResponse) {
      const val = employeesResponse.val()
      if (val) {
        for (let i in val) {
          const workingPeriods = val[i][Constants.WORKING_PERIOD] || []
          allDates.push(...workingPeriods)
        }
      }
    }
    const maxHr = 999
    const minHr = -999
    for (let i in storeWorkingPeriod) {
      const workingPeriod = storeWorkingPeriod[i]
      const workingHours = workingPeriod[Constants.HOURS] || []
      const workingHour = workingHours.length > 0 ? workingHours[0] : {}
      // reset to compare
      workingHour[Constants.START] = maxHr
      workingHour[Constants.END] = minHr
      for (let j in allDates) {
        const wPeriod = allDates[j]
        if (wPeriod[Constants.IS_CHECK] && wPeriod[Constants.DATE_OF_WEEK] == workingPeriod[Constants.DATE_OF_WEEK]) {
          workingPeriod[Constants.IS_CHECK] = true
          const wHours = wPeriod[Constants.HOURS] || []
          for (let k in wHours) {
            const wHour = wHours[k] || {}
            if (workingHour[Constants.START] > wHour[Constants.START]) {
              workingHour[Constants.START] = wHour[Constants.START]
            }
            if (workingHour[Constants.END] < wHour[Constants.END]) {
              workingHour[Constants.END] = wHour[Constants.END]
            }
          }
        }
      }
      // revert back to default if needed
      if (workingHour[Constants.START] == maxHr) {
        workingHour[Constants.START] = Constants.CALENDAR_START_HOUR
      }
      if (workingHour[Constants.END] == minHr) {
        workingHour[Constants.END] = Constants.CALENDAR_END_HOUR + 1
      }
    }
    
    updates = {}
    updates[Constants.WORKING_PERIOD] = storeWorkingPeriod
    await refStore.update(updates)
    
    setLoading(props, false)
    showToast(props, TOAST_SUCCESS, Strings.employeeCreated)
    refTimer.current = setTimeout(() => {
      Navigator.navigate(props, Constants.PAGES_STORE_VIEW_EMPLOYEES)
    }, 2000)
  }

  const handleClickDay = (checked, item) => {
    let list = []
    for (let i in dates) {
      let date = dates[i]
      if (date[Constants.DATE_OF_WEEK] == item[Constants.DATE_OF_WEEK]) {
        date[Constants.IS_CHECK] = checked
        if (checked) {
          const hours = date[Constants.HOURS]
          if (!hours) {
            date[Constants.HOURS] = DateUtils.createDefaultHoursOfDate()
          }
        }
      }
      list.push(date)
    }
    setDates(list)
  }

  const handleClickTime = (item) => {
    if (!item[Constants.IS_CHECK]) {
      handleClickDay(true, item)
    }
    if (props.onClickTime) {
      props.onClickTime(item)
    }
  }

  const onAddServices = () => {
    if (props.onAddServices) {
      props.onAddServices()
    }
  }

  const getServicesText = () => {
    let text = ''
    for (let i in services) {
      if (text != '') {
        text += ', '
      }
      let service = services[i]
      text += service[Constants.TITLE]
    }
    return text
  }

  props.onServicesSelected = (list) => {
    setServices(list)
  }

  props.onTimesSelected = (item, hours) => {
    let list = []
    for (let i in dates) {
      let date = dates[i]
      if (date[Constants.DATE_OF_WEEK] == item[Constants.DATE_OF_WEEK]) {
        date[Constants.HOURS] = hours
      }
      list.push(date)
    }
    setDates(list)
  }

  const classes = useStyles()

  return <div>
    <div style={{ textAlign: 'center' }}>
      <ITypography className={classes.heading}>{Strings.addEmployee}</ITypography>
    </div>
    <div className={classes.content}>
      <div>
        <ICard>
          <ICardContent>
            <IFormControl style={{ maxWidth: Styles.formMaxWidth, alignItems: 'center' }}>
              <ITextField className={classes.textField} label={Strings.email + '*'} value={email} onChange={(event) => { setEmail(event.target.value) }} />
              <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.firstName + '*'} value={firstName} onChange={(event) => { setFirstName(event.target.value) }} />
              <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.lastName} value={lastName} onChange={(event) => { setLastName(event.target.value) }} />
              <PhoneInput className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.phone + '*'} value={phone} onChange={(event) => { setPhone(event) }} />
              <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.address} value={address} onChange={(event) => { setAddress(event.target.value) }} />
              <div className={classes.textField} style={{ display: 'flex', alignItems: 'center', marginTop: Styles.margin2Br }}>
                <ITypography className='MuiFormLabel-root'>{Strings.services}:</ITypography>
                <IIconButton onClick={() => onAddServices()}><AddIcon /></IIconButton>
              </div>
              <div className={classes.textField}>
                <ITypography variant='body1'>{getServicesText()}</ITypography>
              </div>
              <div className={classes.textField} style={{ marginTop: Styles.margin2Br }}>
                <ITypography className='MuiFormLabel-root'>{Strings.avatar}:</ITypography>
              </div>
              <ITextField className={classes.textField} type='file' onChange={handleImageChange} />
              <IImage src={src || settings[Constants.DEFAULT_AVATAR]} style={{ width: '200px', height: '200px', marginTop: Styles.margin1Br }} />
            </IFormControl>
          </ICardContent>
        </ICard>
      </div>
      <div className={classes.workingTime}>
        <ITableContainer component={IPaper}>
          <ITable className={classes.table} aria-label='customized table'>
            <ITableHead>
              <ITableRow>
                <ITableCell>{Strings.workingTime}</ITableCell>
                <ITableCell align='center'></ITableCell>
                <ITableCell>{Strings.editShifts}</ITableCell>
              </ITableRow>
            </ITableHead>
            <ITableBody>
              {dates.map((row, index) => (
                <ITableRow key={row[Constants.DATE_OF_WEEK]} >
                  <ITableCell component='th' scope='row'>
                    {DateUtils.getDayByNumber(row[Constants.DATE_OF_WEEK])}
                  </ITableCell>
                  <ITableCell align='center'>
                    <ISwitch checked={row[Constants.IS_CHECK]} onChange={event => handleClickDay(event.target.checked, row)}  />
                  </ITableCell>
                  <ITableCell align='center'>
                    <IIconButton onClick={event => handleClickTime(row)}><AccessAlarmIcon /></IIconButton>
                  </ITableCell>
                </ITableRow>
              ))}
            </ITableBody>
          </ITable>
        </ITableContainer>
      </div>
    </div>
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <IButton onClick={() => Navigator.back()}>{Strings.cancel}</IButton>
      <IButton className={classes.rightButton} onClick={postSave}>{Strings.save}</IButton>
    </div>
  </div>
}

function AddEmployee() {
  Logger.log(Constants.PAGES_STORE_ADD_EMPLOYEE)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <TimesView props={props} />
      <ServicesView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default AddEmployee