import * as Constants from './Constants'
import * as StringUtils from './StringUtils'
import moment from 'moment'

export const SORT_ASCE = 'asce'
export const SORT_DESC = 'desc'

function compareDate(date1, date2) {
  if (date1 instanceof moment && date2 instanceof moment) {
    if (date1.isSame(date2)) {
      return 0
    } else if (date1.isBefore(date2)) {
      return -1
    }
  } else {
    const time1 = date1.getTime()
    const time2 = date2.getTime()
    if (time1 == time2) {
      return 0
    } else if (time1 < time2) {
      return -1
    }
  }
  return 1
}

export function sortBy(list, field, by = SORT_ASCE) {
  if (by == SORT_ASCE) {
    list.sort((a, b) => compareDate(a[field], b[field]))
  } else {
    list.sort((a, b) => -compareDate(a[field], b[field]))
  }
}

// Create default hours of an employee
export function createDefaultHoursOfDate() {
    const item1 = createHourOfDate(StringUtils.getUniqueID(), 0)
    const item2 = createHourOfDate(StringUtils.getUniqueID(), item1[Constants.ORDER] + 1, item1[Constants.END] + 1, item1[Constants.END] + 1 + Constants.WORKING_STEP)
    return [item1, item2]
}

// Create hour object in a date
export function createHourOfDate(id = '', order = 0, start = Constants.WORKING_MORNING_START, end = Constants.WORKING_MORNING_END) {
  let hour = {}
  hour[Constants.ID] = id
  hour[Constants.ORDER] = order
  hour[Constants.START] = start
  hour[Constants.END] = end
  return hour
}

// Create date object
export function createDateOfWeek(dateOfWeek, isCheck, hours) {
  let date = {}
  date[Constants.DATE_OF_WEEK] = dateOfWeek
  date[Constants.IS_CHECK] = isCheck
  date[Constants.HOURS] = hours
  return date
}

// Create default all days of a week, start from 1: MONDAY, ...
export function genarateDateOfWeek() {
  return [
    createDateOfWeek(Constants.MONDAY.number, false, null),
    createDateOfWeek(Constants.TUESDAY.number, false, null),
    createDateOfWeek(Constants.WEDNESDAY.number, false, null),
    createDateOfWeek(Constants.THURSDAY.number, false, null),
    createDateOfWeek(Constants.FRIDAY.number, false, null),
    createDateOfWeek(Constants.SATURDAY.number, false, null),
    createDateOfWeek(Constants.SUNDAY.number, false, null)
  ]
}

// Get date string by number, e.g: 1 -> MONDAY, 0 -> SUNDAY ...
export function getDayByNumber(number) {
  if (number == Constants.MONDAY.number) {
    return Constants.MONDAY.name
  } else if (number == Constants.TUESDAY.number) {
    return Constants.TUESDAY.name
  } else if (number == Constants.WEDNESDAY.number) {
    return Constants.WEDNESDAY.name
  } else if (number == Constants.THURSDAY.number) {
    return Constants.THURSDAY.name
  } else if (number == Constants.FRIDAY.number) {
    return Constants.FRIDAY.name
  } else if (number == Constants.SATURDAY.number) {
    return Constants.SATURDAY.name
  }
  return Constants.SUNDAY.name
}

// Get number by date string, e.g: MONDAY -> 1, SUNDAY -> 0 ...
export function getNumberByDay(name) {
  if (name == Constants.MONDAY.name) {
    return Constants.MONDAY.number
  } else if (name == Constants.TUESDAY.name) {
    return Constants.TUESDAY.number
  } else if (name == Constants.WEDNESDAY.name) {
    return Constants.WEDNESDAY.number
  } else if (name == Constants.THURSDAY.name) {
    return Constants.THURSDAY.number
  } else if (name == Constants.FRIDAY.name) {
    return Constants.FRIDAY.number
  } else if (name == Constants.SATURDAY.name) {
    return Constants.SATURDAY.number
  }
  return Constants.SUNDAY.number
}

// Check if event have same start time and end time
export function isEventSameTime(start, end) {
  const startTime = getHourMinuteFromNumber(start)
  const endTime = getHourMinuteFromNumber(end)
  return startTime.hour == endTime.hour && startTime.minute == endTime.minute
}

// Convert hour dicimal to minutes, e.g: 1.5 -> 90 minutes
export function getMinutesFromNumber(hour) {
  const lowest = Math.floor(hour)
  const sub = hour - lowest
  const minute = sub * 60
  return Math.round((lowest * 60) + minute)
}

// Convert hour dicimal to pair of { hour, minute }, e.g: 8.5 -> {hour: 8, minute: 30}, 14.25 -> {hour: 14, minute: 15}
export function getHourMinuteFromNumber(hour) {
  let lowest = Math.floor(hour)
  const sub = hour - lowest
  let minute = Math.round(sub * 60)
  if (minute == 60) {
    lowest += 1
    minute = 0
  }
  return { hour: lowest, minute }
}

// Convert hour pair to decimal, e.g: {hour: 8, minute: 30} -> 8.5, {hour: 14, minute: 15} -> 14.25
export function getNumberFromHourMinute(pair) {
  const { hour, minute } = pair
  return hour + (minute / 60)
}

// Check if 2 hours is overlap each other, e.g: 8h-11h will overlap 9h-10h
export function isHourOverlap(start1, end1, start2, end2) {
  if (start1 == start2 || end1 == end2 || (start2 > start1 && start2 < end1)
    || (start1 > start2 && start1 < end2)) {
    return true
  }
  return false
}

// Split 2 hours that are overlap each other, create new list that contains new pairs, 
// e.g: working 8-11am, offline 9-10am => new list [8-9, 10-11]
export function splitHourOverlap(start1, end1, start2, end2) {  
  var list = []
  if (start1 < start2) {
    const sub = {}
    sub[Constants.START] = start1
    sub[Constants.END] = start2
    list.push(sub)
  }
  if (end1 > end2) {
    const sub = {}
    sub[Constants.START] = end2
    sub[Constants.END] = end1
    list.push(sub)
  }
  return list
}

// Compare date2 with date1 by day, using native Date()
export function isGreaterOrSameDay(date1, date2) {
  if (!date1 || !date2) {
    return false
  }
  
  const y1 = date1.getYear()
  const y2 = date2.getYear()
  if (y2 > y1) {
    return true
  }
  if (y2 < y1) {
    return false
  }

  const m1 = date1.getMonth()
  const m2 = date2.getMonth()
  if (m2 > m1) {
    return true
  }
  if (m2 < m1) {
    return false
  }

  const d1 = date1.getDate()
  const d2 = date2.getDate()
  if (d2 >= d1) {
    return true
  }

  return false
}

// Check if time is inside another time
export function isContains(startTime1, endTime1, startTime2, endTime2) {
  const startHour1 = startTime1.hour
  const startMinute1 = startTime1.minute
  const endHour1 = endTime1.hour
  const endMinute1 = endTime1.minute
  const startHour2 = startTime2.hour
  const startMinute2 = startTime2.minute
  const endHour2 = endTime2.hour
  const endMinute2 = endTime2.minute
  if (startHour1 > startHour2 && endHour1 < endHour2) {
    return true
  } else if (startHour1 == startHour2 && startMinute1 >= startMinute2) {
    return true
  } else if (endHour1 == endHour2 && endMinute1 <= endMinute2) {
    return true
  }
  return false
}