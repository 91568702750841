import * as Logger from './Logger'

const IS_LOG = false

class dbStore {
    store = {}

    set(key, value) {
        this.store[key] = value
    }

    get(key) {
        return this.store[key]
    }

    remove(key) {
        delete this.store[key]
    }

    clearData() {
        this.store = {}
    }
}

const memory = (function () {
    var instance

    function createInstance() {
        var object = new dbStore()
        return object
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance()
            }
            return instance
        }
    }
})()

function isBool(arg) {
    return arg !== null && typeof arg === 'boolean'
}

function isNumber(arg) {
    return arg !== null && typeof arg === 'number'
}

function isString(arg) {
    return arg !== null && typeof arg === 'string' && arg !== ''
}

function isObject(arg) {
    return arg !== null && typeof arg === 'object'
}

function getItemFromMemory(key) {
    return memory.getInstance().get(key)
}

export function setItemToMemory(key, value) {
    memory.getInstance().set(key, value)
}

function getItem(key) {
    let cache = getItemFromMemory(key)
    if (cache) {
        return cache
    }
    return localStorage.getItem(key)
}

function setItem(key, value, isObject = false) {
    setItemToMemory(key, value)
    localStorage.setItem(key, isObject ? JSON.stringify(value) : (value != null ? `${value}` : ''))
}

export function setBool(key, value = false) {
    if (IS_LOG) {
        Logger.logData('[Storage] SET', key, value)
    }
    setItem(key, value)
}

export function getBool(key, def = false) {
    const value = getItem(key)
    if (IS_LOG) {
        Logger.logData('[Storage] GET', key, value)
    }
    if (isBool(value)) {
        return value
    } else if (isString(value)) {
        const parse = value === 'true'
        setItemToMemory(key, parse)
        return parse
    }
    return def
}

export function setString(key, value = null) {
    if (IS_LOG) {
        Logger.logData('[Storage] SET', key, value)
    }
    setItem(key, value)
}

export function getString(key, def = null) {
    const value = getItem(key)
    if (IS_LOG) {
        Logger.logData('[Storage] GET', key, value)
    }
    return value != null ? value : def
}

export function setNumber(key, value = 0) {
    if (IS_LOG) {
        Logger.logData('[Storage] SET', key, value)
    }
    setItem(key, value)
}

export function getNumber(key, def = null) {
    const value = getItem(key)
    if (IS_LOG) {
        Logger.logData('[Storage] GET', key, value)
    }
    if (isNumber(value)) {
        return value
    } else if (isString(value)) {
        const parse = Number(value)
        setItemToMemory(key, parse)
        return parse
    }
    return def
}

export function setJson(key, value = null) {
    if (IS_LOG) {
        Logger.logData('[Storage] SET', key, value)
    }
    setItem(key, value, isObject(value))
}

export function getJson(key, empty = true, def = null) {
    const value = getItem(key)
    if (IS_LOG) {
        Logger.logData('[Storage] GET', key, value)
    }
    if (isObject(value)) {
        return value
    } else if (isString(value)) {
        const parse = JSON.parse(value)
        setItemToMemory(key, parse)
        return parse
    }
    return empty ? {} : def
}

export function clearKey(key) {
    if (IS_LOG) {
        Logger.logData('[Storage] CLEAR', key, '')
    }
    setItem(key, null)
}

// This method will remove all local data, be carefull when use
export function clearData() {
    if (IS_LOG) {
        Logger.log('[Storage] Clear Data')
    }
    memory.getInstance().clearData()
    localStorage.clear()
}