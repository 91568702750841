import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import * as firebase from '../../utils/firebase'
import * as Logger from '../../common/Logger'
import * as StringUtils from '../../common/StringUtils'
import * as Constants from '../../common/Constants'
import ProgressBar from '../widgets/ProgressBar'
import ICard from '../controls/ICard'
import ICardContent from '../controls/ICardContent'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../common/ViewUtils'
import Styles from '../../common/Styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  textBox: {
    fontSize: 16
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const [text, setText] = useState('')

  useEffect(() => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_PUBLIC)
    ref.once('value').then(response => {
      setLoading(props, false)
      if (response) {
        const val = response.val()
        if (val) {
          setText(val[Constants.PRIVACY_POLICY])
        }
      }
    }).catch(error => {
      Logger.log(error)
      setLoading(props, false)
    })
  }, [])

  const classes = useStyles()

  return <div style={{ maxWidth: Styles.contentMaxWidth, alignItems: 'center' }}>
    <div className={classes.textBox} dangerouslySetInnerHTML={{ __html: text }} />
  </div>
}

function PrivacyPolicy() {
  Logger.log(Constants.PAGES_PRIVACY_POLICY)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default PrivacyPolicy