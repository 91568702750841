import React, { useState } from 'react'
import ISnackbar from '../controls/ISnackbar'
import IAlert from '../controls/IAlert'
import * as Logger from '../../common/Logger'
import { onToastViewCompleted } from '../../common/ViewUtils'

export const TOAST_ERROR = 'error'
export const TOAST_WARNING = 'warning'
export const TOAST_INFO = 'info'
export const TOAST_SUCCESS = 'success'

function ToastView({ props }) {
    Logger.log('ToastView')

    const [state, setState] = useState({ open: false, type: 'error', message: 'Alert' })

    const handleClose = () => {
        setState({ ...state, open: false })
    }

    const showToast = (type, message) => {
        setState({ open: true, type: type, message: message })
    }

    onToastViewCompleted(props, showToast)

    return (state.open && <ISnackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000} open={state.open} onClose={handleClose}
    >
        <IAlert onClose={handleClose} severity={state.type}>{state.message}</IAlert>
    </ISnackbar>)
}

export default ToastView