import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import * as DataUtils from '../../../common/DataUtils'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import IIconButton from '../../controls/IIconButton'
import IMenuItem from '../../controls/IMenuItem'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableHead from '../../controls/ITableHead'
import ITableRow from '../../controls/ITableRow'
import IPaper from '../../controls/IPaper'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogTitle from '../../controls/IDialogTitle'
import ICheckbox from '../../controls/ICheckbox'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import IImage from '../../controls/IImage'
import ColorPicker from '../../widgets/ColorPicker'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.formMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  },
  divColor: {
    width: 100,
    height: 40,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  listSection: {
    backgroundColor: 'white',
    marginTop: 10,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
  },
}))

function EmployeesView({ props }) {
  Logger.log('EmployeesView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const [items, setItems] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId)
    ref.once('value').then(response => {
      setLoading(props, false)
      const list = []
      const val = response.val()
      if (val) {
        for (let id in val) {
          list.push(val[id])
        }
      }
      DataUtils.sortBy(list, Constants.NAME)
      setItems(list)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {
      var list = []
      for (let i in checkedItems) {
        for (let j in items) {
          if (checkedItems[i] == items[j][Constants.ID]) {
            list.push(items[j])
            break
          }
        }
      }
      if (props.onEmployeesSelected) {
        props.onEmployeesSelected(list)
      }
    }
  }

  const handleChange = (item) => {
    const index = checkedItems.indexOf(item[Constants.ID])
    var list = []
    if (index >= 0) {
      for (let i in checkedItems) {
        if (index != i) {
          list.push(checkedItems[i])
        }
      }
    } else {
      list.push(...checkedItems)
      list.push(item[Constants.ID])
    }
    setCheckedItems(list)
  }

  props.onAddEmployees = () => {
    setOpenDialog(true)
  }

  const classes = useStyles()

  return <IDialog open={openDialog} maxWidth='sm' fullWidth={true} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title' >
    <IDialogContent>
      <IDialogTitle id='alert-dialog-title'>{Strings.employees}</IDialogTitle>
      <ITableContainer component={IPaper} className={classes.listSection}>
        <ITable>
          <ITableBody>
            {items.map(item => (
              <ITableRow key={item[Constants.ID]}>
                <ITableCell size='small' width={Styles.smallImageSize}>
                  <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.TITLE]} />
                </ITableCell>
                <ITableCell><b>{item[Constants.FIRST_NAME]} {item[Constants.LAST_NAME]}</b></ITableCell>
                <ITableCell align='right'>
                  <ICheckbox checked={checkedItems.indexOf(item[Constants.ID]) >= 0} onChange={(event) => handleChange(item)} value='secondary' color='primary' />
                </ITableCell>
              </ITableRow>
            ))}
          </ITableBody>
        </ITable>
      </ITableContainer>
      <IDialogActions>
        <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
        <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
      </IDialogActions>
    </IDialogContent>
  </IDialog>
}

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const refTimer = useRef()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [duration, setDuration] = useState('')
  const [category, setCategory] = useState()
  const [categories, setCategories] = useState([])
  const [employees, setEmployees] = useState([])
  const [image, setImage] = useState()
  const [src, setSrc] = useState()
  const [color, setColor] = useState('')

  useEffect(() => {
    loadCategories()

    if (refTimer.current) {
      clearTimeout(refTimer.current)
    }
  }, [])

  const loadCategories = async () => {
    setLoading(props, true)
    const list = []
    const ref1 = firebase.databaseRef(Constants.FB_SYSTEM_CATEGORIES)
    const response1 = await ref1.once('value')
    if (response1) {
      const val = response1.val()
      if (val) {
        for (let id in val) {
          list.push(val[id])
        }
      }
    }
    const ref2 = firebase.databaseRef(Constants.FB_STORE_CATEGORIES + '/' + userId + '/' + storeId)
    const response2 = await ref2.once('value')
    if (response2) {
      const val = response2.val()
      if (val) {
        for (let id in val) {
          list.push(val[id])
        }
      }
    }
    DataUtils.sortBy(list, Constants.TITLE)
    setCategories(list)
    setLoading(props, false)
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (!StringUtils.isValidUploadImage(file.name)) {
        setImage(null)
        setSrc('')
        showToast(props, TOAST_ERROR, Strings.errorWrongFormat + StringUtils.arrayToString(Constants.SUPPORT_IMAGE_TYPES))
        return
      }
      setImage(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function (e) {
        setSrc(reader.result)
      }.bind(this)
    }
  }

  const postSave = async () => {
    if (category == null || StringUtils.stringEmpty(description) || StringUtils.stringEmpty(title)
      || StringUtils.stringEmpty(price) || StringUtils.stringEmpty(duration) || !StringUtils.isNumber(price)
      || !StringUtils.isNumber(duration) || StringUtils.stringEmpty(color)
      || employees.length == 0) {
      if (!StringUtils.isNumber(price)) {
        showToast(props, TOAST_ERROR, Strings.errorInvalidPrice)
        return
      }
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    setLoading(props, true)
    const id = StringUtils.getUniqueID()
    const updates = {}
    if (image) {
      const refStorage = firebase.storageRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId + '/' + id + '/' + image.name)
      const downloadURL = await firebase.uploadFile(refStorage, image, 0, 1, (index, length, bytesTransferred, totalBytes) => {
      })
      if (downloadURL) {
        updates[Constants.IMAGE] = downloadURL
      }
    }

    // get next order
    let order = 0
    const refLatest = firebase.databaseRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId)
    const responseLatest = await refLatest.once('value')
    if (responseLatest) {
      const val = responseLatest.val()
      if (val) {
        for (let i in val) {
          if (order < val[i][Constants.ORDER]) {
            order = val[i][Constants.ORDER]
          }
        }
        order = order + 1
      }
    }

    const ref = firebase.databaseRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId + '/' + id)
    updates[Constants.ID] = id
    updates[Constants.USER_ID] = userId
    updates[Constants.STORE_ID] = storeId
    updates[Constants.TITLE] = title
    updates[Constants.CATEGORY_ID] = category[Constants.ID]
    updates[Constants.DESCRIPTION] = description
    updates[Constants.PRICE] = price
    updates[Constants.DURATION] = duration
    updates[Constants.COLOR] = color
    updates[Constants.ORDER] = order
    updates[Constants.EMPLOYEE_IDS] = DataUtils.arrayToArray(employees, Constants.ID)
    updates[Constants.DATE_MODIFIED] = Date.now()
    updates[Constants.DATE_ADDED] = Date.now()
    ref.update(updates).then(() => {
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
      setLoading(props, false)
      refTimer.current = setTimeout(() => {
        Navigator.navigate(props, Constants.PAGES_STORE_VIEW_SERVICES)
      }, 1000)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const handleSelectCategory = (value) => {
    for (let i in categories) {
      let item = categories[i]
      if (item[Constants.ID] == value) {
        setCategory(item)
        break
      }
    }
  }

  const openColorPicker = (value) => {
    if (props.setColorOpen) {
      props.setColorOpen(value, color)
    }
  }

  const onAddEmployees = () => {
    if (props.onAddEmployees) {
      props.onAddEmployees()
    }
  }

  const getEmployeesText = () => {
    var text = ''
    for (let i in employees) {
      if (text != '') {
        text += ', '
      }
      let employee = employees[i]
      text += employee[Constants.FIRST_NAME] + ' ' + employee[Constants.LAST_NAME]
    }
    return text
  }

  props.onColorClosed = (color) => {
    setColor(color)
  }

  props.onEmployeesSelected = (list) => {
    setEmployees(list)
  }

  const classes = useStyles()

  return <IFormControl style={{ maxWidth: Styles.formMaxWidth, alignItems: 'center' }}>
    <ITypography className={classes.heading}>{Strings.addService}</ITypography>
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin2Br }} select
      onChange={(event) => {
        handleSelectCategory(event.target.value)
      }}
      InputLabelProps={{ shrink: true, style: { color: '#fff' } }}
      value={category ? category[Constants.ID] : ''}
      label={Strings.categories}
      helperText={Strings.pleaseSelectCategory}>
      {categories.map(option => (
        <IMenuItem key={option[Constants.ID]} value={option[Constants.ID]}>
          {option[Constants.TITLE]}
        </IMenuItem>
      ))}
    </ITextField>
    <div className={`${classes.textField} ${classes.colorPicker}`} style={{ marginTop: Styles.margin1Br }}>
      <ITypography gutterBottom variant="subtitle1" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
        {category ? category[Constants.DESCRIPTION] : ''}
      </ITypography>
    </div>
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.title} value={title} onChange={(event) => { setTitle(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.description} value={description} onChange={(event) => { setDescription(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.price} helperText={Strings.priceHint} value={price} onChange={(event) => { setPrice(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.duration} helperText={Strings.durationHint} value={duration} onChange={(event) => { setDuration(event.target.value) }} />
    <div className={classes.textField} style={{ display: 'flex', alignItems: 'center', marginTop: Styles.margin1Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.employees}:</ITypography>
      <IIconButton onClick={() => onAddEmployees()}><AddIcon /></IIconButton>
    </div>
    <div className={classes.textField}>
      <ITypography variant='body1'>{getEmployeesText()}</ITypography>
    </div>
    <div className={classes.textField} style={{ marginTop: Styles.margin1Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.color}:</ITypography>
      <div className={classes.divColor} style={{ backgroundColor: color }} onClick={() => openColorPicker(true)} />
      <ColorPicker props={props} />
    </div>
    <div className={classes.textField} style={{ marginTop: Styles.margin1Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.image}:</ITypography>
    </div>
    <ITextField className={classes.textField} type='file' onChange={handleImageChange} />
    <IImage src={src || settings[Constants.DEFAULT_SERVICE_IMAGE]} style={{ width: '200px', height: '200px', marginTop: Styles.margin1Br }} />
    <div style={{ marginTop: Styles.margin2Br }}>
      <IButton onClick={() => Navigator.back()}>{Strings.cancel}</IButton>
      <IButton className={classes.rightButton} onClick={postSave}>{Strings.save}</IButton>
    </div>
  </IFormControl>
}

function AddService() {
  Logger.log(Constants.PAGES_STORE_ADD_SERVICE)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <EmployeesView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default AddService