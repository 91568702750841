import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Navigator from '../../../../common/Navigator'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as ScreenUtils from '../../../../common/ScreenUtils'
import * as DataUtils from '../../../../common/DataUtils'
import * as Constants from '../../../../common/Constants'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import IIconButton from '../../../controls/IIconButton'
import ITypography from '../../../controls/ITypography'
import ICard from '../../../controls/ICard'
import ICardContent from '../../../controls/ICardContent'
import ITable from '../../../controls/ITable'
import ITableBody from '../../../controls/ITableBody'
import ITableCell from '../../../controls/ITableCell'
import ITableContainer from '../../../controls/ITableContainer'
import ITableHead from '../../../controls/ITableHead'
import ITableFooter from '../../../controls/ITableFooter'
import ITableRow from '../../../controls/ITableRow'
import IPaper from '../../../controls/IPaper'
import IDialog from '../../../controls/IDialog'
import IDialogContent from '../../../controls/IDialogContent'
import ITextField from '../../../controls/ITextField'
import SearchIcon from '@material-ui/icons/Search'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import IImage from '../../../controls/IImage'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'

const AddToVisitButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const SearchButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  content: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  storeInfo: {
    marginLeft: 20
  },
  infoSection: {
    width: '100%',
  },
  infoContent: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '0px !important',
    paddingLeft: '0px !important'
  },
  listSection: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
  },
  addedServices: {
    width: Styles.addedServicesWidth,
    marginTop: 10,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 10,
    }
  },
  tab: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  searchBox: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
}))

function InfoView({ props }) {
  Logger.log('InfoView')

  const { settings } = useGlobal()
  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID)
  const [store, setStore] = useState({})

  useEffect(() => {
    
  }, [])

  const onAboutClick = () => {
    const items = []
    if (props.getAddedServices) {
      const list = props.getAddedServices()
      items.push(...list)
    }
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_ABOUT_STORE, query.toString(), { items })
  }

  props.onLoadedStoreInfoView = (data) => {
    setStore(data)
  }

  const classes = useStyles()

  return <ICard className={classes.infoSection}>
    <ICardContent className={classes.infoContent}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={store[Constants.IMAGE] || settings[Constants.DEFAULT_STORE_IMAGE] || ''} alt={store[Constants.NAME]} />
        <ITypography variant='h5' style={{ marginLeft: 10 }}><b>{store[Constants.NAME]}</b></ITypography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <div onClick={() => onAboutClick()} className={classes.tab} style={{ textAlign: 'center' }}>
          <ITypography variant='body1' style={{ marginLeft: 10 }}>{Strings.about}</ITypography>
          <div style={{ width: 100, height: 5, backgroundColor: 'white', marginTop: 10 }} />
        </div>
        <div style={{ marginLeft: 10, textAlign: 'center' }}>
          <ITypography variant='body1' style={{ marginLeft: 10 }}>{Strings.services}</ITypography>
          <div style={{ width: 100, height: 5, backgroundColor: Styles.primaryBgColor, marginTop: 10 }} />
        </div>
      </div>
    </ICardContent>
  </ICard>
}

function EmployeesView({ props }) {
  Logger.log('EmployeesView')

  const { settings } = useGlobal()
  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID)
  const refService = useRef()
  const refItems = useRef()
  const [items, setItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {

  }, [])

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const onEmployeeClick = (item) => {
    setOpenDialog(false)
    if (item) {
      if (props.onEmployeeClick) {
        props.onEmployeeClick(refService.current, item)
      }
    } else {
      // select any provider
      const anyItem = firebase.noPreferenceEmployee(Strings.anyProviderAvailable, settings[Constants.DEFAULT_AVATAR])
      if (props.onEmployeeClick) {
        props.onEmployeeClick(refService.current, anyItem)
      }
    }
  }

  props.onVisitClick = (item) => {
    refService.current = item
    if (refItems.current) {
      const ids = item[Constants.EMPLOYEE_IDS] || []
      let list = []
      let hasOrder = false
      for (let i in ids) {
        for (let j in refItems.current) {
          const item = refItems.current[j]
          if (ids[i] == item[Constants.ID]) {
            hasOrder = hasOrder || item[Constants.ORDER]
            list.push(item)
            break
          }
        }
      }
      DataUtils.sortBy(list, hasOrder ? Constants.ORDER : Constants.FIRST_NAME)
      setItems(list)
      setOpenDialog(true)
    }
  }

  props.onLoadedEmployees = (list) => {
    refItems.current = list
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()
  const imageSize = isMobile ? 80 : 100

  return <IDialog open={openDialog} maxWidth='sm' fullWidth={true} onClose={() => handleCloseDialog(false)}>
    <IDialogContent>
      <div>
        <ITypography variant='h5'>{Strings.selectProvider}</ITypography>
        <IIconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => handleCloseDialog()}><CloseIcon /></IIconButton>
      </div>
      <ITableContainer component={IPaper} className={classes.listSection} style={{ marginTop: Styles.margin1Br }}>
        <ITable>
          <ITableBody>
            {items.map(item => (
              <ITableRow key={item[Constants.ID]}>
                <ITableCell size='small' width={imageSize}>
                  <IImage style={{ width: imageSize, height: imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.TITLE]} />
                </ITableCell>
                <ITableCell>
                  <b>{item[Constants.FIRST_NAME]} {item[Constants.LAST_NAME]}</b>
                  {isMobile && <><AddToVisitButton style={{ marginTop: Styles.margin1Br }} onClick={() => onEmployeeClick(item)}>{Strings.select}</AddToVisitButton></>}
                </ITableCell>
                {!isMobile && <ITableCell align='right'>
                  <AddToVisitButton onClick={() => onEmployeeClick(item)}>{Strings.select}</AddToVisitButton>
                </ITableCell>}
              </ITableRow>
            ))}
            {items.length > 0 && <ITableRow key={Constants.EMPLOYEE_ANY_ONE_AVAILABLE}>
              <ITableCell size='small' style={{ maxWidth: imageSize }}>
                <IImage style={{ width: imageSize, height: imageSize }} src={settings[Constants.DEFAULT_AVATAR]} alt='Avatar' />
              </ITableCell>
              <ITableCell>
                <b>{Strings.anyProviderAvailable}</b>
                {isMobile && <><AddToVisitButton style={{ marginTop: Styles.margin1Br }} onClick={() => onEmployeeClick()}>{Strings.select}</AddToVisitButton></>}
              </ITableCell>
              {!isMobile && <ITableCell align='right'>
                <AddToVisitButton onClick={() => onEmployeeClick()}>{Strings.select}</AddToVisitButton>
              </ITableCell>}
            </ITableRow>}
          </ITableBody>
        </ITable>
      </ITableContainer>
    </IDialogContent>
  </IDialog>
}

function AddedServicesView({ props }) {
  Logger.log('AddedServicesView')

  const { settings } = useGlobal()
  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID)
  const state = Navigator.getState()
  const refItems = useRef(state.items || [])
  const [items, setItems] = useState(state.items || [])

  useEffect(() => {

  }, [])

  const onDeleteItem = (item) => {
    var index = -1
    for (let i in refItems.current) {
      if (item[Constants.ID] == refItems.current[i][Constants.ID]) {
        index = i
        break
      }
    }
    if (index != -1) {
      refItems.current.splice(index, 1)
      setItems([...refItems.current])
    }
  }

  const onChooseDateTime = () => {
    if (items.length == 0) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
      return
    }
    query.set(Constants.USER_ID, userId)
    query.set(Constants.STORE_ID, storeId)
    const startDate = moment()
    query.set(Constants.DATE, startDate.toISOString())
    query.set(Constants.START, startDate.hour())
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_SCHEDULE_NOW, query.toString(), { items })
  }

  props.onEmployeeClick = (service, employee) => {
    const item = {}
    item[Constants.ID] = StringUtils.getUniqueID()
    item[Constants.SERVICE] = service
    item[Constants.EMPLOYEE] = employee
    refItems.current.push(item)
    setItems([...refItems.current])
  }

  props.getAddedServices = () => {
    return items
  }

  const classes = useStyles()

  return <div className={classes.addedServices}>
    <ITableContainer component={IPaper}>
      <ITable>
        <ITableHead>
          <ITableRow>
            <ITableCell><ITypography variant='body1'><b>{Strings.yourVisit}</b></ITypography></ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          {items.map(item => (
            <ITableRow key={item[Constants.ID]}>
              <ITableCell size='small' style={{ maxWidth: 100 }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 5, backgroundColor: item[Constants.SERVICE][Constants.COLOR] }} />
                  <div style={{ marginLeft: 10, width: '100%' }}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <ITypography variant='body2'><b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                        <ITypography variant='body2'>{item[Constants.SERVICE][Constants.DURATION]} {Strings.mins} ${item[Constants.SERVICE][Constants.DURATION]}</ITypography>
                      </div>
                      <IIconButton style={{ marginLeft: 'auto' }} aria-label={Strings.delete} onClick={() => onDeleteItem(item)}><DeleteIcon /></IIconButton>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize, marginRight: 10 }} src={item[Constants.EMPLOYEE][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''}
                        alt={item[Constants.EMPLOYEE][Constants.FIRST_NAME]} />
                      <ITypography variant='body2'>{item[Constants.EMPLOYEE][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE][Constants.LAST_NAME]}</ITypography>
                    </div>
                  </div>
                </div>
              </ITableCell>
            </ITableRow>
          ))}
        </ITableBody>
        <ITableFooter>
          <ITableRow>
            <ITableCell style={{ textAlign: 'center' }}>
              <AddToVisitButton style={{ marginTop: 10, marginBottom: 10 }} onClick={() => onChooseDateTime()}>{Strings.chooseDateAndTime}</AddToVisitButton>
            </ITableCell>
          </ITableRow>
        </ITableFooter>
      </ITable>
    </ITableContainer>
  </div>
}

function SearchView({ props }) {
  Logger.log('SearchView')

  const [keyword, setKeyword] = useState('')

  useEffect(() => {

  }, [])

  const onSearchClick = (e) => {
    e.preventDefault()
    if (props.onSearchClick) {
      props.onSearchClick(keyword.toLowerCase())
    }
  }

  const classes = useStyles()

  return <form><div className={classes.searchBox}>
    <ITextField className={classes.textField} inputProps={{ style: { fontSize: Styles.searchFontSize } }} label={Strings.searchServiceHint} value={keyword} onChange={(event) => { setKeyword(event.target.value) }} />
    <SearchButton type='submit' startIcon={<SearchIcon />} onClick={(e) => onSearchClick(e)}>{Strings.search}</SearchButton>
  </div></form>
}

function ContentView({ props }) {
  Logger.log('ContentView')

  const { settings } = useGlobal()
  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID)
  const refItems = useRef([])
  const [items, setItems] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(props, true)
    try {
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_STORE)
      const storeResponse = await httpsCallable({ userId, storeId })
      let orders = []
      if (storeResponse.data.store) {
        const value = storeResponse.data.store
        orders = value[Constants.EMPLOYEE_ORDERS] || []        
        if (props.onLoadedStoreInfoView) {
          props.onLoadedStoreInfoView(value)
        }
      }
      const servicesCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_SERVICES)
      const response = await servicesCallable({ userId, storeId })
      const list = []
      if (response.data.items) {
        for (let i in response.data.items) {
          list.push(response.data.items[i])
        }
      }
      DataUtils.sortBy(list, Constants.ORDER)
      refItems.current = list
      setItems(list)
      const employeeCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_EMPLOYEES)
      const employeeResponse = await employeeCallable({ userId, storeId })
      if (employeeResponse.data.items) {
        if (props.onLoadedEmployees) {
          let list = employeeResponse.data.items
          // for (let i in list) {
          //   const item = list[i]
          //   item[Constants.ORDER] = orders.findIndex(value => value[Constants.RESOURCE_ID] == item[Constants.ID])
          // }
          DataUtils.sortBy(list, Constants.DATE_ADDED)
          props.onLoadedEmployees(list)
        }
      }
      setLoading(props, false)
    } catch(err) {
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    }
  }

  const onVisitClick = (item) => {
    if (props.onVisitClick) {
      props.onVisitClick(item)
    }
  }

  props.onSearchClick = (keyword) => {
    const list = []
    const isSearch = keyword && keyword != ''
    for (let i in refItems.current) {
      const item = refItems.current[i]
      if (isSearch) {
        let text = `${item[Constants.TITLE]}`.toLowerCase()
        if (text.indexOf(keyword) != -1) {
          list.push(item)
        }
      } else {
        list.push(item)
      }
    }
    DataUtils.sortBy(list, Constants.ORDER)
    setItems(list)
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return <div className={classes.listSection}>
    <div style={{ width: '100%' }}>
      <ITableContainer component={IPaper}>
        <ITable>
          <ITableBody>
            {items.map(item => (
              <ITableRow key={item[Constants.ID]}>
                <ITableCell size='small' width={isMobile ? 50 : 100}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 5, height: Styles.imageSize, backgroundColor: item[Constants.COLOR] }} />
                    <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.TITLE]} />
                  </div>
                </ITableCell>
                <ITableCell>
                  <ITypography variant='body1'><b>{item[Constants.TITLE]}</b></ITypography>
                  <ITypography variant='body1'>{item[Constants.DURATION]} {Strings.mins}</ITypography>
                  {isMobile && <>
                    <b>${item[Constants.PRICE]}</b>
                    <AddToVisitButton style={{ marginTop: Styles.margin1Br }} onClick={() => onVisitClick(item)}>{Strings.addToVisit}</AddToVisitButton>
                  </>}
                </ITableCell>
                {!isMobile && <>
                  <ITableCell><b>${item[Constants.PRICE]}</b></ITableCell>
                  <ITableCell align='right'>
                    <AddToVisitButton onClick={() => onVisitClick(item)}>{Strings.addToVisit}</AddToVisitButton>
                  </ITableCell>
                </>}
              </ITableRow>
            ))}
          </ITableBody>
        </ITable>
      </ITableContainer>
    </div>
  </div>
}

function ViewServices() {
  Logger.log(Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_VIEW_SERVICES)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <div className={classes.root}>
    <InfoView props={props} />
    <div className={classes.content}>
      <div style={{ width: '100%' }}>
        <SearchView props={props} />
        <ContentView props={props} />
      </div>      
      <AddedServicesView props={props} />
    </div>
    <EmployeesView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </div>
}

export default ViewServices