import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Navigator from '../../../../common/Navigator'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as ScreenUtils from '../../../../common/ScreenUtils'
import * as DataUtils from '../../../../common/DataUtils'
import * as Constants from '../../../../common/Constants'
import * as Storage from '../../../../common/Storage'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import IIconButton from '../../../controls/IIconButton'
import ITypography from '../../../controls/ITypography'
import ITable from '../../../controls/ITable'
import ITableBody from '../../../controls/ITableBody'
import ITableCell from '../../../controls/ITableCell'
import ITableContainer from '../../../controls/ITableContainer'
import ITableRow from '../../../controls/ITableRow'
import IPaper from '../../../controls/IPaper'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import IImage from '../../../controls/IImage'
import moment from 'moment'

const AddToVisitButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  listSection: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const state = Navigator.getState()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const [items, setItems] = useState([])
  const refBookedServices = useRef();
  const refItems = useRef()
  const refBookedTimes = useRef([])
  const booking = Storage.getJson(Constants.BOOKING)

  useEffect(() => {
    refBookedServices.current = Storage.getJson(Constants.CACHE_STORE_BOOKINGS, false)
    const allEmployees = Storage.getJson(Constants.CACHE_STORE_EMPLOYEES, false) || []
    let list = []
    let service = booking[Constants.SERVICE] || {}
    const id = state[Constants.ID]
    const extra = booking[Constants.ITEMS] || []
    const index = extra.findIndex(value => value[Constants.ID] == id)
    if (index >= 0) {
      service = extra[index][Constants.SERVICE]
    }
    const employeeIds = service[Constants.EMPLOYEE_IDS] || []
    for (let i in allEmployees) {
      const employee = allEmployees[i]
      if (employeeIds.indexOf(employee[Constants.ID]) >= 0) {
        list.push(employee)
      }
    }
    refItems.current = list
    checkAvailableTimes()
  }, [])

  const checkAvailableTime = (id, date, qStart, duration, employee) => {
    let available = null
    // step 1: get all working hours
    const list = firebase.getWorkingPeriodPairs(employee, date)
    if (list.length == 0) {
      return available
    }
    // step 2: set start to selected time if it is available
    if (qStart && qStart > 0) {
      for (let i in list) {
        const item = list[i]
        if (qStart >= item[Constants.START] && qStart <= item[Constants.END]) {
          item[Constants.START] = qStart
          break
        }
      }
    }
    // step 3: calculate available times    
    let timesPair = firebase.calculateTimesPair(list)
    let availableTimes = firebase.calculateAvailableTimes(id, date, duration, employee, timesPair, refBookedServices.current, refBookedTimes.current)

    let last = -1
    // find the lowest available time that bigger or equal to selected time
    for (let i in availableTimes) {
      let item1 = availableTimes[i]
      if (item1[Constants.START] >= qStart) {
        if (last == -1 || item1[Constants.START] < last) {
          last = item1[Constants.START]
          available = item1
        }
      }
    }

    return available
  }

  const checkAvailableTimes = () => {
    if (!refBookedServices.current || !refItems.current) {
      return
    }
    let item = { ...booking }
    const id = state[Constants.ID]
    const extra = booking[Constants.ITEMS] || []
    const index = extra.findIndex(value => value[Constants.ID] == id)
    if (index >= 0) {
      item = extra[index]
    }
    item[Constants.ITEMS] = null
    const date = moment(item[Constants.DATE])
    const serviceDuration = StringUtils.getNumber(item[Constants.SERVICE][Constants.DURATION]) / 60 // in hour  
    const list = []
    for (let i in refItems.current) {
      const startInit = item[Constants.START_INIT] || item[Constants.START]
      let qStart = startInit
      const employee = refItems.current[i]
      // increase the time for same employee, e.g: current employee have 1 appointment at 13pm, 
      // then second appointment should start at 13:30pm with duration = 30 minutes
      if (booking[Constants.ID] != id && booking[Constants.EMPLOYEE][Constants.ID] == employee[Constants.ID]
        && booking[Constants.START] >= startInit) {
        const duration = StringUtils.getNumber(booking[Constants.SERVICE][Constants.DURATION]) / 60 // in hour  
        let next = booking[Constants.START] + duration
        qStart = next
      }
      for (let i in extra) {
        const item1 = extra[i]
        if (item1[Constants.ID] != id && item1[Constants.EMPLOYEE][Constants.ID] == employee[Constants.ID]
          && item1[Constants.START] >= startInit) {
          const duration = StringUtils.getNumber(item1[Constants.SERVICE][Constants.DURATION]) / 60 // in hour  
          let next = item1[Constants.START] + duration
          qStart = next
        }
      }
      const available = checkAvailableTime(id, date, qStart, serviceDuration, employee)
      if (available) {
        employee[Constants.START] = available[Constants.START]
        list.push(employee)
      }
    }
    setItems(list)
    if (list.length == 0) {
      showToast(props, TOAST_ERROR, Strings.noAvailableProvider)
    }
  }

  const onEmployeeClick = (item) => {
    const booking = Storage.getJson(Constants.BOOKING)
    const id = state[Constants.ID]
    const extra = booking[Constants.ITEMS] || []
    const index = extra.findIndex(value => value[Constants.ID] == id)
    if (index >= 0) {
      extra[index][Constants.START] = item[Constants.START]
      extra[index][Constants.EMPLOYEE] = item
      extra[index][Constants.EMPLOYEE_AUTO] = null
    } else {
      booking[Constants.START] = item[Constants.START]
      booking[Constants.EMPLOYEE] = item
      booking[Constants.EMPLOYEE_AUTO] = null
      Storage.setItemToMemory(Constants.CACHE_BOOK_TIME, booking[Constants.DATE] + booking[Constants.START])
    }
    Storage.setJson(Constants.BOOKING, booking)
    Navigator.back()
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()
  const imageSize = isMobile ? 80 : 100

  return <ITableContainer component={IPaper} className={classes.listSection}>
    <ITable>
      <ITableBody>
        {items.map(item => (
          <ITableRow key={item[Constants.ID]}>
            {isMobile && <ITableCell>
              <div style={{ display: 'flex' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.TITLE]} />
                <div style={{ marginLeft: 10 }}>
                  <div>
                    <b>{item[Constants.FIRST_NAME]} {item[Constants.LAST_NAME]}</b>
                  </div>
                  <div>
                    <b>Start Time: </b>{StringUtils.getHourFromNumber(item[Constants.START])}
                  </div>
                </div>
              </div>
              <AddToVisitButton onClick={() => onEmployeeClick(item)}>{Strings.addToVisit}</AddToVisitButton>
            </ITableCell>}
            {!isMobile && <>
              <ITableCell size='small' width={150}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.TITLE]} />
              </ITableCell>
              <ITableCell>
                <div>
                  <b>{item[Constants.FIRST_NAME]} {item[Constants.LAST_NAME]}</b>
                </div>
                <div>
                  <b>Start Time: </b>{StringUtils.getHourFromNumber(item[Constants.START])}
                </div>
              </ITableCell>
              <ITableCell align='right'>
                <AddToVisitButton onClick={() => onEmployeeClick(item)}>{Strings.addToVisit}</AddToVisitButton>
              </ITableCell></>}
          </ITableRow>
        ))}
      </ITableBody>
    </ITable>
  </ITableContainer>
}

function ViewEmployees() {
  Logger.log(Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_EMPLOYEES)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <div className={classes.root}>
    <ITypography variant='h5'>{Strings.employees}</ITypography>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </div>
}

export default ViewEmployees