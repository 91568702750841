import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import * as Navigator from '../../../common/Navigator'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITypography from '../../controls/ITypography'
import ICard from '../../controls/ICard'
import ISwitch from '../../controls/ISwitch'
import ICardContent from '../../controls/ICardContent'
import IDivider from '../../controls/IDivider'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.formMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const [remind, setRemind] = useState(false)

  useEffect(() => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_USER_SETTINGS + '/' + user[Constants.ID])
    ref.once('value').then(response => {
      setLoading(props, false)
      const val = response.val()
      if (val) {        
        // right now, notify is applied for all types of notification, but keep separately in case we need later
        setRemind(val[Constants.REMIND_24_BEFORE] || val[Constants.REMIND_CANCEL])
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const postSave = async () => {
    setLoading(props, true)
    try {
      const ref = firebase.databaseRef(Constants.FB_USER_SETTINGS + '/' + user[Constants.ID])
      const updates = {}
      updates[Constants.REMIND_24_BEFORE] = remind
      updates[Constants.REMIND_CANCEL] = remind
      await ref.update(updates)
      setLoading(props, false)
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
    } catch (err) {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    }
  }

  const classes = useStyles()

  return <div>
    <ITypography className={classes.heading}>{Strings.settings}</ITypography>
    <ITypography variant='h6' style={{ marginTop: Styles.margin2Br }}>{Strings.textReminders}</ITypography>
    <IDivider />
    <div style={{ display: 'flex', alignItems: 'center', marginTop: Styles.margin1Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.appointmentTextReminders}</ITypography>
      <ISwitch checked={remind} onChange={event => setRemind(event.target.checked)} />
    </div>
    <div style={{ textAlign: 'center', marginTop: Styles.margin4Br }}>
      <IButton onClick={postSave}>{Strings.save}</IButton>
    </div>
  </div>
}

function CustomerSettings() {
  Logger.log(Constants.PAGES_CUSTOMER_SETTINGS)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default CustomerSettings