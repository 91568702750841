import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import ColorPicker from '../../widgets/ColorPicker'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  },
  colorPicker: {
    marginTop: 20
  },
  divColor: {
    width: 100,
    height: 40,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const refTimer = useRef()
  const id = Navigator.getQuery().get(Constants.ID)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [duration, setDuration] = useState('')
  const [color, setColor] = useState('')

  useEffect(() => {    
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_SYSTEM_SERVICES + '/' + id)
    ref.once('value').then(response => {
      setLoading(props, false)
      const val = response.val()
      if (val) {
        setTitle(val[Constants.TITLE])
        setDescription(val[Constants.DESCRIPTION])
        setPrice(val[Constants.PRICE])
        setDuration(val[Constants.DURATION])
        setColor(val[Constants.COLOR])
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })

    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const postSave = () => {
    if (StringUtils.stringEmpty(title) || StringUtils.stringEmpty(description)
      || StringUtils.stringEmpty(price) || StringUtils.stringEmpty(duration)
      || !StringUtils.isNumber(price) || !StringUtils.isNumber(duration)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_SYSTEM_SERVICES + '/' + id)
    const updates = {}
    updates[Constants.TITLE] = title
    updates[Constants.DESCRIPTION] = description
    updates[Constants.PRICE] = price
    updates[Constants.DURATION] = duration
    updates[Constants.COLOR] = color
    updates[Constants.DATE_MODIFIED] = Date.now()
    ref.update(updates).then(() => {
      setLoading(props, false)
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
      refTimer.current = setTimeout(() => {
        Navigator.back()
      }, 1000)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const openColorPicker = (value) => {
    if (props.setColorOpen) {
      props.setColorOpen(value, color)
    }
  }

  props.onColorClosed = (color) => {
    setColor(color)
  }

  const classes = useStyles()

  return <IFormControl className={classes.root}>
    <ITypography className={classes.heading}>{Strings.editService}</ITypography>
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin2Br }} label={Strings.title} value={title} onChange={(event) => { setTitle(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.description} value={description} onChange={(event) => { setDescription(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.price} helperText={Strings.priceHint} value={price} onChange={(event) => { setPrice(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.duration} helperText={Strings.durationHint} value={duration} onChange={(event) => { setDuration(event.target.value) }} />
    <div className={`${classes.textField} ${classes.colorPicker}`} style={{ marginTop: Styles.margin1Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.color}</ITypography>
      <div className={classes.divColor} style={{ backgroundColor: color }} onClick={() => openColorPicker(true)} />
      <ColorPicker props={props} />
    </div>
    <div style={{ marginTop: Styles.margin2Br }}>
      <IButton onClick={() => Navigator.back()}>{Strings.cancel}</IButton>
      <IButton className={classes.rightButton} onClick={postSave}>{Strings.save}</IButton>
    </div>
  </IFormControl>
}

function EditService() {
  Logger.log(Constants.PAGES_SYSTEM_EDIT_SERVICE)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default EditService