import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router'
import { useGlobal } from '../utils/useGlobal'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import HeaderView from '../pages/widgets/HeaderView'
import DrawerView from '../pages/widgets/DrawerView'
import history from '../utils/history'
import * as Logger from './Logger'
import * as Constants from './Constants'
import * as Navigator from './Navigator'
import * as Storage from './Storage'
import Home from '../pages/components/Home'
import Login from '../pages/components/Login'
import ChangePassword from '../pages/components/ChangePassword'
import ForgotPassword from '../pages/components/ForgotPassword'
import PrivacyPolicy from '../pages/components/PrivacyPolicy'
import RegisterCustomer from '../pages/components/register/RegisterCustomer'
import RegisterAdmin from '../pages/components/register/RegisterAdmin'
import CreateStore from '../pages/components/register/CreateStore'
import Payment from '../pages/components/payment/Payment'
import SelectPlanPage from '../pages/components/payment/SelectPlanPage'
import EditSubscription from '../pages/components/payment/EditSubscription'
import GuestAppointmentAboutStore from '../pages/components/guest/AboutStore'
import GuestAppointmentViewServices from '../pages/components/guest/ViewServices'
import CustomerProfile from '../pages/components/customer/Profile'
import CustomerDashboard from '../pages/components/customer/Dashboard'
import CustomerSettings from '../pages/components/customer/Settings'
import CustomerAppointments from '../pages/components/customer/Appointments'
import CustomerAppointment from '../pages/components/customer/Appointment'
import CustomerMultiAppointmentViewStores from '../pages/components/customer/multiAppointment/ViewStores'
import CustomerMultiAppointmentViewServices from '../pages/components/customer/multiAppointment/ViewServices'
import CustomerMultiAppointmentScheduleNow from '../pages/components/customer/multiAppointment/ScheduleNow'
import CustomerMultiAppointmentAboutStore from '../pages/components/customer/multiAppointment/AboutStore'
import CustomerAddAppointmentViewAppointment from '../pages/components/customer/addAppointment/Appointment'
import CustomerAddAppointmentViewStores from '../pages/components/customer/addAppointment/ViewStores'
import CustomerAddAppointmentViewServices from '../pages/components/customer/addAppointment/ViewServices'
import CustomerAddAppointmentViewEmployees from '../pages/components/customer/addAppointment/ViewEmployees'
import CustomerAddAppointmentViewTimes from '../pages/components/customer/addAppointment/ViewTimes'
import CustomerAddAppointmentScheduleNow from '../pages/components/customer/addAppointment/ScheduleNow'
import CustomerEditAppointmentViewServices from '../pages/components/customer/editAppointment/ViewServices'
import CustomerEditAppointmentScheduleNow from '../pages/components/customer/editAppointment/ScheduleNow'
import EmployeeProfile from '../pages/components/employee/Profile'
import EmployeeDashboard from '../pages/components/employee/Dashboard'
import EmployeeAppointments from '../pages/components/employee/Appointments'
import EmployeeAppointment from '../pages/components/employee/Appointment'
import EmployeeAddAppointmentViewAppointment from '../pages/components/employee/addAppointment/Appointment'
import EmployeeAddAppointmentViewCustomers from '../pages/components/employee/addAppointment/ViewCustomers'
import EmployeeAddAppointmentViewServices from '../pages/components/employee/addAppointment/ViewServices'
import EmployeeAddAppointmentViewTimes from '../pages/components/employee/addAppointment/ViewTimes'
import EmployeeAddAppointmentScheduleNow from '../pages/components/employee/addAppointment/ScheduleNow'
import EmployeeEditAppointmentViewServices from '../pages/components/employee/editAppointment/ViewServices'
import EmployeeEditAppointmentScheduleNow from '../pages/components/employee/editAppointment/ScheduleNow'
import EmployeeInviteAppointmentInviteCustomer from '../pages/components/employee/inviteAppointment/InviteCustomer'
import StoreViewCustomers from '../pages/components/store/ViewCustomers'
import StoreViewCustomerProfile from '../pages/components/store/customer/Profile'
import StoreViewCustomerHistory from '../pages/components/store/customer/History'
import StoreViewEmployees from '../pages/components/store/ViewEmployees'
import StoreAddEmployee from '../pages/components/store/AddEmployee'
import StoreEditEmployee from '../pages/components/store/EditEmployee'
import StoreEditService from '../pages/components/store/EditService'
import StoreViewServices from '../pages/components/store/ViewServices'
import StoreAddCategory from '../pages/components/store/AddCategory'
import StoreEditCategory from '../pages/components/store/EditCategory'
import StoreViewCategories from '../pages/components/store/ViewCategories'
import StoreAddService from '../pages/components/store/AddService'
import StoreView from '../pages/components/store/ViewStore'
import StoreEdit from '../pages/components/store/EditStore'
import StoreProfile from '../pages/components/store/Profile'
import StoreDashboard from '../pages/components/store/Dashboard'
import StoreAppointments from '../pages/components/store/Appointments'
import StoreAppointmentsSingle from '../pages/components/store/AppointmentsSingle'
import StoreAppointment from '../pages/components/store/Appointment'
import StoreScheduleEmployee from '../pages/components/store/ScheduleEmployee'
import StoreAddAppointmentViewAppointment from '../pages/components/store/addAppointment/Appointment'
import StoreAddAppointmentViewCustomers from '../pages/components/store/addAppointment/ViewCustomers'
import StoreAddAppointmentViewServices from '../pages/components/store/addAppointment/ViewServices'
import StoreAddAppointmentViewEmployees from '../pages/components/store/addAppointment/ViewEmployees'
import StoreAddAppointmentViewTimes from '../pages/components/store/addAppointment/ViewTimes'
import StoreAddAppointmentScheduleNow from '../pages/components/store/addAppointment/ScheduleNow'
import StoreEditAppointmentViewServices from '../pages/components/store/editAppointment/ViewServices'
import StoreEditAppointmentScheduleNow from '../pages/components/store/editAppointment/ScheduleNow'
import StoreInviteAppointmentInviteCustomer from '../pages/components/store/inviteAppointment/InviteCustomer'
import StoreSettings from '../pages/components/store/Settings'
import SystemDashboard from '../pages/components/system/Dashboard'
import SystemAddService from '../pages/components/system/AddService'
import SystemEditService from '../pages/components/system/EditService'
import SystemViewServices from '../pages/components/system/ViewServices'
import SystemAddCategory from '../pages/components/system/AddCategory'
import SystemEditCategory from '../pages/components/system/EditCategory'
import SystemViewCategories from '../pages/components/system/ViewCategories'
import SystemViewStores from '../pages/components/system/ViewStores'
import SystemAddStore from '../pages/components/system/AddStore'
import SystemEditStore from '../pages/components/system/EditStore'
import SystemAddEmployee from '../pages/components/system/AddEmployee'
import SystemEditEmployee from '../pages/components/system/EditEmployee'
import SystemViewEmployees from '../pages/components/system/ViewEmployees'
import SystemViewStoreServices from '../pages/components/system/ViewStoreServices'
import SystemAddStoreService from '../pages/components/system/AddStoreService'
import SystemEditStoreService from '../pages/components/system/EditStoreService'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        minHeight: '100%'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: 80
    },
}))

const HomeRoute = ({ component: Component, ...rest }) => {
    const { user } = useGlobal()

    const getUserRoute = (props) => {
        if (props.location) {
            const query = Navigator.getQuery(props.location.search)
            const redirect = query.get(Constants.REDIRECT)
            if (redirect) {
                query.delete(Constants.REDIRECT)
                return <Redirect to={Navigator.buildLink(props, redirect, query.toString(), props.location.state)} />
            }
        }
        if (user[Constants.IS_SYSTEM]) {
            return <Redirect to={Navigator.buildLink(props, Constants.PAGES_SYSTEM_DASHBOARD, null, props.location.state)} />
        } else if (user[Constants.ROLE] == Constants.ROLE_CUSTOMER) {
            return <Redirect to={Navigator.buildLink(props, Constants.PAGES_CUSTOMER_DASHBOARD, null, props.location.state)} />
        } else if (user[Constants.ROLE] == Constants.ROLE_ADMIN_STORE) {
            return <Redirect to={Navigator.buildLink(props, Constants.PAGES_STORE_DASHBOARD, null, props.location.state)} />
        } else if (user[Constants.ROLE] == Constants.ROLE_EMPLOYEE_STORE) {
            return <Redirect to={Navigator.buildLink(props, Constants.PAGES_EMPLOYEE_DASHBOARD, null, props.location.state)} />
        }
        return <Component {...props} />
    }

    return (
        <Route {...rest} render={props => user ? getUserRoute(props) : (<Component {...props} />)} />
    )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { user } = useGlobal()

    return (
        <Route {...rest} render={props => user ? (<Component {...props} />) :
            (<Redirect to={Navigator.buildLink(props, Constants.PAGES_LOGIN, null, props.location.state)} />)
        } />
    )
}

const CustomerRoute = ({ component: Component, path, ...rest }) => {
    const { user } = useGlobal()

    if (path === Constants.PAGES_CUSTOMER_APPOINTMENTS) {
        // show choose stores page first if have not choosed before
        const data = Storage.getJson(Constants.BOOKING)
        const store = data[Constants.STORE]
        if (!store) {
            let extra = {}
            extra[Constants.REDIRECT] = Constants.PAGES_CUSTOMER_APPOINTMENTS
            return (
                <Route {...rest} render={props => user && user[Constants.ROLE] == Constants.ROLE_CUSTOMER ? (<CustomerAddAppointmentViewStores {...props} extra={extra} />) :
                    (<Redirect to={Navigator.buildLink(props, Constants.PAGES_LOGIN, null, props.location.state)} />)
                } />
            )
        }
    }

    return (
        <Route {...rest} render={props => user && user[Constants.ROLE] == Constants.ROLE_CUSTOMER ? (<Component {...props} />) :
            (<Redirect to={Navigator.buildLink(props, Constants.PAGES_LOGIN, null, props.location.state)} />)
        } />
    )
}

const EmployeeRoute = ({ component: Component, ...rest }) => {
    const { user } = useGlobal()

    return (
        <Route {...rest} render={props => user && user[Constants.ROLE] == Constants.ROLE_EMPLOYEE_STORE ? (<Component {...props} />) :
            (<Redirect to={Navigator.buildLink(props, Constants.PAGES_LOGIN, null, props.location.state)} />)
        } />
    )
}

const StoreRoute = ({ component: Component, path, ...rest }) => {
    const { user } = useGlobal()

    // If user does not have subscription then only allow to access create store page
    if (user && user[Constants.ROLE] == Constants.ROLE_ADMIN_STORE) {
        if (path !== Constants.PAGES_CREATE_STORE) {
            const hasSubscription = user[Constants.HAS_SUBSCRIPTION] || false
            if (!hasSubscription) {
                const getUserRoute = (props) => {
                    if (props.location) {
                        const query = Navigator.getQuery(props.location.search)
                        const paying = query.get(Constants.PAYING)
                        if (paying) {                
                            return <StoreDashboard {...props} />
                        }
                    }        
                    return <SelectPlanPage {...props} />
                }
                return (
                    <Route {...rest} render={props => getUserRoute(props)} />
                )
            }
        }
    }

    return (
        <Route {...rest} render={props => user && user[Constants.ROLE] == Constants.ROLE_ADMIN_STORE ? (<Component {...props} />) :
            (<Redirect to={Navigator.buildLink(props, Constants.PAGES_LOGIN, null, props.location.state)} />)
        } />
    )
}

const SystemRoute = ({ component: Component, ...rest }) => {
    const { user } = useGlobal()

    return (
        <Route {...rest} render={props => user && user[Constants.IS_SYSTEM] ? (<Component {...props} />) :
            (<Redirect to={Navigator.buildLink(props, Constants.PAGES_LOGIN, null, props.location.state)} />)
        } />
    )
}

function Routes() {
    Logger.log('Routes')

    const classes = useStyles()
    const theme = useTheme()

    let props = { classes, theme }
    props.onHeaderViewCompleted = (func) => {
        props.handleDrawerClose = func
    }
    props.onLeftDrawerViewCompleted = (func) => {
        props.handleDrawerOpen = func
    }
    props.onHandleDrawerOpen = () => {
        if (props.handleDrawerOpen) {
            props.handleDrawerOpen()
        }
    }
    props.onHandleDrawerClose = () => {
        if (props.handleDrawerClose) {
            props.handleDrawerClose()
        }
    }

    return (<Router history={history} >
        <CssBaseline />
        <HeaderView props={props} />
        <DrawerView props={props} />
        <main className={classes.content}>
            <Switch>
                <HomeRoute exact path={Constants.PAGES_HOME} component={Home} />
                <Route path={Constants.PAGES_LOGIN} component={Login} />
                <Route path={Constants.PAGES_REGISTER_CUSTOMER} component={RegisterCustomer} />
                <Route path={Constants.PAGES_REGISTER_ADMIN} component={RegisterAdmin} />
                <Route path={Constants.PAGES_FORGOT_PASSWORD} component={ForgotPassword} />
                <Route path={Constants.PAGES_PRIVACY_POLICY} component={PrivacyPolicy} />
                <Route path={Constants.PAGES_GUEST_APPOINTMENT_ABOUT_STORE} component={GuestAppointmentAboutStore} />
                <Route path={Constants.PAGES_GUEST_APPOINTMENT_VIEW_SERVICES} component={GuestAppointmentViewServices} />
                <PrivateRoute path={Constants.PAGES_CHANGE_PASSWORD} component={ChangePassword} />
                <PrivateRoute path={Constants.PAGES_SUBSCRIPTION} component={EditSubscription} />
                <PrivateRoute path={Constants.PAGES_SELECT_PLAN} component={SelectPlanPage} />
                <PrivateRoute path={Constants.PAGES_PAYMENT} component={Payment} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_PROFILE} component={CustomerProfile} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_DASHBOARD} component={CustomerDashboard} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_SETTINGS} component={CustomerSettings} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_APPOINTMENTS} component={CustomerAppointments} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_APPOINTMENT} component={CustomerAppointment} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_VIEW_STORES} component={CustomerMultiAppointmentViewStores} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_VIEW_SERVICES} component={CustomerMultiAppointmentViewServices} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_SCHEDULE_NOW} component={CustomerMultiAppointmentScheduleNow} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_ABOUT_STORE} component={CustomerMultiAppointmentAboutStore} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_APPOINTMENT} component={CustomerAddAppointmentViewAppointment} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_STORES} component={CustomerAddAppointmentViewStores} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_SERVICES} component={CustomerAddAppointmentViewServices} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_EMPLOYEES} component={CustomerAddAppointmentViewEmployees} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_TIMES} component={CustomerAddAppointmentViewTimes} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_SCHEDULE_NOW} component={CustomerAddAppointmentScheduleNow} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_EDIT_APPOINTMENT_VIEW_SERVICES} component={CustomerEditAppointmentViewServices} />
                <CustomerRoute path={Constants.PAGES_CUSTOMER_EDIT_APPOINTMENT_SCHEDULE_NOW} component={CustomerEditAppointmentScheduleNow} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_PROFILE} component={EmployeeProfile} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_DASHBOARD} component={EmployeeDashboard} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_APPOINTMENTS} component={EmployeeAppointments} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_APPOINTMENT} component={EmployeeAppointment} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_APPOINTMENT} component={EmployeeAddAppointmentViewAppointment} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_CUSTOMERS} component={EmployeeAddAppointmentViewCustomers} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_SERVICES} component={EmployeeAddAppointmentViewServices} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_TIMES} component={EmployeeAddAppointmentViewTimes} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_ADD_APPOINTMENT_SCHEDULE_NOW} component={EmployeeAddAppointmentScheduleNow} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_EDIT_APPOINTMENT_VIEW_SERVICES} component={EmployeeEditAppointmentViewServices} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_EDIT_APPOINTMENT_SCHEDULE_NOW} component={EmployeeEditAppointmentScheduleNow} />
                <EmployeeRoute path={Constants.PAGES_EMPLOYEE_INVITE_APPOINTMENT_INVITE_CUSTOMER} component={EmployeeInviteAppointmentInviteCustomer} />
                <StoreRoute path={Constants.PAGES_CREATE_STORE} component={CreateStore} />
                <StoreRoute path={Constants.PAGES_STORE_ADD_SERVICE} component={StoreAddService} />
                <StoreRoute path={Constants.PAGES_STORE_EDIT_SERVICE} component={StoreEditService} />
                <StoreRoute path={Constants.PAGES_STORE_VIEW_SERVICES} component={StoreViewServices} />
                <StoreRoute path={Constants.PAGES_STORE_ADD_CATEGORY} component={StoreAddCategory} />
                <StoreRoute path={Constants.PAGES_STORE_EDIT_CATEGORY} component={StoreEditCategory} />
                <StoreRoute path={Constants.PAGES_STORE_VIEW_CATEGORIES} component={StoreViewCategories} />
                <StoreRoute path={Constants.PAGES_STORE_VIEW_EMPLOYEES} component={StoreViewEmployees} />
                <StoreRoute path={Constants.PAGES_STORE_ADD_EMPLOYEE} component={StoreAddEmployee} />
                <StoreRoute path={Constants.PAGES_STORE_EDIT_EMPLOYEE} component={StoreEditEmployee} />
                <StoreRoute path={Constants.PAGES_STORE_VIEW_CUSTOMERS} component={StoreViewCustomers} />
                <StoreRoute path={Constants.PAGES_STORE_VIEW_CUSTOMER_PROFILE} component={StoreViewCustomerProfile} />
                <StoreRoute path={Constants.PAGES_STORE_VIEW_CUSTOMER_HISTORY} component={StoreViewCustomerHistory} />
                <StoreRoute path={Constants.PAGES_STORE_VIEW} component={StoreView} />
                <StoreRoute path={Constants.PAGES_STORE_EDIT} component={StoreEdit} />
                <StoreRoute path={Constants.PAGES_STORE_PROFILE} component={StoreProfile} />
                <StoreRoute path={Constants.PAGES_STORE_DASHBOARD} component={StoreDashboard} />
                <StoreRoute path={Constants.PAGES_STORE_APPOINTMENTS} component={StoreAppointments} />
                <StoreRoute path={Constants.PAGES_STORE_APPOINTMENTS_SINGLE} component={StoreAppointmentsSingle} />
                <StoreRoute path={Constants.PAGES_STORE_APPOINTMENT} component={StoreAppointment} />
                <StoreRoute path={Constants.PAGES_STORE_EMPLOYEE_SCHEDULE} component={StoreScheduleEmployee} />
                <StoreRoute path={Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_APPOINTMENT} component={StoreAddAppointmentViewAppointment} />
                <StoreRoute path={Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_CUSTOMERS} component={StoreAddAppointmentViewCustomers} />
                <StoreRoute path={Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_SERVICES} component={StoreAddAppointmentViewServices} />
                <StoreRoute path={Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_EMPLOYEES} component={StoreAddAppointmentViewEmployees} />
                <StoreRoute path={Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_TIMES} component={StoreAddAppointmentViewTimes} />
                <StoreRoute path={Constants.PAGES_STORE_ADD_APPOINTMENT_SCHEDULE_NOW} component={StoreAddAppointmentScheduleNow} />
                <StoreRoute path={Constants.PAGES_STORE_EDIT_APPOINTMENT_VIEW_SERVICES} component={StoreEditAppointmentViewServices} />
                <StoreRoute path={Constants.PAGES_STORE_EDIT_APPOINTMENT_SCHEDULE_NOW} component={StoreEditAppointmentScheduleNow} />
                <StoreRoute path={Constants.PAGES_STORE_INVITE_APPOINTMENT_INVITE_CUSTOMER} component={StoreInviteAppointmentInviteCustomer} />
                <StoreRoute path={Constants.PAGES_STORE_SETTINGS} component={StoreSettings} />                
                <SystemRoute path={Constants.PAGES_SYSTEM_DASHBOARD} component={SystemDashboard} />
                <SystemRoute path={Constants.PAGES_SYSTEM_ADD_SERVICE} component={SystemAddService} />
                <SystemRoute path={Constants.PAGES_SYSTEM_EDIT_SERVICE} component={SystemEditService} />
                <SystemRoute path={Constants.PAGES_SYSTEM_VIEW_SERVICES} component={SystemViewServices} />
                <SystemRoute path={Constants.PAGES_SYSTEM_ADD_CATEGORY} component={SystemAddCategory} />
                <SystemRoute path={Constants.PAGES_SYSTEM_EDIT_CATEGORY} component={SystemEditCategory} />
                <SystemRoute path={Constants.PAGES_SYSTEM_VIEW_CATEGORIES} component={SystemViewCategories} />
                <SystemRoute path={Constants.PAGES_SYSTEM_VIEW_STORES} component={SystemViewStores} />
                <SystemRoute path={Constants.PAGES_SYSTEM_ADD_STORE} component={SystemAddStore} />
                <SystemRoute path={Constants.PAGES_SYSTEM_EDIT_STORE} component={SystemEditStore} />
                <SystemRoute path={Constants.PAGES_SYSTEM_ADD_EMPLOYEE} component={SystemAddEmployee} />
                <SystemRoute path={Constants.PAGES_SYSTEM_EDIT_EMPLOYEE} component={SystemEditEmployee} />
                <SystemRoute path={Constants.PAGES_SYSTEM_VIEW_EMPLOYEES} component={SystemViewEmployees} />
                <SystemRoute path={Constants.PAGES_SYSTEM_VIEW_STORE_SERVICES} component={SystemViewStoreServices} />
                <SystemRoute path={Constants.PAGES_SYSTEM_ADD_STORE_SERVICE} component={SystemAddStoreService} />
                <SystemRoute path={Constants.PAGES_SYSTEM_EDIT_STORE_SERVICE} component={SystemEditStoreService} />
            </Switch>
        </main>
    </Router>
    )
}

export default Routes