import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as DateUtils from '../../../common/DateUtils'
import * as Constants from '../../../common/Constants'
import * as Navigator from '../../../common/Navigator'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITypography from '../../controls/ITypography'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogTitle from '../../controls/IDialogTitle'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableHead from '../../controls/ITableHead'
import ITableRow from '../../controls/ITableRow'
import IPaper from '../../controls/IPaper'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth,
  },
  content: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  workingTime: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  }
}))


function ContentView({ props }) {
  Logger.log('ContentView')

  const { user } = useGlobal()
  const query = Navigator.getQuery()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const id = query.get(Constants.ID)
  const refAvailableTimes = useRef({})
  const refWorkingOffline = useRef([])
  const [events, setEvents] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogDelete, setOpenDialogDelete] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [selectedItem, setSelectedItem] = useState({ start: new Date(), end: new Date() })
  const [workingPeriod, setWorkingPeriod] = useState([])

  useEffect(() => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + id)
    ref.once('value').then(response => {
      setLoading(props, false)
      const val = response.val()
      if (val) {
        setFirstName(val[Constants.FIRST_NAME])
        setLastName(val[Constants.LAST_NAME])
        setWorkingPeriod(val[Constants.WORKING_PERIOD] || [])

        // load avaialble times
        const workingPeriods = val[Constants.WORKING_PERIOD] || []
        for (let i in workingPeriods) {
          const workingPeriod = workingPeriods[i]
          if (workingPeriod[Constants.IS_CHECK]) {
            const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
            const dateStr = DateUtils.getDayByNumber(dateNum)
            refAvailableTimes.current[dateStr] = workingPeriod[Constants.HOURS] || []
          }
        }
        // populate offline times
        refWorkingOffline.current = val[Constants.WORKING_OFFLINE] || []
        populateEvents()
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const populateEvents = () => {
    const list = []
    const workingOffline = refWorkingOffline.current
    for (let i in workingOffline) {
      list.push({
        title: Constants.STATUS_OFFLINE,
        start: moment(workingOffline[i][Constants.START]).toDate(),
        end: moment(workingOffline[i][Constants.END]).toDate(),
      })
    }
    setEvents(list)
  }

  const onSelectEvent = (event) => {
    const item = {}
    item[Constants.START] = event[Constants.START]
    item[Constants.END] = event[Constants.END]
    setSelectedItem(item)
    setOpenDialogDelete(true)
  }

  const onSelectSlot = (event) => {
    const mDateStart = moment(event.start)
    const dateStr = mDateStart.format('dddd').toUpperCase()
    const hours = refAvailableTimes.current[dateStr]
    var isValid = false
    if (hours) {
      const mDateEnd = moment(event.end)
      const numberStart = DateUtils.getNumberFromHourMinute({ hour: mDateStart.hour(), minute: mDateStart.minute() })
      const numberEnd = DateUtils.getNumberFromHourMinute({ hour: mDateEnd.hour(), minute: mDateEnd.minute() })
      for (let i in hours) {
        const hour = hours[i]
        if (numberStart >= hour[Constants.START] && numberEnd <= hour[Constants.END]) {
          isValid = true
          break
        }
      }
    }
    if (!isValid) {
      showToast(props, TOAST_ERROR, Strings.errorNotWorkingPeriod)
      return
    }
    const item = {}
    item[Constants.START] = event[Constants.START]
    item[Constants.END] = event[Constants.END]
    setSelectedItem(item)
    setOpenDialog(true)
  }

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {
      setLoading(props, true)
      const updates = {}
      const offline = {}
      offline[Constants.START] = moment(selectedItem[Constants.START]).toISOString()
      offline[Constants.END] = moment(selectedItem[Constants.END]).toISOString()
      const workingOffline = [...refWorkingOffline.current, offline]
      updates[Constants.WORKING_OFFLINE] = workingOffline
      updates[Constants.DATE_MODIFIED] = Date.now()
      const ref = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + id)
      ref.update(updates).then(() => {
        setLoading(props, false)
        refWorkingOffline.current.push(offline)
        populateEvents()
        showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const handleCloseDialogDelete = (agree) => {
    setOpenDialogDelete(false)
    if (agree) {
      setLoading(props, true)
      const updates = {}
      const start = moment(selectedItem[Constants.START])
      const end = moment(selectedItem[Constants.END])
      const workingOffline = [...refWorkingOffline.current]
      for (let i in workingOffline) {
        if (moment(workingOffline[i][Constants.START]).isSame(start) && moment(workingOffline[i][Constants.END]).isSame(end)) {
          workingOffline.splice(i, 1)
          break
        }
      }
      updates[Constants.WORKING_OFFLINE] = workingOffline
      updates[Constants.DATE_MODIFIED] = Date.now()
      const ref = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + id)
      ref.update(updates).then(() => {
        setLoading(props, false)
        refWorkingOffline.current = workingOffline
        populateEvents()
        showToast(props, TOAST_SUCCESS, Strings.deletedSussessfully)
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const slotPropGetter = (date) => {
    const mDateStart = moment(date)
    const dateStr = mDateStart.format('dddd').toUpperCase()    
    const hours = refAvailableTimes.current[dateStr]
    if (hours) {      
      const mDateEnd = moment(date).add(Constants.CALENDAR_TIME_STEP, 'minute')
      const numberStart = DateUtils.getNumberFromHourMinute({ hour: mDateStart.hour(), minute: mDateStart.minute() })
      const numberEnd = DateUtils.getNumberFromHourMinute({ hour: mDateEnd.hour(), minute: mDateEnd.minute() })
      for (let i in hours) {
        const hour = hours[i]
        if (numberStart >= hour[Constants.START] && numberEnd <= hour[Constants.END]) {
          return { className: 'rbc-slot-available' }
        }
      }
    }
    return {}
  }

  const localizer = momentLocalizer(moment)
  const classes = useStyles()

  return <div className={classes.root}>
    <ITypography className={classes.heading}>{Strings.offlineEmployee}</ITypography>
    <div className={classes.content}>
      <div style={{ width: Styles.workingTimeWidth }}>
        <ITableContainer component={IPaper}>
          <ITable>
            <ITableHead>
              <ITableRow>
                <ITableCell>
                  <ITypography variant='body1' >{Strings.name}: <b>{firstName + ' ' + lastName}</b></ITypography>
                  <ITypography variant='body1'>{Strings.workingTime}:</ITypography>
                </ITableCell>
              </ITableRow>
            </ITableHead>
            <ITableBody>
              {workingPeriod.map((row, index) => (
                <ITableRow key={row[Constants.DATE_OF_WEEK]} >
                  <ITableCell component='th' scope='row'>
                    <b>{DateUtils.getDayByNumber(row[Constants.DATE_OF_WEEK]) + ': '}</b>
                    {row[Constants.IS_CHECK] && row[Constants.HOURS] && row[Constants.HOURS].map((hour, i) => (
                      <ITypography key={i} variant='body2' variantMapping={{ body2: 'span' }}> {i != 0 ? ', ' : ''}{StringUtils.getHourFromNumber(hour[Constants.START])} - {StringUtils.getHourFromNumber(hour[Constants.END])}</ITypography>
                    ))}
                    {!row[Constants.IS_CHECK] && <ITypography variant='body2' variantMapping={{ body2: 'span' }}>{Strings.offline}</ITypography>}
                  </ITableCell>
                </ITableRow>
              ))}
            </ITableBody>
          </ITable>
        </ITableContainer>
      </div>
      <div className={classes.workingTime}>
        <ICard>
          <ICardContent>
            <Calendar
              formats={Constants.APPOINTMENT_FORMATS}
              events={events}
              min={moment().hour(Constants.CALENDAR_START_HOUR).minute(Constants.CALENDAR_START_MINUTE).toDate()}
              max={moment().hour(Constants.CALENDAR_END_HOUR).minute(Constants.CALENDAR_END_MINUTE).toDate()}
              selectable
              localizer={localizer}
              defaultView='week'
              timeslots={Constants.CALENDAR_TIME_SLOTS}
              step={Constants.CALENDAR_TIME_STEP}
              views={{ week: true }}
              style={{ height: 750 }}
              slotPropGetter={slotPropGetter}
              longPressThreshold={Styles.longPressThreshold}
              onSelectEvent={(event) => onSelectEvent(event)}
              onSelectSlot={(event) => onSelectSlot(event)}
            />
          </ICardContent>
        </ICard>
        <IDialog open={openDialog} maxWidth='sm' fullWidth={true} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title'>
          <IDialogTitle id='alert-dialog-title'>{Strings.areYourSureOffline}</IDialogTitle>
          <IDialogContent>
            <ITypography variant='body1'>{Strings.employee}: <b>{firstName} &#32;{lastName}</b></ITypography>
            <ITypography variant='body1'>{Strings.from}: <b>{moment(selectedItem[Constants.START]).format('ddd, MMMM Do YYYY, h:mm a')}</b></ITypography>
            <ITypography variant='body1'>{Strings.to}: <b>{moment(selectedItem[Constants.END]).format('ddd, MMMM Do YYYY, h:mm a')}</b></ITypography>
          </IDialogContent>
          <IDialogActions>
            <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
            <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
          </IDialogActions>
        </IDialog>
        <IDialog open={openDialogDelete} maxWidth='sm' fullWidth={true} onClose={() => handleCloseDialogDelete(false)} aria-labelledby='alert-dialog-title'>
          <IDialogTitle id='alert-dialog-title'>{Strings.delete}</IDialogTitle>
          <IDialogContent>
            <ITypography variant='body1'>{Strings.employee}: <b>{firstName} &#32;{lastName}</b></ITypography>
            <ITypography variant='body1'>{Strings.from}: <b>{moment(selectedItem[Constants.START]).format('ddd, MMMM Do YYYY, h:mm a')}</b></ITypography>
            <ITypography variant='body1'>{Strings.to}: <b>{moment(selectedItem[Constants.END]).format('ddd, MMMM Do YYYY, h:mm a')}</b></ITypography>
          </IDialogContent>
          <IDialogActions>
            <IButton onClick={() => handleCloseDialogDelete(false)}>{Strings.cancel}</IButton>
            <IButton onClick={() => handleCloseDialogDelete(true)} autoFocus>{Strings.ok}</IButton>
          </IDialogActions>
        </IDialog>
      </div>
    </div>
  </div>
}

function ScheduleEmployee() {
  Logger.log(Constants.PAGES_STORE_EMPLOYEE_SCHEDULE)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default ScheduleEmployee