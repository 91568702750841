import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import * as Navigator from '../../common/Navigator'
import * as Logger from '../../common/Logger'
import * as Constants from '../../common/Constants'
import Strings from '../../common/Strings'
import Styles from '../../common/Styles'
import { useGlobal } from '../../utils/useGlobal'
import IDrawer from '../controls/IDrawer'
import IList from '../controls/IList'
import IDivider from '../controls/IDivider'
import IIconButton from '../controls/IIconButton'
import IListItem from '../controls/IListItem'
import IListItemIcon from '../controls/IListItemIcon'
import IListItemText from '../controls/IListItemText'
import ITypography from '../controls/ITypography'
import HomeIcon from '@material-ui/icons/Home'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import AddIcon from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/List'
import StoreIcon from '@material-ui/icons/Store'
import InfoIcon from '@material-ui/icons/Info'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import PaymentIcon from '@material-ui/icons/Payment'
import SettingsIcon from '@material-ui/icons/Settings'
import PeopleIcon from '@material-ui/icons/People'

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 280
    },
    toolbar: {
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        backgroundColor: Styles.appBarColor,
        ...theme.mixins.toolbar,
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0
    },
    menuHeader: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16
    },
    menuHeaderHide: {
        display: 'none'
    },
    closeIcon: {
        marginLeft: 'auto',
        marginRight: 0
    }
}))

const DrawerView = ({ props }) => {
    Logger.log('DrawerView')

    const { user } = useGlobal()
    const [open, setOpen] = useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
        props.onHandleDrawerClose()
    }

    const getListItemIcon = (value) => {
        if (value == Constants.PAGES_LOGIN) {
            return <LockOpenIcon />
        } else if (value == Constants.PAGES_SYSTEM_ADD_SERVICE || value == Constants.PAGES_SYSTEM_ADD_STORE
            || value == Constants.PAGES_SYSTEM_ADD_CATEGORY
            || value == Constants.PAGES_STORE_ADD_EMPLOYEE || value == Constants.PAGES_STORE_ADD_SERVICE
            || value == Constants.PAGES_STORE_ADD_CATEGORY) {
            return <AddIcon />
        } else if (value == Constants.PAGES_SYSTEM_VIEW_SERVICES || value == Constants.PAGES_SYSTEM_VIEW_STORES
            || value == Constants.PAGES_SYSTEM_VIEW_CATEGORIES || value == Constants.PAGES_STORE_VIEW_EMPLOYEES
            || value == Constants.PAGES_STORE_VIEW_SERVICES || value == Constants.PAGES_STORE_VIEW_CATEGORIES) {
            return <ListIcon />
        } else if (value == Constants.PAGES_STORE_VIEW) {
            return <StoreIcon />
        } else if (value == Constants.PAGES_CUSTOMER_PROFILE || value == Constants.PAGES_EMPLOYEE_PROFILE
            || value == Constants.PAGES_STORE_PROFILE) {
            return <InfoIcon />
        } else if (value == Constants.PAGES_CUSTOMER_APPOINTMENTS || value == Constants.PAGES_EMPLOYEE_APPOINTMENTS
            || value == Constants.PAGES_STORE_APPOINTMENTS) {
            return <MenuBookIcon />
        } else if (value == Constants.PAGES_SUBSCRIPTION) {
            return <PaymentIcon />
        } else if (value == Constants.PAGES_CUSTOMER_SETTINGS || value == Constants.PAGES_STORE_SETTINGS) {
            return <SettingsIcon />
        } else if (value == Constants.PAGES_STORE_VIEW_CUSTOMERS) {
            return <PeopleIcon />
        }
        return <HomeIcon />
    }

    const handleListItemClick = (event, id) => {
        handleDrawerClose()
        Navigator.navigate(props, id)
    }

    props.onLeftDrawerViewCompleted(handleDrawerOpen)

    const classes = useStyles()

    let options = []
    if (user) {
        if (user[Constants.IS_SYSTEM]) {
            options.push({ id: Constants.PAGES_SYSTEM_DASHBOARD, text: Strings.dashboard, type: 'item' })
            options.push({ id: 'divider' + options.length, text: '', type: 'divider' })
            options.push({ id: 'header' + options.length, text: Strings.system, type: 'header' })
            options.push({ id: Constants.PAGES_SYSTEM_VIEW_CATEGORIES, text: Strings.categories, type: 'item' })
            options.push({ id: Constants.PAGES_SYSTEM_ADD_CATEGORY, text: Strings.addCategory, type: 'item' })   
            options.push({ id: 'divider' + options.length, text: '', type: 'divider' })
            options.push({ id: 'header' + options.length, text: Strings.stores, type: 'header' })
            options.push({ id: Constants.PAGES_SYSTEM_VIEW_STORES, text: Strings.stores, type: 'item' })
        } else if (user[Constants.ROLE] == Constants.ROLE_ADMIN_STORE) {
            options.push({ id: Constants.PAGES_STORE_DASHBOARD, text: Strings.dashboard, type: 'item' })
            options.push({ id: Constants.PAGES_STORE_VIEW, text: Strings.store, type: 'item' })
            options.push({ id: Constants.PAGES_STORE_APPOINTMENTS, text: Strings.appointments, type: 'item' })
            options.push({ id: Constants.PAGES_STORE_VIEW_CUSTOMERS, text: Strings.customers, type: 'item' })
            options.push({ id: Constants.PAGES_SUBSCRIPTION, text: Strings.subscription, type: 'item' }) 
            options.push({ id: 'divider' + options.length, text: '', type: 'divider' })
            options.push({ id: 'header' + options.length, text: Strings.employees, type: 'header' })
            options.push({ id: Constants.PAGES_STORE_VIEW_EMPLOYEES, text: Strings.employees, type: 'item' })
            options.push({ id: Constants.PAGES_STORE_ADD_EMPLOYEE, text: Strings.addEmployee, type: 'item' }) 
            options.push({ id: 'divider' + options.length, text: '', type: 'divider' })
            options.push({ id: 'header' + options.length, text: Strings.services, type: 'header' })
            options.push({ id: Constants.PAGES_STORE_VIEW_SERVICES, text: Strings.services, type: 'item' })
            options.push({ id: Constants.PAGES_STORE_ADD_SERVICE, text: Strings.addService, type: 'item' })
            options.push({ id: 'divider' + options.length, text: '', type: 'divider' })
            options.push({ id: 'header' + options.length, text: Strings.categories, type: 'header' })
            options.push({ id: Constants.PAGES_STORE_VIEW_CATEGORIES, text: Strings.categories, type: 'item' })
            options.push({ id: Constants.PAGES_STORE_ADD_CATEGORY, text: Strings.addCategory, type: 'item' })      
            options.push({ id: 'header' + options.length, text: Strings.settings, type: 'header' })
            options.push({ id: Constants.PAGES_STORE_SETTINGS, text: Strings.textReminders, type: 'item' })      
        } else if (user[Constants.ROLE] == Constants.ROLE_EMPLOYEE_STORE) {
            options.push({ id: Constants.PAGES_EMPLOYEE_DASHBOARD, text: Strings.dashboard, type: 'item' })
            options.push({ id: Constants.PAGES_EMPLOYEE_APPOINTMENTS, text: Strings.appointments, type: 'item' })
        } else if (user[Constants.ROLE] == Constants.ROLE_CUSTOMER) {
            options.push({ id: Constants.PAGES_CUSTOMER_DASHBOARD, text: Strings.dashboard, type: 'item' })
            options.push({ id: Constants.PAGES_CUSTOMER_APPOINTMENTS, text: Strings.appointments, type: 'item' })
            options.push({ id: Constants.PAGES_CUSTOMER_SETTINGS, text: Strings.settings, type: 'item' })
        }
    } else {
        options.push({ id: Constants.PAGES_HOME, text: Strings.home, type: 'item' })
        options.push({ id: Constants.PAGES_LOGIN, text: Strings.signIn, type: 'item' })
    }

    return (<IDrawer open={open} classes={{ paper: classes.drawer }} onClose={handleDrawerClose} >
        <div className={classes.toolbar}>
            <IIconButton className={classes.closeIcon} onClick={handleDrawerClose}>
               <ChevronLeftIcon style={{ color: 'white' }}/>
            </IIconButton>
        </div>
        <IDivider />
        <IList className={classes.list}>
            {options.map((item, index) => (
                <div key={item.id}>
                    {item.type == 'item' && <IListItem button key={item.id} onClick={event => handleListItemClick(event, item.id)}>
                        <IListItemIcon>{getListItemIcon(item.id)}</IListItemIcon>
                        <IListItemText primary={item.text} />
                    </IListItem>}
                    {item.type == 'divider' && <IDivider key={item.id} />}
                    {item.type == 'header' && <ITypography key={item.id} className={clsx({ [classes.menuHeader]: open, [classes.menuHeaderHide]: !open, })}>{item.text}</ITypography>}
                </div>
            ))}
        </IList>

    </IDrawer>)
}

export default DrawerView