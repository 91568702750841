import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Navigator from '../../../../common/Navigator'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as ScreenUtils from '../../../../common/ScreenUtils'
import * as DataUtils from '../../../../common/DataUtils'
import * as Constants from '../../../../common/Constants'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import IIconButton from '../../../controls/IIconButton'
import ITypography from '../../../controls/ITypography'
import ICard from '../../../controls/ICard'
import ICardContent from '../../../controls/ICardContent'
import ITable from '../../../controls/ITable'
import ITableBody from '../../../controls/ITableBody'
import ITableCell from '../../../controls/ITableCell'
import ITableContainer from '../../../controls/ITableContainer'
import ITableHead from '../../../controls/ITableHead'
import ITableFooter from '../../../controls/ITableFooter'
import ITableRow from '../../../controls/ITableRow'
import IPaper from '../../../controls/IPaper'
import ITextField from '../../../controls/ITextField'
import SearchIcon from '@material-ui/icons/Search'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import IImage from '../../../controls/IImage'
import DeleteIcon from '@material-ui/icons/Delete'

const AddToVisitButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const SearchButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  content: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  storeInfo: {
    marginLeft: 20
  },
  infoSection: {
    width: '100%',
  },
  infoMain: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  listSection: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
  },
  addedServices: {
    width: Styles.addedServicesWidth,
    marginTop: 10,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 10,
    }
  },
  searchBox: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
}))

function InfoView({ props }) {
  Logger.log('InfoView')

  const { settings } = useGlobal()
  const state = Navigator.getState()
  const user = state[Constants.USER] || {}

  useEffect(() => {

  }, [])

  const classes = useStyles()

  return <div className={classes.infoSection}>
    <ICard className={classes.infoMain}>
      <ICardContent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={user[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={user[Constants.FIRST_NAME]} />
          <div style={{ marginLeft: 10 }}>
            <ITypography variant='h5'><b>{user[Constants.FIRST_NAME]} {user[Constants.LAST_NAME]}</b></ITypography>
            <ITypography variant='body1'>{user[Constants.EMAIL]}</ITypography>
            <ITypography variant='body1'>{user[Constants.PHONE]}</ITypography>
          </div>
        </div>
      </ICardContent>
    </ICard>
  </div>
}

function AddedServicesView({ props }) {
  Logger.log('AddedServicesView')

  const { user, settings } = useGlobal()
  const query = Navigator.getQuery()
  const path = query.get(Constants.PATH)
  const state = Navigator.getState()
  const refItems = useRef(state.items || [])
  const [items, setItems] = useState(state.items || [])

  useEffect(() => {

  }, [])

  const onDeleteItem = (item) => {
    var index = -1
    for (let i in refItems.current) {
      if (item[Constants.ID] == refItems.current[i][Constants.ID]) {
        index = i
        break
      }
    }
    if (index != -1) {
      refItems.current.splice(index, 1)
      setItems([...refItems.current])
    }
  }

  const onChooseDateTime = () => {
    if (items.length == 0) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
      return
    }
    const data = { items, ...state }
    Navigator.navigate(props, Constants.PAGES_EMPLOYEE_EDIT_APPOINTMENT_SCHEDULE_NOW, `${Constants.PATH}=${path}`, data)
  }

  props.onEmployeeClick = (service, employee) => {
    // only allow 1 service at a time
    if (refItems.current.length > 0) {
      refItems.current = []
    }
    const item = {}
    item[Constants.ID] = StringUtils.getUniqueID()
    item[Constants.SERVICE] = service
    item[Constants.EMPLOYEE] = employee
    refItems.current.push(item)
    setItems([...refItems.current])
  }

  const classes = useStyles()

  return <div className={classes.addedServices}>
    <ITableContainer component={IPaper}>
      <ITable>
        <ITableHead>
          <ITableRow>
            <ITableCell><ITypography variant='body1'><b>{Strings.yourVisit}</b></ITypography></ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          {items.map(item => (
            <ITableRow key={item[Constants.ID]}>
              <ITableCell size='small' style={{ maxWidth: 100 }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 5, backgroundColor: item[Constants.SERVICE][Constants.COLOR] }} />
                  <div style={{ marginLeft: 10, width: '100%' }}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <ITypography variant='body2'><b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                        <ITypography variant='body2'>{item[Constants.SERVICE][Constants.DURATION]} {Strings.mins} ${item[Constants.SERVICE][Constants.DURATION]}</ITypography>
                      </div>
                      <IIconButton style={{ marginLeft: 'auto' }} aria-label={Strings.delete} onClick={() => onDeleteItem(item)}><DeleteIcon /></IIconButton>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize, marginRight: 10 }} src={item[Constants.EMPLOYEE][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''}
                        alt={item[Constants.EMPLOYEE][Constants.FIRST_NAME]} />
                      <ITypography variant='body2'>{item[Constants.EMPLOYEE][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE][Constants.LAST_NAME]}</ITypography>
                    </div>
                  </div>
                </div>
              </ITableCell>
            </ITableRow>
          ))}
        </ITableBody>
        <ITableFooter>
          <ITableRow>
            <ITableCell style={{ textAlign: 'center' }}>
              <AddToVisitButton style={{ marginTop: 10, marginBottom: 10 }} onClick={() => onChooseDateTime()}>{Strings.chooseDateAndTime}</AddToVisitButton>
            </ITableCell>
          </ITableRow>
        </ITableFooter>
      </ITable>
    </ITableContainer>
  </div>
}

function SearchView({ props }) {
  Logger.log('SearchView')

  const [keyword, setKeyword] = useState('')

  useEffect(() => {

  }, [])

  const onSearchClick = (e) => {
    e.preventDefault()
    if (props.onSearchClick) {
      props.onSearchClick(keyword.toLowerCase())
    }
  }

  const classes = useStyles()

  return <form><div className={classes.searchBox}>
    <ITextField className={classes.textField} inputProps={{ style: { fontSize: Styles.searchFontSize } }} label={Strings.searchServiceHint} value={keyword} onChange={(event) => { setKeyword(event.target.value) }} />
    <SearchButton type='submit' startIcon={<SearchIcon />} onClick={(e) => onSearchClick(e)}>{Strings.search}</SearchButton>
  </div></form>
}

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.STORE_ID]
  const userId = user[Constants.USER_ID]
  const refEmployee = useRef()
  const refItems = useRef([])
  const [items, setItems] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(props, true)
    try {
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_EMPLOYEE_VIEW_SERVICES)
      const response = await httpsCallable({ userId, storeId })
      if (response.data.items) {
        const list = [...response.data.items]
        DataUtils.sortBy(list, Constants.ORDER)
        refItems.current = list
        setItems(list)
      }
      const employeeCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_EMPLOYEE_GET_INFO)
      const employeeResponse = await employeeCallable({ userId, storeId })
      if (employeeResponse.data.user) {
        refEmployee.current = employeeResponse.data.user
      }
      setLoading(props, false)
    } catch (err) {
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    }
  }

  const onVisitClick = (item) => {
    if (refEmployee.current && props.onEmployeeClick) {
      props.onEmployeeClick(item, refEmployee.current)
    }
  }

  props.onSearchClick = (keyword) => {
    const list = []
    const isSearch = keyword && keyword != ''
    for (let i in refItems.current) {
      const item = refItems.current[i]
      if (isSearch) {
        let text = `${item[Constants.TITLE]}`.toLowerCase()
        if (text.indexOf(keyword) != -1) {
          list.push(item)
        }
      } else {
        list.push(item)
      }
    }
    DataUtils.sortBy(list, Constants.ORDER)
    setItems(list)
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return <div className={classes.listSection}>
    <div style={{ width: '100%' }}>
      <ITableContainer component={IPaper}>
        <ITable>
          <ITableBody>
            {items.map(item => (
              <ITableRow key={item[Constants.ID]}>
                {isMobile && <ITableCell>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 5, height: Styles.imageSize, backgroundColor: item[Constants.COLOR] }} />
                    <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.TITLE]} />
                    <div style={{ marginLeft: 10 }}>
                      <ITypography variant='body1'><b>{item[Constants.TITLE]}</b></ITypography>
                      <ITypography variant='body1'>{item[Constants.DURATION]} {Strings.mins}</ITypography>
                      <ITypography variant='body1'><b>${item[Constants.PRICE]}</b></ITypography>
                    </div>
                  </div>
                  <AddToVisitButton onClick={() => onVisitClick(item)}>{Strings.addToVisit}</AddToVisitButton>
                </ITableCell>}
                {!isMobile && <>
                  <ITableCell size='small' style={{ maxWidth: 100 }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: 5, height: Styles.imageSize, backgroundColor: item[Constants.COLOR] }} />
                      <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.TITLE]} />
                    </div>
                  </ITableCell>
                  <ITableCell>
                    <ITypography variant='body1'><b>{item[Constants.TITLE]}</b></ITypography>
                    <ITypography variant='body1'>{item[Constants.DURATION]} {Strings.mins}</ITypography>
                  </ITableCell>
                  <ITableCell><ITypography variant='body1'><b>${item[Constants.PRICE]}</b></ITypography></ITableCell>
                  <ITableCell align='right'>
                    <AddToVisitButton onClick={() => onVisitClick(item)}>{Strings.addToVisit}</AddToVisitButton>
                  </ITableCell></>}
              </ITableRow>
            ))}
          </ITableBody>
        </ITable>
      </ITableContainer>
    </div>
  </div>
}

function ViewServices() {
  Logger.log(Constants.PAGES_EMPLOYEE_EDIT_APPOINTMENT_VIEW_SERVICES)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <div className={classes.root}>
    <InfoView props={props} />
    <div className={classes.content}>
      <div style={{ width: '100%' }}>
        <SearchView props={props} />
        <ContentView props={props} />
      </div>
      <AddedServicesView props={props} />
    </div>
    <ProgressBar props={props} />
    <ToastView props={props} />
  </div>
}

export default ViewServices