import * as Constants from './Constants'

export function getUniqueID() {
    var dt = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return uuid
}

export function stringText(value) {
    return value || ''
}

export function stringEmpty(value) {
    if (value) {
        return value === ''
    }
    return true
}

export function stringContains(value, search) {
    if (value && search) {
        return value.includes(search)
    }
    return false
}

export function stringFormatFileName(value) {
    if (value) {
        return value.replace(/[\s-_]/g, '').replace(/\.jpg$/g, '.jpeg')
    }
    return ''
}

export function isNetworkUrl(value) {
    if (value) {
        return value.startsWith('https://') || value.startsWith('http://')
    }
    return false
}

export function validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true
    }
    return false
}

export function validatePhone(email) {
    // format: XXX-XXX-XXXX or XXX.XXX.XXXX or XXX XXX XXXX
    if (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(email)) {
        return true
    }
    return false
}

export function formatDateYYYYMMDD(date) {
    var month = '' + (date.getMonth() + 1)
    var day = '' + date.getDate()
    var year = date.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}

export function timeSince(unix) {
    var diff = Math.abs((Date.now() - unix) / 1000)

    if (isNaN(diff) || diff < 0) {
        return ''
    }

    if (diff < Constants.TIME.second * 2) {
        // within 2 seconds
        return 'right now'
    }
    if (diff < Constants.TIME.minute) {
        return Math.floor(diff) + ' seconds ago'
    }
    if (diff < Constants.TIME.minute * 2) {
        return 'about 1 minute ago'
    }
    if (diff < Constants.TIME.hour) {
        return Math.floor(diff / Constants.TIME.minute) + ' minutes ago'
    }
    if (diff < Constants.TIME.hour * 2) {
        return 'about 1 hour ago'
    }
    if (diff < Constants.TIME.day) {
        return Math.floor(diff / Constants.TIME.hour) + ' hours ago'
    }
    if (diff > Constants.TIME.day && diff < Constants.TIME.day * 2) {
        return 'yesterday'
    }
    if (diff < Constants.TIME.day * 365) {
        return Math.floor(diff / Constants.TIME.day) + ' days ago'
    } else {
        return 'over a year ago'
    }
}

export function formatBytes(bytes, decimals) {
    if (bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function calcDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1)
    var dLon = toRad(lon2 - lon1)
    var dLat1 = toRad(lat1)
    var dLat2 = toRad(lat2)

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(dLat1) * Math.cos(dLat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d
}

// Converts numeric degrees to radians
export function toRad(value) {
    return value * Math.PI / 180
}

export function getPath(path) {
    if (stringEmpty(path) || isNetworkUrl(path)) {
        return path
    } else {
        return path.startsWith('file://') ? path : ('file://' + path)
    }
}

export function getNumber(value) {
    if (value) {
        return Number(value)
    }
    return 0
}

export function isNumber(value) {    
    return value !== null && !isNaN(value)
}

export function getFileExt(path) {
    if (!stringEmpty(path)) {
        const lastIndex = path.lastIndexOf('.')
        if (lastIndex != -1) {
            return path.substring(lastIndex + 1)
        }
    }
    return ''
}

export function arrayToString(array) {
    return array.join(', ')
}

export function isValidUploadImage(name) {
    var isValid = false
    for (let i in Constants.SUPPORT_IMAGE_TYPES) {
        const type = Constants.SUPPORT_IMAGE_TYPES[i]
        if ((name.lastIndexOf(type) + type.length) == name.length) {
            isValid = true
            break
        }
    }
    return isValid
}

export function formatMinutes(minutes) {
    if (minutes !== null && typeof minutes === 'number') {
        return minutes.toFixed(0)
    }
    return getNumber(minutes).toFixed(0)
}

// convert hour decimal to string, e.g: 8.5 -> 8:30 am, 14.25 -> 2:15 pm
export function getHourFromNumber(hour) {
    const lowest = Math.floor(hour)
    const sub = hour - lowest
    const minutes = Math.round(sub * 60)
    var str = ''
    if (lowest < 12) {
        str = `${lowest}`
        if (minutes > 0) {
            if (minutes < 10) {
                str += `:0${minutes}`
            } else {
                str += `:${minutes}`
            }
        } else {
            str += ':00'
        }
        str += ' AM'
    } else {
        if (lowest == 12) {
            str = '12'
            if (minutes > 0) {
                if (minutes < 10) {
                    str += `:0${minutes}`
                } else {
                    str += `:${minutes}`
                }
            } else {
                str += ':00'
            }
            str += ' PM'
        } else if (lowest == 24) {
            str = '0'
            if (minutes > 0) {
                if (minutes < 10) {
                    str += `:0${minutes}`
                } else {
                    str += `:${minutes}`
                }
            } else {
                str += ':00'
            }
            str += ' AM'
        } else {
            str = `${lowest - 12}`
            if (minutes > 0) {
                if (minutes < 10) {
                    str += `:0${minutes}`
                } else {
                    str += `:${minutes}`
                }
            } else {
                str += ':00'
            }
            str += ' PM'
        }
    }
    return str
}

export function checkUndefined(value) {
    return value || null
}

export function trimPhone(value) {
    if (value) {
        return value.replace(/[\s\+-]/g, '')
    }
    return value
}
