import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Navigator from '../../../../common/Navigator'
import * as Logger from '../../../../common/Logger'
import * as Constants from '../../../../common/Constants'
import * as DataUtils from '../../../../common/DataUtils'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import ITypography from '../../../controls/ITypography'
import ICard from '../../../controls/ICard'
import ICardContent from '../../../controls/ICardContent'
import ITable from '../../../controls/ITable'
import ITableBody from '../../../controls/ITableBody'
import ITableCell from '../../../controls/ITableCell'
import ITableContainer from '../../../controls/ITableContainer'
import ITableHead from '../../../controls/ITableHead'
import ITableRow from '../../../controls/ITableRow'
import IPaper from '../../../controls/IPaper'
import IDivider from '../../../controls/IDivider'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import IImage from '../../../controls/IImage'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import GoogleMapReact from 'google-map-react'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  content: {
    maxWidth: Styles.contentMaxWidth,
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  storeInfo: {
    marginLeft: 20
  },
  infoSection: {
    width: '100%',
  },
  infoContent: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '0px !important',
    paddingLeft: '0px !important'
  },
  listSection: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
  },
  scheduleView: {
    maxWidth: 500,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 10,
      width: 500,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  },
  mapBox: {
    marginTop: 10,
    width: '100%',
    height: Styles.mapHeight
  },
  tab: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function InfoView({ props }) {
  Logger.log('InfoView')

  const { settings } = useGlobal()
  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID) 
  const [store, setStore] = useState({})

  useEffect(() => {

  }, [])

  const onServicesClick = () => {
    const state = Navigator.getState()
    const items = state.items
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_VIEW_SERVICES, query.toString(), { items })
  }

  props.onLoadedStoreInfoView = (value) => {
    setStore(value)    
  }

  const classes = useStyles()

  return <ICard className={classes.infoSection}>
    <ICardContent className={classes.infoContent}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={store[Constants.IMAGE] || settings[Constants.DEFAULT_STORE_IMAGE] || ''} alt={store[Constants.NAME]} />
        <ITypography variant='h5' style={{ marginLeft: 10 }}><b>{store[Constants.NAME]}</b></ITypography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <div style={{ textAlign: 'center' }}>
          <ITypography variant='body1' style={{ marginLeft: 10 }}>{Strings.about}</ITypography>
          <div style={{ width: 100, height: 5, backgroundColor: Styles.primaryBgColor, marginTop: 10 }} />
        </div>
        <div onClick={() => onServicesClick()} className={classes.tab} style={{ marginLeft: 10, textAlign: 'center' }}>
          <ITypography variant='body1' style={{ marginLeft: 10 }}>{Strings.services}</ITypography>
          <div style={{ width: 100, height: 5, backgroundColor: 'white', marginTop: 10 }} />
        </div>
      </div>
    </ICardContent>
  </ICard>
}

function MapView({ props }) {
  Logger.log('MapView')

  const refMaps = useRef()
  const refMap = useRef()
  const refLocationLat = useRef(Constants.STORE_DEFAULT_LOCATION_LAT)
  const refLocationLng = useRef(Constants.STORE_DEFAULT_LOCATION_LNG)
  const refLocationZoom = useRef(Constants.STORE_DEFAULT_ZOOM)
  const refLocationTitle = useRef()
  const refMarkers = useRef([])
  const [store, setStore] = useState({})

  useEffect(() => {

  }, [])

  props.onLoadedStoreMapView = (value) => {
    setStore(value)
    refLocationLat.current = value[Constants.LOCATION_LAT]
    refLocationLng.current = value[Constants.LOCATION_LNG]
    refLocationZoom.current = value[Constants.LOCATION_ZOOM]
    refLocationTitle.current = value[Constants.ADDRESS]
    renderMarkers()
  }

  const clearMarkers = () => {
    for (let i in refMarkers.current) {
      refMarkers.current[i].setMap(null)
    }
  }

  const renderMarkers = () => {
    if (refMap.current && refMaps.current) {
      clearMarkers()
      let marker = new refMaps.current.Marker({
        position: { lat: refLocationLat.current, lng: refLocationLng.current },
        draggable: false,
      })
      let infowindow = new refMaps.current.InfoWindow({
        content: refLocationTitle.current
      })
      marker.addListener('click', function() {
        infowindow.open(refMap.current, marker)
      })
      marker.setMap(refMap.current)
      const center = new refMaps.current.LatLng(refLocationLat.current, refLocationLng.current)
      refMap.current.setCenter(center)
      refMap.current.setZoom(refLocationZoom.current)
      refMarkers.current.push(marker)
    }
  }

  const classes = useStyles()

  return <ICard className={classes.listSection}>
    <ICardContent style={{ width: '100%' }}>
      <div>
        <ITypography variant='body1'><b>{Strings.location}</b></ITypography>
      </div>
      <div className={classes.mapBox}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: Constants.GOOGLE_MAP_KEY }}
          defaultCenter={{ lat: Constants.STORE_DEFAULT_LOCATION_LAT, lng: Constants.STORE_DEFAULT_LOCATION_LNG }}
          defaultZoom={Constants.STORE_DEFAULT_ZOOM}
          onGoogleApiLoaded={({ map, maps }) => {
            refMap.current = map
            refMaps.current = maps
            renderMarkers()
          }}
          yesIWantToUseGoogleMapApiInternals
        />
      </div>
    </ICardContent>
  </ICard>
}

function EmployeesView({ props }) {
  Logger.log('EmployeesView')

  const { settings } = useGlobal()
  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID)
  const [items, setItems] = useState([])

  useEffect(() => {

  }, [])

  props.onLoadedEmployeesView = (list) => {
    setItems(list)
  }

  const classes = useStyles()

  return <ITableContainer component={IPaper} className={classes.listSection}>
    <ITable>
      <ITableHead>
        <ITableRow>
          <ITableCell><ITypography variant='body1'><b>{Strings.staff}</b></ITypography></ITableCell>
        </ITableRow>
      </ITableHead>
      <ITableBody>
        {items.map(item => (
          <ITableRow key={item[Constants.ID]}>
            <ITableCell style={{ display: 'flex', alignItems: 'center' }}>
              <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.TITLE]} />
              <div style={{ marginLeft: 10 }}>
                <ITypography variant='body2'><b>{item[Constants.FIRST_NAME]} {item[Constants.LAST_NAME]}</b></ITypography>
                <ITypography variant='body2'>{item[Constants.DESCRIPTION]}</ITypography>
              </div>
            </ITableCell>
          </ITableRow>
        ))}
      </ITableBody>
    </ITable>
  </ITableContainer>
}

function ScheduleView({ props }) {
  Logger.log('ScheduleView')

  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID)
  const [store, setStore] = useState({})

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(props, true)
    try {
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_STORE)
      const storeResponse = await httpsCallable({ userId, storeId })
      let orders = []
      if (storeResponse.data.store) {
        const value = storeResponse.data.store
        orders = value[Constants.EMPLOYEE_ORDERS] || []
        setStore(value)
        if (props.onLoadedStoreInfoView) {
          props.onLoadedStoreInfoView(value)
        }
        if (props.onLoadedStoreContentView) {
          props.onLoadedStoreContentView(value)
        }
        if (props.onLoadedStoreMapView) {
          props.onLoadedStoreMapView(value)
        }
      }
      const employeesCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_EMPLOYEES)
      const employeesResponse = await employeesCallable({ userId, storeId })
      if (employeesResponse.data.items) {
        if (props.onLoadedEmployeesView) {
          let list = employeesResponse.data.items
          //for (let i in list) {
            //const item = list[i]
            //item[Constants.ORDER] = orders.findIndex(value => value[Constants.RESOURCE_ID] == item[Constants.ID])
          //}
          DataUtils.sortBy(list, Constants.DATE_ADDED)
          props.onLoadedEmployeesView(list)
        }
      }
    } catch(err) {
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    }
    setLoading(props, false)
  }

  const onScheduleNowClick = () => {
    const state = Navigator.getState()
    const items = state.items
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_VIEW_SERVICES, query.toString(), { items })
  }

  const classes = useStyles()

  return <div className={classes.scheduleView}>
    <ICard>
      <ICardContent>
        <div style={{ textAlign: 'center' }}>
          <IButton onClick={() => onScheduleNowClick()}>{Strings.scheduleNow}</IButton>
        </div>
        <IDivider style={{ marginTop: 20 }} />
        <div style={{ display: 'flex', justifyItems: 'center', marginTop: 20 }}><LocationOnIcon /><ITypography variant='body1'>{Strings.address}</ITypography></div>
        <ITypography variant='body2' style={{ marginTop: Styles.margin1Br }}>{store[Constants.ADDRESS]}</ITypography>
        <div style={{ display: 'flex', justifyItems: 'center', marginTop: Styles.margin1Br }}><PhoneIcon /><ITypography variant='body1'>{store[Constants.PHONE]}</ITypography></div>
        <div style={{ display: 'flex', justifyItems: 'center', marginTop: Styles.margin1Br }}><EmailIcon /><ITypography variant='body1'>{store[Constants.EMAIL]}</ITypography></div>
      </ICardContent>
    </ICard>
  </div>
}

function ContentView({ props }) {
  Logger.log('ContentView')

  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID)
  const [store, setStore] = useState({})

  useEffect(() => {

  }, [])

  props.onLoadedStoreContentView = (value) => {
    setStore(value)
  }

  const classes = useStyles()

  return <ICard>
    <ICardContent>
      <ITypography variant='body2'>{store[Constants.DESCRIPTION]}</ITypography>
    </ICardContent>
  </ICard>
}

function AboutStore() {
  Logger.log(Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_ABOUT_STORE)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <div className={classes.root}>
    <InfoView props={props} />
    <div className={classes.content}>
      <div style={{ width: '100%' }}>
        <ContentView props={props} />
        <EmployeesView props={props} />
        <MapView props={props} />
      </div>
      <ScheduleView props={props} />
    </div>
    <ProgressBar props={props} />
    <ToastView props={props} />
  </div>
}

export default AboutStore