import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as DateUtils from '../../../common/DateUtils'
import * as ScreenUtils from '../../../common/ScreenUtils'
import * as Constants from '../../../common/Constants'
import * as Navigator from '../../../common/Navigator'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import IIconButton from '../../controls/IIconButton'
import ITypography from '../../controls/ITypography'
import ISwitch from '../../controls/ISwitch'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import IImage from '../../controls/IImage'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogContentText from '../../controls/IDialogContentText'
import IDialogTitle from '../../controls/IDialogTitle'
import IDivider from '../../controls/IDivider'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, isLoading, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import moment from 'moment'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '95%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  text: {
    color: '#666666'
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  headerBox: {
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const query = Navigator.getQuery()
  const path = query.get(Constants.PATH)
  const { user, settings } = useGlobal()
  const userId = user[Constants.USER_ID]
  const storeId = user[Constants.STORE_ID]
  const refTimer = useRef()
  const [booking, setBooking] = useState({})
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [notify, setNotify] = useState(true)

  useEffect(() => {
    setLoading(props, true)
    const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_EMPLOYEE_VIEW_BOOKING)
    httpsCallable({ userId, storeId, path }).then(({ data }) => {
      setLoading(props, false)
      if (data.booking) {
        const val = data.booking
        setBooking(val)
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })

    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const getDateTimeText = () => {
    const dateStr = moment(booking[Constants.DATE]).format('dddd, MMMM Do YYYY')
    return dateStr + ', ' + StringUtils.getHourFromNumber(booking[Constants.START])
  }
  
  const getDurationText = () => {
    const minutes = DateUtils.getMinutesFromNumber(booking[Constants.END] - booking[Constants.START])
    return StringUtils.formatMinutes(minutes)
  }

  const onCancel = () => {
    setOpenCancelDialog(true)
  }

  const handleCancelDialog = (agree) => {
    setOpenCancelDialog(false)
    if (agree) {
      setLoading(props, true)
      const userId = booking[Constants.STORE][Constants.USER_ID]
      const storeId = booking[Constants.STORE][Constants.ID]
      const subject = 'Appointment was canceled'
      const message = `Appointment was canceled on ${moment().format('MMMM Do YYYY, h:mm a')}.<br/>
        Store: ${booking[Constants.STORE][Constants.NAME]}.<br/>  
        Service: ${booking[Constants.SERVICE][Constants.TITLE]}.<br/>
        Customer Name: ${booking[Constants.USER][Constants.FIRST_NAME]} ${booking[Constants.USER][Constants.LAST_NAME]}.<br/>
        Customer Email: ${booking[Constants.USER][Constants.EMAIL]}.<br/>
        Provider Name: ${booking[Constants.EMPLOYEE][Constants.FIRST_NAME]} ${booking[Constants.EMPLOYEE][Constants.LAST_NAME]}.<br/>
        Provider Email: ${booking[Constants.EMPLOYEE][Constants.EMAIL]}.
        `
      const smsPhone = Constants.SMS_DEFAULT_PHONE
      const smsText = `Appointment was canceled on ${moment().format('MMMM Do YYYY, h:mm a')}.
        Store: ${booking[Constants.STORE][Constants.NAME]}.
        Service: ${booking[Constants.SERVICE][Constants.TITLE]}.
        Customer: ${booking[Constants.USER][Constants.FIRST_NAME]} ${booking[Constants.USER][Constants.LAST_NAME]}.
        Provider: ${booking[Constants.EMPLOYEE][Constants.FIRST_NAME]} ${booking[Constants.EMPLOYEE][Constants.LAST_NAME]}.
        `
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_EMPLOYEE_CANCEL_BOOKING)
      httpsCallable({ userId, storeId, path, notify, subject, message, smsPhone, smsText }).then(({ data }) => {
        setLoading(props, false)
        if (data.success) {
          showToast(props, TOAST_SUCCESS, Strings.cancelSuccessfully)
          refTimer.current = setTimeout(() => {
            Navigator.back()
          }, 2000)
        } else {
          showToast(props, TOAST_ERROR, Strings.errorLoadingData)
        }
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const onEditService = async () => {
    const state = {}
    state[Constants.ID] = booking[Constants.ID]
    state[Constants.USER] = booking[Constants.USER]
    state[Constants.EMPLOYEE] = user
    state[Constants.DATE] = booking[Constants.DATE]
    state[Constants.START] = booking[Constants.START]
    Navigator.navigate(props, Constants.PAGES_EMPLOYEE_EDIT_APPOINTMENT_VIEW_SERVICES, `${Constants.PATH}=${path}`, state)
  }

  const onEditDate = async () => {
    const state = {}
    const item = {}
    item[Constants.ID] = StringUtils.getUniqueID()
    item[Constants.SERVICE] = booking[Constants.SERVICE]
    item[Constants.EMPLOYEE] = user
    state.items = [item]
    state[Constants.ID] = booking[Constants.ID]
    state[Constants.USER] = booking[Constants.USER]
    state[Constants.DATE] = booking[Constants.DATE]
    state[Constants.START] = booking[Constants.START]
    Navigator.navigate(props, Constants.PAGES_EMPLOYEE_EDIT_APPOINTMENT_SCHEDULE_NOW, `${Constants.PATH}=${path}`, state)
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return <div className={classes.root}>
    <ICard style={{ minHeight: 500 }}>
      <ICardContent>
        <div className={classes.headerBox}>
          <ITypography variant='h5' style={{ width: '100%' }}>{Strings.appointmentDetails}</ITypography>
          <div className={classes.addButton}>
            <AddButton style={{ marginLeft: 10 }} startIcon={<CancelIcon />} onClick={() => onCancel()}>{Strings.cancel}</AddButton>
          </div>
        </div>
        <div className={classes.content} style={{ marginTop: Styles.margin1Br }}>
          <div style={{ flex: 1 }}>
            <IDivider />
            {booking[Constants.USER] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.customer}:</b></ITypography>
              {isMobile && <>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.USER][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={booking[Constants.USER][Constants.FIRST_NAME]} />
                <ITypography className={classes.text} variant='body1'><b>{booking[Constants.USER][Constants.FIRST_NAME]} {booking[Constants.USER][Constants.LAST_NAME]}</b></ITypography>
                <ITypography className={classes.text} variant='body1'>{Strings.address}: <b>{booking[Constants.USER][Constants.ADDRESS]}</b></ITypography>
                <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{booking[Constants.USER][Constants.PHONE]}</b></ITypography>
                <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{booking[Constants.USER][Constants.EMAIL]}</b></ITypography>
              </>}
              {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.USER][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={booking[Constants.USER][Constants.FIRST_NAME]} />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.USER][Constants.FIRST_NAME]} {booking[Constants.USER][Constants.LAST_NAME]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.address}: <b>{booking[Constants.USER][Constants.ADDRESS]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{booking[Constants.USER][Constants.PHONE]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{booking[Constants.USER][Constants.EMAIL]}</b></ITypography>
                </div>
              </div>}
            </div>}
            <IDivider />
            {booking[Constants.SERVICE] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.service}:</b></ITypography>
              {isMobile && <div>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.SERVICE][Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={booking[Constants.SERVICE][Constants.TITLE]} />
                <ITypography className={classes.text} variant='body1'><b>{booking[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                <ITypography className={classes.text} variant='body1'>{Strings.price}: <b>${booking[Constants.SERVICE][Constants.PRICE]}</b></ITypography>
                <IIconButton onClick={() => onEditService()}><EditIcon /></IIconButton>
              </div>}
              {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.SERVICE][Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={booking[Constants.SERVICE][Constants.TITLE]} />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.price}: <b>${booking[Constants.SERVICE][Constants.PRICE]}</b></ITypography>
                </div>
                <div>
                  <IIconButton onClick={() => onEditService()}><EditIcon /></IIconButton>
                </div>
              </div>}
            </div>}
          </div>
          <div style={{ flex: 1 }}>
            <IDivider />
            {booking[Constants.DATE] && booking[Constants.START] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.dateAndTime}:</b></ITypography>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1' style={{ fontSize: 20 }}><b>{getDateTimeText()}</b></ITypography>
                </div>
                <div>
                  <IIconButton onClick={() => onEditDate()}><EditIcon /></IIconButton>
                </div>
              </div>
            </div>}
            <IDivider style={{ marginTop: Styles.margin1Br }} />
            {booking[Constants.SERVICE] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.duration}:</b></ITypography>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1' style={{ fontSize: 20 }}><b>{getDurationText()} {Strings.mins}</b></ITypography>
                </div>
              </div>
            </div>}
            <IDivider style={{ marginTop: Styles.margin1Br }} />
            <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.textReminders}:</b></ITypography>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
                <ITypography className={classes.text}>{Strings.muteAllNotifications}</ITypography>
                <ISwitch checked={notify} value={notify} onChange={event => setNotify(event.target.checked)} />
              </div>
            </div>
          </div>
        </div>
      </ICardContent>
    </ICard>
    <IDialog open={openCancelDialog} onClose={() => handleCancelDialog(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <IDialogTitle id='alert-dialog-title'>{Strings.cancelAppointment}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText id='alert-dialog-description'>{Strings.cancelAppointmentConfirm}</IDialogContentText>
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleCancelDialog(false)}>{Strings.cancel}</IButton>
        <IButton onClick={() => handleCancelDialog(true)} autoFocus>{Strings.ok}</IButton>
      </IDialogActions>
    </IDialog>
  </div>
}

function EmployeeAppointment() {
  Logger.log(Constants.PAGES_EMPLOYEE_APPOINTMENT)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default EmployeeAppointment