import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'
import SideCalendar from 'rc-calendar'
import React, { useEffect, useRef, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import * as Constants from '../../../common/Constants'
import * as DataUtils from '../../../common/DataUtils'
import * as DateUtils from '../../../common/DateUtils'
import * as Logger from '../../../common/Logger'
import * as Navigator from '../../../common/Navigator'
import * as Storage from '../../../common/Storage'
import Strings from '../../../common/Strings'
import * as StringUtils from '../../../common/StringUtils'
import Styles from '../../../common/Styles'
import { createPropsWithActions, setLoading } from '../../../common/ViewUtils'
import { initWorkingPeriodCustomer } from '../../../common/WorkingPeriod'
import * as firebase from '../../../utils/firebase'
import { useGlobal } from '../../../utils/useGlobal'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import IDialog from '../../controls/IDialog'
import IDialogContent from '../../controls/IDialogContent'
import IIconButton from '../../controls/IIconButton'
import ITypography from '../../controls/ITypography'
import ProgressBar from '../../widgets/ProgressBar'
import ToastView from '../../widgets/ToastView'
import { isMobile } from '../../../common/ScreenUtils'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  sideCalendar: {
    width: Styles.sideCalendar,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  },
  headerBox: {
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  addButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  overlapContainter: {
    marginTop: 20,
    marginBottom: 20
  },
  overlapItem: {
    border: 'solid 1px',
    padding: 10,
    borderRadius: 5,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  overlapClose: {
    position: 'absolute',
    top: 5,
    right: 5,
    width: 25,
    height: 25,
    border: 'solid 1px',
    borderRadius: 5,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function OverlapView({ props }) {
  Logger.log('OverlapView')

  const [items, setItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
  }

  const getTimeText = (item) => {
    const start = item.start
    const end = item.end
    const startStr = moment(start).format(Constants.FORMAT.TIME_24)
    const endStr = moment(end).format(Constants.FORMAT.TIME_24)
    return `${startStr} - ${endStr}`
  }

  const onItemClick = (item) => {
    const resource = item.resource
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_APPOINTMENT, `${Constants.PATH}=${resource.path}`)
  }

  props.onShowEvents = (list) => {
    setItems(list)
    setOpenDialog(true)
  }

  const classes = useStyles()

  return <IDialog open={openDialog} maxWidth='sm' fullWidth={true} onClose={() => handleCloseDialog(false)}>
    <IDialogContent>
      <div className={classes.overlapContainter}>
        {items.map(item => (
          <div className={classes.overlapItem} style={{ ...item.resource.style }} onClick={() => onItemClick(item)}>
            <ITypography variant='body1'>{getTimeText(item)}: {item.title}</ITypography>
          </div>
        ))}
      </div>
      <div className={classes.overlapClose} onClick={() => handleCloseDialog(false)}>X</div>
    </IDialogContent>
  </IDialog>
}

function ColumnCalendarView({ props }) {
  Logger.log('ColumnCalendarView')

  const { user, settings } = useGlobal()
  const refItems = useRef([])
  const refTotal = useRef({})
  const refEvents = useRef([])
  const refDate = useRef(moment())
  const [events, setEvents] = useState([])
  const [date, setDate] = useState(moment())
  const [total, setTotal] = useState(0)
  const [display, setDisplay] = useState('day')
  const refMinMax = useRef({
    startHr: Constants.CALENDAR_START_HOUR, startMin: Constants.CALENDAR_START_MINUTE,
    endHr: Constants.CALENDAR_END_HOUR, endMin: Constants.CALENDAR_END_MINUTE
  })

  const refStore = useRef();

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(props, true)

    // get store working time
    const data = Storage.getJson(Constants.BOOKING)
    refStore.current = data[Constants.STORE]
    if (refStore.current) {
      const store = Storage.getJson(Constants.CACHE_STORE_INFO, false)
      if (!store) {
        const userId = refStore.current[Constants.USER_ID]
        const storeId = refStore.current[Constants.ID]
        const storeCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_STORE)
        const storeResponse = await storeCallable({ userId, storeId })
        if (storeResponse.data && storeResponse.data.store) {
          refStore.current = storeResponse.data.store
        }
      }
      Storage.setItemToMemory(Constants.CACHE_STORE_INFO, refStore.current)
      populateMinMax()
    }

    const date = refDate.current
    const year = date.year()
    const month = date.month() + 1
    const ref = firebase.databaseRef(Constants.FB_USER_BOOKINGS + '/' + user[Constants.ID] + '/' + year + '/' + month)
    ref.once('value').then(response => {
      setLoading(props, false)
      refTotal.current = {}
      let list = []
      const val = response.val()
      if (val) {
        for (let i in val) { // day
          const item1 = val[i]
          for (let j in item1) { // id
            const item2 = item1[j]
            item2[Constants.YEAR] = year
            item2[Constants.MONTH] = month
            item2[Constants.DAY] = i
            list.push(item2)
            const key = `${year}-${month}-${i}`
            refTotal.current[key] = refTotal.current[key] ? refTotal.current[key] + 1 : 1
          }
        }
      }
      refItems.current = list
      filterData(refDate.current)
      Storage.setItemToMemory(Constants.CACHE_USER_BOOKINGS, refItems.current)

      if (props.onLoadedTotal) {
        props.onLoadedTotal(refTotal.current)
      }
      const key = `${refDate.current.year()}-${refDate.current.month() + 1}-${refDate.current.date()}`
      setTotal(refTotal.current[key] ?? 0)
    }).catch(err => {
      Logger.log(err.message)
      setLoading(props, false)
    })
  }

  const populateMinMax = () => {
    const date = refDate.current
    const now = moment()
    if (date.isBefore(now, 'day')) {
      refMinMax.current = {
        startHr: Constants.CALENDAR_START_HOUR, startMin: Constants.CALENDAR_START_MINUTE,
        endHr: Constants.CALENDAR_END_HOUR, endMin: Constants.CALENDAR_END_MINUTE
      }
      return
    }
    if (refStore.current) {
      const workingPeriods = refStore.current[Constants.WORKING_PERIOD] || []
      const day = date.format('dddd').toUpperCase()
      for (let i in workingPeriods) {
        const workingPeriod = workingPeriods[i]
        const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
        const dateStr = DateUtils.getDayByNumber(dateNum)
        if (day == dateStr) {
          const hours = workingPeriod[Constants.HOURS] || []
          if (hours.length > 0) {
            const hour = hours[0]
            const startHrMin = DateUtils.getHourMinuteFromNumber(hour[Constants.START])
            const endHrMin = DateUtils.getHourMinuteFromNumber(hour[Constants.END])
            refMinMax.current = {
              startHr: startHrMin.hour, startMin: Constants.CALENDAR_START_MINUTE,
              endHr: endHrMin.hour - 1, endMin: Constants.CALENDAR_END_MINUTE
            }
          }
          break
        }
      }
    }
  }

  const loadAvailableTimes = (date) => {
    let list = []
    const mobile = isMobile()
    // step 1: get all working hours in specific day, will be overrided by store working hours
    let workingPeriods = initWorkingPeriodCustomer()
    if (refStore.current) {
      workingPeriods = refStore.current[Constants.WORKING_PERIOD] || initWorkingPeriodCustomer()
    }
    const day = date.format('dddd').toUpperCase()
    for (let i in workingPeriods) {
      const workingPeriod = workingPeriods[i]
      const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
      const dateStr = DateUtils.getDayByNumber(dateNum)
      if (day == dateStr && workingPeriod[Constants.IS_CHECK]) {
        const hours = workingPeriod[Constants.HOURS] || []
        for (let j in hours) {
          const item = hours[j]
          list.push(item)
        }
        break
      }
    }
    if (list.length > 0) {
      // step 2: calculate available times for booking
      // using start and end of each pair, then plus with duration
      DataUtils.sortBy(list, Constants.START)
      var timesPair = [] // hold [pair]
      var pair = {} // hold {start, end}
      for (let i in list) {
        const item = list[i]
        if (i == 0) {
          pair[Constants.START] = item
          pair[Constants.END] = item
        } else {
          const previous = list[i - 1]
          if (item[Constants.START] == previous[Constants.END]) {
            pair[Constants.END] = item
          } else {
            timesPair.push(pair)
            pair = {}
            pair[Constants.START] = item
            pair[Constants.END] = item
          }
        }
      }
      timesPair.push(pair)
      const durationInMinutes = Constants.CALENDAR_TIME_STEP // step in calendar
      const duration = durationInMinutes / 60 // in hour      
      var availableTimes = []
      for (let i in timesPair) {
        const pair = timesPair[i]
        var start = pair[Constants.START][Constants.START]
        const end = pair[Constants.END][Constants.END]
        while (start + duration <= end) {
          var available = {}
          available[Constants.ID] = StringUtils.getUniqueID()
          available[Constants.DATE] = date
          available[Constants.START] = start
          available[Constants.END] = start + duration
          available[Constants.DISPLAY] = StringUtils.getHourFromNumber(start)

          var canSet = true
          const _end = available[Constants.END]
          // Condition 1: check with other booked services of user
          for (let i in refItems.current) {
            const item = refItems.current[i]
            const iDate = moment(item[Constants.DATE])
            const iStart = item[Constants.START]
            const iEnd = item[Constants.END]
            if (date.isSame(iDate, 'day') && user[Constants.ID] == item[Constants.USER][Constants.ID]
              && DateUtils.isHourOverlap(iStart, iEnd, start, _end)) {
              // check top to add availables
              var availableTop = null
              if (start < iStart) {
                availableTop = {}
                availableTop[Constants.ID] = StringUtils.getUniqueID()
                availableTop[Constants.DATE] = date
                availableTop[Constants.START] = start
                availableTop[Constants.END] = iStart
              }
              // check bottom to add availables
              const nextStart = start + duration
              var availableBottom = null
              if (iEnd < nextStart) {
                availableBottom = {}
                availableBottom[Constants.ID] = StringUtils.getUniqueID()
                availableBottom[Constants.DATE] = date
                availableBottom[Constants.START] = iEnd
                availableBottom[Constants.END] = nextStart
              }
              if (availableTop || availableBottom) {
                let canSetTop = availableTop != null
                let canSetBottom = availableBottom != null
                // check do not overlap with other booked services
                for (let j in refItems.current) {
                  const itemJ = refItems.current[j]
                  const iDateJ = moment(itemJ[Constants.DATE])
                  const iStartJ = itemJ[Constants.START]
                  const iEndJ = itemJ[Constants.END]
                  if (date.isSame(iDateJ, 'day')) {
                    if (availableTop && canSetTop && DateUtils.isHourOverlap(iStartJ, iEndJ, availableTop[Constants.START], availableTop[Constants.END])) {
                      canSetTop = false
                    }
                    if (availableBottom && canSetBottom && DateUtils.isHourOverlap(iStartJ, iEndJ, availableBottom[Constants.START], availableBottom[Constants.END])) {
                      canSetBottom = false
                    }
                  }
                  if (!canSetTop && !canSetBottom) {
                    break
                  }
                }
                if (canSetTop && !DateUtils.isEventSameTime(availableTop[Constants.START], availableTop[Constants.END])) {
                  availableTimes.push(availableTop)
                }
                if (canSetBottom && !DateUtils.isEventSameTime(availableBottom[Constants.START], availableBottom[Constants.END])) {
                  availableTimes.push(availableBottom)
                }
              }
              canSet = false
              break
            }
          }

          if (canSet && !DateUtils.isEventSameTime(available[Constants.START], available[Constants.END])) {
            availableTimes.push(available)
          }

          start += duration
        }
      }

      const path = date.year() + '/' + (date.month() + 1) + '/' + date.date()
      const now = moment()
      for (let i in availableTimes) {
        const item = availableTimes[i]
        const dStart = moment(item[Constants.DATE]).second(0)
        const dEnd = moment(item[Constants.DATE]).second(0)
        const startTime = DateUtils.getHourMinuteFromNumber(item[Constants.START])
        const endTime = DateUtils.getHourMinuteFromNumber(item[Constants.END])
        const dStartTime = dStart.hour(startTime.hour).minute(startTime.minute)
        const dEndTime = dEnd.hour(endTime.hour).minute(endTime.minute)

        // only select time that bigger than current time
        if (dStartTime.isBefore(now)) {
          continue
        }

        const event = {
          id: item[Constants.ID],
          title: '',
          start: dStartTime.toDate(),
          end: dEndTime.toDate(),
          resource: { hasEvent: false, path, className: mobile ? 'mobile-no-event' : 'no-event' }
        }
        refEvents.current.push(event)
      }
    }
  }

  const filterData = async (currentDate) => {
    refEvents.current = []
    const mobile = isMobile()

    for (let i in refItems.current) {
      const item = refItems.current[i]
      var date = moment(item[Constants.DATE])
      if (!currentDate.isSame(date, 'day')) {
        continue
      }
      var hourMinute = DateUtils.getHourMinuteFromNumber(item[Constants.START])
      const start = date.hour(hourMinute.hour).minute(hourMinute.minute)
      date = moment(item[Constants.DATE])
      hourMinute = DateUtils.getHourMinuteFromNumber(item[Constants.END])
      const end = date.hour(hourMinute.hour).minute(hourMinute.minute)
      const path = item[Constants.YEAR] + '/' + item[Constants.MONTH] + '/' + item[Constants.DAY] + '/' + item[Constants.ID]
      const key = `${start.year()}_${start.month()}_${start.date()}_${start.hour()}_${start.minute()}_${end.year()}_${end.month()}_${end.date()}_${end.hour()}_${end.minute()}`
      const event = {
        id: item[Constants.ID],
        title: `${item[Constants.STORE][Constants.NAME]} - ${item[Constants.EMPLOYEE][Constants.FIRST_NAME]} ${StringUtils.stringText(item[Constants.EMPLOYEE][Constants.LAST_NAME])} - ${item[Constants.SERVICE][Constants.TITLE]}`,
        start: start.toDate(),
        end: end.toDate(),
        resource: { hasEvent: true, path, key, count: 1, className: 'has-event-customer', style: { backgroundColor: item[Constants.SERVICE][Constants.COLOR] } }
      }
      refEvents.current.push(event)
    }

    // check overlap event
    // for (let i in refEvents.current) {
    //   const event = refEvents.current[i]
    //   const time = event.resource.time
    //   const key = event.resource.key
    //   let count = 1
    //   for (let j in refEvents.current) {
    //     const eventJ = refEvents.current[j]
    //     const keyJ = eventJ.resource.key
    //     if (key == keyJ && event.id != eventJ.id) {  
    //       count = count + 1
    //     }
    //   }
    //   event.resource.count = count
    // }

    // load available times for date that bigger than today
    if (currentDate.isSameOrAfter(moment(), 'day')) {
      loadAvailableTimes(currentDate)
    }

    // add last event if it is smaller than end minnute
    if (refEvents.current.length > 0) {
      const last = refEvents.current[refEvents.current.length - 1]
      const lastHours = last.end.getHours()
      const lastMinutes = last.end.getMinutes()
      const maxHours = refMinMax.current.endHr
      const maxMinutes = refMinMax.current.endMin
      if (lastHours == maxHours && lastMinutes + Constants.CALENDAR_TIME_STEP <= maxMinutes + 1) {
        const path = currentDate.year() + '/' + (currentDate.month() + 1) + '/' + currentDate.date()
        const event = {
          id: StringUtils.getUniqueID(),
          title: '',
          start: moment(last.end).toDate(),
          end: moment(last.end).add(1, 'hour').minute(0).toDate(),
          resource: { hasEvent: false, path, className: mobile ? 'mobile-no-event tiny' : 'no-event tiny' }
        }
        refEvents.current.push(event)
      }
    }

    //DateUtils.sortBy(refEvents.current, Constants.START)
    setEvents(refEvents.current)
  }

  const onSelectEvent = (event) => {
    const resource = event.resource
    if (resource.hasEvent) {
      // show popup to choose event if overlap
      const count = resource.count
      if (count && count > 1) {
        if (props.onShowEvents) {
          const list = []
          for (let i in refEvents.current) {
            const rEvent = refEvents.current[i]
            if (rEvent.resource.key && rEvent.resource.key == resource.key) {
              list.push(rEvent)
            }
          }
          props.onShowEvents(list)
        }
      } else {
        Navigator.navigate(props, Constants.PAGES_CUSTOMER_APPOINTMENT, `${Constants.PATH}=${resource.path}`)
      }
    } else {
      const startDate = moment(event.start)
      const hourMinute = { hour: startDate.hour(), minute: startDate.minute() }
      const hourNum = DateUtils.getNumberFromHourMinute(hourMinute) // convert to number
      let booking = Storage.getJson(Constants.BOOKING)
      Storage.clearKey(Constants.BOOKING)
      Storage.clearKey(Constants.CACHE_STORE_BOOKINGS)
      Storage.clearKey(Constants.CACHE_STORE_EMPLOYEES)
      Storage.clearKey(Constants.CACHE_STORE_SERVCIES)
      Storage.clearKey(Constants.CACHE_BOOK_TIME)
      booking = Storage.getJson(Constants.BOOKING)
      booking[Constants.ID] = StringUtils.getUniqueID()
      booking[Constants.STORE] = refStore.current
      booking[Constants.USER] = user
      booking[Constants.DATE] = startDate.toISOString()
      booking[Constants.START] = hourNum
      booking[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
      Storage.setJson(Constants.BOOKING, booking)
      Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_APPOINTMENT)
    }
  }

  const handleDisplayChange = (type) => {
    setDisplay(type)
  }

  const eventPropGetter = (event, start, end, isSelected) => {
    return { className: event.resource.className, style: event.resource.style }
  }

  const slotPropGetter = (date) => {
    return { style: { background: Constants.APPOINTMENT_TIME_SLOT_UNAVAILABLE_COLOR } }
  }

  const eventAgendaRender = ({ event }) => {
    return <span className='agenda-title' onClick={() => onSelectEvent(event)}><em>{event.title}</em></span>
  }

  const eventRender = ({ event }) => {
    const count = event.resource.count
    if (count && count > 1) {
      return `${event.title} (+${count - 1} more)`
    }
    return event.title
  }

  props.onClickDay = (value) => {
    // check to load new date
    if (!refDate.current.isSame(value, 'day')) {
      // check to load new appoiments for new month
      if (!refDate.current.isSame(value, 'month')) {
        refDate.current = value
        setDate(value)
        loadData()
      } else {
        refDate.current = value
        populateMinMax()
        setDate(value)
        const key = `${value.year()}-${value.month() + 1}-${value.date()}`
        setTotal(refTotal.current[key] ?? 0)
        filterData(value)
      }
    }
  }

  const classes = useStyles()
  const localizer = momentLocalizer(moment)
  const minMax = refMinMax.current
  const calendarHeight = (minMax.endHr - minMax.startHr) * Constants.CALENDAR_TIME_SLOTS * Constants.CALENDAR_SLOT_HEIGHT

  return <>
    <div className={classes.headerBox}>
      <ITypography variant='h5' style={{ width: '100%' }}>{date.format('dddd, MMMM Do YYYY')}</ITypography>
      <div className={classes.addButton}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ITypography variant='body1'>{Strings.selectedStore}:</ITypography>
          <ITypography variant='body1' style={{ marginLeft: 5 }}>{refStore.current ? refStore.current[Constants.NAME] : ''}</ITypography>
          <IIconButton onClick={() => Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_STORES)}><EditIcon /></IIconButton>
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
          <IRadio color='default' checked={display == 'day'} onChange={() => handleDisplayChange('day')} value='day' />
          <ITypography variant='body2'>{Strings.day}</ITypography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IRadio color='default' checked={display == 'agenda'} onChange={() => handleDisplayChange('agenda')} value='agenda' />
          <ITypography variant='body2'>{Strings.agenda}</ITypography>
        </div> */}
      </div>
    </div>
    <ITypography variant='body2'>{total} {total == 1 ? Strings.appointment : Strings.appointments}</ITypography>
    <Calendar
      formats={Constants.APPOINTMENT_FORMATS}
      date={date.toDate()}
      min={moment(date).hour(minMax.startHr).minute(minMax.startMin).toDate()}
      max={moment(date).hour(minMax.endHr).minute(minMax.endMin).toDate()}
      events={events}
      selectable={false}
      localizer={localizer}
      defaultView='day'
      view={display}
      toolbar={false}
      timeslots={Constants.CALENDAR_TIME_SLOTS}
      step={Constants.CALENDAR_TIME_STEP}
      onNavigate={() => { }}
      views={{ day: true, agenda: true }}
      style={{ height: calendarHeight || Styles.appointmentsHeight }}
      eventPropGetter={eventPropGetter}
      slotPropGetter={slotPropGetter}
      onSelectEvent={(event) => onSelectEvent(event)}
      onSelecting={slot => false}
      onView={() => { }}
      components={{
        agenda: {
          event: eventAgendaRender,
        },
        event: eventRender
      }}
    />
  </>
}

function SideCalendarView({ props }) {
  Logger.log('SideCalendarView')

  const { user } = useGlobal()
  const [date, setDate] = useState(moment())
  const [total, setTotal] = useState({})

  useEffect(() => {
    const chooseDate = Storage.getString(Constants.APPOINTMENT_CHOOSE_DATE)
    if (chooseDate) {
      onClickDay(moment(chooseDate))
    }
  }, [])

  const onClickDay = (value, fromUser) => {
    setDate(value)
    if (props.onClickDay) {
      props.onClickDay(value)
    }
    if (fromUser) {
      Storage.setString(Constants.APPOINTMENT_CHOOSE_DATE, value.toISOString())
    }
  }

  const dateRender = (current, value) => {
    const key = `${current.year()}-${current.month() + 1}-${current.date()}`
    if (total[key] && total[key] > 0) {
      return <div className='rc-calendar-date' style={{ lineHeight: '10px', paddingTop: 5 }}>
        <span>{current.date()}</span>
        <div style={{ width: 4, height: 4, borderRadius: 2, background: 'red', margin: 'auto', marginTop: 2 }} />
      </div>
    }
    return <div className='rc-calendar-date' style={{ lineHeight: '10px', paddingTop: 5 }}>
      <span>{current.date()}</span>
    </div>
  }

  props.onLoadedTotal = (value) => {
    setTotal(value)
  }

  const classes = useStyles()

  return <SideCalendar showDateInput={false} value={date}
    onChange={(value) => onClickDay(value, true)}
    dateRender={(current, value) => dateRender(current, value)} />
}

function CustomerAppointments() {
  Logger.log(Constants.PAGES_CUSTOMER_APPOINTMENTS)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <div className={classes.root}>
    <div style={{ width: '100%' }}>
      <ICard>
        <ICardContent>
          <ColumnCalendarView props={props} />
        </ICardContent>
      </ICard>
    </div>
    <div className={classes.sideCalendar}>
      <ICard>
        <ICardContent style={{ padding: 0 }}>
          <SideCalendarView props={props} />
        </ICardContent>
      </ICard>
    </div>
    <OverlapView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </div>
}

export default CustomerAppointments