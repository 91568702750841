import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as ScreenUtils from '../../../common/ScreenUtils'
import * as DataUtils from '../../../common/DataUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITypography from '../../controls/ITypography'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableHead from '../../controls/ITableHead'
import ITableRow from '../../controls/ITableRow'
import IPaper from '../../controls/IPaper'
import IIconButton from '../../controls/IIconButton'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogContentText from '../../controls/IDialogContentText'
import IDialogTitle from '../../controls/IDialogTitle'
import IImage from '../../controls/IImage'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  divColor: {
    width: 50,
    height: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.54)'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const refItem = useRef()
  const refCategories = useRef()
  const refEmployees = useRef()
  const [items, setItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(props, true)

    if (refCategories.current == null) {
      const categories = []
      const ref1 = firebase.databaseRef(Constants.FB_SYSTEM_CATEGORIES)
      const response1 = await ref1.once('value')
      if (response1) {
        const val = response1.val()
        if (val) {
          for (let id in val) {
            categories.push(val[id])
          }
        }
      }
      const ref2 = firebase.databaseRef(Constants.FB_STORE_CATEGORIES + '/' + userId + '/' + storeId)
      const response2 = await ref2.once('value')
      if (response2) {
        const val = response2.val()
        if (val) {
          for (let id in val) {
            categories.push(val[id])
          }
        }
      }
      refCategories.current = categories
    }

    if (refEmployees.current == null) {
      const employees = []
      const ref1 = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId)
      const response1 = await ref1.once('value')
      if (response1) {
        const val = response1.val()
        if (val) {
          for (let id in val) {
            employees.push(val[id])
          }
        }
      }
      refEmployees.current = employees
    }

    const ref = firebase.databaseRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId)
    const response = await ref.once('value')
    const list = []
    const val = response.val()
    if (val) {
      for (let id in val) {
        list.push(val[id])
      }
    }
    DataUtils.sortBy(list, Constants.ORDER)
    setItems(list)

    setLoading(props, false)
  }

  const onAddService = () => {
    Navigator.navigate(props, Constants.PAGES_STORE_ADD_SERVICE)
  }

  const onEditItem = (item) => {
    Navigator.navigate(props, Constants.PAGES_STORE_EDIT_SERVICE, `${Constants.ID}=${item[Constants.ID]}`)
  }

  const onDeleteItem = (item) => {
    refItem.current = item
    setOpenDialog(true)
  }

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {
      const serviceId = refItem.current[Constants.ID]
      setLoading(props, true)
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_STORE_DELETE_SERVICE)
      httpsCallable({ storeId, serviceId }).then(({ data }) => {
        setLoading(props, false)
        if (data.message) {
          showToast(props, TOAST_ERROR, data.message)
        } else {
          loadData()
        }
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const getCategoryName = (value) => {
    for (let i in refCategories.current) {
      let item = refCategories.current[i]
      if (item[Constants.ID] == value) {
        return item[Constants.TITLE]
      }
    }
    return ''
  }

  const getEmployeesName = (ids) => {
    var text = ''
    for (let i in ids) {
      for (let j in refEmployees.current) {
        let employee = refEmployees.current[j]
        if (ids[i] == employee[Constants.ID]) {
          if (text != '') {
            text += ', '
          }
          text += employee[Constants.FIRST_NAME] + ' ' + employee[Constants.LAST_NAME]
          break
        }
      }
    }
    return text
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: 'rgb(235,235,235)'
    })
  })

  const onDragEnd = (result) => {
    if (!result.destination || result.source.index == result.destination.index) {
      return
    }

    const orderList = Array.from(items)
    const [removed] = orderList.splice(result.source.index, 1)
    orderList.splice(result.destination.index, 0, removed)
    let index = result.destination.index
    let newOrder = index
    if (index == 0) {
      let nextOrder = orderList[index + 1][Constants.ORDER]
      newOrder = nextOrder - 1
    } else if (index == orderList.length - 1) {
      let preOrder = orderList[index - 1][Constants.ORDER]
      newOrder = preOrder + 1
    } else {
      let preOrder = orderList[index - 1][Constants.ORDER]
      let nextOrder = orderList[index + 1][Constants.ORDER]
      newOrder = (preOrder + nextOrder) / 2
    }
    removed[Constants.ORDER] = newOrder
    setItems(orderList)

    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId + '/' + removed[Constants.ID])
    let updates = {}
    updates[Constants.ORDER] = newOrder
    ref.update(updates).then(() => {
      setLoading(props, false)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return <div className={classes.root}>
    <ITypography className={classes.heading}>{Strings.services}</ITypography>
    <div className={classes.addButton}>
      <AddButton startIcon={<AddIcon />} onClick={() => onAddService()}>{Strings.addService}</AddButton>
    </div>
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <ITableContainer component={IPaper} style={{ marginTop: Styles.margin1Br }}>
            <ITable className={classes.table} ref={provided.innerRef}>
              <ITableHead>
                <ITableRow>
                  {isMobile && <>
                    <ITableCell>{Strings.details}</ITableCell>
                  </>}
                  {!isMobile && <>
                    <ITableCell>{Strings.category}</ITableCell>
                    <ITableCell>{Strings.image}</ITableCell>
                    <ITableCell>{Strings.title}</ITableCell>
                    <ITableCell>{Strings.description}</ITableCell>
                    <ITableCell>{Strings.price}</ITableCell>
                    <ITableCell>{Strings.duration} ({Strings.minutes.toLowerCase()})</ITableCell>
                    <ITableCell>{Strings.employees}</ITableCell>
                    <ITableCell>{Strings.color}</ITableCell>
                    <ITableCell></ITableCell>
                  </>}
                </ITableRow>
              </ITableHead>
              <ITableBody>
                {items.map((row, index) => (
                  <Draggable key={row[Constants.ID]} draggableId={row[Constants.ID]} index={index}>
                    {(provided, snapshot) => (
                      <ITableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        {isMobile && <ITableCell>
                          <div style={{ marginLeft: 10 }}>
                            {Strings.category}: {getCategoryName(row[Constants.CATEGORY_ID])}
                            <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize }} src={row[Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={row[Constants.TITLE]} />
                            <div>{Strings.title}: {row[Constants.TITLE]}</div>
                            <div>{Strings.description}: {row[Constants.DESCRIPTION]}</div>
                            <div>{Strings.price}: ${row[Constants.PRICE]}</div>
                            <div>{Strings.duration}: {row[Constants.DURATION]}</div>
                            <div>{Strings.employees}: {getEmployeesName(row[Constants.EMPLOYEE_IDS])}</div>
                            <div>{Strings.color}: <div className={classes.divColor} style={{ backgroundColor: row[Constants.COLOR] || 'white' }} /></div>
                            <div style={{ display: 'flex' }}>
                              <IIconButton aria-label={Strings.edit} onClick={() => onEditItem(row)}><EditIcon /></IIconButton>
                              <IIconButton aria-label={Strings.delete} onClick={() => onDeleteItem(row)}><DeleteIcon /></IIconButton>
                            </div>
                          </div>
                        </ITableCell>}
                        {!isMobile && <>
                          <ITableCell>{getCategoryName(row[Constants.CATEGORY_ID])}</ITableCell>
                          <ITableCell>
                            <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize }} src={row[Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={row[Constants.TITLE]} />
                          </ITableCell>
                          <ITableCell>{row[Constants.TITLE]}</ITableCell>
                          <ITableCell>{row[Constants.DESCRIPTION]}</ITableCell>
                          <ITableCell>${row[Constants.PRICE]}</ITableCell>
                          <ITableCell>{row[Constants.DURATION]}</ITableCell>
                          <ITableCell>{getEmployeesName(row[Constants.EMPLOYEE_IDS])}</ITableCell>
                          <ITableCell><div className={classes.divColor} style={{ backgroundColor: row[Constants.COLOR] || 'white' }} /></ITableCell>
                          <ITableCell>
                            <IIconButton aria-label={Strings.edit} onClick={() => onEditItem(row)}><EditIcon /></IIconButton>
                            <IIconButton aria-label={Strings.delete} onClick={() => onDeleteItem(row)}><DeleteIcon /></IIconButton>
                          </ITableCell>
                        </>}
                      </ITableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ITableBody>
            </ITable>
          </ITableContainer>
        )}
      </Droppable>
    </DragDropContext>
    <IDialog open={openDialog} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <IDialogTitle id='alert-dialog-title'>{Strings.delete}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText id='alert-dialog-description'>{Strings.deleteConfirm}</IDialogContentText>
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
        <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
      </IDialogActions>
    </IDialog>
  </div>
}

function ViewServices() {
  Logger.log(Constants.PAGES_STORE_VIEW_SERVICES)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default ViewServices