import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../utils/useGlobal'
import * as firebase from '../../utils/firebase'
import * as Logger from '../../common/Logger'
import * as StringUtils from '../../common/StringUtils'
import * as Constants from '../../common/Constants'
import * as Navigator from '../../common/Navigator'
import ProgressBar from '../widgets/ProgressBar'
import Strings from '../../common/Strings'
import IButton from '../controls/IButton'
import ITextField from '../controls/ITextField'
import IFormControl from '../controls/IFormControl'
import ITypography from '../controls/ITypography'
import IAvatar from '../controls/IAvatar'
import ICard from '../controls/ICard'
import ICardContent from '../controls/ICardContent'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../common/ViewUtils'
import Styles from '../../common/Styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.formMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth,
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const refTimer = useRef()
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  useEffect(() => {
    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const postSave = async () => {
    if (StringUtils.stringEmpty(oldPassword) || StringUtils.stringEmpty(password)
      || StringUtils.stringEmpty(passwordConfirm)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    if (password != passwordConfirm) {
      showToast(props, TOAST_ERROR, Strings.errorPasswordMismatch)
      return
    }
    setLoading(props, true)
    try {
      const user = firebase.auth().currentUser
      const credential = firebase.emailAuthProvider().credential(user.email, oldPassword)
      await user.reauthenticateWithCredential(credential)
      await user.updatePassword(password)
      setLoading(props, false)
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
      refTimer.current = setTimeout(() => {
        Navigator.back()
      }, 1000)
    } catch (err) {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    }
  }

  const classes = useStyles()

  return <IFormControl style={{ maxWidth: Styles.formMaxWidth, alignItems: 'center' }}>
    <ITypography className={classes.heading}>{Strings.changePassword}</ITypography>
    <ITextField type='password' className={classes.textField} style={{ marginTop: Styles.margin2Br }} label={Strings.currentPassword} value={oldPassword} onChange={(event) => { setOldPassword(event.target.value) }} />
    <ITextField type='password' className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.newPassword} value={password} onChange={(event) => { setPassword(event.target.value) }} />
    <ITextField type='password' className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.confirmPassword} value={passwordConfirm} onChange={(event) => { setPasswordConfirm(event.target.value) }} />
    <div style={{ marginTop: Styles.margin3Br }}>
      <IButton onClick={() => Navigator.back()}>{Strings.cancel}</IButton>
      <IButton className={classes.rightButton} onClick={postSave}>{Strings.save}</IButton>
    </div>
  </IFormControl>
}

function ChangePassword() {
  Logger.log(Constants.PAGES_CHANGE_PASSWORD)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default ChangePassword