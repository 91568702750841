import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../utils/useGlobal'
import * as firebase from '../../utils/firebase'
import * as Navigator from '../../common/Navigator'
import * as Logger from '../../common/Logger'
import * as StringUtils from '../../common/StringUtils'
import * as ScreenUtils from '../../common/ScreenUtils'
import * as DataUtils from '../../common/DataUtils'
import * as Constants from '../../common/Constants'
import * as Storage from '../../common/Storage'
import ProgressBar from '../widgets/ProgressBar'
import Strings from '../../common/Strings'
import IButton from '../controls/IButton'
import ITextField from '../controls/ITextField'
import ITypography from '../controls/ITypography'
import IGridList from '../controls/IGridList'
import IGridListTileBar from '../controls/IGridListTileBar'
import IGridListTitle from '../controls/IGridListTitle'
import SearchIcon from '@material-ui/icons/Search'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../common/ViewUtils'
import Styles from '../../common/Styles'
import IImage from '../controls/IImage'

const SearchButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  searchBox: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  gridList: {
    justifyContent: 'center'
  },
  storeInfo: {
    marginLeft: 20,
    width: '100%'
  },
  card: {
    display: 'flex',
    width: '100%'
  },
  scheduleButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  box: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#f7f7f7',
    borderStyle: 'solid',
    width: 250,
    margin: 'auto',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  logoBox: { 
    marginBottom: Styles.margin4Br,
    textAlign: 'center'
  },
  textBox: {
    fontSize: 16,
  }
}))

function LogoView({ props }) {
  Logger.log('LogoView') 

  const [logo, setLogo] = useState('')
  const [text, setText] = useState('')

  useEffect(() => {
    const ref = firebase.databaseRef(Constants.FB_PUBLIC)
    ref.once('value').then(response => {
      if (response) {
        const val = response.val()
        if (val) {
          setLogo(val[Constants.LOGO_URL])
          setText(val[Constants.LOGO_TEXT])
        }
      }
    }).catch(error => {
      Logger.log(error)
    })
  }, [])

  const classes = useStyles()

  return <div className={classes.logoBox}>
    <IImage style={{ width: Styles.logoSize, height: Styles.logoSize, margin: 'auto' }} src={logo || ''} alt={Strings.appName} />
    <div className={classes.textBox} dangerouslySetInnerHTML={{ __html: text }} />
  </div>
}

function SearchView({ props }) {
  Logger.log('SearchView')

  const [keyword, setKeyword] = useState('')

  useEffect(() => {

  }, [])

  const onSearchClick = (e) => {
    e.preventDefault()
    if (props.onSearchClick) {
      props.onSearchClick(keyword.toLowerCase())
    }
  }

  const classes = useStyles()

  return <form><div className={classes.searchBox}>
    <ITextField className={classes.textField} inputProps={{ style: { fontSize: Styles.searchFontSize }}} label={Strings.searchStoreHint} value={keyword} onChange={(event) => { setKeyword(event.target.value) }} />
    <SearchButton type='submit' startIcon={<SearchIcon />} onClick={(e) => onSearchClick(e)}>{Strings.search}</SearchButton>
  </div></form>
}

function StoresView({ props }) {
  Logger.log('StoresView')

  const { user, settings } = useGlobal()
  const [items, setItems] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = (keyword) => {
    const cacheStores = Storage.getJson(Constants.CACHE_STORES, false)
    if (cacheStores) {
      const isSearch = keyword && keyword != ''
      if (isSearch) {
        const list = []
        for (let id1 in cacheStores) {
          const child = cacheStores[id1]
          let text = `${child[Constants.NAME]}`.toLowerCase()
          if (text.indexOf(keyword) != -1) {
            list.push(child)
          }
        }
        setItems(list)
      } else {
        setItems(cacheStores)
      }
    } else {
      setLoading(props, true)
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_GUEST_VIEW_STORES)
      httpsCallable({ keyword }).then(({ data }) => {
        setLoading(props, false)
        const list = []
        if (data.items) {
          for (let i in data.items) {
            list.push(data.items[i])
          }
        }
        DataUtils.sortBy(list, Constants.NAME)
        setItems(list)
        Storage.setItemToMemory(Constants.CACHE_STORES, list)
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const onStoreClick = (item) => {
    const booking = Storage.getJson(Constants.BOOKING)
    booking[Constants.STORE] = item
    booking[Constants.SERVICE] = null
    booking[Constants.EMPLOYEE] = null
    Storage.setJson(Constants.BOOKING, booking)    
    if (user) {
      if (user[Constants.ROLE] == Constants.ROLE_CUSTOMER) {
        Navigator.navigate(props, Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_VIEW_SERVICES, `${Constants.USER_ID}=${item[Constants.USER_ID]}&${Constants.STORE_ID}=${item[Constants.ID]}`)
      } else if (user[Constants.ROLE] == Constants.ROLE_ADMIN_STORE) {
        Navigator.navigate(props, Constants.PAGES_STORE_DASHBOARD)
      } else if (user[Constants.ROLE] == Constants.ROLE_EMPLOYEE_STORE) {
        Navigator.navigate(props, Constants.PAGES_EMPLOYEE_DASHBOARD)
      }
    } else {
      Navigator.navigate(props, Constants.PAGES_GUEST_APPOINTMENT_ABOUT_STORE, `${Constants.USER_ID}=${item[Constants.USER_ID]}&${Constants.STORE_ID}=${item[Constants.ID]}`)
    }
  }

  props.onSearchClick = (keyword) => {
    loadData(keyword)
  }

  const classes = useStyles()
  const cols = ScreenUtils.isMobile() ? 1 : 4

  return <IGridList cellHeight='auto' cols={cols} spacing={10} className={classes.gridList} style={{ marginTop: Styles.margin2Br }}>
    {items.map(item => (
      <IGridListTitle key={item[Constants.ID]} classes={{ tile: classes.box }} onClick={() => onStoreClick(item)}>
        <IImage style={{ width: 250, height: 250 }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_STORE_IMAGE] || ''} alt={item[Constants.NAME]} />
        <IGridListTileBar title={item[Constants.NAME]} subtitle={item[Constants.DESCRIPTION]} />
      </IGridListTitle>
    ))}
  </IGridList>
}

function Home() {
  Logger.log(Constants.PAGES_HOME)

  let props = createPropsWithActions()
  const classes = useStyles()

  const query = Navigator.getQuery()
  const auth = query.get(Constants.AUTH)
  const { user } = useGlobal()

  const fontVariant = ScreenUtils.isMobile() ? 'body2' : 'h3'

  const getContent = () => {
    if (auth === '1' && !user) {
      return <div className='pageLoading' />
    } else {
      return <div className={classes.root}>
        <LogoView props={props} />
        <SearchView props={props} />
        <StoresView props={props} />
        <ProgressBar props={props} />
      </div>
    }
  }

  return getContent()
}

export default Home