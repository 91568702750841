import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import * as Navigator from '../../../common/Navigator'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import IAvatar from '../../controls/IAvatar'
import PhoneInput from '../../widgets/PhoneInput'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.formMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  avatar: {
    width: '200px',
    height: '200px'
  },
  changePassword: {
    textAlign: 'right'
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [image, setImage] = useState()
  const [src, setSrc] = useState('')

  useEffect(() => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_USERS + '/' + user[Constants.ID])
    ref.once('value').then(response => {
      setLoading(props, false)
      const val = response.val()
      if (val) {
        setEmail(val[Constants.EMAIL])
        setFirstName(val[Constants.FIRST_NAME])
        setLastName(val[Constants.LAST_NAME])
        setPhone(val[Constants.PHONE])
        setAddress(val[Constants.ADDRESS])
        setSrc(val[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR])
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const handleChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (!StringUtils.isValidUploadImage(file.name)) {
        setImage(null)
        setSrc('')
        showToast(props, TOAST_ERROR, Strings.errorWrongFormat + StringUtils.arrayToString(Constants.SUPPORT_IMAGE_TYPES))
        return
      }
      setImage(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function (e) {
        setSrc(reader.result)
      }.bind(this)
    }
  }

  const postSave = async () => {
    if (StringUtils.stringEmpty(firstName) || StringUtils.stringEmpty(lastName)
      || StringUtils.stringEmpty(phone) || StringUtils.stringEmpty(address)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    if (!StringUtils.validatePhone(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorPhoneInvalid)
      return
    }
    setLoading(props, true)
    const updates = {}
    if (image) {
      const refStorage = firebase.storageRef(Constants.FB_USERS + '/' + user[Constants.ID] + '/' + image.name)
      const downloadURL = await firebase.uploadFile(refStorage, image, 0, 1, (index, length, bytesTransferred, totalBytes) => {
        //updateProgress(Math.ceil(bytesTransferred / totalBytes * 100))
      })
      if (downloadURL) {
        updates[Constants.IMAGE] = downloadURL
      }
    }
    const ref = firebase.databaseRef(Constants.FB_USERS + '/' + user[Constants.ID])
    updates[Constants.FIRST_NAME] = firstName
    updates[Constants.LAST_NAME] = lastName
    updates[Constants.PHONE] = phone
    updates[Constants.ADDRESS] = address
    updates[Constants.DATE_MODIFIED] = Date.now()
    ref.update(updates).then(() => {
      setLoading(props, false)
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const classes = useStyles()

  return <IFormControl style={{ maxWidth: Styles.formMaxWidth, alignItems: 'center' }}>
    <ITypography className={classes.heading}>{Strings.profile}</ITypography>
    <div className={`${classes.textField} ${classes.changePassword}`} style={{ marginTop: Styles.margin1Br }}>
      <Link to={Navigator.buildLink(props, Constants.PAGES_CHANGE_PASSWORD)}>{Strings.changePassword}</Link>
    </div>
    <ITextField disabled className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.email} value={email} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.firstName} value={firstName} onChange={(event) => { setFirstName(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.lastName} value={lastName} onChange={(event) => { setLastName(event.target.value) }} />
    <PhoneInput className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.phone} value={phone} onChange={(event) => { setPhone(event) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.address} value={address} onChange={(event) => { setAddress(event.target.value) }} />
    <div className={classes.textField} style={{ marginTop: Styles.margin2Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.avatar}:</ITypography>
    </div>
    <ITextField className={classes.textField} type='file' onChange={handleChange} />
    <IAvatar src={src} className={classes.avatar} style={{ marginTop: Styles.margin1Br }} />
    <IButton style={{ marginTop: Styles.margin2Br }} onClick={postSave}>{Strings.save}</IButton>
  </IFormControl>
}

function StoreProfile() {
  Logger.log(Constants.PAGES_STORE_PROFILE)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default StoreProfile