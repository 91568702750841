export const SORT_ASCE = 'asce'
export const SORT_DESC = 'desc'

export function sortBy(list, field, by = SORT_ASCE) {
    if (by == SORT_ASCE) {
        list.sort((a, b) => (a[field] < b[field]) ? -1 : ((b[field] < a[field]) ? 1 : 0))
    } else {
        list.sort((a, b) => (a[field] < b[field]) ? 1 : ((b[field] < a[field]) ? -1 : 0))
    }
}

export function arrayToArray(array, field) {
    var list = []
    for (let i in array) {
        list.push(array[i][field])
    }
    return list
}