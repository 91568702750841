import { makeStyles, styled } from '@material-ui/core/styles'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment-timezone'
import SideCalendar from 'rc-calendar'
import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import * as Constants from '../../../common/Constants'
import * as DataUtils from '../../../common/DataUtils'
import * as DateUtils from '../../../common/DateUtils'
import * as Logger from '../../../common/Logger'
import * as Navigator from '../../../common/Navigator'
import * as ScreenUtils from '../../../common/ScreenUtils'
import * as Storage from '../../../common/Storage'
import Strings from '../../../common/Strings'
import * as StringUtils from '../../../common/StringUtils'
import Styles from '../../../common/Styles'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import { initWorkingPeriodEmployee } from '../../../common/WorkingPeriod'
import * as firebase from '../../../utils/firebase'
import { useGlobal } from '../../../utils/useGlobal'
import IButton from '../../controls/IButton'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogTitle from '../../controls/IDialogTitle'
import IDivider from '../../controls/IDivider'
import IGridList from '../../controls/IGridList'
import IIconButton from '../../controls/IIconButton'
import IImage from '../../controls/IImage'
import IMenuItem from '../../controls/IMenuItem'
import IPaper from '../../controls/IPaper'
import ISwitch from '../../controls/ISwitch'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableRow from '../../controls/ITableRow'
import ITextField from '../../controls/ITextField'
import ITypography from '../../controls/ITypography'
import { useWindowSize } from '../../hooks/useWindowSize'
import ProgressBar from '../../widgets/ProgressBar'
import TimePicker from '../../widgets/TimePicker'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'

const DragAndDropCalendar = withDragAndDrop(Calendar)

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35,
  fontSize: Styles.buttonFontSize
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  sideCalendar: {
    width: Styles.sideCalendar,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  },
  addButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  headerBox: {
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
  },
  draggable: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function EmployeesView({ props }) {
  Logger.log('EmployeesView')

  const [employee, setEmployee] = useState()
  const [employees, setEmployees] = useState([])

  useEffect(() => {
  }, [])

  const onSelectEmployee = (value) => {
    for (let i in employees) {
      let item = employees[i]
      if (item[Constants.ID] == value) {
        setEmployee(item)
        if (props.onSelectEmployee) {
          props.onSelectEmployee(item)
        }
        Storage.setString(Constants.APPOINTMENT_CHOOSE_EMPLOYEE, value)
        break
      }
    }
  }

  props.onLoadEmployees = (list) => {
    list.sort((a, b) => {
      let f1 = `${a[Constants.FIRST_NAME]} ${a[Constants.LAST_NAME]}`
      let f2 = `${b[Constants.FIRST_NAME]} ${b[Constants.LAST_NAME]}`
      return (f1 < f2) ? -1 : ((f2 < f1) ? 1 : 0)
    })
    setEmployees(list)
    if (list.length > 0) {
      let index = 0
      const choose = Storage.getString(Constants.APPOINTMENT_CHOOSE_EMPLOYEE)
      if (choose) {
        index = list.findIndex((value) => value[Constants.ID] == choose)
        if (index < 0) {
          index = 0
        }
      }
      setEmployee(list[index])
      if (props.onSelectEmployee) {
        props.onSelectEmployee(list[index])
      }
    }
  }

  const classes = useStyles()

  return <div style={{ marginTop: 10, marginBottom: 20 }}>
    <div className={classes.searchBox}>
      <ITypography variant='body1'>{Strings.employee}:</ITypography>
      <ITextField className={classes.textField} select
        onChange={(event) => {
          onSelectEmployee(event.target.value)
        }}
        InputLabelProps={{ shrink: true, style: { color: '#fff' } }}
        value={employee ? employee[Constants.ID] : ''}>
        {employees.map(option => (
          <IMenuItem key={option[Constants.ID]} value={option[Constants.ID]}>
            <ITypography variant='body2'>{option[Constants.FIRST_NAME]} {option[Constants.LAST_NAME]}</ITypography>
          </IMenuItem>
        ))}
      </ITextField>
    </div>
  </div>
}

function OrderView({ props }) {
  Logger.log('OrderView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const [items, setItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {

  }, [])

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {
      setLoading(props, true)
      const ref = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
      const updates = {}
      updates[Constants.EMPLOYEE_ORDERS] = items
      ref.update(updates).then(() => {
        setLoading(props, false)
        Storage.setJson(Constants.EMPLOYEE_ORDERS, items)
        if (props.onChangedResourcesMap) {
          props.onChangedResourcesMap(items)
        }
      }).catch(error => {
        Logger.log(error)
        setLoading(props, false)
        showToast(props, TOAST_ERROR, error.message || Strings.errorLoadingData)
      })
    }
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: 'rgb(235,235,235)'
    })
  })

  const onDragEnd = (result) => {
    if (!result.destination || result.source.index == result.destination.index) {
      return
    }

    const orderList = Array.from(items)
    const [removed] = orderList.splice(result.source.index, 1)
    orderList.splice(result.destination.index, 0, removed)
    setItems(orderList)
  }

  props.onEditOrder = () => {
    setOpenDialog(true)
  }

  props.onLoadedResourcesMap = (list) => {
    setItems(list)
  }

  const classes = useStyles()

  return <IDialog open={openDialog} maxWidth='sm' fullWidth={true} onClose={() => handleCloseDialog(false)}>
    <IDialogContent>
      <div>
        <ITypography variant='h5'>{Strings.editOrder}</ITypography>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <ITableContainer component={IPaper} style={{ marginTop: Styles.margin1Br }}>
              <ITable className={classes.table} ref={provided.innerRef}>
                <ITableBody>
                  {items.map((item, index) => (
                    <Draggable key={item[Constants.RESOURCE_ID]} draggableId={item[Constants.RESOURCE_ID]} index={index}>
                      {(provided, snapshot) => (
                        <ITableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <ITableCell width={Styles.smallImageSize}>
                            <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize }} src={item[Constants.RESOURCE_IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.RESOURCE_TITLE]} />
                          </ITableCell>
                          <ITableCell><b>{item[Constants.RESOURCE_TITLE]}</b></ITableCell>
                        </ITableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ITableBody>
              </ITable>
            </ITableContainer>
          )}
        </Droppable>
      </DragDropContext>
    </IDialogContent>
    <IDialogActions>
      <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
      <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
    </IDialogActions>
  </IDialog>
}

function EmployeeColumnsView({ props }) {
  Logger.log('EmployeeColumnsView')

  const { user } = useGlobal()
  const userId = user[Constants.ID]
  const storeId = user[Constants.ID]
  const [screenWidth, screenHeight] = useWindowSize()
  const refScreenWidth = useRef(screenWidth)
  const refDiv = useRef()
  const refDivGutter = useRef()
  const refAllItems = useRef({}) // store all items of all selected months
  const refItems = useRef([]) // store items of a selected month
  const refTotal = useRef({})
  const refEmployees = useRef([])
  const refEmployee = useRef()
  const refEvents = useRef([])
  const refSlots = useRef({})
  const refToday = useRef(new Date())
  const refDate = useRef(moment())
  const refResourceMap = useRef([])
  const refAvailableTimes = useRef([])
  const refDraggingPoint = useRef()
  const refTimer = useRef()
  const refUpdates = useRef({})
  const refUpdateFuncs = useRef({})
  const refIsFirst = useRef(true)
  const refTimeHeaderStyle = useRef({ height: 0, scrollWidth: 0, gutterWidth: 0, gutterHeight: 0 })
  const refGutterTimes = useRef([])
  const refCacheRealAvailableTimes = useRef({})
  const refCacheMapHrs = useRef({})
  const [events, setEvents] = useState([])
  const [resourcesMap, setResourcesMap] = useState([])
  const [timeHeaderStyle, setTimeHeaderStyle] = useState(refTimeHeaderStyle.current)

  useEffect(() => {
    for (let i = Constants.CALENDAR_START_HOUR; i <= Constants.CALENDAR_END_HOUR; i++) {
      refGutterTimes.current.push(i)
    }

    loadData()

    return () => {
      for (let i in refUpdates.current) {
        if (refUpdates.current[i]) {
          refUpdates.current[i].off('value', refUpdateFuncs.current[i])
          refUpdates.current[i] = null
        }
      }
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const onInviteAppointment = () => {
    Navigator.navigate(props, Constants.PAGES_STORE_INVITE_APPOINTMENT_INVITE_CUSTOMER)
  }

  const onAddSingleAppointment = () => {
    Navigator.navigate(props, Constants.PAGES_STORE_APPOINTMENTS_SINGLE)
  }

  // Get items in Updated View
  const getUpdatedItems = () => {
    if (props.getUpdatedItems) {
      return props.getUpdatedItems()
    }
    return []
  }

  // Get items in Draggable View
  const getDraggableItems = () => {
    if (props.getDraggableItems) {
      return props.getDraggableItems()
    }
    return []
  }

  const getDroppableBounds = () => {
    return ScreenUtils.getBoundsForNode(refDiv.current)
  }

  const loadData = async () => {
    setLoading(props, true)

    // clear cache
    refCacheRealAvailableTimes.current = {}
    refCacheMapHrs.current = {}

    const refStore = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
    const responseStore = await refStore.once('value')
    if (responseStore) {
      const val = responseStore.val()
      if (val) {
        const orders = val[Constants.EMPLOYEE_ORDERS]
        if (orders) {
          Storage.setJson(Constants.EMPLOYEE_ORDERS, orders)
        }
      }
    }
    refEmployees.current = []
    const employees = await firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId).once('value')
    if (employees) {
      let list = []
      const val = employees.val()
      if (val) {
        for (let i in val) {
          const item = val[i]
          let resource = {}
          resource[Constants.RESOURCE_ID] = item[Constants.ID]
          resource[Constants.RESOURCE_TITLE] = `${item[Constants.FIRST_NAME]} ${item[Constants.LAST_NAME]}`
          resource[Constants.RESOURCE_IMAGE] = StringUtils.checkUndefined(item[Constants.IMAGE])
          list.push(resource)
          refEmployees.current.push(item)
        }
      }
      DataUtils.sortBy(list, Constants.RESOURCE_TITLE)
      refResourceMap.current = list
      // if there are changes in employees list then we remove the cache columns
      const orderColumns = Storage.getJson(Constants.EMPLOYEE_ORDERS, false)
      if (orderColumns) {
        let changed = orderColumns.length != list.length
        if (!changed) {
          for (let i in orderColumns) {
            const index = list.findIndex(value => value[Constants.ID] == orderColumns[i][Constants.ID])
            if (index == -1) {
              changed = true
              break
            }
          }
        }
        if (changed) {
          Storage.clearKey(Constants.EMPLOYEE_ORDERS)
        }
      }
    }

    refIsFirst.current = true
    monitorData()
  }

  const monitorData = () => {
    for (let i in refUpdates.current) {
      if (refUpdates.current[i]) {
        refUpdates.current[i].off('value', refUpdateFuncs.current[i])
        refUpdates.current[i] = null
      }
    }
    const date = refDate.current
    const year = date.year()
    const month = date.month() + 1
    // load appoiments for year/month
    const refBookings = firebase.databaseRef(Constants.FB_STORE_BOOKINGS + '/' + userId + '/' + storeId + '/' + year + '/' + month)
    refUpdateFuncs.current['bookings'] = (response) => {
      refTotal.current = {}
      let list = []
      if (response) {
        const val = response.val()
        if (val) {
          for (let i in val) { // day
            const item1 = val[i]
            for (let j in item1) { // id
              const item2 = item1[j]
              item2[Constants.DATE_PARSED] = moment(item2[Constants.DATE])
              list.push(item2)
              const key = `${year}-${month}-${i}`
              refTotal.current[key] = refTotal.current[key] ? refTotal.current[key] + 1 : 1

              // push to allItems
              refAllItems.current[item2[Constants.ID]] = item2
            }
          }
        }
      }
      refItems.current = list

      // only pass employees at the first time
      if (refIsFirst.current) {
        refIsFirst.current = false
        if (props.onLoadEmployees) {
          props.onLoadEmployees(refEmployees.current)
        }
        if (props.onLoadEmployeesTimesView) {
          props.onLoadEmployeesTimesView(refEmployees.current)
        }
      }

      const isMobile = ScreenUtils.isMobile()
      if (!isMobile) {
        filterData(refDate.current)
        if (props.onLoadedTotal) {
          props.onLoadedTotal(refTotal.current)
        }
      }

      setLoading(props, false)
    }

    refBookings.on('value', refUpdateFuncs.current['bookings'])
    refUpdates.current['bookings'] = refBookings
  }

  const loadRealAvailableTimes = (employee, date) => {
    let key = employee[Constants.ID] + `_${date.year()}_${date.month() + 1}_${date.date()}`
    if (refCacheRealAvailableTimes.current[key]) {
      return refCacheRealAvailableTimes.current[key]
    }

    let list = []
    // step 1: get all working hours in specific day and also filter offline hours
    const workingPeriods = firebase.getEmployeeWorkingPeriods(employee, date)
    const workingOfflines = employee[Constants.WORKING_OFFLINE] || []
    const day = date.format('dddd').toUpperCase()
    let offlinePair = [] // pair of hours [{start, end}]
    for (let i in workingOfflines) {
      const workingOffline = workingOfflines[i]
      const start = workingOffline[Constants.START_PARSED] || moment(workingOffline[Constants.START])
      workingOffline[Constants.START_PARSED] = start
      if (date.isSame(start, 'day')) {
        const end = workingOffline[Constants.END_PARSED] || moment(workingOffline[Constants.END])
        workingOffline[Constants.END_PARSED] = end
        var pair = {}
        pair[Constants.START] = DateUtils.getNumberFromHourMinute({ hour: start.hour(), minute: start.minute() })
        pair[Constants.END] = DateUtils.getNumberFromHourMinute({ hour: end.hour(), minute: end.minute() })
        offlinePair.push(pair)
      }
    }
    for (let i in workingPeriods) {
      const workingPeriod = workingPeriods[i]
      const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
      const dateStr = DateUtils.getDayByNumber(dateNum)
      if (day == dateStr && workingPeriod[Constants.IS_CHECK]) {
        const hours = workingPeriod[Constants.HOURS] || []
        for (let j in hours) {
          const item = hours[j]
          var pairs = [item]
          // check with offline hours, create new list that contains new pairs, 
          // e.g: working 8-11am, offline 9-10am => new list [8-9, 10-11]
          for (let k in offlinePair) {
            const offline = offlinePair[k]
            let subList = []
            for (let l in pairs) {
              const pair = pairs[l]
              if (DateUtils.isHourOverlap(pair[Constants.START], pair[Constants.END], offline[Constants.START], offline[Constants.END])) {
                subList.push(...DateUtils.splitHourOverlap(pair[Constants.START], pair[Constants.END], offline[Constants.START], offline[Constants.END]))
              } else {
                subList.push(pair)
              }
            }
            pairs = [...subList]
          }
          list.push(...pairs)
        }
        break
      }
    }
    refCacheRealAvailableTimes.current[key] = list
    return list
  }

  const loadAvailableTimes = (employee, date) => {
    let list = []
    // step 1: get all working hours in specific day and also filter offline hours
    const workingPeriods = initWorkingPeriodEmployee()
    const day = date.format('dddd').toUpperCase()
    for (let i in workingPeriods) {
      const workingPeriod = workingPeriods[i]
      const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
      const dateStr = DateUtils.getDayByNumber(dateNum)
      if (day == dateStr) {
        const hours = workingPeriod[Constants.HOURS] || []
        for (let j in hours) {
          const item = hours[j]
          list.push(item)
        }
        break
      }
    }
    if (list.length > 0) {
      // step 2: calculate available times for booking
      // using start and end of each pair, then plus with duration
      DataUtils.sortBy(list, Constants.START)
      let timesPair = [] // hold [pair]
      let pair = {} // hold {start, end}
      for (let i in list) {
        const item = list[i]
        if (i == 0) {
          pair[Constants.START] = item
          pair[Constants.END] = item
        } else {
          const previous = list[i - 1]
          if (item[Constants.START] == previous[Constants.END]) {
            pair[Constants.END] = item
          } else {
            timesPair.push(pair)
            pair = {}
            pair[Constants.START] = item
            pair[Constants.END] = item
          }
        }
      }
      timesPair.push(pair)
      const durationInMinutes = Constants.CALENDAR_TIME_STEP // step in calendar
      const duration = durationInMinutes / 60 // in hour      
      let availableTimes = []
      for (let i in timesPair) {
        const pair = timesPair[i]
        let start = pair[Constants.START][Constants.START]
        const end = pair[Constants.END][Constants.END]
        while (start + duration <= end) {
          let available = {}
          available[Constants.ID] = StringUtils.getUniqueID()
          available[Constants.DATE] = date
          available[Constants.START] = start
          available[Constants.END] = start + duration

          let canSet = true
          const _end = available[Constants.END]
          // Condition 1: check with other booked services of same employee
          for (let i in refItems.current) {
            const item = refItems.current[i]
            if (employee[Constants.ID] == item[Constants.EMPLOYEE][Constants.ID]) {
              const iDate = item[Constants.DATE_PARSED] || moment(item[Constants.DATE])
              item[Constants.DATE_PARSED] = iDate
              const iStart = item[Constants.START]
              const iEnd = item[Constants.END]
              if (date.isSame(iDate, 'day') && DateUtils.isHourOverlap(iStart, iEnd, start, _end)) {
                // check top to add availables
                let availableTop = null
                if (start < iStart) {
                  availableTop = {}
                  availableTop[Constants.ID] = StringUtils.getUniqueID()
                  availableTop[Constants.DATE] = date
                  availableTop[Constants.START] = start
                  availableTop[Constants.END] = iStart
                }
                // check bottom to add availables
                const nextStart = start + duration
                let availableBottom = null
                if (iEnd < nextStart) {
                  availableBottom = {}
                  availableBottom[Constants.ID] = StringUtils.getUniqueID()
                  availableBottom[Constants.DATE] = date
                  availableBottom[Constants.START] = iEnd
                  availableBottom[Constants.END] = nextStart
                }
                if (availableTop || availableBottom) {
                  let canSetTop = availableTop != null
                  let canSetBottom = availableBottom != null
                  // check do not overlap with other booked services
                  for (let j in refItems.current) {
                    const itemJ = refItems.current[j]
                    const iDateJ = itemJ[Constants.DATE_PARSED] || moment(itemJ[Constants.DATE])
                    itemJ[Constants.DATE_PARSED] = iDateJ
                    const iStartJ = itemJ[Constants.START]
                    const iEndJ = itemJ[Constants.END]
                    if (date.isSame(iDateJ, 'day')) {
                      if (availableTop && canSetTop && DateUtils.isHourOverlap(iStartJ, iEndJ, availableTop[Constants.START], availableTop[Constants.END])) {
                        canSetTop = false
                      }
                      if (availableBottom && canSetBottom && DateUtils.isHourOverlap(iStartJ, iEndJ, availableBottom[Constants.START], availableBottom[Constants.END])) {
                        canSetBottom = false
                      }
                    }
                    if (!canSetTop && !canSetBottom) {
                      break
                    }
                  }
                  if (canSetTop && !DateUtils.isEventSameTime(availableTop[Constants.START], availableTop[Constants.END])) {
                    availableTimes.push(availableTop)
                  }
                  if (canSetBottom && !DateUtils.isEventSameTime(availableBottom[Constants.START], availableBottom[Constants.END])) {
                    availableTimes.push(availableBottom)
                  }
                }
                canSet = false
                break
              }
            }
          }

          if (canSet && !DateUtils.isEventSameTime(available[Constants.START], available[Constants.END])) {
            availableTimes.push(available)
          }

          start += duration
        }
      }

      const path = date.year() + '/' + (date.month() + 1) + '/' + date.date()
      //const now = moment()
      const realAvailableTimes = loadRealAvailableTimes(employee, date)

      for (let i in availableTimes) {
        const item = availableTimes[i]
        const dStart = moment(item[Constants.DATE]).second(0)
        const dEnd = moment(item[Constants.DATE]).second(0)
        const startTime = DateUtils.getHourMinuteFromNumber(item[Constants.START])
        const endTime = DateUtils.getHourMinuteFromNumber(item[Constants.END])
        const dStartTime = dStart.hour(startTime.hour).minute(startTime.minute)
        const dEndTime = dEnd.hour(endTime.hour).minute(endTime.minute)

        // only select time that bigger than current time
        //if (dStartTime.isBefore(now)) {
        //  continue
        //}

        let className = 'store-no-event store-time-unavailable'
        // still show dark gray if time is unavailalbe
        for (let j in realAvailableTimes) {
          const real = realAvailableTimes[j]
          const realStartTime = DateUtils.getHourMinuteFromNumber(real[Constants.START])
          const realEndTime = DateUtils.getHourMinuteFromNumber(real[Constants.END])
          if (DateUtils.isContains(startTime, endTime, realStartTime, realEndTime)) {
            className = 'store-no-event'
            break
          }
        }

        // in mobile version, we will show available slots as events
        if (refEmployee.current) {
          className = className.replace('store-', 'mobile-')
          const event = {
            id: item[Constants.ID],
            title: '',
            start: dStartTime.toDate(),
            end: dEndTime.toDate(),
            resource: { hasEvent: false, path, className: className }
          }
          event[Constants.RESOURCE_ID] = employee[Constants.ID]
          refEvents.current.push(event)
        } else {
          const startDate = dStartTime.toDate()
          const key = employee[Constants.ID] + `_${startDate.getYear()}_${startDate.getMonth() + 1}_${startDate.getDate()}_${startDate.getHours()}_${startDate.getMinutes()}`
          refSlots.current[key] = className
        }
      }
    }
  }

  const createEventFromItem = (item, isEdit = false) => {
    let date = moment(isEdit ? item[Constants.DATE_EDIT] : (item[Constants.DATE_PARSED] || item[Constants.DATE]))
    let hourMinute = DateUtils.getHourMinuteFromNumber(isEdit ? item[Constants.START_EDIT] : item[Constants.START])
    const start = date.hour(hourMinute.hour).minute(hourMinute.minute)
    const path = date.year() + '/' + (date.month() + 1) + '/' + date.date() + '/' + item[Constants.ID]
    date = moment(isEdit ? item[Constants.DATE_EDIT] : (item[Constants.DATE_PARSED] || item[Constants.DATE]))
    hourMinute = DateUtils.getHourMinuteFromNumber(isEdit ? item[Constants.END_EDIT] : item[Constants.END])
    const end = date.hour(hourMinute.hour).minute(hourMinute.minute)
    const event = {
      id: item[Constants.ID],
      title: `${item[Constants.USER][Constants.FIRST_NAME]} ${item[Constants.USER][Constants.LAST_NAME]} - ${item[Constants.SERVICE][Constants.TITLE]}`,
      start: start.toDate(),
      end: end.toDate(),
      resource: { hasEvent: true, path, className: 'has-event', style: { backgroundColor: item[Constants.COLOR] || item[Constants.SERVICE][Constants.COLOR] } }
    }
    const employee = isEdit ? item[Constants.EMPLOYEE_EDIT] : item[Constants.EMPLOYEE]
    event[Constants.RESOURCE_ID] = employee[Constants.ID]
    return event
  }

  const filterData = async (currentDate) => {
    refEvents.current = []
    refSlots.current = {}

    for (let i in refItems.current) {
      const item = refItems.current[i]
      const date = item[Constants.DATE_PARSED] || moment(item[Constants.DATE])
      item[Constants.DATE_PARSED] = date

      // only get items in selected day
      if (!currentDate.isSame(date, 'day')) {
        continue
      }

      // filter out items that was already in Draggable or Updated View
      const updatedItems = getUpdatedItems()
      let index = updatedItems.findIndex(value => value[Constants.ID] == item[Constants.ID])
      if (index == -1) {
        const draggableItems = getDraggableItems()
        index = draggableItems.findIndex(value => value[Constants.ID] == item[Constants.ID])
      }
      if (index != -1) {
        continue
      }

      const event = createEventFromItem(item)
      refEvents.current.push(event)
    }

    // load available times for date that bigger than today
    refAvailableTimes.current = []
    if (currentDate.isSameOrAfter(moment(), 'day')) {
      if (refEmployee.current) {
        loadAvailableTimes(refEmployee.current, currentDate)
      } else {
        for (let i in refEmployees.current) {
          loadAvailableTimes(refEmployees.current[i], currentDate)
        }
      }
    }

    // If did not order before then order from left to right the employees based on their starting work times for that day.
    const orderColumns = Storage.getJson(Constants.EMPLOYEE_ORDERS, false)
    let mapHrs = null
    if (orderColumns) {
      refResourceMap.current = orderColumns
    } else {
      let key = `${currentDate.year()}_${currentDate.month() + 1}_${currentDate.date()}`
      if (refCacheMapHrs.current[key]) {
        mapHrs = refCacheMapHrs.current[key]
      } else {
        mapHrs = {}
        const day = currentDate.format('dddd').toUpperCase()
        for (let i in refEmployees.current) {
          const employee = refEmployees.current[i]
          const workingPeriods = firebase.getEmployeeWorkingPeriods(employee, currentDate)
          for (let i in workingPeriods) {
            const workingPeriod = workingPeriods[i]
            const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
            const dateStr = DateUtils.getDayByNumber(dateNum)
            if (day == dateStr) {
              if (workingPeriod[Constants.IS_CHECK]) {
                const hours = workingPeriod[Constants.HOURS] || []
                let startHr = Constants.CALENDAR_END_HOUR
                for (let j in hours) {
                  const hour = hours[j]
                  if (startHr == Constants.CALENDAR_END_HOUR || startHr > hour[Constants.START]) {
                    startHr = hour[Constants.START]
                  }
                }
                mapHrs[employee[Constants.ID]] = startHr
              }
              break
            }
          }
        }
        refCacheMapHrs.current[key] = mapHrs
      }
    }

    if (refEmployee.current) {
      let resource = refResourceMap.current.find((value) => value[Constants.RESOURCE_ID] == refEmployee.current[Constants.ID])
      if (resource) {
        setResourcesMap([resource])
      } else {
        resource = {}
        resource[Constants.RESOURCE_ID] = refEmployee.current[Constants.ID]
        resource[Constants.RESOURCE_TITLE] = `${refEmployee.current[Constants.FIRST_NAME]} ${refEmployee.current[Constants.LAST_NAME]}`
        resource[Constants.RESOURCE_IMAGE] = StringUtils.checkUndefined(refEmployee.current[Constants.IMAGE])
        setResourcesMap([resource])
      }
      let total = {}
      for (let i in refItems.current) {
        const item = refItems.current[i]
        if (item[Constants.EMPLOYEE][Constants.ID] == refEmployee.current[Constants.ID]) {
          const date = item[Constants.DATE_PARSED] || moment(item[Constants.DATE])
          item[Constants.DATE_PARSED] = date
          const key = `${date.year()}-${date.month() + 1}-${date.date()}`
          total[key] = total[key] ? total[key] + 1 : 1
        }
      }
      if (props.onLoadedTotal) {
        props.onLoadedTotal(total)
      }
    } else {
      if (mapHrs) {
        refResourceMap.current.sort((a, b) => {
          const h1 = mapHrs[a[Constants.RESOURCE_ID]] || Constants.CALENDAR_END_HOUR
          const h2 = mapHrs[b[Constants.RESOURCE_ID]] || Constants.CALENDAR_END_HOUR
          const t1 = a[Constants.RESOURCE_TITLE]
          const t2 = b[Constants.RESOURCE_TITLE]
          return (h1 < h2) ? -1 : ((h2 < h1) ? 1 : ((t1 < t2) ? -1 : ((t2 < t1) ? 1 : 0)))
        })
      }
      setResourcesMap(refResourceMap.current)
      if (props.onLoadedResourcesMap) {
        props.onLoadedResourcesMap(refResourceMap.current)
      }
      if (props.onLoadedResourcesMapTimesView) {
        props.onLoadedResourcesMapTimesView(refResourceMap.current)
      }
    }

    setEvents(refEvents.current)

    addTimeHeaderNode()
  }

  const eventPropGetter = (event, start, end, isSelected) => {
    return { className: event.resource.className, style: event.resource.style }
  }

  const onSelectEvent = (event) => {
    const resource = event.resource
    if (resource.hasEvent) {
      Navigator.navigate(props, Constants.PAGES_STORE_APPOINTMENT, `${Constants.PATH}=${resource.path}`)
    } else {
      onSelectSlot(event)
    }
  }

  const onSelectSlot = (slot) => {
    const employeeId = slot.resourceId
    const startDate = moment(slot.start)
    // can not choose the date is before today
    if (startDate.isBefore(moment(), 'day')) {
      return
    }
    const hourMinute = { hour: startDate.hour(), minute: startDate.minute() }
    const hourNum = DateUtils.getNumberFromHourMinute(hourMinute) // convert to number       
    for (let i in refEmployees.current) {
      if (refEmployees.current[i][Constants.ID] == employeeId) {
        Storage.clearKey(Constants.BOOKING)
        Storage.clearKey(Constants.CACHE_STORE_INFO)
        Storage.clearKey(Constants.CACHE_STORE_BOOKINGS)
        Storage.clearKey(Constants.CACHE_STORE_EMPLOYEES)
        Storage.clearKey(Constants.CACHE_STORE_SERVCIES)
        Storage.clearKey(Constants.CACHE_BOOK_TIME)
        const booking = Storage.getJson(Constants.BOOKING)
        booking[Constants.ID] = StringUtils.getUniqueID()
        booking[Constants.USER] = null
        booking[Constants.SERVICE] = null
        booking[Constants.EMPLOYEE] = refEmployees.current[i]
        booking[Constants.ITEMS] = null
        booking[Constants.DATE] = startDate.toISOString()
        booking[Constants.START] = hourNum
        booking[Constants.COLOR] = null
        Storage.setJson(Constants.BOOKING, booking)
        Navigator.navigate(props, Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_APPOINTMENT)
        break
      }
    }
  }

  const getTotal = () => {
    const key = `${refDate.current.year()}-${refDate.current.month() + 1}-${refDate.current.date()}`
    const total = refTotal.current[key] ?? 0
    return `${total} ${total == 1 ? Strings.appointment : Strings.appointments}`
  }

  const onEditOrder = () => {
    if (props.onEditOrder) {
      props.onEditOrder()
    }
  }

  const onEditTimes = () => {
    if (props.onClickTimes) {
      props.onClickTimes(refDate.current)
    }
  }

  const eventComponent = (event) => {
    return <div draggable='true'>
      {event.title}
    </div>
  }

  const timeSlotWrapper = (wrapper) => {
    if (!wrapper.resource || !wrapper.value) {
      return wrapper.children
    }
    let date = wrapper.value
    let today = refToday.current
    if (!DateUtils.isGreaterOrSameDay(today, date)) {
      const child = React.Children.only(wrapper.children)
      return React.cloneElement(child, { className: child.props.className + ' store-no-event store-time-unavailable' })
    }

    let h = date.getHours()
    let m = date.getMinutes()
    let x = h >= 12 ? 'PM' : 'AM'
    h = h % 12
    h = h ? h : 12
    m = m < 10 ? '0' + m : m
    let text = h + ':' + m + ' ' + x
    const child = React.Children.only(wrapper.children)

    let key = `${wrapper.resource}_${date.getYear()}_${date.getMonth() + 1}_${date.getDate()}_${date.getHours()}_${date.getMinutes()}`
    let className = `${child.props.className} ${refSlots.current[key] || 'store-no-event store-time-unavailable'}`

    return React.cloneElement(child, { className: className }, <span>{text}</span>)
  }

  const draggableAccessor = (event) => {
    return event.resource.hasEvent
  }

  const resizableAccessor = (event) => {
    return event.resource.hasEvent
  }

  const onAppointmentChanged = async (item, deleteOutside = false) => {
    let list = []
    // step 1: get all working hours in specific day and also filter offline hours
    const employee = item[Constants.EMPLOYEE_EDIT] || item[Constants.EMPLOYEE]
    const service = item[Constants.SERVICE]
    const workingPeriods = initWorkingPeriodEmployee()
    const date = moment(item[Constants.DATE_EDIT] || item[Constants.DATE_PARSED] || item[Constants.DATE])
    const day = date.format('dddd').toUpperCase()
    for (let i in workingPeriods) {
      const workingPeriod = workingPeriods[i]
      const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
      const dateStr = DateUtils.getDayByNumber(dateNum)
      if (day == dateStr) {
        const hours = workingPeriod[Constants.HOURS] || []
        for (let j in hours) {
          const item = hours[j]
          list.push({ ...item })
        }
        break
      }
    }
    let timesPair = [] // hold [pair]
    if (list.length > 0) {
      DataUtils.sortBy(list, Constants.START)

      // step 2: set start to selected time if it is available
      const qStart = item[Constants.START_EDIT] || item[Constants.START]
      if (qStart && qStart > 0) {
        for (let i in list) {
          const item = list[i]
          if (qStart >= item[Constants.START] && qStart <= item[Constants.END]) {
            item[Constants.START] = qStart
            break
          }
        }
      }

      // step 3: calculate available times for booking
      // using start and end of each pair
      let pair = {} // hold {start, end}
      for (let i in list) {
        const item = list[i]
        if (i == 0) {
          pair[Constants.START] = item
          pair[Constants.END] = item
        } else {
          const previous = list[i - 1]
          if (item[Constants.START] == previous[Constants.END]) {
            pair[Constants.END] = item
          } else {
            timesPair.push(pair)
            pair = {}
            pair[Constants.START] = item
            pair[Constants.END] = item
          }
        }
      }
      timesPair.push(pair)
    }
    let available = false
    const startEdit = item[Constants.START_EDIT] || item[Constants.START]
    const endEdit = item[Constants.END_EDIT] || item[Constants.END]
    // Condition 1: check with available times    
    for (let i in timesPair) {
      const pair = timesPair[i]
      const start = pair[Constants.START][Constants.START]
      const end = pair[Constants.END][Constants.END]
      if (startEdit >= start && endEdit <= end) {
        available = true
        break
      }
    }
    // Condition 2: check with other booked services of same employee
    if (available) {
      const id = item[Constants.ID]
      for (let i in refItems.current) {
        const item1 = refItems.current[i]
        const iDate = item1[Constants.DATE_PARSED] || moment(item1[Constants.DATE])
        item1[Constants.DATE_PARSED] = iDate
        const iStart = item1[Constants.START]
        const iEnd = item1[Constants.END]
        // check case when already choose time
        if (id == item1[Constants.ID]) {
          continue
        }
        if (employee[Constants.ID] == item1[Constants.EMPLOYEE][Constants.ID] && date.isSame(iDate, 'day')
          && DateUtils.isHourOverlap(iStart, iEnd, startEdit, endEdit)) {
          available = false
          break
        }
      }
    }
    if (available) {
      // update to db
      const year = date.year()
      const month = date.month() + 1 // month is zero index
      const day = date.date()
      const user = item[Constants.USER]
      const oldDate = moment(item[Constants.DATE])
      const path = oldDate.year() + '/' + (oldDate.month() + 1) + '/' + oldDate.date() + '/' + item[Constants.ID]
      const updates = {}
      updates[Constants.STORE] = firebase.storeForBooking(item[Constants.STORE])
      updates[Constants.SERVICE] = firebase.serviceForBooking(item[Constants.SERVICE])
      updates[Constants.EMPLOYEE] = firebase.employeeForBooking(item[Constants.EMPLOYEE_EDIT] || item[Constants.EMPLOYEE])
      updates[Constants.USER] = firebase.userForBooking(user)
      updates[Constants.COLOR] = item[Constants.COLOR]
      updates[Constants.DATE] = date.toISOString()
      updates[Constants.START] = item[Constants.START_EDIT] || item[Constants.START]
      updates[Constants.END] = item[Constants.END_EDIT] || item[Constants.END]
      updates[Constants.DATE_MODIFIED] = Date.now()
      updates[Constants.TIME_ZOME] = moment.tz.guess()
      setLoading(props, true)
      let success = false
      try {
        const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_STORE_EDIT_BOOKING)
        await httpsCallable({ path, userId: user[Constants.ID], storeId, year, month, day, updates })
        success = true
      } catch (err) {
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      }
      if (success) {
        // delete item in Draggable View
        if (deleteOutside && props.onEventDeleteOutside) {
          props.onEventDeleteOutside(item)
        }
        showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
      }
      setLoading(props, false)
    } else {
      showToast(props, TOAST_ERROR, Strings.noAvailableTime)
    }
  }

  const onEventDrop = (data) => {
    const dStart = moment(data[Constants.START])
    // if (dStart.isBefore(moment())) {
    //   showToast(props, TOAST_ERROR, Strings.timeMoveUnavailableBiggerThanToday)
    //   return
    // }
    const event = data.event
    const item = refAllItems.current[event[Constants.ID]]
    if (item) {
      const dEnd = moment(data[Constants.END])
      item[Constants.DATE_EDIT] = dStart.toISOString()
      item[Constants.START_EDIT] = DateUtils.getNumberFromHourMinute({ hour: dStart.hour(), minute: dStart.minute() })
      item[Constants.END_EDIT] = DateUtils.getNumberFromHourMinute({ hour: dEnd.hour(), minute: dEnd.minute() })
      // need to re-populate employee to get working period and offline
      const employee = refEmployees.current.find(value => value[Constants.ID] == item[Constants.EMPLOYEE][Constants.ID])
      if (employee) {
        item[Constants.EMPLOYEE] = employee
      }
      const employeeEdit = refEmployees.current.find(value => value[Constants.ID] == data[Constants.RESOURCE_ID])
      if (employeeEdit) {
        item[Constants.EMPLOYEE_EDIT] = employeeEdit
      }
      // const index = refEvents.current.findIndex(value => value[Constants.ID] == event[Constants.ID])
      // if (index != -1) {
      //   refEvents.current.splice(index, 1)
      //   setEvents([...refEvents.current])
      // }
      onAppointmentChanged(item)
    }
  }

  const onEventDropOutside = (event) => {
    if (props.getDraggableBounds && refDraggingPoint.current) {
      const bounds = props.getDraggableBounds()
      const point = refDraggingPoint.current
      const { left, right, top, bottom } = bounds
      const { x, y } = point
      if (x < right && x > left && y > top && y < bottom) {
        if (props.onEventDropOutside) {
          const item = refAllItems.current[event[Constants.ID]]
          if (item) {
            const index = refEvents.current.findIndex(value => value[Constants.ID] == event[Constants.ID])
            if (index != -1) {
              refEvents.current.splice(index, 1)
              setEvents([...refEvents.current])
            }
            // put item to Draggable View
            props.onEventDropOutside(item)
          }
        }
      }
      refDraggingPoint.current = null
    }
  }

  const onEventResize = (data) => {
    const dStart = moment(data[Constants.START])
    // if (dStart.isBefore(moment())) {
    //   showToast(props, TOAST_ERROR, Strings.timeMoveUnavailableBiggerThanToday)
    //   return
    // }
    const event = data.event
    const item = refAllItems.current[event[Constants.ID]]
    if (item) {
      const dEnd = moment(data[Constants.END])
      item[Constants.DATE_EDIT] = dStart.toISOString()
      item[Constants.START_EDIT] = DateUtils.getNumberFromHourMinute({ hour: dStart.hour(), minute: dStart.minute() })
      item[Constants.END_EDIT] = DateUtils.getNumberFromHourMinute({ hour: dEnd.hour(), minute: dEnd.minute() })
      // need to re-populate employee to get working period and offline
      const employee = refEmployees.current.find(value => value[Constants.ID] == item[Constants.EMPLOYEE][Constants.ID])
      if (employee) {
        item[Constants.EMPLOYEE] = employee
      }
      const employeeEdit = refEmployees.current.find(value => value[Constants.ID] == data[Constants.RESOURCE_ID])
      if (employeeEdit) {
        item[Constants.EMPLOYEE_EDIT] = employeeEdit
      }
      // const index = refEvents.current.findIndex(value => value[Constants.ID] == event[Constants.ID])
      // if (index != -1) {
      //   refEvents.current.splice(index, 1)
      //   setEvents([...refEvents.current])
      // }
      onAppointmentChanged(item)
    }
  }

  const dragFromOutsideItem = () => {
    if (props.dragFromOutsideItem) {
      return props.dragFromOutsideItem()
    }
    return null
  }

  const onDragOver = (event) => {
    event.preventDefault()
  }

  const onDropFromOutside = (data) => {
    const bounds = getDroppableBounds()
    const point = data.point
    const { left, right, top, bottom } = bounds
    const { x, y } = point
    if (x < right && x > left && y > top && y < bottom) {
      const dStart = moment(data[Constants.START])
      // if (dStart.isBefore(moment())) {
      //   showToast(props, TOAST_ERROR, Strings.timeMoveUnavailableBiggerThanToday)
      //   return
      // }
      const event = dragFromOutsideItem()
      if (event) {
        const item = refAllItems.current[event[Constants.ID]]
        if (item) {
          const duration = item[Constants.END] - item[Constants.START]
          item[Constants.DATE_EDIT] = dStart.toISOString()
          item[Constants.START_EDIT] = DateUtils.getNumberFromHourMinute({ hour: dStart.hour(), minute: dStart.minute() })
          item[Constants.END_EDIT] = item[Constants.START_EDIT] + duration
          // need to re-populte employee to get working period and offline
          const employee = refEmployees.current.find(value => value[Constants.ID] == item[Constants.EMPLOYEE][Constants.ID])
          if (employee) {
            item[Constants.EMPLOYEE] = employee
          }
          const employeeEdit = refEmployees.current.find(value => value[Constants.ID] == data[Constants.RESOURCE_ID])
          if (employeeEdit) {
            item[Constants.EMPLOYEE_EDIT] = employeeEdit
          }
          onAppointmentChanged(item, true)
        }
      }
    }
  }

  const onDragStart = (event) => {
  }

  const onDragToOutside = (event, point) => {
    refDraggingPoint.current = point
  }

  const addTimeHeaderNode = () => {
    if (refTimer.current) {
      clearTimeout(refTimer.current)
      refTimer.current = null
    }
    refTimer.current = setTimeout(() => {
      doAddTimeHeaderNode()
      // double check to ensure the UI is inflated
      // if (refTimer.current) {
      //   clearTimeout(refTimer.current)
      //   refTimer.current = null
      // }
      // refTimer.current = setTimeout(() => {
      //   doAddTimeHeaderNode()
      // }, 1000)
    }, 1000)
  }

  const doAddTimeHeaderNode = () => {
    // calculate scroll width
    if (refDiv.current && refDiv.current.querySelector) {
      let height = refTimeHeaderStyle.current.height
      let scrollWidth = refTimeHeaderStyle.current.scrollWidth
      let gutterWidth = refTimeHeaderStyle.current.gutterWidth
      let gutterHeight = refTimeHeaderStyle.current.gutterHeight
      const child = refDiv.current.querySelector('.rbc-time-header')
      if (child) {
        const bounds = child.getBoundingClientRect()
        height = bounds.height
        scrollWidth = child.scrollWidth
      }
      const timeContent = refDiv.current.querySelector('.rbc-time-content')
      if (timeContent) {
        const timeBounds = timeContent.getBoundingClientRect()
        gutterHeight = timeBounds.height
        const gutter = timeContent.querySelector('.rbc-time-gutter')
        if (gutter) {
          const gutterBounds = gutter.getBoundingClientRect()
          gutterWidth = gutterBounds.width + 1
        }
      }
      if (height != refTimeHeaderStyle.current.height || scrollWidth != refTimeHeaderStyle.current.scrollWidth
        || gutterWidth != refTimeHeaderStyle.current.gutterWidth || gutterHeight != refTimeHeaderStyle.current.gutterHeight) {
        refTimeHeaderStyle.current = { height, scrollWidth, gutterWidth, gutterHeight }
        setTimeHeaderStyle(refTimeHeaderStyle.current)
      }
    }
  }

  const onScroll = (e) => {
    if (refDivGutter.current) {
      refDivGutter.current.scrollTop = e.target.scrollTop
    }
  }

  const getGutterText = (value) => {
    if (value == 12) {
      return `${value} PM`
    } else if (value > 12) {
      return `${value - 12} PM`
    }
    return `${value} AM`
  }
  
  props.onClickDay = (value) => {
    refToday.current = new Date()
    // check to load new date
    if (!refDate.current.isSame(value, 'day')) {
      // check to load new appoiments for new month
      if (!refDate.current.isSame(value, 'month')) {
        refDate.current = value
        loadData()
      } else {
        refDate.current = value
        filterData(value)
      }
    }
  }

  props.onSelectEmployee = (value) => {
    refEmployee.current = value
    filterData(refDate.current)
  }

  props.onChangedResourcesMap = (list) => {
    refResourceMap.current = list
    setResourcesMap(list)
    addTimeHeaderNode()
  }

  props.onDeleteEventOutside = (item) => {
    const event = createEventFromItem(item)
    refEvents.current.push(event)
    setEvents([...refEvents.current])
  }

  props.onTimesUpdated = () => {
    loadData()
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()
  const isMobileBrowser = ScreenUtils.isMobileBrowser()
  const cWidth = screenWidth - (isMobile ? 0 : Styles.sideCalendar)
  const cHeight = screenHeight - 10 - Styles.toolbarHeight * 3
  const cScrollWidth = timeHeaderStyle.scrollWidth > 0 ? (timeHeaderStyle.scrollWidth + 10) : '100%'
  const calendarHeight = (Constants.CALENDAR_END_HOUR - Constants.CALENDAR_START_HOUR) * Constants.CALENDAR_TIME_SLOTS * Constants.CALENDAR_SLOT_HEIGHT

  if (refScreenWidth.current != screenWidth) {
    refScreenWidth.current = screenWidth
    addTimeHeaderNode()
  }

  return <>
    <div className={classes.headerBox}>
      <ITypography variant={isMobile ? 'body1' : 'h5'} style={{ width: 500 }}>{refDate.current.format('dddd, MMMM Do YYYY')}</ITypography>
      <div className={classes.addButton}>
        {!isMobile && <AddButton style={{ marginLeft: 10 }} startIcon={<EditIcon />} onClick={onEditTimes}>{Strings.editSchedule}</AddButton>}
        {!isMobile && <AddButton style={{ marginLeft: 10 }} startIcon={<EditIcon />} onClick={onEditOrder}>{Strings.editOrder}</AddButton>}
        {!isMobileBrowser && <AddButton style={{ marginLeft: 10 }} startIcon={<AddIcon />} onClick={() => onInviteAppointment()}>{Strings.inviteAppointment}</AddButton>}
        {!isMobile && isMobileBrowser && <AddButton style={{ marginLeft: 10 }} startIcon={<AddIcon />} onClick={() => onAddSingleAppointment()}>{Strings.addAppointment}</AddButton>}
      </div>
    </div>
    <ITypography variant='body2'>{getTotal()}</ITypography>
    <div ref={refDiv} onScroll={onScroll} style={{ width: cWidth, height: cHeight, overflow: 'scroll', display: 'flex' }}>
      <DragAndDropCalendar
        formats={Constants.APPOINTMENT_FORMATS}
        date={refDate.current.toDate()}
        min={moment(refDate.current).hour(Constants.CALENDAR_START_HOUR).minute(Constants.CALENDAR_START_MINUTE).toDate()}
        max={moment(refDate.current).hour(Constants.CALENDAR_END_HOUR).minute(Constants.CALENDAR_END_MINUTE).toDate()}
        events={events}
        resources={resourcesMap}
        resourceIdAccessor={Constants.RESOURCE_ID}
        resourceTitleAccessor={Constants.RESOURCE_TITLE}
        selectable={!isMobileBrowser}
        resizable={!isMobileBrowser}
        localizer={momentLocalizer(moment)}
        defaultView='day'
        toolbar={false}
        timeslots={Constants.CALENDAR_TIME_SLOTS}
        step={Constants.CALENDAR_TIME_STEP}
        onNavigate={() => { }}
        views={{ day: true }}
        style={{ width: cScrollWidth, height: calendarHeight || Styles.appointmentsHeight }}
        eventPropGetter={eventPropGetter}
        onSelectEvent={(event) => onSelectEvent(event)}
        onSelectSlot={(slot) => onSelectSlot(slot)}
        onSelecting={slot => false}
        onEventDrop={(event) => onEventDrop(event)}
        onEventDropOutside={(event) => onEventDropOutside(event)}
        onEventResize={(event) => onEventResize(event)}
        draggableAccessor={(event) => !isMobileBrowser && draggableAccessor(event)}
        resizableAccessor={(event) => !isMobileBrowser && resizableAccessor(event)}
        dragFromOutsideItem={() => dragFromOutsideItem()}
        onDropFromOutside={(event) => onDropFromOutside(event)}
        onDragToOutside={(event, point) => onDragToOutside(event, point)}
        onDragStart={(event) => onDragStart(event)}
        onDragOver={(event) => onDragOver(event)}
        components={{
          event: eventComponent,
          timeSlotWrapper: timeSlotWrapper,
        }}
      />
      {!isMobile && <div ref={refDivGutter} style={{
        width: timeHeaderStyle.gutterWidth, height: cHeight - 15, position: 'absolute',
        zIndex: 999, overflow: 'hidden', background: 'white', border: '1px solid #ddd'
      }}>
        <div style={{ width: timeHeaderStyle.gutterWidth, height: Styles.appointmentsHeight }}>
          <div style={{
            marginTop: timeHeaderStyle.height - 1, width: timeHeaderStyle.gutterWidth, height: timeHeaderStyle.gutterHeight, display: 'flex', flexDirection: 'column'
          }}>
            {refGutterTimes.current.map((value) => {
              return <div key={value} style={{ flex: 1, borderTop: '1px solid #DDD', display: 'flex', justifyContent: 'center', fontSize: '90%' }}>
                {getGutterText(value)}
              </div>
            })}
          </div>
        </div>
      </div>}
    </div>
  </>
}

function SideCalendarView({ props }) {
  Logger.log('SideCalendarView')

  const [date, setDate] = useState(moment())
  const [total, setTotal] = useState({})

  useEffect(() => {
    const chooseDate = Storage.getString(Constants.APPOINTMENT_CHOOSE_DATE)
    if (chooseDate) {
      onClickDay(moment(chooseDate))
    }
  }, [])

  const onClickDay = (value, fromUser) => {
    setDate(value)
    if (props.onClickDay) {
      props.onClickDay(value)
    }
    if (fromUser) {
      Storage.setString(Constants.APPOINTMENT_CHOOSE_DATE, value.toISOString())
    }
  }

  const dateRender = (current, value) => {
    const key = `${current.year()}-${current.month() + 1}-${current.date()}`
    if (total[key] && total[key] > 0) {
      return <div className='rc-calendar-date' style={{ lineHeight: '10px', paddingTop: 5 }}>
        <span>{current.date()}</span>
        <div style={{ width: 4, height: 4, borderRadius: 2, background: 'red', margin: 'auto', marginTop: 2 }} />
      </div>
    }
    return <div className='rc-calendar-date' style={{ lineHeight: '10px', paddingTop: 5 }}>
      <span>{current.date()}</span>
    </div>
  }

  props.onLoadedTotal = async (value) => {
    setTotal(value)
  }

  return <SideCalendar showDateInput={false} value={date}
    onChange={(value) => onClickDay(value, true)}
    dateRender={(current, value) => dateRender(current, value)} />
}

function SideDraggableView({ props }) {
  Logger.log('SideDraggableView')

  const { user, settings } = useGlobal()
  const refDiv = useRef()
  const refDragItem = useRef()
  const [items, setItems] = useState(Storage.getJson(Constants.APPOINTMENT_DRAGGABLE_ITEMS, false, []))

  useEffect(() => {

  }, [])

  const handleDragStart = (e, item) => {
    // need to calculate position of mouse compared to top & left of drag item
    const bounds = e.target.getBoundingClientRect()
    const { clientX, clientY } = e
    const { left, top } = bounds
    item.offsetX = -(clientX - left)
    item.offsetY = -(clientY - top)
    refDragItem.current = item
  }

  const onDeleteItem = (item, putBack = true) => {
    var index = -1
    for (let i in items) {
      if (item[Constants.ID] == items[i][Constants.ID]) {
        index = i
        break
      }
    }
    if (index != -1) {
      items.splice(index, 1)
      setItems([...items])
      Storage.setJson(Constants.APPOINTMENT_DRAGGABLE_ITEMS, items)
      if (putBack && props.onDeleteEventOutside) {
        props.onDeleteEventOutside(item)
      }
    }
  }

  const getTimeText = (item) => {
    const dateStr = moment(item[Constants.DATE]).format('dddd, MMMM Do YYYY')
    const startStr = StringUtils.getHourFromNumber(item[Constants.START])
    const endStr = StringUtils.getHourFromNumber(item[Constants.END])
    return dateStr + ', ' + startStr + ' - ' + endStr
  }

  const getDurationText = (item) => {
    const minutes = DateUtils.getMinutesFromNumber(item[Constants.END] - item[Constants.START])
    return StringUtils.formatMinutes(minutes)
  }

  props.dragFromOutsideItem = () => {
    return refDragItem.current
  }

  props.getDraggableBounds = () => {
    return ScreenUtils.getBoundsForNode(refDiv.current)
  }

  props.onEventDropOutside = (item) => {
    const index = items.findIndex(value => value[Constants.ID] == item[Constants.ID])
    if (index < 0) {
      items.push(item)
      setItems([...items])
      Storage.setJson(Constants.APPOINTMENT_DRAGGABLE_ITEMS, items)
    }
  }

  props.onEventDeleteOutside = (item) => {
    onDeleteItem(item, false)
  }

  props.getDraggableItems = () => {
    return items
  }

  const classes = useStyles()

  return <div ref={refDiv}>
    <ITypography variant='body1' style={{ color: '#666', margin: 10 }}><b>{Strings.selectedAppointments}</b></ITypography>
    <IDivider />
    {items.length == 0 && <ITypography variant='body2' style={{ color: '#666', margin: 10 }}>{Strings.dropAppointmentHint}</ITypography>}
    <IGridList cellHeight='auto' cols={1} style={{ marginBottom: Styles.margin1Br, minHeight: 50 }}>
      {items.map(item => (
        <div className={classes.draggable} draggable='true' key={item[Constants.ID]} onDragStart={(event) => handleDragStart(event, item)}>
          <div style={{ display: 'flex', marginLeft: 5 }} >
            <div style={{ width: 5, backgroundColor: item[Constants.COLOR] || item[Constants.SERVICE][Constants.COLOR] }} />
            <div style={{ marginLeft: 10, width: '100%' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <ITypography variant='body1'><b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                  <ITypography variant='body2'>{getDurationText(item)} {Strings.mins} ${item[Constants.SERVICE][Constants.PRICE]}</ITypography>
                </div>
                <IIconButton style={{ marginLeft: 'auto' }} aria-label={Strings.delete} onClick={() => onDeleteItem(item)}><DeleteIcon /></IIconButton>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize, marginRight: 10 }} draggable='false' src={item[Constants.EMPLOYEE][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''}
                  alt={item[Constants.EMPLOYEE][Constants.FIRST_NAME]} />
                <ITypography variant='body2'>{item[Constants.EMPLOYEE][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE][Constants.LAST_NAME]}</ITypography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AccessAlarmIcon style={{ marginRight: 5 }} /><ITypography variant='body2'>{getTimeText(item)}</ITypography>
              </div>
            </div>
          </div>
          <IDivider style={{ marginTop: 2 }} />
        </div>
      ))}
    </IGridList>
  </div>
}

function TimesView({ props }) {
  Logger.log('TimesView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const refResourceMap = useRef([])
  const refEmployees = useRef([])
  const refDate = useRef(moment())
  const [items, setItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {

  }, [])

  const handleCloseDialog = async (agree) => {
    setOpenDialog(false)
    if (agree) {
      setLoading(props, true)
      let updated = false
      for (let i in items) {
        let item = items[i]
        if (item[Constants.IS_MODIFIED]) {
          // remove old working edit dates
          const refWorkingEdits = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + item[Constants.ID] + '/' + Constants.WORKING_EDIT)
          const responseWorkingEdits = await refWorkingEdits.once('value')
          if (responseWorkingEdits) {
            const val = responseWorkingEdits.val()
            if (val) {
              const now = moment()
              for (let j in val) {
                const edit = val[j]
                const date = moment(edit[Constants.DATE])
                if (date.isBefore(now, 'day')) {
                  const key = `${date.year()}_${date.month() + 1}_${date.date()}`
                  const refRemove = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + item[Constants.ID] + '/' + Constants.WORKING_EDIT + '/' + key)
                  await refRemove.remove()
                }
              }
            }
          }
          // add new working edit
          const date = refDate.current
          const key = `${date.year()}_${date.month() + 1}_${date.date()}`
          const ref = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + item[Constants.ID] + '/' + Constants.WORKING_EDIT + '/' + key)
          item[Constants.ITEMS].forEach((value) => {
            value[Constants.KEY] = null
            value[Constants.PARENT_ID] = null
          }) // clear unused properties
          let updates = {}
          updates[Constants.DATE] = date.toISOString()
          updates[Constants.HOURS] = item[Constants.ITEMS]
          await ref.update(updates)
          updated = true
        }
      }
      setLoading(props, false)
      if (updated) {
        showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
        if (props.onTimesUpdated) {
          props.onTimesUpdated()
        }
      }
    }
  }

  const getInOutStatus = (item) => {
    const child = item[Constants.ITEMS] || []
    for (let i in child) {
      if (child[i][Constants.IS_CHECK]) {
        return true
      }
    }
    return false
  }

  const handleOnOff = (checked, item) => {
    let list = []
    for (let i in items) {
      const value = items[i]
      if (value[Constants.ID] == item[Constants.PARENT_ID]) {
        value[Constants.KEY] = StringUtils.getUniqueID()
        const child = value[Constants.ITEMS] || []
        for (let j in child) {
          const value1 = child[j]
          value1[Constants.KEY] = value[Constants.KEY] + j
          if (value1[Constants.ID] == item[Constants.ID]) {
            value1[Constants.IS_CHECK] = checked
          }
        }
        value[Constants.ITEMS] = [...child]
        value[Constants.IS_MODIFIED] = true
        list.push({ ...value })
      } else {
        list.push(value)
      }
    }
    setItems(list)
  }

  const handleInOut = (checked, item) => {
    let list = []
    for (let i in items) {
      const value = items[i]
      if (value[Constants.ID] == item[Constants.ID]) {
        value[Constants.KEY] = StringUtils.getUniqueID()
        const child = value[Constants.ITEMS] || []
        for (let j in child) {
          child[j][Constants.KEY] = value[Constants.KEY] + j
          child[j][Constants.IS_CHECK] = checked
        }
        value[Constants.ITEMS] = [...child]
        value[Constants.IS_MODIFIED] = true
        list.push({ ...value })
      } else {
        list.push(value)
      }
    }
    setItems(list)
  }

  const handleDeleteTime = (item) => {
    let list = []
    for (let i in items) {
      const v = items[i]
      if (v[Constants.ID] == item[Constants.PARENT_ID]) {
        const child = v[Constants.ITEMS] || []
        const index = child.findIndex(value => value[Constants.ID] == item[Constants.ID])
        if (index >= 0) {
          child.splice(index, 1)
          v[Constants.ITEMS] = [...child]
        }
        v[Constants.IS_MODIFIED] = true
        list.push({ ...v })
      } else {
        list.push(v)
      }
    }
    setItems(list)
  }

  const onAddTime = (item) => {
    const child = item[Constants.ITEMS] || []
    let order = 0
    let start = Constants.WORKING_MORNING_START
    let end = Constants.WORKING_MORNING_END
    if (child.length > 0) {
      const last = child[child.length - 1]
      order = last[Constants.ORDER] + 1
      start = last[Constants.END] + 1
      if (start > Constants.CALENDAR_END_HOUR) {
        start = Constants.CALENDAR_END_HOUR
      }
      end = last[Constants.END] + 1 + Constants.WORKING_STEP
      if (end > Constants.CALENDAR_END_HOUR) {
        end = Constants.CALENDAR_END_HOUR
      }
    }
    let newItem = DateUtils.createHourOfDate(StringUtils.getUniqueID(), order, start, end)
    newItem[Constants.KEY] = item[Constants.KEY] + (child.length + 1)
    newItem[Constants.PARENT_ID] = item[Constants.ID]
    newItem[Constants.IS_CHECK] = true
    item[Constants.ITEMS] = [...child, newItem]
    item[Constants.IS_MODIFIED] = true
    setItems([...items])
  }

  props.onLoadEmployeesTimesView = (list) => {
    refEmployees.current = list
  }

  props.onLoadedResourcesMapTimesView = (list) => {
    refResourceMap.current = list
  }

  props.onClickTimes = (date) => {
    refDate.current = date
    const list = []
    for (let i in refEmployees.current) {
      const employee = refEmployees.current[i]
      const header = {}
      header[Constants.KEY] = StringUtils.getUniqueID()
      header[Constants.ID] = employee[Constants.ID]
      header[Constants.IMAGE] = employee[Constants.IMAGE]
      header[Constants.TITLE] = employee[Constants.FIRST_NAME] + ' ' + employee[Constants.LAST_NAME]
      header[Constants.ORDER] = refResourceMap.current.findIndex(value => value[Constants.RESOURCE_ID] == employee[Constants.ID])
      const child = []
      let useDefault = true
      if (employee[Constants.WORKING_EDIT]) {
        const key = `${date.year()}_${date.month() + 1}_${date.date()}`
        if (employee[Constants.WORKING_EDIT][key]) {
          useDefault = false
          const hours = employee[Constants.WORKING_EDIT][key][Constants.HOURS] || []
          for (let j in hours) {
            const item = hours[j]
            item[Constants.KEY] = header[Constants.KEY] + j
            item[Constants.PARENT_ID] = header[Constants.ID]
            child.push({ ...item })
          }
        }
      }
      if (useDefault && employee[Constants.WORKING_PERIOD]) {
        const workingPeriods = employee[Constants.WORKING_PERIOD]
        const day = date.format('dddd').toUpperCase()
        for (let i in workingPeriods) {
          const workingPeriod = workingPeriods[i]
          const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
          const dateStr = DateUtils.getDayByNumber(dateNum)
          if (day == dateStr) {
            const isCheck = workingPeriod[Constants.IS_CHECK]
            const hours = workingPeriod[Constants.HOURS] || []
            for (let j in hours) {
              const item = hours[j]
              item[Constants.KEY] = header[Constants.KEY] + j
              item[Constants.PARENT_ID] = header[Constants.ID]
              item[Constants.IS_CHECK] = isCheck
              child.push({ ...item })
            }
            break
          }
        }
      }
      header[Constants.ITEMS] = child
      list.push(header)
    }
    DataUtils.sortBy(list, Constants.ORDER)
    setItems(list)
    setOpenDialog(true)
  }

  props.onTimeChanged = (item, hr, min, extra) => {
    for (let i in items) {
      const value = items[i]
      if (value[Constants.ID] == item[Constants.PARENT_ID]) {
        const child = value[Constants.ITEMS] || []
        for (let j in child) {
          const value1 = child[j]
          if (value1[Constants.ID] == item[Constants.ID]) {
            const number = DateUtils.getNumberFromHourMinute({ hour: hr, minute: min })
            if (extra == 'start') {
              value1[Constants.START] = number
            } else {
              value1[Constants.END] = number
            }
            value[Constants.IS_MODIFIED] = true
            break
          }
        }
        break
      }
    }
    setItems([...items])
  }

  return <IDialog open={openDialog} maxWidth='sm' fullWidth={true} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title'>
    <IDialogTitle id='alert-dialog-title'>{Strings.editScheduleFor} {refDate.current.format('dddd, MMMM Do YYYY')}</IDialogTitle>
    <IDialogContent>
      <div style={{ width: '100%' }}>
        <IDivider />
        {openDialog && items.map((row, i) => <div key={row[Constants.KEY]}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
            <IImage style={{ width: Styles.superSmallImageSize, height: Styles.superSmallImageSize }} src={row[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={row[Constants.TITLE]} />
            <ITypography variant='body1' style={{ marginLeft: 10 }}>{row[Constants.TITLE]}</ITypography>
          </div>
          <IDivider style={{ marginTop: 10 }} />
          {row[Constants.ITEMS].map((child, j) => (
            <div key={child[Constants.KEY]}>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                <TimePicker props={props} item={child} select={child[Constants.START]} extra='start' />
                <div style={{ width: '40%', textAlign: 'center' }}><ITypography variant='body2' style={{ marginLeft: 10, marginRight: 10 }}>{Strings.to}</ITypography></div>
                <TimePicker props={props} item={child} select={child[Constants.END]} extra='end' />
                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <ISwitch checked={child[Constants.IS_CHECK]} onChange={event => handleOnOff(event.target.checked, child)} />
                  <IIconButton onClick={() => handleDeleteTime(child)}><CancelIcon /></IIconButton>
                </div>
              </div>
              {j != row[Constants.ITEMS].length - 1 && <IDivider style={{ marginTop: 10, marginLeft: 20 }} light={true} />}
            </div>
          ))}
          <IDivider style={{ marginTop: 10, marginLeft: 20 }} light={true} />
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
            <ITypography variant='body2' style={{ marginLeft: 20 }}>{Strings.inout}:</ITypography>
            <ISwitch checked={getInOutStatus(row)} onChange={event => handleInOut(event.target.checked, row)} />
            <ITypography variant='body2' style={{ marginLeft: 20 }}>{Strings.addTime}</ITypography>
            <IIconButton onClick={() => onAddTime(row)}><AddIcon /></IIconButton>
          </div>
          <IDivider style={{ marginTop: 10 }} />
        </div>)}
      </div>
    </IDialogContent>
    <IDialogActions>
      <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
      <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
    </IDialogActions>
  </IDialog>
}

function StoreAppointments() {
  Logger.log(Constants.PAGES_STORE_APPOINTMENTS)

  let props = createPropsWithActions()
  const classes = useStyles()

  const isMobile = ScreenUtils.isMobile()
  const isMobileBrowser = ScreenUtils.isMobileBrowser()

  return <div className={classes.root}>
    <div style={{ width: '100%' }}>
      <ICard>
        <ICardContent>
          {isMobile && <EmployeesView props={props} />}
          <EmployeeColumnsView props={props} />
          {!isMobile && <OrderView props={props} />}
        </ICardContent>
      </ICard>
    </div>
    <div className={classes.sideCalendar}>
      <div>
        <ICard>
          <ICardContent style={{ padding: 0 }}>
            <SideCalendarView props={props} />
          </ICardContent>
        </ICard>
      </div>
      {!isMobileBrowser && <div style={{ marginTop: 20 }}>
        <ICard className={classes.draggableDiv} >
          <ICardContent style={{ padding: 0 }}>
            <SideDraggableView props={props} />
          </ICardContent>
        </ICard>
      </div>}
    </div>
    <TimesView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </div>
}

export default StoreAppointments