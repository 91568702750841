import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import * as DataUtils from '../../../common/DataUtils'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import MenuItem from '../../controls/IMenuItem'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import IImage from '../../controls/IImage'
import ColorPicker from '../../widgets/ColorPicker'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  },
  divColor: {
    width: 100,
    height: 40,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID)
  const refTimer = useRef()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [duration, setDuration] = useState('')
  const [category, setCategory] = useState()
  const [categories, setCategories] = useState([])
  const [image, setImage] = useState()
  const [src, setSrc] = useState('')
  const [color, setColor] = useState('')

  useEffect(() => {
    loadCategories()

    if (refTimer.current) {
      clearTimeout(refTimer.current)
    }
  }, [])

  const loadCategories = async () => {
    setLoading(props, true)
    const list = []
    const ref1 = firebase.databaseRef(Constants.FB_SYSTEM_CATEGORIES)
    const response1 = await ref1.once('value')
    if (response1) {
      const val = response1.val()
      if (val) {
        for (let id in val) {
          list.push(val[id])
        }
      }
    }
    const ref2 = firebase.databaseRef(Constants.FB_STORE_CATEGORIES + '/' + userId + '/' + storeId)
    const response2 = await ref2.once('value')
    if (response2) {
      const val = response2.val()
      if (val) {
        for (let id in val) {
          list.push(val[id])
        }
      }
    }
    DataUtils.sortBy(list, Constants.TITLE)
    setCategories(list)
    setLoading(props, false)
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (!StringUtils.isValidUploadImage(file.name)) {
        setImage(null)
        setSrc('')
        showToast(props, TOAST_ERROR, Strings.errorWrongFormat + StringUtils.arrayToString(Constants.SUPPORT_IMAGE_TYPES))
        return
      }
      setImage(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function (e) {
        setSrc(reader.result)
      }.bind(this)
    }
  }

  const postSave = async () => {
    if (category == null || StringUtils.stringEmpty(description) || StringUtils.stringEmpty(title)
      || StringUtils.stringEmpty(price) || StringUtils.stringEmpty(duration) || !StringUtils.isNumber(price)
      || !StringUtils.isNumber(duration) || StringUtils.stringEmpty(color) || image == null) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    setLoading(props, true)
    const id = StringUtils.getUniqueID()
    const updates = {}
    const refStorage = firebase.storageRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId + '/' + id + '/' + image.name)
    const downloadURL = await firebase.uploadFile(refStorage, image, 0, 1, (index, length, bytesTransferred, totalBytes) => {
    })
    if (downloadURL) {
      updates[Constants.IMAGE] = downloadURL
    }
    const ref = firebase.databaseRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId + '/' + id)
    updates[Constants.ID] = id
    updates[Constants.USER_ID] = userId
    updates[Constants.STORE_ID] = storeId
    updates[Constants.TITLE] = title
    updates[Constants.CATEGORY_ID] = category[Constants.ID]
    updates[Constants.DESCRIPTION] = description
    updates[Constants.PRICE] = price
    updates[Constants.DURATION] = duration
    updates[Constants.COLOR] = color
    updates[Constants.DATE_MODIFIED] = Date.now()
    updates[Constants.DATE_ADDED] = Date.now()
    ref.update(updates).then(() => {
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
      setLoading(props, false)
      refTimer.current = setTimeout(() => {
        Navigator.back()
      }, 1000)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const handleSelectCategory = (value) => {
    for (let i in categories) {
      let item = categories[i]
      if (item[Constants.ID] == value) {
        setCategory(item)
        break
      }
    }
  }

  const openColorPicker = (value) => {
    if (props.setColorOpen) {
      props.setColorOpen(value, color)
    }
  }

  props.onColorClosed = (color) => {
    setColor(color)
  }

  const classes = useStyles()

  return <IFormControl className={classes.root}>
    <ITypography className={classes.heading}>{Strings.addService}</ITypography>
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin2Br }} select
      onChange={(event) => {
        handleSelectCategory(event.target.value) 
      }}
      InputLabelProps={{ shrink: true, style: { color: '#fff' } }}
      value={category ? category[Constants.ID] : ''}
      label={Strings.categories}
      helperText={Strings.pleaseSelectCategory}>
      {categories.map(option => (
        <MenuItem key={option[Constants.ID]} value={option[Constants.ID]}>
          {option[Constants.TITLE]}
        </MenuItem>
      ))}
    </ITextField>
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.title} value={title} onChange={(event) => { setTitle(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.description} value={description} onChange={(event) => { setDescription(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.price} helperText={Strings.priceHint} value={price} onChange={(event) => { setPrice(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.duration} helperText={Strings.durationHint} value={duration} onChange={(event) => { setDuration(event.target.value) }} />
    <div className={classes.textField} style={{ marginTop: Styles.margin2Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.color}</ITypography>
      <div className={classes.divColor} style={{ backgroundColor: color }} onClick={() => openColorPicker(true)} />
      <ColorPicker props={props} />
    </div>
    <div className={classes.textField} style={{ marginTop: Styles.margin2Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.image}:</ITypography>      
    </div>
    <ITextField className={classes.textField} type='file' onChange={handleImageChange} />
    <IImage src={src || settings[Constants.DEFAULT_SERVICE_IMAGE]} style={{ width: '200px', height: '200px', marginTop: Styles.margin1Br }} />
    <div style={{ marginTop: Styles.margin2Br }}>
      <IButton onClick={() => Navigator.back()}>{Strings.cancel}</IButton>
      <IButton className={classes.rightButton} onClick={postSave}>{Strings.save}</IButton>
    </div>
  </IFormControl>
}

function AddStoreService() {
  Logger.log(Constants.PAGES_SYSTEM_ADD_STORE_SERVICE)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default AddStoreService