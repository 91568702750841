import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { styled } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useGlobal } from '../../utils/useGlobal'
import * as firebase from '../../utils/firebase'
import * as Logger from '../../common/Logger'
import * as Constants from '../../common/Constants'
import * as Navigator from '../../common/Navigator'
import { setLoading } from '../../common/ViewUtils'
import Styles from '../../common/Styles'
import Strings from '../../common/Strings'
import IAppBar from '../controls/IAppBar'
import IToolbar from '../controls/IToolbar'
import ITypography from '../controls/ITypography'
import IIconButton from '../controls/IIconButton'
import IMenu from '../controls/IMenu'
import IMenuItem from '../controls/IMenuItem'
import IButton from '../controls/IButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuIcon from '@material-ui/icons/Menu'

const LoginButton = styled(IButton)({
    background: 'white',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    border: 0,
    borderRadius: 3,
    color: Styles.primaryBgColor,
    height: 35,
    padding: '0 30px',
})

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: Styles.appBarColor
    },
    toolbar: {
        minHeight: Styles.toolbarHeight
    },
    rightBox: {
        marginLeft: 'auto',
        marginRight: 0
    },
    loginBox: {
        marginLeft: 'auto',
        marginRight: 0,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    welcomeText: {
        fontSize: 18,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
}))

const HeaderView = ({ props }) => {
    Logger.log('HeaderView')

    const { user } = useGlobal()
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [appInfo, setAppInfo] = useState(null)
    const openMore = Boolean(anchorEl)

    useEffect(() => {
        if (user && user[Constants.ROLE] == Constants.ROLE_ADMIN_STORE) {
            const userId = user[Constants.ID]
            const storeId = user[Constants.ID]
            const ref = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
            ref.once('value').then(response => {
                const val = response.val()
                if (val) {                    
                    setAppInfo({ appName: val[Constants.NAME], appColor: val[Constants.COLOR] || 'white' })
                } else {
                    setAppInfo(null)
                }
            }).catch(err => {
                setAppInfo(null)
            })
        } else {
            setAppInfo(null)
        }
    }, [user])

    const handleDrawerOpen = () => {
        setOpen(true)
        props.handleDrawerOpen()
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleMoreClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleMoreClose = () => {
        setAnchorEl(null)
    }

    const handleMoreItemClick = (id) => {
        handleMoreClose()
        if (id == 'signOut') {
            setLoading(props, true)
            firebase.auth().signOut()
        } else if (id == 'profile') {
            const role = user[Constants.ROLE]
            if (role == Constants.ROLE_CUSTOMER) {
                Navigator.navigate(props, Constants.PAGES_CUSTOMER_PROFILE)
            } else if (role == Constants.ROLE_EMPLOYEE_STORE) {
                Navigator.navigate(props, Constants.PAGES_EMPLOYEE_PROFILE)
            } else if (role == Constants.ROLE_ADMIN_STORE) {
                Navigator.navigate(props, Constants.PAGES_STORE_PROFILE)
            }
        }
    }

    props.onHeaderViewCompleted(handleDrawerClose)

    const classes = useStyles()

    return (<IAppBar position='fixed' className={classes.appBar}>
        <IToolbar className={classes.toolbar}>
            <IIconButton color='inherit' aria-label='open drawer' onClick={handleDrawerOpen} edge='start' className={clsx(classes.menuButton, {
                [classes.hide]: open,
            })}
            >
                <MenuIcon />
            </IIconButton>
            {appInfo && <ITypography variant='h6' style={{ color: appInfo.appColor }} noWrap>{appInfo.appName}</ITypography>}
            {user && <div className={classes.rightBox}>
                <ITypography variant='body1' variantMapping={{ body1: 'span' }} className={classes.welcomeText}>{Strings.hi} - {user[Constants.FIRST_NAME]} {user[Constants.LAST_NAME]}</ITypography>
                <IIconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={handleMoreClick} >
                    <MoreVertIcon style={{ color: 'white' }} />
                </IIconButton>
                <IMenu id='long-menu' anchorEl={anchorEl} keepMounted open={openMore} onClose={handleMoreClose}
                    PaperProps={{ style: { maxHeight: 20 * 4.5, width: 200, }, }}>
                    {
                        <div>
                            <IMenuItem key='profile' onClick={() => handleMoreItemClick('profile')}>{Strings.profile}</IMenuItem>
                            <IMenuItem key='signOut' onClick={() => handleMoreItemClick('signOut')}>{Strings.signOut}</IMenuItem>
                        </div>
                    }
                </IMenu>
            </div>}
            {!user && <div className={classes.loginBox}>
                <LoginButton onClick={() => Navigator.navigate(props, Constants.PAGES_LOGIN)}>{Strings.signIn}</LoginButton>
            </div>}
        </IToolbar>
    </IAppBar>)
}

export default HeaderView