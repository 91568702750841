//import Image from 'material-ui-image'
import React from 'react'

class Image extends React.Component {
    render() {
        const { style = { width: 100, height: 100 }, src = '', alt = '', draggable = 'true' } = this.props

        return <div style={{ ...style }}>
            {src && src != '' && <img style={{ objectFit: 'cover', ...style, marginTop: 0, marginLeft: 0, marginRight: 0, marginBottom: 0 }}
                src={src} alt={alt} draggable={draggable} />}
        </div>
    }
}

export default Image