import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import SideCalendar from 'rc-calendar'
import React, { useEffect, useRef, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import * as Constants from '../../../common/Constants'
import * as DataUtils from '../../../common/DataUtils'
import * as DateUtils from '../../../common/DateUtils'
import * as Logger from '../../../common/Logger'
import * as Navigator from '../../../common/Navigator'
import * as ScreenUtils from '../../../common/ScreenUtils'
import * as Storage from '../../../common/Storage'
import Strings from '../../../common/Strings'
import * as StringUtils from '../../../common/StringUtils'
import Styles from '../../../common/Styles'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import { initWorkingPeriodEmployee } from '../../../common/WorkingPeriod'
import * as firebase from '../../../utils/firebase'
import { useGlobal } from '../../../utils/useGlobal'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import IMenuItem from '../../controls/IMenuItem'
import ITextField from '../../controls/ITextField'
import ITypography from '../../controls/ITypography'
import { useWindowSize } from '../../hooks/useWindowSize'
import ProgressBar from '../../widgets/ProgressBar'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  sideCalendar: {
    width: Styles.sideCalendar,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  },
  addButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  headerBox: {
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
  },
  draggable: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function EmployeesView({ props }) {
  Logger.log('EmployeesView')

  const [employee, setEmployee] = useState()
  const [employees, setEmployees] = useState([])

  useEffect(() => {
  }, [])

  const onSelectEmployee = (value) => {
    for (let i in employees) {
      let item = employees[i]
      if (item[Constants.ID] == value) {
        setEmployee(item)
        if (props.onSelectEmployee) {
          props.onSelectEmployee(item)
        }
        Storage.setString(Constants.APPOINTMENT_CHOOSE_EMPLOYEE, value)
        break
      }
    }
  }

  props.onLoadEmployees = (list) => {
    list.sort((a, b) => {
      let f1 = `${a[Constants.FIRST_NAME]} ${a[Constants.LAST_NAME]}`
      let f2 = `${b[Constants.FIRST_NAME]} ${b[Constants.LAST_NAME]}`
      return (f1 < f2) ? -1 : ((f2 < f1) ? 1 : 0)
    })
    setEmployees(list)
    if (list.length > 0) {
      let index = 0
      const choose = Storage.getString(Constants.APPOINTMENT_CHOOSE_EMPLOYEE)
      if (choose) {
        index = list.findIndex((value) => value[Constants.ID] == choose)
        if (index < 0) {
          index = 0
        }
      }
      setEmployee(list[index])
      if (props.onSelectEmployee) {
        props.onSelectEmployee(list[index])
      }
    }
  }

  const classes = useStyles()

  return <div style={{ marginTop: 10, marginBottom: 20 }}>
    <div className={classes.searchBox}>
      <ITypography variant='body1'>{Strings.employee}:</ITypography>
      <ITextField className={classes.textField} select
        onChange={(event) => {
          onSelectEmployee(event.target.value)
        }}
        InputLabelProps={{ shrink: true, style: { color: '#fff' } }}
        value={employee ? employee[Constants.ID] : ''}>
        {employees.map(option => (
          <IMenuItem key={option[Constants.ID]} value={option[Constants.ID]}>
            <ITypography variant='body2'>{option[Constants.FIRST_NAME]} {option[Constants.LAST_NAME]}</ITypography>
          </IMenuItem>
        ))}
      </ITextField>
    </div>
  </div>
}

function EmployeeColumnsView({ props }) {
  Logger.log('EmployeeColumnsView')

  const { user } = useGlobal()
  const userId = user[Constants.ID]
  const storeId = user[Constants.ID]
  const [screenWidth, screenHeight] = useWindowSize()
  const refScreenWidth = useRef(screenWidth)
  const refDiv = useRef()
  const refDivGutter = useRef()
  const refAllItems = useRef({}) // store all items of all selected months
  const refItems = useRef([]) // store items of a selected month
  const refTotal = useRef({})
  const refEmployees = useRef([])
  const refEmployee = useRef()
  const refEvents = useRef([])
  const refSlots = useRef({})
  const refToday = useRef(new Date())
  const refDate = useRef(moment())
  const refResourceMap = useRef([])
  const refAvailableTimes = useRef([])
  const refTimer = useRef()
  const refUpdates = useRef({})
  const refUpdateFuncs = useRef({})
  const refIsFirst = useRef(true)
  const refTimeHeaderStyle = useRef({ height: 0, scrollWidth: 0, gutterWidth: 0, gutterHeight: 0 })
  const refGutterTimes = useRef([])
  const refCacheRealAvailableTimes = useRef({})
  const refCacheMapHrs = useRef({})
  const [events, setEvents] = useState([])
  const [resourcesMap, setResourcesMap] = useState([])
  const [timeHeaderStyle, setTimeHeaderStyle] = useState(refTimeHeaderStyle.current)

  useEffect(() => {
    for (let i = Constants.CALENDAR_START_HOUR; i <= Constants.CALENDAR_END_HOUR; i++) {
      refGutterTimes.current.push(i)
    }

    loadData()

    return () => {
      for (let i in refUpdates.current) {
        if (refUpdates.current[i]) {
          refUpdates.current[i].off('value', refUpdateFuncs.current[i])
          refUpdates.current[i] = null
        }
      }
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const loadData = async () => {
    setLoading(props, true)

    // clear cache
    refCacheRealAvailableTimes.current = {}
    refCacheMapHrs.current = {}

    const refStore = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
    const responseStore = await refStore.once('value')
    if (responseStore) {
      const val = responseStore.val()
      if (val) {
        const orders = val[Constants.EMPLOYEE_ORDERS]
        if (orders) {
          Storage.setJson(Constants.EMPLOYEE_ORDERS, orders)
        }
      }
    }
    refEmployees.current = []
    const employees = await firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId).once('value')
    if (employees) {
      let list = []
      const val = employees.val()
      if (val) {
        for (let i in val) {
          const item = val[i]
          let resource = {}
          resource[Constants.RESOURCE_ID] = item[Constants.ID]
          resource[Constants.RESOURCE_TITLE] = `${item[Constants.FIRST_NAME]} ${item[Constants.LAST_NAME]}`
          resource[Constants.RESOURCE_IMAGE] = StringUtils.checkUndefined(item[Constants.IMAGE])
          list.push(resource)
          refEmployees.current.push(item)
        }
      }

      DataUtils.sortBy(list, Constants.RESOURCE_TITLE)
      refResourceMap.current = list
      // if there are changes in employees list then we remove the cache columns
      const orderColumns = Storage.getJson(Constants.EMPLOYEE_ORDERS, false)
      if (orderColumns) {
        let changed = orderColumns.length != list.length
        if (!changed) {
          for (let i in orderColumns) {
            const index = list.findIndex(value => value[Constants.ID] == orderColumns[i][Constants.ID])
            if (index == -1) {
              changed = true
              break
            }
          }
        }
        if (changed) {
          Storage.clearKey(Constants.EMPLOYEE_ORDERS)
        }
      }
    }

    refIsFirst.current = true
    monitorData()
  }

  const monitorData = () => {
    for (let i in refUpdates.current) {
      if (refUpdates.current[i]) {
        refUpdates.current[i].off('value', refUpdateFuncs.current[i])
        refUpdates.current[i] = null
      }
    }
    const date = refDate.current
    const year = date.year()
    const month = date.month() + 1
    // load appoiments for year/month
    const refBookings = firebase.databaseRef(Constants.FB_STORE_BOOKINGS + '/' + userId + '/' + storeId + '/' + year + '/' + month)
    refUpdateFuncs.current['bookings'] = (response) => {
      refTotal.current = {}
      let list = []
      if (response) {
        const val = response.val()
        if (val) {
          for (let i in val) { // day
            const item1 = val[i]
            for (let j in item1) { // id
              const item2 = item1[j]
              item2[Constants.DATE_PARSED] = moment(item2[Constants.DATE])
              list.push(item2)
              const key = `${year}-${month}-${i}-${item2[Constants.EMPLOYEE][Constants.ID]}`
              refTotal.current[key] = refTotal.current[key] ? refTotal.current[key] + 1 : 1

              // push to allItems
              refAllItems.current[item2[Constants.ID]] = item2
            }
          }
        }
      }
      refItems.current = list

      // only pass employees at the first time
      if (refIsFirst.current) {
        refIsFirst.current = false
        if (props.onLoadEmployees) {
          props.onLoadEmployees(refEmployees.current)
        }
        if (props.onLoadEmployeesTimesView) {
          props.onLoadEmployeesTimesView(refEmployees.current)
        }
      }

      setLoading(props, false)
    }

    refBookings.on('value', refUpdateFuncs.current['bookings'])
    refUpdates.current['bookings'] = refBookings
  }

  const loadRealAvailableTimes = (employee, date) => {
    let key = employee[Constants.ID] + `_${date.year()}_${date.month() + 1}_${date.date()}`
    if (refCacheRealAvailableTimes.current[key]) {
      return refCacheRealAvailableTimes.current[key]
    }

    let list = []
    // step 1: get all working hours in specific day and also filter offline hours
    const workingPeriods = firebase.getEmployeeWorkingPeriods(employee, date)
    const workingOfflines = employee[Constants.WORKING_OFFLINE] || []
    const day = date.format('dddd').toUpperCase()
    let offlinePair = [] // pair of hours [{start, end}]
    for (let i in workingOfflines) {
      const workingOffline = workingOfflines[i]
      const start = workingOffline[Constants.START_PARSED] || moment(workingOffline[Constants.START])
      workingOffline[Constants.START_PARSED] = start
      if (date.isSame(start, 'day')) {
        const end = workingOffline[Constants.END_PARSED] || moment(workingOffline[Constants.END])
        workingOffline[Constants.END_PARSED] = end
        var pair = {}
        pair[Constants.START] = DateUtils.getNumberFromHourMinute({ hour: start.hour(), minute: start.minute() })
        pair[Constants.END] = DateUtils.getNumberFromHourMinute({ hour: end.hour(), minute: end.minute() })
        offlinePair.push(pair)
      }
    }
    for (let i in workingPeriods) {
      const workingPeriod = workingPeriods[i]
      const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
      const dateStr = DateUtils.getDayByNumber(dateNum)
      if (day == dateStr && workingPeriod[Constants.IS_CHECK]) {
        const hours = workingPeriod[Constants.HOURS] || []
        for (let j in hours) {
          const item = hours[j]
          var pairs = [item]
          // check with offline hours, create new list that contains new pairs, 
          // e.g: working 8-11am, offline 9-10am => new list [8-9, 10-11]
          for (let k in offlinePair) {
            const offline = offlinePair[k]
            let subList = []
            for (let l in pairs) {
              const pair = pairs[l]
              if (DateUtils.isHourOverlap(pair[Constants.START], pair[Constants.END], offline[Constants.START], offline[Constants.END])) {
                subList.push(...DateUtils.splitHourOverlap(pair[Constants.START], pair[Constants.END], offline[Constants.START], offline[Constants.END]))
              } else {
                subList.push(pair)
              }
            }
            pairs = [...subList]
          }
          list.push(...pairs)
        }
        break
      }
    }
    refCacheRealAvailableTimes.current[key] = list
    return list
  }

  const loadAvailableTimes = (employee, date) => {
    let list = []
    // step 1: get all working hours in specific day and also filter offline hours
    const workingPeriods = initWorkingPeriodEmployee()
    const day = date.format('dddd').toUpperCase()
    for (let i in workingPeriods) {
      const workingPeriod = workingPeriods[i]
      const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
      const dateStr = DateUtils.getDayByNumber(dateNum)
      if (day == dateStr) {
        const hours = workingPeriod[Constants.HOURS] || []
        for (let j in hours) {
          const item = hours[j]
          list.push(item)
        }
        break
      }
    }
    if (list.length > 0) {
      // step 2: calculate available times for booking
      // using start and end of each pair, then plus with duration
      DataUtils.sortBy(list, Constants.START)
      let timesPair = [] // hold [pair]
      let pair = {} // hold {start, end}
      for (let i in list) {
        const item = list[i]
        if (i == 0) {
          pair[Constants.START] = item
          pair[Constants.END] = item
        } else {
          const previous = list[i - 1]
          if (item[Constants.START] == previous[Constants.END]) {
            pair[Constants.END] = item
          } else {
            timesPair.push(pair)
            pair = {}
            pair[Constants.START] = item
            pair[Constants.END] = item
          }
        }
      }
      timesPair.push(pair)
      const durationInMinutes = Constants.CALENDAR_TIME_STEP // step in calendar
      const duration = durationInMinutes / 60 // in hour      
      let availableTimes = []
      for (let i in timesPair) {
        const pair = timesPair[i]
        let start = pair[Constants.START][Constants.START]
        const end = pair[Constants.END][Constants.END]
        while (start + duration <= end) {
          let available = {}
          available[Constants.ID] = StringUtils.getUniqueID()
          available[Constants.DATE] = date
          available[Constants.START] = start
          available[Constants.END] = start + duration

          let canSet = true
          const _end = available[Constants.END]
          // Condition 1: check with other booked services of same employee
          for (let i in refItems.current) {
            const item = refItems.current[i]
            if (employee[Constants.ID] == item[Constants.EMPLOYEE][Constants.ID]) {
              const iDate = item[Constants.DATE_PARSED] || moment(item[Constants.DATE])
              item[Constants.DATE_PARSED] = iDate
              const iStart = item[Constants.START]
              const iEnd = item[Constants.END]
              if (date.isSame(iDate, 'day') && DateUtils.isHourOverlap(iStart, iEnd, start, _end)) {
                // check top to add availables
                let availableTop = null
                if (start < iStart) {
                  availableTop = {}
                  availableTop[Constants.ID] = StringUtils.getUniqueID()
                  availableTop[Constants.DATE] = date
                  availableTop[Constants.START] = start
                  availableTop[Constants.END] = iStart
                }
                // check bottom to add availables
                const nextStart = start + duration
                let availableBottom = null
                if (iEnd < nextStart) {
                  availableBottom = {}
                  availableBottom[Constants.ID] = StringUtils.getUniqueID()
                  availableBottom[Constants.DATE] = date
                  availableBottom[Constants.START] = iEnd
                  availableBottom[Constants.END] = nextStart
                }
                if (availableTop || availableBottom) {
                  let canSetTop = availableTop != null
                  let canSetBottom = availableBottom != null
                  // check do not overlap with other booked services
                  for (let j in refItems.current) {
                    const itemJ = refItems.current[j]
                    const iDateJ = itemJ[Constants.DATE_PARSED] || moment(itemJ[Constants.DATE])
                    itemJ[Constants.DATE_PARSED] = iDateJ
                    const iStartJ = itemJ[Constants.START]
                    const iEndJ = itemJ[Constants.END]
                    if (date.isSame(iDateJ, 'day')) {
                      if (availableTop && canSetTop && DateUtils.isHourOverlap(iStartJ, iEndJ, availableTop[Constants.START], availableTop[Constants.END])) {
                        canSetTop = false
                      }
                      if (availableBottom && canSetBottom && DateUtils.isHourOverlap(iStartJ, iEndJ, availableBottom[Constants.START], availableBottom[Constants.END])) {
                        canSetBottom = false
                      }
                    }
                    if (!canSetTop && !canSetBottom) {
                      break
                    }
                  }
                  if (canSetTop && !DateUtils.isEventSameTime(availableTop[Constants.START], availableTop[Constants.END])) {
                    availableTimes.push(availableTop)
                  }
                  if (canSetBottom && !DateUtils.isEventSameTime(availableBottom[Constants.START], availableBottom[Constants.END])) {
                    availableTimes.push(availableBottom)
                  }
                }
                canSet = false
                break
              }
            }
          }

          if (canSet && !DateUtils.isEventSameTime(available[Constants.START], available[Constants.END])) {
            availableTimes.push(available)
          }

          start += duration
        }
      }

      const path = date.year() + '/' + (date.month() + 1) + '/' + date.date()
      //const now = moment()
      const realAvailableTimes = loadRealAvailableTimes(employee, date)

      for (let i in availableTimes) {
        const item = availableTimes[i]
        const dStart = moment(item[Constants.DATE]).second(0)
        const dEnd = moment(item[Constants.DATE]).second(0)
        const startTime = DateUtils.getHourMinuteFromNumber(item[Constants.START])
        const endTime = DateUtils.getHourMinuteFromNumber(item[Constants.END])
        const dStartTime = dStart.hour(startTime.hour).minute(startTime.minute)
        const dEndTime = dEnd.hour(endTime.hour).minute(endTime.minute)

        // only select time that bigger than current time
        //if (dStartTime.isBefore(now)) {
        //  continue
        //}

        let className = 'store-no-event store-time-unavailable'
        // still show dark gray if time is unavailalbe
        for (let j in realAvailableTimes) {
          const real = realAvailableTimes[j]
          const realStartTime = DateUtils.getHourMinuteFromNumber(real[Constants.START])
          const realEndTime = DateUtils.getHourMinuteFromNumber(real[Constants.END])
          if (DateUtils.isContains(startTime, endTime, realStartTime, realEndTime)) {
            className = 'store-no-event'
            break
          }
        }

        // in mobile version, we will show available slots as events
        if (refEmployee.current) {
          className = className.replace('store-', 'mobile-')
          const event = {
            id: item[Constants.ID],
            title: '',
            start: dStartTime.toDate(),
            end: dEndTime.toDate(),
            resource: { hasEvent: false, path, className: className }
          }
          event[Constants.RESOURCE_ID] = employee[Constants.ID]
          refEvents.current.push(event)
        } else {
          const startDate = dStartTime.toDate()
          const key = employee[Constants.ID] + `_${startDate.getYear()}_${startDate.getMonth() + 1}_${startDate.getDate()}_${startDate.getHours()}_${startDate.getMinutes()}`
          refSlots.current[key] = className
        }
      }
    }
  }

  const createEventFromItem = (item, isEdit = false) => {
    let date = moment(isEdit ? item[Constants.DATE_EDIT] : (item[Constants.DATE_PARSED] || item[Constants.DATE]))
    let hourMinute = DateUtils.getHourMinuteFromNumber(isEdit ? item[Constants.START_EDIT] : item[Constants.START])
    const start = date.hour(hourMinute.hour).minute(hourMinute.minute)
    const path = date.year() + '/' + (date.month() + 1) + '/' + date.date() + '/' + item[Constants.ID]
    date = moment(isEdit ? item[Constants.DATE_EDIT] : (item[Constants.DATE_PARSED] || item[Constants.DATE]))
    hourMinute = DateUtils.getHourMinuteFromNumber(isEdit ? item[Constants.END_EDIT] : item[Constants.END])
    const end = date.hour(hourMinute.hour).minute(hourMinute.minute)
    const event = {
      id: item[Constants.ID],
      title: `${item[Constants.USER][Constants.FIRST_NAME]} ${item[Constants.USER][Constants.LAST_NAME]} - ${item[Constants.SERVICE][Constants.TITLE]}`,
      start: start.toDate(),
      end: end.toDate(),
      resource: { hasEvent: true, path, className: 'has-event', style: { backgroundColor: item[Constants.COLOR] || item[Constants.SERVICE][Constants.COLOR] } }
    }
    const employee = isEdit ? item[Constants.EMPLOYEE_EDIT] : item[Constants.EMPLOYEE]
    event[Constants.RESOURCE_ID] = employee[Constants.ID]
    return event
  }

  const filterData = async (currentDate) => {
    refEvents.current = []
    refSlots.current = {}

    for (let i in refItems.current) {
      const item = refItems.current[i]
      const date = item[Constants.DATE_PARSED] || moment(item[Constants.DATE])
      item[Constants.DATE_PARSED] = date

      // only get items in selected day
      if (!currentDate.isSame(date, 'day')) {
        continue
      }

      const event = createEventFromItem(item)
      refEvents.current.push(event)
    }

    // load available times for date that bigger than today
    refAvailableTimes.current = []
    if (currentDate.isSameOrAfter(moment(), 'day')) {
      if (refEmployee.current) {
        loadAvailableTimes(refEmployee.current, currentDate)
      } else {
        for (let i in refEmployees.current) {
          loadAvailableTimes(refEmployees.current[i], currentDate)
        }
      }
    }

    // If did not order before then order from left to right the employees based on their starting work times for that day.
    const orderColumns = Storage.getJson(Constants.EMPLOYEE_ORDERS, false)
    let mapHrs = null
    if (orderColumns) {
      refResourceMap.current = orderColumns
    } else {
      let key = `${currentDate.year()}_${currentDate.month() + 1}_${currentDate.date()}`
      if (refCacheMapHrs.current[key]) {
        mapHrs = refCacheMapHrs.current[key]
      } else {
        mapHrs = {}
        const day = currentDate.format('dddd').toUpperCase()
        for (let i in refEmployees.current) {
          const employee = refEmployees.current[i]
          const workingPeriods = firebase.getEmployeeWorkingPeriods(employee, currentDate)
          for (let i in workingPeriods) {
            const workingPeriod = workingPeriods[i]
            const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
            const dateStr = DateUtils.getDayByNumber(dateNum)
            if (day == dateStr) {
              if (workingPeriod[Constants.IS_CHECK]) {
                const hours = workingPeriod[Constants.HOURS] || []
                let startHr = Constants.CALENDAR_END_HOUR
                for (let j in hours) {
                  const hour = hours[j]
                  if (startHr == Constants.CALENDAR_END_HOUR || startHr > hour[Constants.START]) {
                    startHr = hour[Constants.START]
                  }
                }
                mapHrs[employee[Constants.ID]] = startHr
              }
              break
            }
          }
        }
        refCacheMapHrs.current[key] = mapHrs
      }
    }

    if (refEmployee.current) {
      let resource = refResourceMap.current.find((value) => value[Constants.RESOURCE_ID] == refEmployee.current[Constants.ID])
      if (resource) {
        setResourcesMap([resource])
      } else {
        resource = {}
        resource[Constants.RESOURCE_ID] = refEmployee.current[Constants.ID]
        resource[Constants.RESOURCE_TITLE] = `${refEmployee.current[Constants.FIRST_NAME]} ${refEmployee.current[Constants.LAST_NAME]}`
        resource[Constants.RESOURCE_IMAGE] = StringUtils.checkUndefined(refEmployee.current[Constants.IMAGE])
        setResourcesMap([resource])
      }
      let total = {}
      for (let i in refItems.current) {
        const item = refItems.current[i]
        if (item[Constants.EMPLOYEE][Constants.ID] == refEmployee.current[Constants.ID]) {
          const date = item[Constants.DATE_PARSED] || moment(item[Constants.DATE])
          item[Constants.DATE_PARSED] = date
          const key = `${date.year()}-${date.month() + 1}-${date.date()}`
          total[key] = total[key] ? total[key] + 1 : 1
        }
      }
      if (props.onLoadedTotal) {
        props.onLoadedTotal(total)
      }
    } else {
      if (mapHrs) {
        refResourceMap.current.sort((a, b) => {
          const h1 = mapHrs[a[Constants.RESOURCE_ID]] || Constants.CALENDAR_END_HOUR
          const h2 = mapHrs[b[Constants.RESOURCE_ID]] || Constants.CALENDAR_END_HOUR
          const t1 = a[Constants.RESOURCE_TITLE]
          const t2 = b[Constants.RESOURCE_TITLE]
          return (h1 < h2) ? -1 : ((h2 < h1) ? 1 : ((t1 < t2) ? -1 : ((t2 < t1) ? 1 : 0)))
        })
      }
      setResourcesMap(refResourceMap.current)
      if (props.onLoadedResourcesMap) {
        props.onLoadedResourcesMap(refResourceMap.current)
      }
      if (props.onLoadedResourcesMapTimesView) {
        props.onLoadedResourcesMapTimesView(refResourceMap.current)
      }
    }

    setEvents(refEvents.current)

    addTimeHeaderNode()
  }

  const eventPropGetter = (event, start, end, isSelected) => {
    return { className: event.resource.className, style: event.resource.style }
  }

  const onSelectEvent = (event) => {
    const resource = event.resource
    if (resource.hasEvent) {
      Navigator.navigate(props, Constants.PAGES_STORE_APPOINTMENT, `${Constants.PATH}=${resource.path}`)
    } else {
      onSelectSlot(event)
    }
  }

  const onSelectSlot = (slot) => {
    const employeeId = slot.resourceId
    const startDate = moment(slot.start)
    // can not choose the date is before today
    if (startDate.isBefore(moment(), 'day')) {
      return
    }
    const hourMinute = { hour: startDate.hour(), minute: startDate.minute() }
    const hourNum = DateUtils.getNumberFromHourMinute(hourMinute) // convert to number       
    for (let i in refEmployees.current) {
      if (refEmployees.current[i][Constants.ID] == employeeId) {
        Storage.clearKey(Constants.BOOKING)
        Storage.clearKey(Constants.CACHE_STORE_INFO)
        Storage.clearKey(Constants.CACHE_STORE_BOOKINGS)
        Storage.clearKey(Constants.CACHE_STORE_EMPLOYEES)
        Storage.clearKey(Constants.CACHE_STORE_SERVCIES)
        Storage.clearKey(Constants.CACHE_BOOK_TIME)
        const booking = Storage.getJson(Constants.BOOKING)
        booking[Constants.ID] = StringUtils.getUniqueID()
        booking[Constants.USER] = null
        booking[Constants.SERVICE] = null
        booking[Constants.EMPLOYEE] = refEmployees.current[i]
        booking[Constants.ITEMS] = null
        booking[Constants.DATE] = startDate.toISOString()
        booking[Constants.START] = hourNum
        booking[Constants.COLOR] = null
        Storage.setJson(Constants.BOOKING, booking)
        Navigator.navigate(props, Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_APPOINTMENT)
        break
      }
    }
  }

  const getTotal = () => {
    const key = `${refDate.current.year()}-${refDate.current.month() + 1}-${refDate.current.date()}-${refEmployee.current ? refEmployee.current[Constants.ID] : ''}`
    const total = refTotal.current[key] ?? 0
    return `${total} ${total == 1 ? Strings.appointment : Strings.appointments}`
  }

  const eventComponent = (event) => {
    return <div draggable='true'>
      {event.title}
    </div>
  }

  const timeSlotWrapper = (wrapper) => {
    if (!wrapper.resource || !wrapper.value) {
      return wrapper.children
    }
    let date = wrapper.value
    let today = refToday.current
    if (!DateUtils.isGreaterOrSameDay(today, date)) {
      const child = React.Children.only(wrapper.children)
      return React.cloneElement(child, { className: child.props.className + ' store-no-event store-time-unavailable' })
    }

    let h = date.getHours()
    let m = date.getMinutes()
    let x = h >= 12 ? 'PM' : 'AM'
    h = h % 12
    h = h ? h : 12
    m = m < 10 ? '0' + m : m
    let text = h + ':' + m + ' ' + x
    const child = React.Children.only(wrapper.children)

    let key = `${wrapper.resource}_${date.getYear()}_${date.getMonth() + 1}_${date.getDate()}_${date.getHours()}_${date.getMinutes()}`
    let className = `${child.props.className} ${refSlots.current[key] || 'store-no-event store-time-unavailable'}`

    return React.cloneElement(child, { className: className }, <span>{text}</span>)
  }

  const addTimeHeaderNode = () => {
    if (refTimer.current) {
      clearTimeout(refTimer.current)
      refTimer.current = null
    }
    refTimer.current = setTimeout(() => {
      doAddTimeHeaderNode()
      // double check to ensure the UI is inflated
      // if (refTimer.current) {
      //   clearTimeout(refTimer.current)
      //   refTimer.current = null
      // }
      // refTimer.current = setTimeout(() => {
      //   doAddTimeHeaderNode()
      // }, 1000)
    }, 1000)
  }

  const doAddTimeHeaderNode = () => {
    // calculate scroll width
    if (refDiv.current && refDiv.current.querySelector) {
      let height = refTimeHeaderStyle.current.height
      let scrollWidth = refTimeHeaderStyle.current.scrollWidth
      let gutterWidth = refTimeHeaderStyle.current.gutterWidth
      let gutterHeight = refTimeHeaderStyle.current.gutterHeight
      const child = refDiv.current.querySelector('.rbc-time-header')
      if (child) {
        const bounds = child.getBoundingClientRect()
        height = bounds.height
        scrollWidth = child.scrollWidth
      }
      const timeContent = refDiv.current.querySelector('.rbc-time-content')
      if (timeContent) {
        const timeBounds = timeContent.getBoundingClientRect()
        gutterHeight = timeBounds.height
        const gutter = timeContent.querySelector('.rbc-time-gutter')
        if (gutter) {
          const gutterBounds = gutter.getBoundingClientRect()
          gutterWidth = gutterBounds.width + 1
        }
      }
      if (height != refTimeHeaderStyle.current.height || scrollWidth != refTimeHeaderStyle.current.scrollWidth
        || gutterWidth != refTimeHeaderStyle.current.gutterWidth || gutterHeight != refTimeHeaderStyle.current.gutterHeight) {
        refTimeHeaderStyle.current = { height, scrollWidth, gutterWidth, gutterHeight }
        setTimeHeaderStyle(refTimeHeaderStyle.current)
      }
    }
  }

  const onScroll = (e) => {
    if (refDivGutter.current) {
      refDivGutter.current.scrollTop = e.target.scrollTop
    }
  }

  const getGutterText = (value) => {
    if (value == 12) {
      return `${value} PM`
    } else if (value > 12) {
      return `${value - 12} PM`
    }
    return `${value} AM`
  }

  props.onClickDay = (value) => {
    refToday.current = new Date()
    // check to load new date
    if (!refDate.current.isSame(value, 'day')) {
      // check to load new appoiments for new month
      if (!refDate.current.isSame(value, 'month')) {
        refDate.current = value
        loadData()
      } else {
        refDate.current = value
        filterData(value)
      }
    }
  }

  props.onSelectEmployee = (value) => {
    refEmployee.current = value
    filterData(refDate.current)
  }

  const classes = useStyles()
  const cWidth = screenWidth - Styles.sideCalendar
  const cHeight = screenHeight - 10 - Styles.toolbarHeight * 3
  const cScrollWidth = timeHeaderStyle.scrollWidth > 0 ? (timeHeaderStyle.scrollWidth + 10) : '100%'
  const calendarHeight = (Constants.CALENDAR_END_HOUR - Constants.CALENDAR_START_HOUR) * Constants.CALENDAR_TIME_SLOTS * Constants.CALENDAR_SLOT_HEIGHT

  if (refScreenWidth.current != screenWidth) {
    refScreenWidth.current = screenWidth
    addTimeHeaderNode()
  }

  return <>
    <div className={classes.headerBox}>
      <ITypography variant={'body1'} style={{ width: 500 }}>{refDate.current.format('dddd, MMMM Do YYYY')}</ITypography>
    </div>
    <ITypography variant='body2'>{getTotal()}</ITypography>
    <div ref={refDiv} onScroll={onScroll} style={{ width: cWidth, height: cHeight, overflow: 'scroll', display: 'flex' }}>
      <Calendar
        formats={Constants.APPOINTMENT_FORMATS}
        date={refDate.current.toDate()}
        min={moment(refDate.current).hour(Constants.CALENDAR_START_HOUR).minute(Constants.CALENDAR_START_MINUTE).toDate()}
        max={moment(refDate.current).hour(Constants.CALENDAR_END_HOUR).minute(Constants.CALENDAR_END_MINUTE).toDate()}
        events={events}
        resources={resourcesMap}
        resourceIdAccessor={Constants.RESOURCE_ID}
        resourceTitleAccessor={Constants.RESOURCE_TITLE}
        localizer={momentLocalizer(moment)}
        defaultView='day'
        toolbar={false}
        selectable={false}
        timeslots={Constants.CALENDAR_TIME_SLOTS}
        step={Constants.CALENDAR_TIME_STEP}
        onNavigate={() => { }}
        views={{ day: true }}
        style={{ width: cScrollWidth, height: calendarHeight || Styles.appointmentsHeight }}
        eventPropGetter={eventPropGetter}
        onSelectEvent={(event) => onSelectEvent(event)}
        onSelectSlot={(slot) => onSelectSlot(slot)}
        onSelecting={slot => false}
        components={{
          event: eventComponent,
          timeSlotWrapper: timeSlotWrapper,
        }}
      />
    </div>
  </>
}

function SideCalendarView({ props }) {
  Logger.log('SideCalendarView')

  const [date, setDate] = useState(moment())
  const [total, setTotal] = useState({})

  useEffect(() => {
    const chooseDate = Storage.getString(Constants.APPOINTMENT_CHOOSE_DATE)
    if (chooseDate) {
      onClickDay(moment(chooseDate))
    }
  }, [])

  const onClickDay = (value, fromUser) => {
    setDate(value)
    if (props.onClickDay) {
      props.onClickDay(value)
    }
    if (fromUser) {
      Storage.setString(Constants.APPOINTMENT_CHOOSE_DATE, value.toISOString())
    }
  }

  const dateRender = (current, value) => {
    const key = `${current.year()}-${current.month() + 1}-${current.date()}`
    if (total[key] && total[key] > 0) {
      return <div className='rc-calendar-date' style={{ lineHeight: '10px', paddingTop: 5 }}>
        <span>{current.date()}</span>
        <div style={{ width: 4, height: 4, borderRadius: 2, background: 'red', margin: 'auto', marginTop: 2 }} />
      </div>
    }
    return <div className='rc-calendar-date' style={{ lineHeight: '10px', paddingTop: 5 }}>
      <span>{current.date()}</span>
    </div>
  }

  props.onLoadedTotal = async (value) => {
    setTotal(value)
  }

  return <SideCalendar showDateInput={false} value={date}
    onChange={(value) => onClickDay(value, true)}
    dateRender={(current, value) => dateRender(current, value)} />
}

function StoreAppointments() {
  Logger.log(Constants.PAGES_STORE_APPOINTMENTS)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <div className={classes.root}>
    <div style={{ width: '100%' }}>
      <ICard>
        <ICardContent>
          <EmployeesView props={props} />
          <EmployeeColumnsView props={props} />
        </ICardContent>
      </ICard>
    </div>
    <div className={classes.sideCalendar}>
      <div>
        <ICard>
          <ICardContent style={{ padding: 0 }}>
            <SideCalendarView props={props} />
          </ICardContent>
        </ICard>
      </div>
    </div>
    <ProgressBar props={props} />
    <ToastView props={props} />
  </div>
}

export default StoreAppointments