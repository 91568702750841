import { isAndroid, isIOS } from 'react-device-detect'

export function screenWidth() {
    return window.innerWidth
}

export function screenHeight() {
    return window.innerHeight
}

export function isMobile() {
    return window.innerWidth < 700
}

export function isMobileBrowser() {
    return isAndroid || isIOS
}

export function pageOffset(dir) {
    if (dir === 'left') return window.pageXOffset || document.body.scrollLeft || 0
    if (dir === 'top') return window.pageYOffset || document.body.scrollTop || 0
}

/**
 * Given a node, get everything needed to calculate its boundaries
 * @param  {HTMLElement} node
 * @return {Object}
 */
export function getBoundsForNode(node) {
    if (!node || !node.getBoundingClientRect) {
        return { top: 0, left: 0, right: 0, bottom: 0, width: 0, height: 0 }
    }

    let rect = node.getBoundingClientRect(),
        left = rect.left + pageOffset('left'),
        top = rect.top + pageOffset('top'),
        right = (node.offsetWidth || 0) + left,
        bottom = (node.offsetHeight || 0) + top

    return {
        top,
        left,
        right: right,
        bottom: bottom,
        width: right - left,
        height: bottom - top
    }
}