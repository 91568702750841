import React, { useEffect, useState } from 'react'
import Switch from '@material-ui/core/Switch'

function ISwitch({ props, checked = false, onChange }) {

    const [isChecked, setIsChecked] = useState(checked)

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    const handleOnOff = (event) => {
        setIsChecked(event.target.checked)
        if (onChange) {
            onChange(event)
        }
    }

    return <Switch checked={isChecked} value={isChecked} onChange={event => handleOnOff(event)} color='primary'
        inputProps={{ 'aria-label': 'primary checkbox' }} />
}

export default ISwitch