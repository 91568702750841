import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as DateUtils from '../../../common/DateUtils'
import * as Constants from '../../../common/Constants'
import * as Navigator from '../../../common/Navigator'
import * as Storage from '../../../common/Storage'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import ITypography from '../../controls/ITypography'
import IImage from '../../controls/IImage'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import IPaper from '../../controls/IPaper'
import ITableRow from '../../controls/ITableRow'
import ITableHead from '../../controls/ITableHead'
import IIconButton from '../../controls/IIconButton'
import AddIcon from '@material-ui/icons/Add'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, isLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import moment from 'moment'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  appointments: {
    width: '100%',
  },
  summary: {
    width: Styles.summaryWidth,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  }
}))

function AppointmentsView({ props }) {
  Logger.log('AppointmentsView')

  const { user, settings } = useGlobal()
  const [items, setItems] = useState([])

  useEffect(() => {
    setLoading(props, true)
    const date = moment()
    const year = date.year()
    const month = date.month() + 1
    const day = date.date()
    const path = `${year}/${month}/${day}`
    const ref = firebase.databaseRef(Constants.FB_USER_BOOKINGS + '/' + user[Constants.ID] + '/' + path)
    ref.once('value').then(response => {
      setLoading(props, false)
      var list = []
      const val = response.val()
      if (val) {
        for (let i in val) {
          const item = val[i]
          item[Constants.PATH] = path + '/' + item[Constants.ID]
          const time = DateUtils.getHourMinuteFromNumber(item[Constants.START])
          item[Constants.DATE_TIME] = moment(item[Constants.DATE]).hour(time.hour).minute(time.minute)
          list.push(item)
        }
      }
      DateUtils.sortBy(list, Constants.DATE_TIME)
      setItems(list)
    }).catch(err => {
      Logger.log(err.message)
      setLoading(props, false)
    })
  }, [])

  const getTimeText = (item) => {
    return item[Constants.DATE_TIME].format('MMMM Do YYYY, h:mm a')
  }
  
  const onClickAdd = () => {
    const startDate = moment()
    let minute = startDate.minute() + 1
    // round minute to correct calendar time step, e.g: with step is 15 minutes then 11:24 -> 11:30, 11:00 -> 11:15
    const step = Constants.CALENDAR_TIME_STEP
    if (minute % step != 0) {
      minute = (Math.floor(minute / step) * step) + step
    }
    const hourMinute = { hour: startDate.hour(), minute: minute }
    const hourNum = DateUtils.getNumberFromHourMinute(hourMinute) // convert to number
    let booking = Storage.getJson(Constants.BOOKING)
    const store = booking[Constants.STORE]
    Storage.clearKey(Constants.BOOKING)
    Storage.clearKey(Constants.CACHE_STORE_BOOKINGS)
    Storage.clearKey(Constants.CACHE_STORE_EMPLOYEES)
    Storage.clearKey(Constants.CACHE_STORE_SERVCIES)
    Storage.clearKey(Constants.CACHE_BOOK_TIME)
    booking = Storage.getJson(Constants.BOOKING)
    booking[Constants.ID] = StringUtils.getUniqueID()
    booking[Constants.STORE] = store
    booking[Constants.USER] = user
    booking[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
    booking[Constants.DATE] = startDate.toISOString()
    booking[Constants.START] = hourNum
    Storage.setJson(Constants.BOOKING, booking)
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_APPOINTMENT)
  }

  const classes = useStyles()

  return <div className={classes.appointments}>
    <ITableContainer component={IPaper} className={classes.listSection}>
      <ITable>
        <ITableHead>
          <ITableRow>
            <ITableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ITypography variant='h5'>{Strings.appointmentsToday}</ITypography>
                <div style={{ marginLeft: 'auto' }}>
                  <IIconButton onClick={() => onClickAdd()}><AddIcon /></IIconButton>
                </div>
              </div>
            </ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          {items.map(item => (
            <ITableRow key={item[Constants.ID]}>
              <ITableCell style={{ display: 'flex', justifyItems: 'center' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.USER][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.USER][Constants.FIRST_NAME]} />
                <div style={{ width: '100%', marginLeft: 20 }}>
                  <ITypography variant='body1'>{Strings.store}: <b>{item[Constants.STORE][Constants.NAME]}</b></ITypography>
                  <ITypography variant='body1'>{Strings.service}: <b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                  <ITypography variant='body1'>{Strings.provider}: <b>{item[Constants.EMPLOYEE][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE][Constants.LAST_NAME]}</b></ITypography>
                  <ITypography variant='body1'>{Strings.time}: <b>{getTimeText(item)}</b></ITypography>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Link to={Navigator.buildLink(props, Constants.PAGES_CUSTOMER_APPOINTMENT, `${Constants.PATH}=${item[Constants.PATH]}`)}>{Strings.viewDetail}</Link>
                  </div>
                </div>
              </ITableCell>
            </ITableRow>
          ))}
          {!isLoading(props) && items.length == 0 && <ITableRow>
            <ITableCell>
              <ITypography variant='body1'>{Strings.noAppointmentsToday}</ITypography>
            </ITableCell>
          </ITableRow>}
        </ITableBody>
      </ITable>
    </ITableContainer>
  </div>
}

function SummaryView({ props }) {
  Logger.log('SummaryView')

  const { user, settings } = useGlobal()
  const [totalMonth, setTotalMonth] = useState(-1)

  useEffect(() => {
    const date = moment()
    const year = date.year()
    const month = date.month() + 1
    const ref = firebase.databaseRef(Constants.FB_USER_BOOKINGS + '/' + user[Constants.ID] + '/' + year + '/' + month)
    ref.once('value').then(response => {
      var total = 0
      const val = response.val()
      if (val) {
        for (let i in val) {
          for (let j in val[i]) {
            total += 1
          }
        }
      }
      setTotalMonth(total)
    }).catch(err => {
      Logger.log(err.message)
    })
  }, [])

  const classes = useStyles()

  return <div className={classes.summary}>
    <ITableContainer component={IPaper} className={classes.listSection}>
      <ITable>
        <ITableHead>
          <ITableRow>
            <ITableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ITypography variant='h5'>{Strings.summary}</ITypography>
                <div style={{ marginLeft: 'auto' }}>
                  <IIconButton onClick={() => Navigator.navigate(props, Constants.PAGES_CUSTOMER_APPOINTMENTS)}><MenuBookIcon /></IIconButton>
                </div>
              </div>
            </ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          <ITableRow >
            <ITableCell style={{ display: 'flex', justifyItems: 'center' }}>
              <ITypography variant='body1'>{Strings.totalAppointmentsThisMonth}: <b>{totalMonth < 0 ? '...' : totalMonth}</b></ITypography>
            </ITableCell>
          </ITableRow>
        </ITableBody>
      </ITable>
    </ITableContainer>
  </div>
}

function CustomerDashboard() {
  Logger.log(Constants.PAGES_CUSTOMER_DASHBOARD)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <>
    <div className={classes.root}>
      <AppointmentsView props={props} />
      <SummaryView props={props} />
    </div>
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default CustomerDashboard