import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as DataUtils from '../../../common/DataUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITypography from '../../controls/ITypography'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableHead from '../../controls/ITableHead'
import ITableRow from '../../controls/ITableRow'
import IPaper from '../../controls/IPaper'
import IIconButton from '../../controls/IIconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogContentText from '../../controls/IDialogContentText'
import IDialogTitle from '../../controls/IDialogTitle'
import IImage from '../../controls/IImage'
import AddIcon from '@material-ui/icons/Add'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  label: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.up('md')]: {
      width: Styles.inputWidth,
    },
  },
  color: {
    primary: '#FF5A5F'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const refItem = useRef()
  const [items, setItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORE_CATEGORIES + '/' + userId + '/' + storeId)
    ref.once('value').then(response => {
      setLoading(props, false)
      const list = []
      const val = response.val()
      if (val) {
        for (let id in val) {
          list.push(val[id])
        }
      }
      DataUtils.sortBy(list, Constants.TITLE)
      setItems(list)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const onAddCategory = () => {
    Navigator.navigate(props, Constants.PAGES_STORE_ADD_CATEGORY)
  }

  const onEditItem = (item) => {
    Navigator.navigate(props, Constants.PAGES_STORE_EDIT_CATEGORY, `${Constants.ID}=${item[Constants.ID]}`)
  }

  const onDeleteItem = (item) => {
    refItem.current = item
    setOpenDialog(true)
  }

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {
      setLoading(props, true)
      const id = refItem.current[Constants.ID]
      const ref = firebase.databaseRef(Constants.FB_STORE_CATEGORIES + '/' + userId + '/' + storeId + '/' + id)
      ref.remove().then(() => {
        loadData()
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const classes = useStyles()

  return <div className={classes.root}>
    <ITypography className={classes.heading}>{Strings.categories}</ITypography>
    <div className={classes.addButton}>
      <AddButton startIcon={<AddIcon />} onClick={() => onAddCategory()}>{Strings.addCategory}</AddButton>
    </div>
    <ITableContainer component={IPaper} style={{ marginTop: Styles.margin1Br }}>
      <ITable className={classes.table}>
        <ITableHead>
          <ITableRow>
            <ITableCell>{Strings.title}</ITableCell>
            <ITableCell>{Strings.description}</ITableCell>
            <ITableCell></ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          {items.map(row => (
            <ITableRow key={row[Constants.ID]}>
              <ITableCell component='th' scope='row'>{row[Constants.TITLE]}</ITableCell>
              <ITableCell>{row[Constants.DESCRIPTION]}</ITableCell>
              <ITableCell>
                <IIconButton aria-label={Strings.edit} onClick={() => onEditItem(row)}><EditIcon /></IIconButton>
                <IIconButton aria-label={Strings.delete} onClick={() => onDeleteItem(row)}><DeleteIcon /></IIconButton>
              </ITableCell>
            </ITableRow>
          ))}
        </ITableBody>
      </ITable>
    </ITableContainer>
    <IDialog open={openDialog} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <IDialogTitle id='alert-dialog-title'>{Strings.delete}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText id='alert-dialog-description'>{Strings.deleteConfirm}</IDialogContentText>
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
        <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
      </IDialogActions>
    </IDialog>
  </div>
}

function ViewCategories() {
  Logger.log(Constants.PAGES_STORE_VIEW_CATEGORIES)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default ViewCategories  