import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import PhoneInput from '../../widgets/PhoneInput'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.formMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth,   
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')
  
  const refTimer = useRef()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const postRegister = () => {
    if (StringUtils.stringEmpty(email) || StringUtils.stringEmpty(password) || StringUtils.stringEmpty(firstName)
      || StringUtils.stringEmpty(lastName) || StringUtils.stringEmpty(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    if (!StringUtils.validateEmail(email)) {
      showToast(props, TOAST_ERROR, Strings.errorEmailInvalid)
      return
    }
    if (!StringUtils.validatePhone(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorPhoneInvalid)
      return
    }
    if (password != confirmPassword) {
      showToast(props, TOAST_ERROR, Strings.errorPasswordMismatch)
      return
    }
    setLoading(props, true)
    firebase.auth().createUserWithEmailAndPassword(email, password).then(response => {
      const user = response.user.toJSON()
      const ref = firebase.databaseRef(Constants.FB_USERS + '/' + user.uid)
      const updates = {}
      updates[Constants.ID] = user.uid
      updates[Constants.EMAIL] = email
      updates[Constants.FIRST_NAME] = firstName
      updates[Constants.LAST_NAME] = lastName
      updates[Constants.PHONE] = phone
      updates[Constants.ROLE] = Constants.ROLE_CUSTOMER
      updates[Constants.DATE_MODIFIED] = Date.now()
      updates[Constants.DATE_ADDED] = Date.now()
      ref.update(updates).then(() => {
        setLoading(props, false)
        showToast(props, TOAST_SUCCESS, Strings.registerSuccessed)
        refTimer.current = setTimeout(() => {
          const state = Navigator.getState()
          const query = Navigator.getQuery()
          query.set(Constants.AUTH, 1)
          Navigator.navigate(props, Constants.PAGES_HOME, query.toString(), state)          
        }, 1000)
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const classes = useStyles()

  return <IFormControl style={{ maxWidth: Styles.formMaxWidth, alignItems: 'center' }}>
    <ITypography className={classes.heading}>{Strings.register}</ITypography>
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin2Br }} label={Strings.email + '*'} value={email} onChange={(event) => { setEmail(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.password + '*'} value={password} onChange={(event) => { setPassword(event.target.value) }} type='password' />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.confirmPassword + '*'} value={confirmPassword} onChange={(event) => { setConfirmPassword(event.target.value) }} type='password' />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.firstName + '*'} value={firstName} onChange={(event) => { setFirstName(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.lastName + '*'} value={lastName} onChange={(event) => { setLastName(event.target.value) }} />
    <PhoneInput className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.phone + '*'} value={phone} onChange={(value) => { setPhone(value) }} />
    <IButton style={{ marginTop: Styles.margin3Br }} onClick={postRegister}>{Strings.register}</IButton>
  </IFormControl>
}

function RegisterCustomer() {
  Logger.log(Constants.PAGES_REGISTER_CUSTOMER)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default RegisterCustomer