import * as Constants from '../../../common/Constants'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Label from '@material-ui/core/FormLabel'
import React from 'react'
import * as functionPlan from './usePlans'
import { styled } from '@material-ui/core/styles'
import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-table'
import { useGlobal } from '../../../utils/useGlobal'
import { Redirect } from 'react-router'
import { navigate, buildLink } from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as useDatabase from './useDatabase'
import moment from 'moment'

const CenterContainer = styled(Container)({
  textAlign: 'center'
})

function SelectPlanPage() {
  const { user } = useGlobal()
  
  const isStoreAdmin = user[Constants.ROLE] === Constants.ROLE_ADMIN_STORE
  // How about system ADMIN ?
  if (!isStoreAdmin) {
    return <Redirect to={buildLink({}, Constants.PAGES_STORE_DASHBOARD)} />
  }
  let { loading, result } = useDatabase.useSubscription(user[Constants.ID], user[Constants.ID])

  if (loading) {
    return <div>Loading</div>
  }

  if (result && (result[Constants.STATUS] === Constants.STATUS_ACTIVE || moment().isBefore(moment(result.billingPeriodEndDate)))) {
    return <Redirect to={buildLink({}, Constants.PAGES_STORE_DASHBOARD)} />
  }

  return <SelectPlan />
}

function SelectPlan() {
  const {
    loading: loadingPlan,
    error: loadPlanError,
    result: plans
  } = functionPlan.usePlans()

  return (
    <>
      <Label>Select a plan:</Label>
      {loadingPlan && <Loading />}
      {loadPlanError && (
        <div>
          <Label>{loadPlanError}</Label>
        </div>
      )}
      {loadingPlan || <Plans plans={plans} />}
    </>
  )
}

function Plans({ plans }) {
  const onPlanSelect = React.useCallback(plan => {
    navigate({}, Constants.PAGES_PAYMENT, null, { plan })
  }, [])

  const items = []

  return (
    <PricingTable>
      {plans.filter((value) => value[Constants.IS_CHECK]).map(plan => (
        <PLanItem key={plan[Constants.ID]} plan={plan} onSelect={onPlanSelect} />
      ))}
    </PricingTable>
  )
}

function PLanItem({ plan, onSelect }) {
  const onClick = React.useCallback(() => onSelect(plan), [plan, onSelect])

  return (
    <PricingSlot
      data-plan={plan[Constants.ID]}
      onClick={onClick}
      highlighted
      buttonText='SELECT'
      title={plan[Constants.NAME]}
      priceText={`\$${plan[Constants.PRICE]}/month`}
    >
      <PricingDetail>{plan.duration} {plan.duration > 1 ? 'months' : 'month'}</PricingDetail>
      {plan[Constants.FEATURES].map(feature => (
        <PricingDetail key={feature}>{feature}</PricingDetail>
      ))}
    </PricingSlot>

  )
}

const Loading = () => (
  <CenterContainer fixed>
    <CircularProgress disableShrink />
  </CenterContainer>
)

export default SelectPlanPage
