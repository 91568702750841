import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ICircularProgress from '../controls/ICircularProgress'
import * as Logger from '../../common/Logger'
import { onProgressBarCompleted } from '../../common/ViewUtils'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        display: 'flex',
        top: '50%',
        left: 'calc(50% - 25px)'
    },
}))

function ProgressBar({ props }) {
    Logger.log('ProgressBar')

    const classes = useStyles()
    const [loading, setLoading] = useState(false)

    const isLoading = () => {
        return loading
    }

    onProgressBarCompleted(props, isLoading, setLoading)
    
    return (loading && <div className={classes.root}>
        <ICircularProgress color='primary' />
    </div>)
}

export default ProgressBar