import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as DataUtils from '../../../common/DataUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITypography from '../../controls/ITypography'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableHead from '../../controls/ITableHead'
import ITableRow from '../../controls/ITableRow'
import IPaper from '../../controls/IPaper'
import IIconButton from '../../controls/IIconButton'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogContentText from '../../controls/IDialogContentText'
import IDialogTitle from '../../controls/IDialogTitle'
import IImage from '../../controls/IImage'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  divColor: {
    width: 50,
    height: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.54)'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID)  
  const refItem = useRef()
  const refCategories = useRef()
  const [items, setItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(props, true)

    if (refCategories.current == null) {
      const categories = []
      const ref1 = firebase.databaseRef(Constants.FB_SYSTEM_CATEGORIES)
      const response1 = await ref1.once('value')
      if (response1) {
        const val = response1.val()
        if (val) {
          for (let id in val) {
            categories.push(val[id])
          }
        }
      }
      const ref2 = firebase.databaseRef(Constants.FB_STORE_CATEGORIES + '/' + userId + '/' + storeId)
      const response2 = await ref2.once('value')
      if (response2) {
        const val = response2.val()
        if (val) {
          for (let id in val) {
            categories.push(val[id])
          }
        }
      }
      refCategories.current = categories
    }

    const ref = firebase.databaseRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId)
    const response = await ref.once('value')
    const list = []
    const val = response.val()
    if (val) {
      for (let id in val) {
        list.push(val[id])
      }
    }
    DataUtils.sortBy(list, Constants.ORDER)
    setItems(list)

    setLoading(props, false)
  }

  const onAddService = () => {
    Navigator.navigate(props, Constants.PAGES_SYSTEM_ADD_STORE_SERVICE, 
      `${Constants.USER_ID}=${userId}&${Constants.STORE_ID}=${storeId}`)
  }

  const onEditItem = (item) => {
    Navigator.navigate(props, Constants.PAGES_SYSTEM_EDIT_STORE_SERVICE, 
      `${Constants.USER_ID}=${userId}&${Constants.STORE_ID}=${storeId}&${Constants.ID}=${item[Constants.ID]}`)
  }

  const onDeleteItem = (item) => {
    refItem.current = item
    setOpenDialog(true)
  }

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {
      setLoading(props, true)
      const id = refItem.current[Constants.ID]
      const ref = firebase.databaseRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId + '/' + id)
      ref.remove().then(() => {
        loadData()
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const getCategoryName = (value) => {
    for (let i in refCategories.current) {
      let item = refCategories.current[i]
      if (item[Constants.ID] == value) {
        return item[Constants.TITLE]
      }
    }
    return ''
  }

  const classes = useStyles()

  return <div className={classes.root}>
    <ITypography className={classes.heading}>{Strings.services}</ITypography>
    <div className={classes.addButton}>
      <AddButton startIcon={<AddIcon />} onClick={() => onAddService()}>{Strings.addService}</AddButton>
    </div>
    <ITableContainer component={IPaper} style={{ marginTop: Styles.margin1Br }}>
      <ITable className={classes.table}>
        <ITableHead>
          <ITableRow>
            <ITableCell>{Strings.category}</ITableCell>
            <ITableCell>{Strings.image}</ITableCell>
            <ITableCell>{Strings.title}</ITableCell>
            <ITableCell>{Strings.description}</ITableCell>
            <ITableCell>{Strings.price} $</ITableCell>
            <ITableCell>{Strings.duration} ({Strings.minutes.toLowerCase()})</ITableCell>
            <ITableCell>{Strings.color}</ITableCell>
            <ITableCell></ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          {items.map(row => (
            <ITableRow key={row[Constants.ID]}>
              <ITableCell component='th' scope='row'>{getCategoryName(row[Constants.CATEGORY_ID])}</ITableCell>
              <ITableCell component='th' scope='row'>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={row[Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={row[Constants.TITLE]} />
              </ITableCell>
              <ITableCell>{row[Constants.TITLE]}</ITableCell>
              <ITableCell>{row[Constants.DESCRIPTION]}</ITableCell>
              <ITableCell>{row[Constants.PRICE]}</ITableCell>
              <ITableCell>{row[Constants.DURATION]}</ITableCell>
              <ITableCell><div className={classes.divColor} style={{ backgroundColor: row[Constants.COLOR] || 'white' }} /></ITableCell>
              <ITableCell>
                <IIconButton aria-label={Strings.edit} onClick={() => onEditItem(row)}><EditIcon /></IIconButton>
                <IIconButton aria-label={Strings.delete} onClick={() => onDeleteItem(row)}><DeleteIcon /></IIconButton>
              </ITableCell>
            </ITableRow>
          ))}
        </ITableBody>
      </ITable>
    </ITableContainer>
    <IDialog open={openDialog} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <IDialogTitle id='alert-dialog-title'>{Strings.delete}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText id='alert-dialog-description'>{Strings.deleteConfirm}</IDialogContentText>
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
        <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
      </IDialogActions>
    </IDialog>
  </div>
}

function ViewStoreServices() {
  Logger.log(Constants.PAGES_SYSTEM_VIEW_STORE_SERVICES)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default ViewStoreServices