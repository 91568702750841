///
/// Map actions from childs to parent
///
export function createPropsWithActions() {
    let props = {}
    props.onProgressBarCompleted = (func1, func2) => {
        props.isLoadingFunc = func1
        props.setLoadingFunc = func2
    }

    props.onToastViewCompleted = (func) => {
        props.showToastFunc = func
    }
    return props
}

export function isLoading(props) {
    if (props.isLoadingFunc) {
        return props.isLoadingFunc()
    }
    return false
}

export function setLoading(props, value) {
    if (props.setLoadingFunc) {
        props.setLoadingFunc(value)
    }
}

export function showToast(props, type, message) {
    if (props.showToastFunc) {
        props.showToastFunc(type, message)
    }
}
///
/// Actions are called in child
///
export function onProgressBarCompleted(props, isLoading, setLoading) {
    if (props.onProgressBarCompleted) {
        props.onProgressBarCompleted(isLoading, setLoading)
    }
}

export function onToastViewCompleted(props, showToast) {
    if (props.onToastViewCompleted) {
        props.onToastViewCompleted(showToast)
    }
}