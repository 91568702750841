import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import IImage from '../../controls/IImage'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import PhoneInput from '../../widgets/PhoneInput'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import GoogleMapReact from 'google-map-react'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  avatar: {
    width: 200,
    height: 200
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  },
  content: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  mapContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  },
  mapBox: {
    marginTop: 10,
    width: '100%',
    height: Styles.mapHeight
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')
  
  const { user, settings } = useGlobal()
  const refTimer = useRef()
  const refMaps = useRef()
  const refMap = useRef()
  const refLocationLat = useRef(Constants.STORE_DEFAULT_LOCATION_LAT)
  const refLocationLng = useRef(Constants.STORE_DEFAULT_LOCATION_LNG)
  const refLocationZoom = useRef(Constants.STORE_DEFAULT_ZOOM)
  const refMarkers = useRef([])
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [image, setImage] = useState()
  const [src, setSrc] = useState('')

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        refLocationLat.current = position.coords.latitude
        refLocationLng.current = position.coords.longitude
        renderMarkers()
      })
    }
    if (refTimer.current) {
      clearTimeout(refTimer.current)
    }
  }, [])

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (!StringUtils.isValidUploadImage(file.name)) {
        setImage(null)
        setSrc('')
        showToast(props, TOAST_ERROR, Strings.errorWrongFormat + StringUtils.arrayToString(Constants.SUPPORT_IMAGE_TYPES))
        return
      }
      setImage(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function (e) {
        setSrc(reader.result)
      }.bind(this)
    }
  }

  const postRegister = async () => {
    if (StringUtils.stringEmpty(name) || StringUtils.stringEmpty(description) || StringUtils.stringEmpty(category)
      || StringUtils.stringEmpty(address) || StringUtils.stringEmpty(phone) || StringUtils.stringEmpty(email) || image == null) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    if (!StringUtils.validateEmail(email)) {
      showToast(props, TOAST_ERROR, Strings.errorEmailInvalid)
      return
    }
    if (!StringUtils.validatePhone(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorPhoneInvalid)
      return
    }
    setLoading(props, true)
    const id = user[Constants.ID] // same as user id at the moment
    const ref = firebase.databaseRef(Constants.FB_STORES + '/' + user[Constants.ID] + '/' + id)
    const updates = {}
    const refStorage = firebase.storageRef(Constants.FB_STORES + '/' + user[Constants.ID] + '/' + id + '/' + image.name)
    const downloadURL = await firebase.uploadFile(refStorage, image, 0, 1, (index, length, bytesTransferred, totalBytes) => {
      //updateProgress(Math.ceil(bytesTransferred / totalBytes * 100))
    })
    if (downloadURL) {
      updates[Constants.IMAGE] = downloadURL
    }
    updates[Constants.ID] = id
    updates[Constants.USER_ID] = user[Constants.ID]
    updates[Constants.NAME] = name
    updates[Constants.DESCRIPTION] = description
    updates[Constants.CATEGORY] = category
    updates[Constants.ADDRESS] = address
    updates[Constants.PHONE] = phone
    updates[Constants.EMAIL] = email
    updates[Constants.LOCATION_LAT] = refLocationLat.current
    updates[Constants.LOCATION_LNG] = refLocationLng.current
    updates[Constants.LOCATION_ZOOM] = refLocationZoom.current
    updates[Constants.DATE_MODIFIED] = Date.now()
    updates[Constants.DATE_ADDED] = Date.now()
    ref.update(updates).then(() => {
      setLoading(props, false)
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
      refTimer.current = setTimeout(() => {
        Navigator.navigate(props, Constants.PAGES_SELECT_PLAN, `${Constants.AUTH}=1`)
      }, 1000)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const onMarkerDragged = (e) => {
    refLocationLat.current = e.latLng.lat()
    refLocationLng.current = e.latLng.lng()
    refLocationZoom.current = refMap.current.getZoom()
  }

  const clearMarkers = () => {
    for (let i in refMarkers.current) {
      refMarkers.current[i].setMap(null)
    }
  }

  const renderMarkers = () => {
    if (refMap.current && refMaps.current) {
      clearMarkers()
      let marker = new refMaps.current.Marker({
        position: { lat: refLocationLat.current, lng: refLocationLng.current },
        draggable: true,
      })
      marker.setMap(refMap.current)
      const center = new refMaps.current.LatLng(refLocationLat.current, refLocationLng.current)
      refMap.current.setCenter(center)
      refMap.current.setZoom(refLocationZoom.current)
      refMaps.current.event.addListener(marker, 'dragend', onMarkerDragged)
      refMarkers.current.push(marker)
    }
  }

  const classes = useStyles()

  return <>
    <div style={{ textAlign: 'center' }}>
      <ITypography className={classes.heading}>{Strings.createStore}</ITypography>
    </div>
    <div className={classes.content} style={{ marginTop: Styles.margin2Br }}>
      <div>
        <IFormControl>
          <ITextField className={classes.textField} label={Strings.name} value={name} onChange={(event) => { setName(event.target.value) }} />
          <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.description} value={description} onChange={(event) => { setDescription(event.target.value) }} />
          <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.category} value={category} onChange={(event) => { setCategory(event.target.value) }} />
          <PhoneInput className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.phone} value={phone} onChange={(event) => { setPhone(event) }} />
          <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.address} value={address} onChange={(event) => { setAddress(event.target.value) }} />
          <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.email} value={email} onChange={(event) => { setEmail(event.target.value) }} />
          <div className={classes.textField} style={{ marginTop: Styles.margin1Br }} >
            <ITypography className='MuiFormLabel-root'>{Strings.image}:</ITypography>
          </div>
          <ITextField className={classes.textField} type='file' onChange={handleImageChange} />
          <IImage src={src || settings[Constants.DEFAULT_STORE_IMAGE]} style={{ width: '200px', height: '200px', marginTop: Styles.margin1Br }} />
        </IFormControl>
      </div>
      <div className={classes.mapContainer}>
        <div>
          <ITypography className='MuiFormLabel-root'>{Strings.location}:</ITypography>
        </div>
        <div className={classes.mapBox}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: Constants.GOOGLE_MAP_KEY }}
            defaultCenter={{ lat: Constants.STORE_DEFAULT_LOCATION_LAT, lng: Constants.STORE_DEFAULT_LOCATION_LNG }}
            defaultZoom={Constants.STORE_DEFAULT_ZOOM}
            onGoogleApiLoaded={({ map, maps }) => {
              refMap.current = map
              refMaps.current = maps
              renderMarkers()
            }}
            yesIWantToUseGoogleMapApiInternals
          />
        </div>
      </div>
    </div>
    <div style={{ textAlign: 'center', marginTop: Styles.margin1Br }}>
      <IButton onClick={postRegister}>{Strings.finish}</IButton>
    </div>
  </>
}

function CreateStore() {
  Logger.log(Constants.PAGES_CREATE_STORE)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default CreateStore