import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import * as Logger from '../../common/Logger'
import { ChromePicker } from 'react-color'

const useStyles = makeStyles(theme => ({
    root: {
        position: props => props.position,
        zIndex: theme.zIndex.modal + 1
    },
    shadow: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    divColorSample: {
        width: 40,
        height: 40,
        borderRadius: 5,
        marginRight: 5,
        zIndex: theme.zIndex.modal + 2,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    divSaveContainer: {
        marginTop: 10,
        zIndex: theme.zIndex.modal + 2,
    },
    divSave: {
        backgroundColor: '#0693e3',
        color: 'white',
        width: 80,
        height: 30,
        borderRadius: 5,
        textAlign: 'center',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: theme.zIndex.modal + 2,
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))

function ColorPicker({ props, id = null, style = { position: 'absolute' } }) {
    Logger.log('ColorPicker')

    const classes = useStyles(style)
    const [open, setOpen] = useState(false)
    const [color, setColor] = useState({ r: '255', g: '255', b: '255', a: '1' })
    const [rootStyle, setRootStyle] = useState({})

    const handleChange = (value) => {
        setColor(value.hex)
    }

    const chooseColor = (value) => {
        setOpen(false)
        if (props.onColorClosed) {
            props.onColorClosed(value || color, id)
        }
    }

    if (id) {
        if (!props.setColorOpens) {
            props.setColorOpens = {} // {id: func}
        }
        props.setColorOpens[id] = (value, initColor = null, style = null) => {
            if (initColor) {
                setColor(initColor)
            }
            if (style) {
                setRootStyle(style)
            }
            setOpen(value)            
        }
    }

    props.setColorOpen = (value, initColor, ref = null, style = null) => {
        if (ref) {
            if (props.setColorOpens && props.setColorOpens[ref]) {
                props.setColorOpens[ref](value, initColor, style)
            }
        } else {           
            if (initColor) {
                setColor(initColor)
            }
            if (style) {
                setRootStyle(style)
            }
            setOpen(value)
        }
    }

    return (open && <div className={classes.root} style={{ ...rootStyle }}>
        <div className={classes.shadow} onClick={() => chooseColor()} />
        <div style={{ backgroundColor: '#eaeaea', padding: 10, borderRadius: 5 }}>
            <div style={{ display: 'flex' }}>
                <div className={classes.divColorSample} style={{ backgroundColor: '#ff6900' }} onClick={() => chooseColor('#ff6900')} />
                <div className={classes.divColorSample} style={{ backgroundColor: '#fcb900' }} onClick={() => chooseColor('#fcb900')} />
                <div className={classes.divColorSample} style={{ backgroundColor: '#7bdcb5' }} onClick={() => chooseColor('#7bdcb5')} />
                <div className={classes.divColorSample} style={{ backgroundColor: '#00d084' }} onClick={() => chooseColor('#00d084')} />
                <div className={classes.divColorSample} style={{ backgroundColor: '#8ed1fc' }} onClick={() => chooseColor('#8ed1fc')} />
            </div>
            <div style={{ display: 'flex', marginTop: 5 }}>
                <div className={classes.divColorSample} style={{ backgroundColor: '#0693e3' }} onClick={() => chooseColor('#0693e3')} />
                <div className={classes.divColorSample} style={{ backgroundColor: '#abb8c3' }} onClick={() => chooseColor('#abb8c3')} />
                <div className={classes.divColorSample} style={{ backgroundColor: '#eb144c' }} onClick={() => chooseColor('#eb144c')} />
                <div className={classes.divColorSample} style={{ backgroundColor: '#f78da7' }} onClick={() => chooseColor('#f78da7')} />
                <div className={classes.divColorSample} style={{ backgroundColor: '#9900ef' }} onClick={() => chooseColor('#9900ef')} />
            </div>
            <div style={{ marginTop: 5 }}>
                <ChromePicker color={color} onChange={(value) => handleChange(value)} />
            </div>
            <div className={classes.divSaveContainer}>
                <div className={classes.divSave} onClick={() => chooseColor()}>Save</div>
            </div>
        </div>
    </div>)
}

export default ColorPicker