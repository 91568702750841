import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Navigator from '../../../../common/Navigator'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as ScreenUtils from '../../../../common/ScreenUtils'
import * as DataUtils from '../../../../common/DataUtils'
import * as DateUtils from '../../../../common/DateUtils'
import * as Constants from '../../../../common/Constants'
import * as Storage from '../../../../common/Storage'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import ITypography from '../../../controls/ITypography'
import ITable from '../../../controls/ITable'
import ITableBody from '../../../controls/ITableBody'
import ITableCell from '../../../controls/ITableCell'
import ITableContainer from '../../../controls/ITableContainer'
import ITableRow from '../../../controls/ITableRow'
import IPaper from '../../../controls/IPaper'
import ITextField from '../../../controls/ITextField'
import SearchIcon from '@material-ui/icons/Search'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import IImage from '../../../controls/IImage'
import moment from 'moment'

const AddToVisitButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const SearchButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  content: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  listSection: {
    marginTop: 10,
    width: '100%',
    display: 'flex',
  },
  searchBox: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
}))

function SearchView({ props }) {
  Logger.log('SearchView')

  const [keyword, setKeyword] = useState('')

  useEffect(() => {

  }, [])

  const onSearchClick = (e) => {
    e.preventDefault()
    if (props.onSearchClick) {
      props.onSearchClick(keyword.toLowerCase())
    }
  }

  const classes = useStyles()

  return <form><div className={classes.searchBox}>
    <ITextField className={classes.textField} inputProps={{ style: { fontSize: Styles.searchFontSize } }} label={Strings.searchServiceHint} value={keyword} onChange={(event) => { setKeyword(event.target.value) }} />
    <SearchButton type='submit' startIcon={<SearchIcon />} onClick={(e) => onSearchClick(e)}>{Strings.search}</SearchButton>
  </div></form>
}

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const state = Navigator.getState()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const refItems = useRef([])
  const refBookedServices = useRef([])
  const [items, setItems] = useState([])

  useEffect(() => {
    setLoading(props, true)
    refBookedServices.current = Storage.getJson(Constants.CACHE_STORE_BOOKINGS, false)
    const ref = firebase.databaseRef(Constants.FB_STORE_SERVICES + '/' + userId + '/' + storeId)
    ref.once('value').then(response => {
      setLoading(props, false)
      const list = []
      const val = response.val()
      if (val) {
        for (let id in val) {
          list.push(val[id])
        }
      }
      DataUtils.sortBy(list, Constants.ORDER)
      refItems.current = list
      setItems(list)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const onVisitClick = (item) => {
    // only select service
    if (state.isSelectService) {
      Storage.setItemToMemory(Constants.CACHE_SELECT_SERVICE, item)
    } else {
      const booking = Storage.getJson(Constants.BOOKING)
      const id = state[Constants.ID]
      const extra = booking[Constants.ITEMS] || []
      const index = extra.findIndex(value => value[Constants.ID] == id)
      if (index >= 0) {
        extra[index][Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
        extra[index][Constants.SERVICE] = item
      } else {
        // check if time is available
        let isAvailable = true
        const serviceDuration = StringUtils.getNumber(item[Constants.DURATION]) / 60 // in hour  
        const startTime = booking[Constants.START]
        const endTime = startTime + serviceDuration
        const date1 = moment(booking[Constants.DATE])
        console.log('start ' + startTime + ' end ' + endTime)
        for (let i in refBookedServices.current) {
          const value = refBookedServices.current[i]
          const employee = value[Constants.EMPLOYEE]
          const date2 = moment(value[Constants.DATE])
          if (employee[Constants.ID] == booking[Constants.EMPLOYEE][Constants.ID]
            && date1.isSame(date2, 'day')) {
            console.log('start1 ' + value[Constants.START] + ' end2 ' + value[Constants.END])
            if (DateUtils.isHourOverlap(startTime, endTime, value[Constants.START], value[Constants.END])) {
              isAvailable = false
              break
            }
          }
        }
        if (!isAvailable) {
          booking[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
        }
        booking[Constants.SERVICE] = item
      }
      Storage.setJson(Constants.BOOKING, booking)
    }
    Navigator.back()
  }

  props.onSearchClick = (keyword) => {
    const list = []
    const isSearch = keyword && keyword != ''
    for (let i in refItems.current) {
      const item = refItems.current[i]
      if (isSearch) {
        let text = `${item[Constants.TITLE]}`.toLowerCase()
        if (text.indexOf(keyword) != -1) {
          list.push(item)
        }
      } else {
        list.push(item)
      }
    }
    DataUtils.sortBy(list, Constants.ORDER)
    setItems(list)
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return <ITableContainer className={classes.listSection} component={IPaper}>
    <ITable>
      <ITableBody>
        {items.map(item => (
          <ITableRow key={item[Constants.ID]}>
            {isMobile && <ITableCell>
              <div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 5, height: Styles.imageSize, backgroundColor: item[Constants.COLOR] }} />
                  <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.TITLE]} />
                  <div style={{ marginLeft: 10 }}>
                    <ITypography variant='body1'><b>{item[Constants.TITLE]}</b></ITypography>
                    <ITypography variant='body1'>{item[Constants.DURATION]} {Strings.mins}</ITypography>
                    <ITypography variant='body1'><b>${item[Constants.PRICE]}</b></ITypography>
                  </div>
                </div>
                <AddToVisitButton onClick={() => onVisitClick(item)}>{Strings.addToVisit}</AddToVisitButton>
              </div>
            </ITableCell>}
            {!isMobile && <>
              <ITableCell size='small' width={150}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 5, height: Styles.imageSize, backgroundColor: item[Constants.COLOR] }} />
                  <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.TITLE]} />
                </div>
              </ITableCell>
              <ITableCell>
                <ITypography variant='body1'><b>{item[Constants.TITLE]}</b></ITypography>
                <ITypography variant='body1'>{item[Constants.DURATION]} {Strings.mins}</ITypography>
              </ITableCell>
              <ITableCell>
                <ITypography variant='body1'><b>${item[Constants.PRICE]}</b></ITypography>
              </ITableCell>
              <ITableCell align='right'>
                <AddToVisitButton onClick={() => onVisitClick(item)}>{Strings.addToVisit}</AddToVisitButton>
              </ITableCell>
            </>}
          </ITableRow>
        ))}
      </ITableBody>
    </ITable>
  </ITableContainer>
}

function ViewServices() {
  Logger.log(Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_SERVICES)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <div className={classes.root}>
    <ITypography variant='h5'>{Strings.services}</ITypography>
    <SearchView props={props} />
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </div>
}

export default ViewServices