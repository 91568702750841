import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Navigator from '../../../../common/Navigator'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as DataUtils from '../../../../common/DataUtils'
import * as Constants from '../../../../common/Constants'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import ITextField from '../../../controls/ITextField'
import ITypography from '../../../controls/ITypography'
import ICard from '../../../controls/ICard'
import ICardContent from '../../../controls/ICardContent'
import IFormControl from '../../../controls/IFormControl'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  },
  content: {
    minHeight: 500,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  editorContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    }
  },
  editorBox: {
    marginTop: 10,
    width: Styles.editorWidth
  },
  copyLink: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  editorWrapper: {
  },
  editorContent: {
    borderColor: '#F1F1F1',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 5,
    padding: 10
  },
  readOnly: {
    color: '#9e9e9e'
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const userId = user[Constants.ID]
  const storeId = user[Constants.ID]
  const refTimer = useRef()
  const [inviteLink, setInviteLink] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [canCopy, setCanCopy] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    if (window.location) {
      const pathname = window.location.hostname + Constants.PAGES_REGISTER_CUSTOMER
      let link = ''
      if (pathname.startsWith('http://') || pathname.startsWith('https://')) {
        link = Navigator.buildLinkString(props, pathname, `${Constants.USER_ID}=${userId}&${Constants.STORE_ID}=${storeId}&${Constants.REDIRECT}=${Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_ABOUT_STORE}`)
      } else {
        link = Navigator.buildLinkString(props, 'http://' + pathname, `${Constants.USER_ID}=${userId}&${Constants.STORE_ID}=${storeId}&${Constants.REDIRECT}=${Constants.PAGES_CUSTOMER_MULTI_APPOINTMENT_ABOUT_STORE}`)
      }
      setInviteLink(link)
    }
    if (navigator.clipboard) {
      setCanCopy(true)
    } else {
      Logger.log('Can not copy')
    }

    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const onCopyClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(inviteLink).then(function () {
        showToast(props, TOAST_SUCCESS, Strings.copiedSuccessfully)
      }, function (err) {
        showToast(props, TOAST_SUCCESS, 'Can not copy text')
      })
    }
  }

  const onSendClick = () => {
    const currentContent = editorState.getCurrentContent()
    if ((StringUtils.stringEmpty(email) && StringUtils.stringEmpty(phone)) || !currentContent.hasText()) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    setLoading(props, true)
    const subject = Strings.inviteSubject + ' ' + Strings.appName
    const message = draftToHtml(convertToRaw(currentContent))
    var smsMessage = currentContent.getPlainText()
    if (smsMessage.indexOf(inviteLink) < 0) {
      smsMessage += '\n' + inviteLink
    }
    const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_STORE_INVITE_CUSTOMER)
    httpsCallable({ subject, email, phone, message, smsMessage }).then(({ data }) => {
      setLoading(props, false)
      if (data.errors && data.errors.length > 0) {
        var errMessage = ''
        for (let i in data.errors) {
          const error = data.errors[i]
          if (errMessage != '') {
            errMessage += ', '
          }
          errMessage += error.message
        }
        showToast(props, TOAST_ERROR, errMessage)
      } else {
        showToast(props, TOAST_SUCCESS, Strings.sentSuccessfully)
        refTimer.current = setTimeout(() => {
          Navigator.back()
        }, 1000)
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const classes = useStyles()

  return <div>
    <div style={{ textAlign: 'center' }}>
      <ITypography className={classes.heading}>{Strings.inviteAppointment}</ITypography>
    </div>
    <div className={classes.content} style={{ marginTop: Styles.margin2Br }}>
      <div>
        <IFormControl>
          <ITypography className={classes.textField} variant='body2' variantMapping={{ body2: 'span' }}>{Strings.inviteLinkHint}</ITypography>
          <div>
            <ITextField className={classes.textField} InputProps={{ readOnly: true, className: classes.readOnly }} value={inviteLink} />
            {canCopy && <ITypography variant='body2' variantMapping={{ body2: 'span' }} className={classes.copyLink} style={{ marginTop: Styles.margin1Br }}
              onClick={() => onCopyClick()}>{Strings.clickToCopy}</ITypography>}
          </div>
          <ITextField className={classes.textField} style={{ marginTop: Styles.margin2Br }} label={Strings.inviteEmailHint} value={email} onChange={(event) => { setEmail(event.target.value) }} />
          <div style={{ textAlign: 'center', marginTop: Styles.margin1Br }}>
            <ITypography variant='body1'>{Strings.or}</ITypography>
          </div>
          <ITextField className={classes.textField} label={Strings.invitePhoneHint} value={phone} onChange={(event) => { setPhone(event.target.value) }} />
        </IFormControl>
      </div>
      <div className={classes.editorContainer}>
        <div>
          <ITypography className='MuiFormLabel-root'>{Strings.inviteMessage}:</ITypography>
        </div>
        <div className={classes.editorBox}>
          <Editor
            wrapperClassName={classes.editorWrapper}
            editorClassName={classes.editorContent}
            editorState={editorState}
            onEditorStateChange={editorState => setEditorState(editorState)} />
        </div>
      </div>
    </div>
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <IButton onClick={() => onSendClick()}>{Strings.send}</IButton>
    </div>
  </div>
}

function InviteCustomer() {
  Logger.log(Constants.PAGES_STORE_INVITE_APPOINTMENT_INVITE_CUSTOMER)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default InviteCustomer