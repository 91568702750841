import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../utils/useGlobal'
import * as firebase from '../../utils/firebase'
import * as Navigator from '../../common/Navigator'
import * as Logger from '../../common/Logger'
import * as StringUtils from '../../common/StringUtils'
import * as Constants from '../../common/Constants'
import * as Storage from '../../common/Storage'
import ProgressBar from '../widgets/ProgressBar'
import Strings from '../../common/Strings'
import IButton from '../controls/IButton'
import ITextField from '../controls/ITextField'
import IFormControl from '../controls/IFormControl'
import ITypography from '../controls/ITypography'
import ICard from '../controls/ICard'
import ICardContent from '../controls/ICardContent'
import ToastView, { TOAST_ERROR } from '../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../common/ViewUtils'
import { Link } from 'react-router-dom'
import Styles from '../../common/Styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.formMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth,
  },
  register: {
    fontSize: 16
  },
  forgotPassword: {
    textAlign: 'right'
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const query = Navigator.getQuery()
  const userId = query.get(Constants.USER_ID)
  const storeId = query.get(Constants.STORE_ID)
  const redirect = query.get(Constants.REDIRECT)
  const { user } = useGlobal()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    // clear memory data
    Storage.clearKey(Constants.APPOINTMENT_CHOOSE_DATE)
    Storage.clearKey(Constants.APPOINTMENT_CHOOSE_EMPLOYEE)    
    Storage.clearKey(Constants.APPOINTMENT_DRAGGABLE_ITEMS)
    Storage.clearKey(Constants.APPOINTMENT_UPDATED_ITEMS)
    Storage.clearKey(Constants.CACHE_STORES)
    Storage.clearKey(Constants.CACHE_STORE_INFO)
    Storage.clearKey(Constants.CACHE_STORE_SERVCIES)
    Storage.clearKey(Constants.CACHE_STORE_BOOKINGS)
    Storage.clearKey(Constants.CACHE_STORE_EMPLOYEES)
    Storage.clearKey(Constants.CACHE_USER_BOOKINGS)
    Storage.clearKey(Constants.CACHE_BOOK_TIME)
    Storage.clearKey(Constants.CACHE_SELECT_SERVICE)
    Storage.clearKey(Constants.CACHE_SELECT_SERVICE_FOR)
    Storage.clearKey(Constants.CACHE_CUSTOMER_NAME)
    // keep booking data if we are going to redirect to schedule now
    if (!redirect) {
      Storage.clearKey(Constants.BOOKING)
    }
  }, [])

  const postLogin = (event) => {
    event.preventDefault()
    if (StringUtils.stringEmpty(email) || StringUtils.stringEmpty(password)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    setLoading(props, true)
    firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
      setLoading(props, false)
      setTimeout(() => {
        const state = Navigator.getState()
        query.set(Constants.AUTH, 1)
        Navigator.navigate(props, Constants.PAGES_HOME, query.toString(), state)
      }, 1000)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const classes = useStyles()

  return <form>
    <IFormControl style={{ maxWidth: Styles.formMaxWidth, alignItems: 'center' }}>
      <ITypography align='center' className={classes.heading}>{userId && storeId ? Strings.pleaseLoginToContinue : Strings.signIn}</ITypography>
      <ITextField className={classes.textField} style={{ marginTop: Styles.margin2Br }} label={Strings.email} value={email} onChange={(event) => { setEmail(event.target.value) }} />
      <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.password} value={password} onChange={(event) => { setPassword(event.target.value) }} type='password' />
      <div className={`${classes.textField} ${classes.forgotPassword}`} style={{ marginTop: Styles.margin1Br }}>
        <Link to={Navigator.buildLink(props, Constants.PAGES_FORGOT_PASSWORD)}>{Strings.forgotPassword}</Link>
      </div>
      <IButton type='submit' style={{ marginTop: Styles.margin2Br }} onClick={postLogin}>{Strings.signIn}</IButton>
      <div style={{ textAlign: 'center', marginTop: Styles.margin2Br }}>
        <Link className={classes.register} to={Navigator.buildLink(props, Constants.PAGES_REGISTER_CUSTOMER, Navigator.getQuery().toString(), Navigator.getState())}>{Strings.askRegisterCustomer}</Link>
      </div>
      {!userId && !storeId && <div style={{ textAlign: 'center', marginTop: Styles.margin1Br }}>
        <ITypography variant='body1'>{Strings.or}</ITypography>
        <div style={{ marginTop: Styles.margin1Br }}>
          <Link className={classes.register} to={Navigator.buildLink(props, Constants.PAGES_REGISTER_ADMIN)}>{Strings.askRegisterAdminStore}</Link>
        </div>
      </div>}
    </IFormControl>
  </form>
}

function Login() {
  Logger.log(Constants.PAGES_LOGIN)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default Login