import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as Constants from '../../../../common/Constants'
import * as Navigator from '../../../../common/Navigator'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import IFormControl from '../../../controls/IFormControl'
import ITypography from '../../../controls/ITypography'
import IAvatar from '../../../controls/IAvatar'
import ICard from '../../../controls/ICard'
import ICardContent from '../../../controls/ICardContent'
import ISwitch from '../../../controls/ISwitch'
import ITextField from '../../../controls/ITextField'
import IDivider from '../../../controls/IDivider'
import PhoneInput from '../../../widgets/PhoneInput'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.formMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  avatar: {
    width: '200px',
    height: '200px'
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const query = Navigator.getQuery()
  const userId = query.get(Constants.USER_ID)
  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [image, setImage] = useState()
  const [src, setSrc] = useState('')
  const [remind, setRemind] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(props, true)
    try {
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_STORE_VIEW_CUSTOMER)
      const response = await httpsCallable({ userId })
      if (response.data) {
        const val = response.data.item
        if (val) {
          setEmail(val[Constants.EMAIL])
          setFirstName(val[Constants.FIRST_NAME])
          setLastName(val[Constants.LAST_NAME])
          setPhone(val[Constants.PHONE])
          setAddress(val[Constants.ADDRESS])
          setSrc(val[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR])
        }
      }
      const settingsHttpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_STORE_VIEW_CUSTOMER_SETTINGS)
      const responseSettings = await settingsHttpsCallable({ userId })
      if (responseSettings.data) {
        const val = responseSettings.data.item
        if (val) {
          setRemind(val[Constants.REMIND_24_BEFORE] || val[Constants.REMIND_CANCEL])
        }
      }
    } catch (err) {
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    }
    setLoading(props, false)
  }

  const handleChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (!StringUtils.isValidUploadImage(file.name)) {
        setImage(null)
        setSrc('')
        showToast(props, TOAST_ERROR, Strings.errorWrongFormat + StringUtils.arrayToString(Constants.SUPPORT_IMAGE_TYPES))
        return
      }
      setImage(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function (e) {
        setSrc(reader.result)
      }.bind(this)
    }
  }

  const postSave = async () => {
    if (StringUtils.stringEmpty(firstName) || StringUtils.stringEmpty(lastName)
      || StringUtils.stringEmpty(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    if (!StringUtils.validatePhone(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorPhoneInvalid)
      return
    }
    setLoading(props, true)
    let updates = {}
    if (image) {
      const refStorage = firebase.storageRef(Constants.FB_USERS + '/' + user[Constants.ID] + '/' + image.name)
      const downloadURL = await firebase.uploadFile(refStorage, image, 0, 1, (index, length, bytesTransferred, totalBytes) => {
        //updateProgress(Math.ceil(bytesTransferred / totalBytes * 100))
      })
      if (downloadURL) {
        updates[Constants.IMAGE] = downloadURL
      }
    }
    updates[Constants.FIRST_NAME] = firstName
    updates[Constants.LAST_NAME] = lastName
    updates[Constants.PHONE] = phone
    updates[Constants.ADDRESS] = address || ''
    updates[Constants.DATE_MODIFIED] = Date.now()
    try {
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_STORE_EDIT_CUSTOMER)
      await httpsCallable({ id: userId, storeId, updates })
      const settingsHttpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_STORE_EDIT_CUSTOMER_SETTINGS)
      updates = {}
      updates[Constants.REMIND_24_BEFORE] = remind
      updates[Constants.REMIND_CANCEL] = remind
      await settingsHttpsCallable({ id: userId, storeId, updates })
    } catch (err) {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      return
    }
    setLoading(props, false)
    showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
  }

  const classes = useStyles()

  return <IFormControl style={{ maxWidth: Styles.formMaxWidth }}>
    <div style={{ textAlign: 'center' }}>
      <ITypography className={classes.heading}>{Strings.profile}</ITypography>
    </div>
    <ITextField disabled className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.email} value={email} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.firstName} value={firstName} onChange={(event) => { setFirstName(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.lastName} value={lastName} onChange={(event) => { setLastName(event.target.value) }} />
    <PhoneInput className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.phone} value={phone} onChange={(event) => { setPhone(event) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.address} value={address} onChange={(event) => { setAddress(event.target.value) }} />
    <div className={classes.textField} style={{ marginTop: Styles.margin2Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.avatar}:</ITypography>
    </div>
    <ITextField className={classes.textField} type='file' onChange={handleChange} />
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <IAvatar src={src} className={classes.avatar} style={{ marginTop: Styles.margin1Br }} />
    </div>
    <IDivider style={{ marginTop: Styles.margin1Br, backgroundColor: 'rgba(0, 0, 0, 0.42)' }}/>
    <div style={{ display: 'flex', alignItems: 'center', marginTop: Styles.margin1Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.appointmentTextReminders}</ITypography>
      <ISwitch checked={remind} onChange={event => setRemind(event.target.checked)} />
    </div>
    <IButton style={{ marginTop: Styles.margin3Br }} onClick={postSave}>{Strings.save}</IButton>
  </IFormControl>
}

function CustomerProfile() {
  Logger.log(Constants.PAGES_STORE_VIEW_CUSTOMER_PROFILE)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default CustomerProfile