import * as Constants from '../common/Constants'
import { stringEmpty } from '../common/StringUtils'
import history from '../utils/history'

export function navigate(props, screen, params = null, state = null) {
    var data = { pathname: screen }
    if (!stringEmpty(params)) {
        data.search = '?' + encodeURIComponent(params)
    }
    if (state) {
        data.state = state
    }
    if (screen == Constants.PAGES_HOME || screen == Constants.PAGES_CUSTOMER_DASHBOARD || screen == Constants.PAGES_EMPLOYEE_DASHBOARD
         || screen == Constants.PAGES_STORE_DASHBOARD || screen == Constants.PAGES_STORE_VIEW || screen == Constants.PAGES_STORE_APPOINTMENTS
         || screen == Constants.PAGES_STORE_VIEW_EMPLOYEES || screen == Constants.PAGES_STORE_VIEW_SERVICES || screen == Constants.PAGES_STORE_VIEW_CATEGORIES
         || screen == Constants.PAGES_STORE_VIEW_CUSTOMERS || screen == Constants.PAGES_SUBSCRIPTION
         || screen == Constants.PAGES_EMPLOYEE_APPOINTMENTS || screen == Constants.PAGES_CUSTOMER_APPOINTMENTS) {        
        history.replace(data)
    } else {
        history.push(data)
    }
}

export function buildLink(props, screen, params = null, state = null) {
    var data = { pathname: screen }
    if (!stringEmpty(params)) {
        data.search = '?' + encodeURIComponent(params)
    }
    if (state) {
        data.state = state
    }
    return data
}

export function buildLinkString(props, screen, params = null) {
    if (!stringEmpty(params)) {
        return screen + '?' + encodeURIComponent(params)
    }
    return screen
}

export function back() {
    history.goBack()
}

export function getState() {
    const location = history.location
    return location.state || {}
}

export function getQuery(value = null) {
    var search = value || history.location.search || ''
    if (search.startsWith('?')) {
        search = search.slice(1, search.length)
    }
    return new URLSearchParams(decodeURIComponent(search))
}