import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as DateUtils from '../../../../common/DateUtils'
import * as Constants from '../../../../common/Constants'
import * as Navigator from '../../../../common/Navigator'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import ITypography from '../../../controls/ITypography'
import ITable from '../../../controls/ITable'
import ITableBody from '../../../controls/ITableBody'
import ITableCell from '../../../controls/ITableCell'
import ITableContainer from '../../../controls/ITableContainer'
import IPaper from '../../../controls/IPaper'
import ITableRow from '../../../controls/ITableRow'
import ITableHead from '../../../controls/ITableHead'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, isLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import moment from 'moment'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  appointments: {
    width: '100%'
  },
}))

function AppointmentsView({ props }) {
  Logger.log('AppointmentsView')

  const query = Navigator.getQuery()
  const id = query.get(Constants.USER_ID)
  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const [items, setItems] = useState([])

  useEffect(() => {
    setLoading(props, true)
    const date = moment()
    const year = date.year()
    const path = `${year}`
    const ref = firebase.databaseRef(Constants.FB_STORE_BOOKINGS + '/' + userId + '/' + storeId + '/' + path)
    ref.once('value').then(response => {
      setLoading(props, false)
      var list = []
      const val = response.val()
      if (val) {
        for (let i in val) {
          const item1 = val[i]
          for (let j in item1) {
            const item2 = item1[j]
            for (let k in item2) {
              const item3 = item2[k]
              if (item3[Constants.USER] && item3[Constants.USER][Constants.ID] == id) {
                item3[Constants.PATH] = `${year}/${i}/${j}/${item3[Constants.ID]}`
                const time = DateUtils.getHourMinuteFromNumber(item3[Constants.START])
                item3[Constants.DATE_TIME] = moment(item3[Constants.DATE]).hour(time.hour).minute(time.minute)
                list.push(item3)
              }
            }
          }
        }
      }
      DateUtils.sortBy(list, Constants.DATE_TIME, DateUtils.SORT_DESC)
      setItems(list)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const getTimeText = (item) => {
    return item[Constants.DATE_TIME].format('MMMM Do YYYY, h:mm a')
  }

  const classes = useStyles()

  return <div className={classes.appointments}>
    <ITableContainer component={IPaper} className={classes.listSection}>
      <ITable>
        <ITableHead>
          <ITableRow>
            <ITableCell>
              <ITypography variant='h5'>{Strings.appointments}</ITypography>
            </ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          {items.map(item => (
            <ITableRow key={item[Constants.ID]}>
              <ITableCell className={classes.containerItem}>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <ITypography variant='body1'>{Strings.service}: <b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                    <ITypography variant='body1'>{Strings.provider}: <b>{item[Constants.EMPLOYEE][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE][Constants.LAST_NAME]}</b></ITypography>
                    <ITypography variant='body1'>{Strings.time}: <b>{getTimeText(item)}</b></ITypography>
                  </div>
                  <div style={{ width: 100, textAlign: 'right' }}>
                    <Link to={Navigator.buildLink(props, Constants.PAGES_STORE_APPOINTMENT, `${Constants.PATH}=${item[Constants.PATH]}`)}>{Strings.viewDetail}</Link>
                  </div>
                </div>
              </ITableCell>
            </ITableRow>
          ))}
          {items.length == 0 && <ITableRow key='noData'>
            <ITableCell>
              <ITypography variant='body2'>{Strings.noData}</ITypography>
            </ITableCell>
          </ITableRow>}
        </ITableBody>
      </ITable>
    </ITableContainer>
  </div>
}

function CustomerHistory() {
  Logger.log(Constants.PAGES_STORE_VIEW_CUSTOMER_HISTORY)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <>
    <div className={classes.root}>
      <AppointmentsView props={props} />
    </div>
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default CustomerHistory