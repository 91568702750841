import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as DateUtils from '../../../../common/DateUtils'
import * as DataUtils from '../../../../common/DataUtils'
import * as ScreenUtils from '../../../../common/ScreenUtils'
import { initWorkingPeriodEmployee } from '../../../../common/WorkingPeriod'
import * as Constants from '../../../../common/Constants'
import * as Navigator from '../../../../common/Navigator'
import * as Storage from '../../../../common/Storage'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import ITypography from '../../../controls/ITypography'
import ICard from '../../../controls/ICard'
import ICardContent from '../../../controls/ICardContent'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import SideCalendar from 'rc-calendar'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  sideCalendar: {
    width: Styles.sideCalendar,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  }
}))

function EmployeeColumnsView({ props }) {
  Logger.log('EmployeeColumnsView')

  const { user } = useGlobal()
  const userId = user[Constants.ID]
  const storeId = user[Constants.ID]
  const refItems = useRef([])
  const refTotal = useRef({})
  const refEvents = useRef([])
  const booking = Storage.getJson(Constants.BOOKING)
  const refDate = useRef(booking[Constants.DATE] ? moment(booking[Constants.DATE]) : moment())
  const refEmployee = useRef({})
  const [date, setDate] = useState(refDate.current)
  const [events, setEvents] = useState([])
  const [resourceMap, setResourceMap] = useState([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(props, true)
    
    const bDate = refDate.current
    const employee = booking[Constants.EMPLOYEE] || {}
    let resource = {}
    resource[Constants.RESOURCE_ID] = employee[Constants.ID]
    resource[Constants.RESOURCE_TITLE] = `${employee[Constants.FIRST_NAME]} ${employee[Constants.LAST_NAME]}`
    setResourceMap([resource])
    const year = bDate.year()
    const month = bDate.month() + 1
    const response = await firebase.databaseRef(Constants.FB_STORE_BOOKINGS + '/' + userId + '/' + storeId + '/' + year + '/' + month).once('value')
    if (response) {
      refTotal.current = {}
      let list = []
      const val = response.val()
      if (val) {
        for (let i in val) { // day
          const item1 = val[i]
          for (let j in item1) { // id
            const item2 = item1[j]
            if (item2[Constants.EMPLOYEE] && item2[Constants.EMPLOYEE][Constants.ID] == employee[Constants.ID]) {
              item2[Constants.YEAR] = year
              item2[Constants.MONTH] = month
              item2[Constants.DAY] = i
              list.push(item2)
              const key = `${year}-${month}-${i}`
              refTotal.current[key] = refTotal.current[key] ? refTotal.current[key] + 1 : 1
            }
          }
        }
      }
      refItems.current = list
      refEmployee.current = employee

      filterData(refDate.current)

      if (props.onLoadedTotal) {
        props.onLoadedTotal(refTotal.current)
      }
      const key = `${refDate.current.year()}-${refDate.current.month() + 1}-${refDate.current.date()}`
      setTotal(refTotal.current[key] ?? 0)
    }
    setLoading(props, false)
  }

  const loadRealAvailableTimes = (employee, date) => {
    let list = []
    // step 1: get all working hours in specific day and also filter offline hours
    const workingPeriods = firebase.getEmployeeWorkingPeriods(employee, date)
    const workingOfflines = employee[Constants.WORKING_OFFLINE] || []
    const day = date.format('dddd').toUpperCase()
    let offlinePair = [] // pair of hours [{start, end}]
    for (let i in workingOfflines) {
      const workingOffline = workingOfflines[i]
      const start = moment(workingOffline[Constants.START])
      if (date.isSame(start, 'day')) {
        const end = moment(workingOffline[Constants.END])
        var pair = {}
        pair[Constants.START] = DateUtils.getNumberFromHourMinute({ hour: start.hour(), minute: start.minute() })
        pair[Constants.END] = DateUtils.getNumberFromHourMinute({ hour: end.hour(), minute: end.minute() })
        offlinePair.push(pair)
      }
    }
    for (let i in workingPeriods) {
      const workingPeriod = workingPeriods[i]
      const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
      const dateStr = DateUtils.getDayByNumber(dateNum)
      if (day == dateStr && workingPeriod[Constants.IS_CHECK]) {
        const hours = workingPeriod[Constants.HOURS] || []
        for (let j in hours) {
          const item = hours[j]
          var pairs = [item]
          // check with offline hours, create new list that contains new pairs, 
          // e.g: working 8-11am, offline 9-10am => new list [8-9, 10-11]
          for (let k in offlinePair) {
            const offline = offlinePair[k]
            let subList = []
            for (let l in pairs) {
              const pair = pairs[l]
              if (DateUtils.isHourOverlap(pair[Constants.START], pair[Constants.END], offline[Constants.START], offline[Constants.END])) {
                subList.push(...DateUtils.splitHourOverlap(pair[Constants.START], pair[Constants.END], offline[Constants.START], offline[Constants.END]))
              } else {
                subList.push(pair)
              }
            }
            pairs = [...subList]
          }
          list.push(...pairs)
        }
        break
      }
    }
    return list
  }

  const loadAvailableTimes = (employee, date) => {
    let list = []
    // step 1: get all working hours in specific day and also filter offline hours
    const workingPeriods = initWorkingPeriodEmployee()
    const day = date.format('dddd').toUpperCase()
    for (let i in workingPeriods) {
      const workingPeriod = workingPeriods[i]
      const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
      const dateStr = DateUtils.getDayByNumber(dateNum)
      if (day == dateStr) {
        const hours = workingPeriod[Constants.HOURS] || []
        for (let j in hours) {
          const item = hours[j]
          list.push(item)
        }
        break
      }
    }
    if (list.length > 0) {
      // step 2: calculate available times for booking
      // using start and end of each pair, then plus with duration
      DataUtils.sortBy(list, Constants.START)
      let timesPair = [] // hold [pair]
      let pair = {} // hold {start, end}
      for (let i in list) {
        const item = list[i]
        if (i == 0) {
          pair[Constants.START] = item
          pair[Constants.END] = item
        } else {
          const previous = list[i - 1]
          if (item[Constants.START] == previous[Constants.END]) {
            pair[Constants.END] = item
          } else {
            timesPair.push(pair)
            pair = {}
            pair[Constants.START] = item
            pair[Constants.END] = item
          }
        }
      }
      timesPair.push(pair)
      const durationInMinutes = Constants.CALENDAR_TIME_STEP // step in calendar
      const duration = durationInMinutes / 60 // in hour      
      let availableTimes = []
      for (let i in timesPair) {
        const pair = timesPair[i]
        let start = pair[Constants.START][Constants.START]
        const end = pair[Constants.END][Constants.END]
        while (start + duration <= end) {
          let available = {}
          available[Constants.ID] = StringUtils.getUniqueID()
          available[Constants.DATE] = date
          available[Constants.START] = start
          available[Constants.END] = start + duration
          available[Constants.DISPLAY] = StringUtils.getHourFromNumber(start)

          let canSet = true
          const _end = available[Constants.END]
          // Condition 1: check with other booked services of same employee
          for (let i in refItems.current) {
            const item = refItems.current[i]
            const iDate = moment(item[Constants.DATE])
            const iStart = item[Constants.START]
            const iEnd = item[Constants.END]
            if (employee[Constants.ID] == item[Constants.EMPLOYEE][Constants.ID] && date.isSame(iDate, 'day')
              && DateUtils.isHourOverlap(iStart, iEnd, start, _end)) {
              // check top to add availables
              var availableTop = null
              if (start < iStart) {  
                availableTop = {}              
                availableTop[Constants.ID] = StringUtils.getUniqueID()
                availableTop[Constants.DATE] = date
                availableTop[Constants.START] = start
                availableTop[Constants.END] = iStart
              }
              // check bottom to add availables
              const nextStart = start + duration
              var availableBottom = null
              if (iEnd < nextStart) {
                availableBottom = {}
                availableBottom[Constants.ID] = StringUtils.getUniqueID()
                availableBottom[Constants.DATE] = date
                availableBottom[Constants.START] = iEnd
                availableBottom[Constants.END] = nextStart                
              }
              if (availableTop || availableBottom) {
                let canSetTop = availableTop != null
                let canSetBottom = availableBottom != null
                // check do not overlap with other booked services
                for (let j in refItems.current) {
                  const itemJ = refItems.current[j]
                  const iDateJ = moment(itemJ[Constants.DATE])
                  const iStartJ = itemJ[Constants.START]
                  const iEndJ = itemJ[Constants.END]
                  if (date.isSame(iDateJ, 'day')) {
                    if (availableTop && canSetTop && DateUtils.isHourOverlap(iStartJ, iEndJ, availableTop[Constants.START], availableTop[Constants.END])) {
                      canSetTop = false                    
                    }
                    if (availableBottom && canSetBottom && DateUtils.isHourOverlap(iStartJ, iEndJ, availableBottom[Constants.START], availableBottom[Constants.END])) {
                      canSetBottom = false                    
                    }
                  }
                  if (!canSetTop && !canSetBottom) {
                    break
                  }
                }
                if (canSetTop && !DateUtils.isEventSameTime(availableTop[Constants.START], availableTop[Constants.END])) {
                  availableTimes.push(availableTop)
                }
                if (canSetBottom && !DateUtils.isEventSameTime(availableBottom[Constants.START], availableBottom[Constants.END])) {
                  availableTimes.push(availableBottom)
                }
              }
              canSet = false
              break
            }
          }

          if (canSet && !DateUtils.isEventSameTime(available[Constants.START], available[Constants.END])) {
            availableTimes.push(available)
          }

          start += duration
        }
      }

      const path = date.year() + '/' + (date.month() + 1) + '/' + date.date()
      const now = moment()
      const realAvailableTimes = loadRealAvailableTimes(employee, date)

      for (let i in availableTimes) {
        const item = availableTimes[i]
        const dStart = moment(item[Constants.DATE]).second(0)
        const dEnd = moment(item[Constants.DATE]).second(0)
        const startTime = DateUtils.getHourMinuteFromNumber(item[Constants.START])
        const endTime = DateUtils.getHourMinuteFromNumber(item[Constants.END])
        const dStartTime = dStart.hour(startTime.hour).minute(startTime.minute)
        const dEndTime = dEnd.hour(endTime.hour).minute(endTime.minute)

        // only select time that bigger than current time
        //if (dStartTime.isBefore(now)) {
        //  continue
        //}

        let className = mobile ? 'mobile-no-event time-unavailable' : 'no-event time-unavailable'
        // still show dark gray if time is unavailalbe
        for (let j in realAvailableTimes) {
          const real = realAvailableTimes[j]
          const realStartTime = DateUtils.getHourMinuteFromNumber(real[Constants.START])
          const realEndTime = DateUtils.getHourMinuteFromNumber(real[Constants.END])
          if (DateUtils.isContains(startTime, endTime, realStartTime, realEndTime)) {
            className = mobile ? 'mobile-no-event' : 'no-event'
            break
          }
        }

        // reduce font size of last item in calendar
        if (i == availableTimes.length - 1) {
          className += ' tiny'
        }

        const event = {
          id: item[Constants.ID],
          title: '',
          start: dStartTime.toDate(),
          end: dEndTime.toDate(),
          resource: { hasEvent: false, path, className: className }
        }
        event[Constants.RESOURCE_ID] = employee[Constants.ID]
        refEvents.current.push(event)
      }
    }
  }

  const filterData = async (currentDate) => {
    refEvents.current = []

    for (let i in refItems.current) {
      const item = refItems.current[i]
      var date = moment(item[Constants.DATE])
      if (!currentDate.isSame(date, 'day')) {
        continue
      }
      var hourMinute = DateUtils.getHourMinuteFromNumber(item[Constants.START])
      const start = date.hour(hourMinute.hour).minute(hourMinute.minute)
      date = moment(item[Constants.DATE])
      hourMinute = DateUtils.getHourMinuteFromNumber(item[Constants.END])
      const end = date.hour(hourMinute.hour).minute(hourMinute.minute)
      const path = item[Constants.YEAR] + '/' + item[Constants.MONTH] + '/' + item[Constants.DAY] + '/' + item[Constants.ID]
      const event = {
        id: item[Constants.ID],
        title: `${item[Constants.USER][Constants.FIRST_NAME]} ${item[Constants.USER][Constants.LAST_NAME]} - ${item[Constants.SERVICE][Constants.TITLE]}`,
        start: start.toDate(),
        end: end.toDate(),
        resource: { hasEvent: true, path, className: 'has-event', style: { backgroundColor: item[Constants.COLOR] || item[Constants.SERVICE][Constants.COLOR] } }
      }
      event[Constants.RESOURCE_ID] = item[Constants.EMPLOYEE][Constants.ID]
      refEvents.current.push(event)
    }

    // load available times for date that bigger than today
    if (currentDate.isSameOrAfter(moment(), 'day')) {
      loadAvailableTimes(refEmployee.current, currentDate)
    }

    //DateUtils.sortBy(refEvents.current, Constants.START)
    setEvents(refEvents.current)
  }

  const eventPropGetter = (event, start, end, isSelected) => {
    return { className: event.resource.className, style: event.resource.style }
  }

  const slotPropGetter = (date) => {
    return { style: { background: Constants.APPOINTMENT_TIME_SLOT_UNAVAILABLE_COLOR } }
  }

  const onSelectEvent = (event) => {
    const resource = event.resource
    if (!resource.hasEvent) {
      const startDate = moment(event.start)
      const hourMinute = { hour: startDate.hour(), minute: startDate.minute() }
      const hourNum = DateUtils.getNumberFromHourMinute(hourMinute) // convert to number       
      const booking = Storage.getJson(Constants.BOOKING)
      Storage.setItemToMemory(Constants.CACHE_BOOK_TIME, booking[Constants.DATE] + booking[Constants.START])
      booking[Constants.DATE] = startDate.toISOString()
      booking[Constants.START] = hourNum
      Storage.setJson(Constants.BOOKING, booking)
      Navigator.back()
    }
  }

  props.onClickDay = (value) => {
    // check to load new date
    if (!refDate.current.isSame(value, 'day')) {
      // check to load new appoiments for new month
      if (!refDate.current.isSame(value, 'month')) {
        refDate.current = value
        setDate(value)
        loadData()
      } else {
        refDate.current = value
        setDate(value)
        const key = `${value.year()}-${value.month() + 1}-${value.date()}`
        setTotal(refTotal.current[key] ?? 0)
        filterData(value)
      }
    }
  }

  const classes = useStyles()
  const localizer = momentLocalizer(moment)
  const calendarHeight = (Constants.CALENDAR_END_HOUR - Constants.CALENDAR_START_HOUR) * Constants.CALENDAR_TIME_SLOTS * Constants.CALENDAR_SLOT_HEIGHT
  const mobile = ScreenUtils.isMobile()

  return <>
    <div style={{ display: 'flex' }}>
      <ITypography variant='h5' style={{ width: '100%' }}>{date.format('dddd, MMMM Do YYYY')}</ITypography>
    </div>
    <ITypography variant='body2'>{total} {total == 1 ? Strings.appointment : Strings.appointments}</ITypography>
    <Calendar
      formats={Constants.APPOINTMENT_FORMATS}
      date={date.toDate()}
      min={moment(date).hour(Constants.CALENDAR_START_HOUR).minute(Constants.CALENDAR_START_MINUTE).toDate()}
      max={moment(date).hour(Constants.CALENDAR_END_HOUR).minute(Constants.CALENDAR_END_MINUTE).toDate()}
      events={events}
      resources={resourceMap}
      resourceIdAccessor={Constants.RESOURCE_ID}
      resourceTitleAccessor={Constants.RESOURCE_TITLE}
      selectable={false}
      localizer={localizer}
      defaultView='day'
      toolbar={false}
      timeslots={Constants.CALENDAR_TIME_SLOTS}
      step={Constants.CALENDAR_TIME_STEP}
      onNavigate={() => { }}
      views={{ day: true }}
      style={{ height: calendarHeight || Styles.appointmentsHeight }}
      eventPropGetter={eventPropGetter}
      slotPropGetter={slotPropGetter}
      onSelectEvent={(event) => onSelectEvent(event)}
      onSelecting={slot => false}
    />
  </>
}

function SideCalendarView({ props }) {
  Logger.log('SideCalendarView')

  const { user } = useGlobal()
  const [date, setDate] = useState(moment())
  const [total, setTotal] = useState({})

  useEffect(() => {
    const booking = Storage.getJson(Constants.BOOKING)
    const chooseDate = booking[Constants.DATE] || moment()
    if (chooseDate) {
      onClickDay(moment(chooseDate))
    }
  }, [])

  const onClickDay = (value, fromUser) => {
    setDate(value)
    if (props.onClickDay) {
      props.onClickDay(value)
    }
  }

  const dateRender = (current, value) => {
    const key = `${current.year()}-${current.month() + 1}-${current.date()}`
    if (total[key] && total[key] > 0) {
      return <div className='rc-calendar-date' style={{ lineHeight: '10px', paddingTop: 5 }}>
        <span>{current.date()}</span>
        <div style={{ width: 4, height: 4, borderRadius: 2, background: 'red', margin: 'auto', marginTop: 2 }} />
      </div>
    }
    return <div className='rc-calendar-date' style={{ lineHeight: '10px', paddingTop: 5 }}>
      <span>{current.date()}</span>
    </div>
  }

  props.onLoadedTotal = (value) => {
    setTotal(value)
  }

  const classes = useStyles()

  return <SideCalendar showDateInput={false} value={date}
    onChange={(value) => onClickDay(value, true)}
    dateRender={(current, value) => dateRender(current, value)} />
}

function StoreViewTimes() {
  Logger.log(Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_TIMES)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <div className={classes.root}>
    <div style={{ width: '100%' }}>
      <ICard>
        <ICardContent>
          <EmployeeColumnsView props={props} />
        </ICardContent>
      </ICard>
    </div>
    <div className={classes.sideCalendar}>
      <ICard>
        <ICardContent style={{ padding: 0 }}>
          <SideCalendarView props={props} />
        </ICardContent>
      </ICard>
    </div>
    <ProgressBar props={props} />
    <ToastView props={props} />
  </div>
}

export default StoreViewTimes