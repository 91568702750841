import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import IImage from '../../controls/IImage'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableHead from '../../controls/ITableHead'
import ITableRow from '../../controls/ITableRow'
import IPaper from '../../controls/IPaper'
import Switch from '@material-ui/core/Switch'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogContentText from '../../controls/IDialogContentText'
import IDialogTitle from '../../controls/IDialogTitle'
import PhoneInput from '../../widgets/PhoneInput'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'  
import * as DateUtils from '../../../common/DateUtils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.up('md')]: {
      width: Styles.inputWidth,
    },
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')
  
  const { settings } = useGlobal()
  const query = Navigator.getQuery()
  const userId = query.get(Constants.USER_ID)
  const storeId = query.get(Constants.STORE_ID)
  const id = query.get(Constants.ID)
  const refTimer = useRef()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [image, setImage] = useState()
  const [src, setSrc] = useState('')
  const [selected, setSelected] = React.useState([])
  const [selectedTime, setSelectedTime] = React.useState([])
  const [dates, setDates] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [currentDateOfWeek, setCurrentDateOfWeek] = useState()

  const isSelected = dateOfWeek => selected.indexOf(dateOfWeek) !== -1
  const isSelectedTime = timeOfDate => selectedTime.indexOf(timeOfDate) !== -1

  useEffect(() => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + id)
    ref.once('value').then(response => {
      setLoading(props, false)
      const val = response.val()
      if (val) {
        setFirstName(val[Constants.FIRST_NAME])
        setLastName(val[Constants.LAST_NAME])
        setPhone(val[Constants.PHONE])
        setAddress(val[Constants.ADDRESS])
        setSrc(val[Constants.IMAGE])

        const list = val[Constants.WORKING_PERIOD]
        for(let i in list){
          if(list[i].isCheck){
            selected.push(list[i].dateOfWeek)
          }
        }
        setDates(list)
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })

    if (refTimer.current) {
      clearTimeout(refTimer.current)
    }
  }, [])

  const handleClickRow = (event, dateOfWeek) => {
    const selectedIndex = selected.indexOf(dateOfWeek);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, dateOfWeek);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  }

  const handleClickTimeRow = (event, hourOfDate) => {
    const selectedIndex = selectedTime.indexOf(hourOfDate);
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTime, hourOfDate);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTime.slice(1));
    } else if (selectedIndex === selectedTime.length - 1) {
      newSelected = newSelected.concat(selectedTime.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTime.slice(0, selectedIndex),
        selectedTime.slice(selectedIndex + 1),
      );
    }
    
    setSelectedTime(newSelected)
  }

  const handleClickTime = (item) => {
    
    if(isSelected(item.dateOfWeek)  === false){
      showToast(props, TOAST_ERROR, Strings.errorNotChoiseDay)
      return
    }

    const currentHours = []
    const currentSeleted = []
    for(let i in item.hours){
      if(item.hours[i].isCheck){
        currentSeleted.push(item.hours[i].hourOfDate)
      }
      currentHours.push(item.hours[i])
    }

    item.hours = currentHours
    setSelectedTime(currentSeleted)
    setCurrentDateOfWeek(item)
    setOpenDialog(true)
  }

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {

      currentDateOfWeek.hours.map((hour)=>{
        hour['isCheck'] = isSelectedTime(hour['hourOfDate'])   
      })

      setSelectedTime([])
         
      for(let i in dates){
        if(dates[i].dateOfWeek == currentDateOfWeek.dateOfWeek){
          dates[i] = currentDateOfWeek
        }
      }
      setDates(dates)
    }
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (!StringUtils.isValidUploadImage(file.name)) {
        setImage(null)
        setSrc('')
        showToast(props, TOAST_ERROR, Strings.errorWrongFormat + StringUtils.arrayToString(Constants.SUPPORT_IMAGE_TYPES))
        return
      }
      setImage(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function (e) {
        setSrc(reader.result)
      }.bind(this)
    }
  }

  const postSave = async () => {
    if (StringUtils.stringEmpty(firstName) || StringUtils.stringEmpty(lastName) 
      || StringUtils.stringEmpty(phone) || StringUtils.stringEmpty(address)
      || selected.length == 0) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    if (!StringUtils.validatePhone(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorPhoneInvalid)
      return
    }
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + id)
    const updates = {}
    if (image) {
      const refStorage = firebase.storageRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + id + '/' + image.name)
      const downloadURL = await firebase.uploadFile(refStorage, image, 0, 1, (index, length, bytesTransferred, totalBytes) => {
        //updateProgress(Math.ceil(bytesTransferred / totalBytes * 100))
      })
      if (downloadURL) {
        updates[Constants.IMAGE] = downloadURL
      }
    }
    dates.map((row)=>{
      row['isCheck'] = isSelected(row['dateOfWeek'])   
    })
    updates[Constants.FIRST_NAME] = firstName
    updates[Constants.LAST_NAME] = lastName
    updates[Constants.PHONE] = phone
    updates[Constants.ADDRESS] = address
    updates[Constants.WORKING_PERIOD] = dates
    updates[Constants.DATE_MODIFIED] = Date.now()
    ref.update(updates).then(() => {
      setLoading(props, false)
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
      refTimer.current = setTimeout(() => {
        Navigator.back()
      }, 1000)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const classes = useStyles()

  return <IFormControl className={classes.root}>
    <ITypography className={classes.heading}>{Strings.editEmployee}</ITypography>
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin2Br }} label={Strings.firstName} value={firstName} onChange={(event) => { setFirstName(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.lastName} value={lastName} onChange={(event) => { setLastName(event.target.value) }} />
    <PhoneInput className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.phone} value={phone} onChange={(event) => { setPhone(event) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.address} value={address} onChange={(event) => { setAddress(event.target.value) }} />
    <div className={`${classes.textField}`} style={{ marginTop: Styles.margin1Br }}>
      <ITableContainer component={IPaper}>
        <ITable className={classes.table} aria-label="customized table">
          <ITableHead>
            <ITableRow>
              <ITableCell>Working Time</ITableCell>
              <ITableCell align="right"></ITableCell>
              <ITableCell >Edit shifts</ITableCell>
            </ITableRow>
          </ITableHead>
          <ITableBody>
            {dates.map((row,index) => (
              
              <ITableRow key={row.dateOfWeek} onClick={event => handleClickRow(event, row.dateOfWeek)}>
                <ITableCell component="th" scope="row">
                  {DateUtils.getDayByNumber(row.dateOfWeek)}
                </ITableCell>
                <ITableCell align="right">
                <Switch
                  checked={isSelected(row.dateOfWeek)}
                  value={row.isCheck}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                </ITableCell>
                <ITableCell>
                <AccessAlarmIcon align="right" onClick={event => handleClickTime(row)}/>
                </ITableCell>
              </ITableRow>
            ))}
          </ITableBody>
        </ITable>
      </ITableContainer>
      <IDialog open={openDialog} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <IDialogTitle id='alert-dialog-title'>{Strings.pickTime}</IDialogTitle>
        <IDialogContent>
          <IDialogContentText id='alert-dialog-description'>{Strings.pickTimeDescription}</IDialogContentText>
          <ITableContainer component={IPaper}>
              <ITable className={classes.table} aria-label="customized table">
                <ITableHead>
                  <ITableRow>
                    <ITableCell>Time</ITableCell>
                    <ITableCell></ITableCell>
                  </ITableRow>
                </ITableHead>
                <ITableBody>
                  {currentDateOfWeek && currentDateOfWeek.hours.length != 0 ? currentDateOfWeek.hours.map((hour,index) => (
                    
                    <ITableRow key={hour.hourOfDate} >
                      <ITableCell component="th" scope="row">
                      </ITableCell>
                      <ITableCell>
                      <Switch
                        checked={isSelectedTime(hour.hourOfDate)}
                        value={hour.isCheck}
                        onClick={event => handleClickTimeRow(event, hour.hourOfDate)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      </ITableCell>
                    </ITableRow>
                  )) : []}
                </ITableBody>
              </ITable>
            </ITableContainer>
        </IDialogContent>
        <IDialogActions>
          <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
          <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
        </IDialogActions>
      </IDialog>
    </div>
    <div className={classes.textField} style={{ marginTop: Styles.margin1Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.avatar}:</ITypography>      
    </div>
    <ITextField className={classes.textField} type='file' onChange={handleImageChange} />
    <IImage src={src || settings[Constants.DEFAULT_AVATAR]} style={{ width: '200px', height: '200px', marginTop: Styles.margin1Br }} />
    <div style={{ marginTop: Styles.margin2Br }}>
      <IButton onClick={() => Navigator.back()}>{Strings.cancel}</IButton>
      <IButton className={classes.rightButton} onClick={postSave}>{Strings.save}</IButton>
    </div>
  </IFormControl>
}

function EditEmployee() {
  Logger.log(Constants.PAGES_SYSTEM_EDIT_EMPLOYEE)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default EditEmployee