import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as DateUtils from '../../../../common/DateUtils'
import * as DataUtils from '../../../../common/DataUtils'
import * as ScreenUtils from '../../../../common/ScreenUtils'
import * as Constants from '../../../../common/Constants'
import * as Navigator from '../../../../common/Navigator'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import ITypography from '../../../controls/ITypography'
import ITextField from '../../../controls/ITextField'
import IMenuItem from '../../../controls/IMenuItem'
import ITable from '../../../controls/ITable'
import ITableBody from '../../../controls/ITableBody'
import ITableCell from '../../../controls/ITableCell'
import ITableContainer from '../../../controls/ITableContainer'
import ITableHead from '../../../controls/ITableHead'
import ITableFooter from '../../../controls/ITableFooter'
import ITableRow from '../../../controls/ITableRow'
import IPaper from '../../../controls/IPaper'
import IImage from '../../../controls/IImage'
import ICard from '../../../controls/ICard'
import ICardContent from '../../../controls/ICardContent'
import IGridList from '../../../controls/IGridList'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment-timezone'

const HourButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: '#000000',
  height: 35,
  minWidth: 120
})

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: Styles.contentMaxWidth,
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  addedServices: {
    width: Styles.addedServicesWidth,
    [theme.breakpoints.up('sm')]: {
      marginRight: 20,
    }
  },
  calendarView: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  }
}))

function AddedServicesView({ props }) {
  Logger.log('AddedServicesView')

  const { user, settings } = useGlobal()
  const state = Navigator.getState()
  const store = state[Constants.STORE]
  const storeId = store[Constants.ID]
  const userId = store[Constants.USER_ID]
  const query = Navigator.getQuery()
  const path = query.get(Constants.PATH)
  const refTimer = useRef()
  const refStore = useRef({})
  const refBookedServices = useRef([])
  const refEmployees = useRef([])
  const refSelectedItem = useRef(state.items && state.items.length > 0 ? state.items[0] : {})
  const [items, setItems] = useState(state.items || [])
  const [selectedItem, setSelectedItem] = useState(state.items && state.items.length > 0 ? state.items[0] : {})
  const [times, setTimes] = useState({})

  useEffect(() => {
    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const autoSelectEmployee = (item, select) => {
    if (refEmployees.current.length > 0) {
      var index = 0
      if (select) {
        index = refEmployees.current.findIndex((value) => {
          return value[Constants.ID] == select[Constants.ID]
        })
      }
      item[Constants.EMPLOYEE_AUTO] = refEmployees.current[index]
    }
  }

  const onSelectService = (item) => {
    refSelectedItem.current = item
    setSelectedItem(item)
    // if choose any provider, then we will automatically choose an employee
    if (item[Constants.EMPLOYEE][Constants.ID] == Constants.EMPLOYEE_ANY_ONE_AVAILABLE && !item[Constants.EMPLOYEE_AUTO]) {
      autoSelectEmployee(item)
    }
    if (props.onSelectService) {
      props.onSelectService(item)
    }
  }

  const onBookClick = async () => {
    for (let i in items) {
      if (!times[items[i][Constants.ID]] || times[items[i][Constants.ID]][Constants.ID] == Constants.APPOINTMENT_NO_AVAILABLE_TIME) {
        showToast(props, TOAST_ERROR, Strings.pleaseSetTimes)
        return
      }
    }
    setLoading(props, true)
    var success = true
    for (let i in items) {
      const item = items[i]
      const time = times[item[Constants.ID]]
      const date = moment(time[Constants.DATE])
      const year = date.year()
      const month = date.month() + 1 // month is zero index
      const day = date.date()
      const updates = {}
      updates[Constants.STORE] = firebase.storeForBooking(refStore.current)
      updates[Constants.SERVICE] = firebase.serviceForBooking(item[Constants.SERVICE])
      updates[Constants.EMPLOYEE] = firebase.employeeForBooking(item[Constants.EMPLOYEE_AUTO] || item[Constants.EMPLOYEE])
      updates[Constants.USER] = firebase.userForBooking(user)
      updates[Constants.DATE] = date.toISOString()
      updates[Constants.START] = time[Constants.START]
      updates[Constants.END] = time[Constants.END]
      updates[Constants.DATE_MODIFIED] = Date.now()
      updates[Constants.TIME_ZOME] = moment.tz.guess()
      try {
        const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_EDIT_BOOKING)
        await httpsCallable({ path, userId, storeId, year, month, day, updates })
      } catch (err) {
        Logger.log(err)
        success = false
      }
    }
    if (success) {
      showToast(props, TOAST_SUCCESS, Strings.updatedSuccessfully)
      refTimer.current = setTimeout(() => {
        Navigator.navigate(props, Constants.PAGES_CUSTOMER_APPOINTMENTS)
      }, 1000)
    } else {
      showToast(props, TOAST_ERROR, Strings.errorLoadingData)
      refTimer.current = setTimeout(() => {
        Navigator.navigate(props, Constants.PAGES_CUSTOMER_APPOINTMENTS)
      }, 2000)
    }
    setLoading(props, false)
  }

  const getTimeText = (time) => {
    // case when can not choose available time
    if (time[Constants.ID] == Constants.APPOINTMENT_NO_AVAILABLE_TIME) {
      return <span>{Strings.noAvailableTime}</span>
    }
    const dateStr = moment(time[Constants.DATE]).format('MMMM Do YYYY')
    return <b>{dateStr + ', ' + time[Constants.DISPLAY]}</b>
  }

  // Called from ContentView
  props.onLoadedBookedServices = (list) => {
    refBookedServices.current.push(...list)
  }

  // Called from ContentView
  props.onLoadedEmployees = (list) => {
    refEmployees.current.push(...list)
  }

  // Called from ContentView
  props.onLoadedStore = (store) => {
    refStore.current = store
    if (refSelectedItem.current[Constants.ID]) {
      // if choose any provider, then we will automatically choose an employee
      if (refSelectedItem.current[Constants.EMPLOYEE][Constants.ID] == Constants.EMPLOYEE_ANY_ONE_AVAILABLE && !refSelectedItem.current[Constants.EMPLOYEE_AUTO]) {
        autoSelectEmployee(refSelectedItem.current)
      }
      if (props.onSelectService) {
        props.onSelectService(refSelectedItem.current)
      }
    }
  }

  // Called from TimeView
  props.onChangeProviderClick = (item) => {
    autoSelectEmployee(refSelectedItem.current, item)
    if (props.onSelectService) {
      props.onSelectService(refSelectedItem.current)
    }
  }

  // Called from TimeView
  props.hasSetTime = (item) => {
    return times[item[Constants.ID]] != null
  }

  // Called from TimeView
  props.onTimeClick = (time) => {
    // case when can not choose available time
    if (time[Constants.ID] == Constants.APPOINTMENT_NO_AVAILABLE_TIME) {
      times[refSelectedItem.current[Constants.ID]] = time
      setTimes({ ...times })
      return
    }
    var canSet = true
    var message = Strings.timeUnavailablePleasePickAnother
    const date = moment(time[Constants.DATE])
    const start = time[Constants.START]
    const end = time[Constants.END]
    // check auto first if use choose any provider
    const employee = refSelectedItem.current[Constants.EMPLOYEE_AUTO] || refSelectedItem.current[Constants.EMPLOYEE]
    const employeeId = employee[Constants.ID]

    // Condition 1: must be bigger than today
    canSet = date.isSameOrAfter(moment(), 'day')
    message = Strings.timeUnavailableBiggerThanToday

    // Condition 2: must be different with other times of same employee
    if (canSet) {
      for (let i in times) {
        const item = times[i]
        if (item[Constants.ID] != time[Constants.ID]) {
          const iDate = moment(item[Constants.DATE])
          const iStart = item[Constants.START]
          const iEnd = item[Constants.END]
          if (date.isSame(iDate, 'day') && employeeId == item[Constants.EMPLOYEE_ID]
            && DateUtils.isHourOverlap(iStart, iEnd, start, end)) {
            canSet = false
            message = Strings.timeUnavailableOverlap
            break
          }
        }
      }
    }

    // Condition 3: must be diffirent with other booked services of same employee
    if (canSet) {
      for (let i in refBookedServices.current) {
        const item = refBookedServices.current[i]
        const iDate = moment(item[Constants.DATE])
        const iStart = item[Constants.START]
        const iEnd = item[Constants.END]

        // check case when already choose time
        if (state[Constants.ID] && state[Constants.ID] == item[Constants.ID]) {
          continue
        }

        if (date.isSame(iDate, 'day') && employeeId == item[Constants.EMPLOYEE][Constants.ID]
          && DateUtils.isHourOverlap(iStart, iEnd, start, end)) {
          canSet = false
          message = Strings.timeUnavailableOverlap
          break
        }
      }
    }

    if (canSet) {
      time[Constants.EMPLOYEE_ID] = employeeId
      times[refSelectedItem.current[Constants.ID]] = time
      setTimes({ ...times })
      if (props.onBookedTimesChanged) {
        props.onBookedTimesChanged(times)
      }
    } else {
      showToast(props, TOAST_ERROR, message)
    }
  }

  const classes = useStyles()

  return <div className={classes.addedServices}>
    <ITableContainer component={IPaper}>
      <ITable>
        <ITableHead>
          <ITableRow>
            <ITableCell><ITypography variant='h5'>{Strings.yourVisit}</ITypography></ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          {items.map(item => (
            <ITableRow key={item[Constants.ID]}>
              <ITableCell className='service-bg' size='small' onClick={() => onSelectService(item)}
                style={{ maxWidth: 100, backgroundColor: selectedItem[Constants.ID] == item[Constants.ID] ? '#eaf6ff' : 'white' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 5, backgroundColor: item[Constants.SERVICE][Constants.COLOR] }} />
                  <div style={{ marginLeft: 10, width: '100%' }}>
                    <ITypography variant='body1'><b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                    <ITypography variant='body2'>{item[Constants.SERVICE][Constants.DURATION]} {Strings.mins} ${item[Constants.SERVICE][Constants.PRICE]}</ITypography>
                    {times[item[Constants.ID]] && item[Constants.EMPLOYEE_AUTO] && <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize, marginRight: 10 }} src={item[Constants.EMPLOYEE_AUTO][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''}
                        alt={item[Constants.EMPLOYEE_AUTO][Constants.FIRST_NAME]} />
                      <ITypography variant='body2'>{item[Constants.EMPLOYEE_AUTO][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE_AUTO][Constants.LAST_NAME]}</ITypography>
                    </div>}
                    {(!times[item[Constants.ID]] || !item[Constants.EMPLOYEE_AUTO]) && <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize, marginRight: 10 }} src={item[Constants.EMPLOYEE][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''}
                        alt={item[Constants.EMPLOYEE][Constants.FIRST_NAME]} />
                      <ITypography variant='body2'>{item[Constants.EMPLOYEE][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE][Constants.LAST_NAME]}</ITypography>
                    </div>}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <AccessAlarmIcon style={{ marginRight: 5 }}/> {times[item[Constants.ID]] ? getTimeText(times[item[Constants.ID]]) : ''}
                    </div>
                  </div>
                </div>
              </ITableCell>
            </ITableRow>
          ))}
        </ITableBody>
        <ITableFooter>
          <ITableRow>
            <ITableCell style={{ textAlign: 'center' }}>
              <IButton style={{ marginTop: 10, marginBottom: 10 }} onClick={() => onBookClick()}>{Strings.update}</IButton>
            </ITableCell>
          </ITableRow>
        </ITableFooter>
      </ITable>
    </ITableContainer>
  </div>
}

function TimeView({ props }) {
  Logger.log('TimeView')

  const state = Navigator.getState()
  const { user, settings } = useGlobal()  
  const refStore = useRef({})
  const refItem = useRef({})
  const refItems = useRef([])
  const refEvent = useRef({})
  const refBookedTimes = useRef([])
  const refBookedServices = useRef([])
  const refEmployees = useRef([])
  const [date, setDate] = useState('')
  const [items, setItems] = useState([])

  useEffect(() => {

  }, [])

  const loadData = async () => {
    let list = []
    // step 1: get all working hours in specific day and also filter offline hours
    // get auto employee first if user choose any provider
    const event = refEvent.current
    const date = moment(event[Constants.START])
    const employee = refItem.current[Constants.EMPLOYEE_AUTO] || refItem.current[Constants.EMPLOYEE]
    const service = refItem.current[Constants.SERVICE]
    const workingPeriods = firebase.getEmployeeWorkingPeriods(employee, date)
    const workingOfflines = employee[Constants.WORKING_OFFLINE] || []    
    const day = date.format('dddd').toUpperCase()
    let offlinePair = [] // pair of hours [{start, end}]
    for (let i in workingOfflines) {
      const workingOffline = workingOfflines[i]
      const start = moment(workingOffline[Constants.START])
      if (date.isSame(start, 'day')) {
        const end = moment(workingOffline[Constants.END])
        let pair = {}
        pair[Constants.START] = DateUtils.getNumberFromHourMinute({ hour: start.hour(), minute: start.minute() })
        pair[Constants.END] = DateUtils.getNumberFromHourMinute({ hour: end.hour(), minute: end.minute() })
        offlinePair.push(pair)
      }
    }
    for (let i in workingPeriods) {
      const workingPeriod = workingPeriods[i]
      const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
      const dateStr = DateUtils.getDayByNumber(dateNum)
      if (day == dateStr && workingPeriod[Constants.IS_CHECK]) {
        const hours = workingPeriod[Constants.HOURS] || []
        for (let j in hours) {
          const item = hours[j]         
          var pairs = [{ ...item }]
          // check with offline hours, create new list that contains new pairs, 
          // e.g: working 8-11am, offline 9-10am => new list [8-9, 10-11]
          for (let k in offlinePair) {
            const offline = offlinePair[k]
            let subList = []
            for (let l in pairs) {
              const pair = pairs[l]
              if (DateUtils.isHourOverlap(pair[Constants.START], pair[Constants.END], offline[Constants.START], offline[Constants.END])) {
                subList.push(...DateUtils.splitHourOverlap(pair[Constants.START], pair[Constants.END], offline[Constants.START], offline[Constants.END]))
              } else {
                subList.push(pair)
              }
            }
            pairs = [...subList]            
          }
          list.push(...pairs)          
        }
        break
      }
    }
    if (list.length > 0) {
      DataUtils.sortBy(list, Constants.START)

      // step 2: set start to selected time if it is available
      const qStart = state[Constants.START]
      if (qStart && qStart > 0) {
        for (let i in list) {
          const item = list[i]
          if (qStart >= item[Constants.START] && qStart <= item[Constants.END]) {
            item[Constants.START] = qStart
            break
          }
        }
      }

      // step 3: calculate available times for booking
      // using start and end of each pair, then plus with duration      
      var timesPair = [] // hold [pair]
      var pair = {} // hold {start, end}
      for (let i in list) {
        const item = list[i]
        if (i == 0) {
          pair[Constants.START] = item
          pair[Constants.END] = item
        } else {
          const previous = list[i - 1]
          if (item[Constants.START] == previous[Constants.END]) {
            pair[Constants.END] = item
          } else {
            timesPair.push(pair)
            pair = {}
            pair[Constants.START] = item
            pair[Constants.END] = item
          }
        }
      }
      timesPair.push(pair)
      // should be every some minutes and the start times are independent of service duration      
      const duration = Constants.CALENDAR_TIME_AVAILABLE_STEP / 60 // in hour  
      const serviceDuration = service[Constants.DURATION] / 60 // in hour
      const now = moment()  
      let availableTimes = []
      for (let i in timesPair) {
        const pair = timesPair[i]
        let start = pair[Constants.START][Constants.START]
        const end = pair[Constants.END][Constants.END]
        while (start + duration <= end) {
          let available = {}
          available[Constants.ID] = refItem.current[Constants.ID]
          available[Constants.DATE] = date
          available[Constants.START] = start
          available[Constants.END] = start + serviceDuration
          available[Constants.DISPLAY] = StringUtils.getHourFromNumber(start)

          let canSet = true
          const _end = available[Constants.END]
          // Condition 0: check with current time
          const startTime = DateUtils.getHourMinuteFromNumber(start)
          const dStartTime = date.hour(startTime.hour).minute(startTime.minute)
          if (dStartTime.isBefore(now)) {
            canSet = false
          }

          // Condition 1: check with other booked services of same employee
          if (canSet) {
            for (let i in refBookedServices.current) {
              const item = refBookedServices.current[i]
              const iDate = moment(item[Constants.DATE])
              const iStart = item[Constants.START]            
              const iEnd = item[Constants.END]

              // check case when already choose time
              if (state[Constants.ID] && state[Constants.ID] == item[Constants.ID]) {
                continue
              }

              if (date.isSame(iDate, 'day') && employee[Constants.ID] == item[Constants.EMPLOYEE][Constants.ID]
                && DateUtils.isHourOverlap(iStart, iEnd, start, _end)) {
                canSet = false
                break
              }
            }
          }
          
          // Condition 2: check with other times of same employee
          if (canSet) {
            for (let i in refBookedTimes.current) {
              const item = refBookedTimes.current[i]
              if (item[Constants.ID] != refItem.current[Constants.ID]) {
                const iDate = moment(item[Constants.DATE])
                const iStart = item[Constants.START]
                const iEnd = item[Constants.END]                
                if (date.isSame(iDate, 'day') && employee[Constants.ID] == item[Constants.EMPLOYEE_ID]
                  && DateUtils.isHourOverlap(iStart, iEnd, start, _end)) {
                  canSet = false
                  break
                }
              }
            }
          }

          if (canSet) {
            availableTimes.push(available)
          }

          start += duration
        }
      }
      refItems.current = availableTimes
      setItems(availableTimes)
    } else {
      refItems.current = list
      setItems(list)
    }

    // case when already choose date, time
    let hasSetTime = false
    if (props.hasSetTime) {
      hasSetTime = props.hasSetTime(refItem.current)
    }
    const qStart = state[Constants.START]
    if (!hasSetTime && qStart && qStart > 0) {
      const selectedHour = StringUtils.getNumber(qStart)
      let available = null
      // find correct time
      for (let i in refItems.current) {
        let item = refItems.current[i]
        if (item[Constants.START] == selectedHour) {
          available = item
          break
        }
      }
      // let last = -1
      // // find the biggest available time that lower or equal to selected hour
      // for (let i in refItems.current) {
      //   let item = refItems.current[i]
      //   if (item[Constants.START] <= selectedHour) {
      //     if (last == -1 || item[Constants.START] > last) {
      //       last = item[Constants.START]
      //       available = item
      //     }
      //   }
      // }
      // // get the first available item if we can not choose any
      // if (!available && refItems.current.length > 0) {
      //   available = refItems.current[0]
      // }
      if (!available) {
        available = {} 
        available[Constants.ID] = Constants.APPOINTMENT_NO_AVAILABLE_TIME
      }
      onTimeClick(available)
    }
  }

  const onTimeClick = (item) => {
    if (props.onTimeClick) {
      props.onTimeClick(item)
    }
  }

  const onChangeProviderClick = (value) => {
    for (let i in refEmployees.current) {
      if (refEmployees.current[i][Constants.ID] == value) {
        if (props.onChangeProviderClick) {
          props.onChangeProviderClick(refEmployees.current[i])
        }
        break
      }
    }
  }

  props.onDateSelect = (store, item, event) => {
    refStore.current = store
    refItem.current = item
    refEvent.current = event
    const dStart = moment(event.start).format('MMMM Do YYYY')
    setDate(dStart)
    loadData()
  }

  props.onLoadedBookedServicesTimeView = (list) => {
    refBookedServices.current = list
  }

  props.onLoadedEmployeesTimeView = (list) => {
    refEmployees.current = list
  }

  props.onBookedTimesChanged = (list) => {
    refBookedTimes.current = list
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return <ICard style={{ marginTop: 10 }}>
    <ICardContent>
      <ITypography variant='h6'>{Strings.chooseTimeToVisitOn}: <b>{date}</b></ITypography>
      {refItem.current && refItem.current[Constants.EMPLOYEE_AUTO] && <div style={{ marginTop: 10, marginBottom: 10 }}>
        <ITypography variant='body1'>{Strings.autoPickProvider}:</ITypography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ITextField style={{ width: 300 }} select
            onChange={(event) => {
              onChangeProviderClick(event.target.value)
            }}
            InputLabelProps={{ shrink: true, style: { color: '#fff' } }}
            value={refItem.current[Constants.EMPLOYEE_AUTO][Constants.ID]}>
            {refEmployees.current.map(option => (
              <IMenuItem key={option[Constants.ID]} value={option[Constants.ID]}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize, marginRight: 10 }} src={option[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={option[Constants.FIRST_NAME]} />
                  <ITypography variant='body2'>{option[Constants.FIRST_NAME]} {option[Constants.LAST_NAME]}</ITypography>
                </div>
              </IMenuItem>
            ))}
          </ITextField>
        </div>
      </div>}
      <IGridList cellHeight={50} cols={isMobile ? 2 : 5} style={{ marginTop: Styles.margin1Br }}>
        {items.map(item => (
          <div key={item[Constants.DISPLAY]}>
            <HourButton onClick={() => onTimeClick(item)}>
              <ITypography variant='body2'>{item[Constants.DISPLAY]}</ITypography>
            </HourButton>
          </div>
        ))}
      </IGridList>
      {items.length == 0 && <ITypography variant='body2'>{Strings.noTimesAvailable}</ITypography>}
    </ICardContent>
  </ICard>
}

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user } = useGlobal()
  const state = Navigator.getState()
  const store = state[Constants.STORE]
  const storeId = store[Constants.ID]
  const userId = store[Constants.USER_ID]
  const refStore = useRef()
  const refItem = useRef()
  const refEvent = useRef()
  const refToday = useRef(moment())
  const [service, setService] = useState()
  const [events, setEvents] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(props, true)
    try {
      // load employees of store
      const employeesCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_EMPLOYEES)
      const employeesResponse = await employeesCallable({ userId, storeId })
      if (employeesResponse.data.items) {
        var list = employeesResponse.data.items
        DataUtils.sortBy(list, Constants.FIRST_NAME)
        if (props.onLoadedEmployees) {
          props.onLoadedEmployees(list)
        }
        if (props.onLoadedEmployeesTimeView) {
          props.onLoadedEmployeesTimeView(list)
        }
      }
      // load booked services of store
      const date = moment()
      const year = date.year()
      const path = `${year}`
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_STORE_BOOKINGS)
      const response = await httpsCallable({ userId, storeId, path })
      var list = []
      if (response && response.data.bookings) {
        for (let i in response.data.bookings) {
          const item1 = response.data.bookings[i]
          for (let j in item1) {
            const item2 = item1[j]
            for (let k in item2) {
              const item3 = item2[k]
              list.push(item3)
            }
          }
        }
      }
      if (props.onLoadedBookedServices) {
        props.onLoadedBookedServices(list)
      }
      if (props.onLoadedBookedServicesTimeView) {
        props.onLoadedBookedServicesTimeView(list)
      }
      // load store infomation, should be called last because it will trigger logic of other views
      const storeCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_STORE)
      const storeResponse = await storeCallable({ userId, storeId })
      if (storeResponse.data && storeResponse.data.store) {
        refStore.current = storeResponse.data.store
        if (props.onLoadedStore) {
          props.onLoadedStore(refStore.current)
        }
      }
      setLoading(props, false)
      // case when already select customer, employee, date and time    
      const qDate = state[Constants.DATE]
      if (StringUtils.stringEmpty(qDate)) {
        onSelectSlot({ start: refToday.current.toDate(), end: refToday.current.toDate() })
      } else {
        const mDate = moment(qDate)
        onSelectSlot({ start: mDate.toDate(), end: mDate.toDate() })
      }
    } catch(err) {
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    }
  }

  const onSelectEvent = (event) => {
    Logger.log(event)
  }

  const onSelectSlot = (event) => {
    if (!refStore.current || !refItem.current) {
      return
    }

    const date = moment(event[Constants.START])
    if (date.isBefore(moment(), 'day')) {
      showToast(props, TOAST_ERROR, Strings.timeUnavailableBiggerThanToday)
      return
    }

    refEvent.current = { ...event, title: '', allDay: true }
    setEvents([refEvent.current])
    if (props.onDateSelect) {
      props.onDateSelect(refStore.current, refItem.current, refEvent.current)
    }
  }

  props.onSelectService = (item) => {
    refItem.current = item
    setService(item[Constants.SERVICE])
    if (refEvent.current) {
      if (props.onDateSelect) {
        props.onDateSelect(refStore.current, refItem.current, refEvent.current)
      }
    }
  }

  const dayPropGetter = (date) => {
    if (moment(date).isBefore(refToday.current, 'day')) {
      return { className: 'rbc-day-bg-disable' }
    }
    return {}
  }

  const classes = useStyles()
  const localizer = momentLocalizer(moment)

  return <ICard className={classes.calendarView}>
    <ICardContent>
      <ITypography variant='h5'>{Strings.chooseDateAndTime}: <b>{service ? service[Constants.TITLE] : ''}</b></ITypography>
      <Calendar
        formats={Constants.APPOINTMENT_FORMATS}
        events={events}
        selectable
        localizer={localizer}
        defaultView='month'
        views={{ month: true }}
        style={{ height: 400, marginTop: Styles.margin1Br }}
        dayPropGetter={dayPropGetter}
        longPressThreshold={Styles.longPressThreshold}
        onSelectEvent={(event) => onSelectEvent(event)}
        onSelectSlot={(event) => onSelectSlot(event)}
      />
    </ICardContent>
  </ICard>
}

function ScheduleNow() {
  Logger.log(Constants.PAGES_CUSTOMER_EDIT_APPOINTMENT_SCHEDULE_NOW)

  let props = createPropsWithActions()

  const classes = useStyles()

  return <>
    <div className={classes.root}>
      <AddedServicesView props={props} />
      <div style={{ width: '100%' }}>
        <ContentView props={props} />
        <TimeView props={props} />
      </div>
    </div>
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default ScheduleNow