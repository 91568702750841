import * as Constants from '../../../common/Constants'
import * as firebase from '../../../utils/firebase'

export function payPlan({ nonce, planId }) {
  const httpsCallable = firebase
    .functions()
    .httpsCallable(Constants.FB_FUNC_PAY_PLAN)

  return httpsCallable({ planId, nonce })
}

export function cancelSubscription({ subscriptionId }) {
  const httpsCallable = firebase
    .functions()
    .httpsCallable(Constants.FB_FUNC_CANCEL_SUBSCRIPTION)

  return httpsCallable({subscriptionId})
}

export async function getPlans() {
  let plansObj = await firebase
    .databaseRef(Constants.FB_SYSTEM_PLANS)
    .limitToLast(100)
    .once('value')

  plansObj = plansObj.val()
  const plans = Object.keys(plansObj).map(id => ({ ...plansObj[id], uid: id }))

  return plans.sort((a, b) => a.duration - b.duration)
}

export async function getSubscription(userId, storeId) {
  const ref = await firebase
    .databaseRef(Constants.FB_STORE_PAYMENTS + '/' + userId+ '/' + storeId)
    .once('value')
  if (ref) {
    const val = ref.val()
    if (val) {
      return val.subscription
    }
  }
  return null
}
