import LocalizedStrings from 'react-localization'

const Strings = new LocalizedStrings({
    en: {
        appName: 'NextNails Care',
        appVersion: '1.0',
        hi: 'Hi',
        home: 'Home',
        dashboard: 'Dashboard',
        signIn: 'Sign In',
        signOut: 'Sign Out',
        register: 'Register',
        next: 'Next',
        finish: 'Finish',
        admin: 'Admin',
        error: 'Error',
        email: 'Email',
        password: 'Password',
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        firstName: 'First Name',
        lastName: 'Last Name',
        address: 'Address',
        image : 'Image',
        phone: 'Phone',
        name : 'Name',
        description: 'Description',
        category: 'Category',
        createStore : 'Create Store',
        color: 'Color',
        edit: 'Edit',
        delete: 'Delete',
        addService: 'Add Service',
        selectService: 'Select Service',
        editService: 'Edit Service',
        addStore: 'Add Store',
        editStore: 'Edit Store',
        services: 'Services',
        service: 'Service',
        stores: 'Stores',
        employee: 'Employee',
        employees: 'Employees',
        addEmployee: 'Add Employee',
        editEmployee: 'Edit Employee',
        title: 'Title',
        description: 'Description',
        price: 'Price',
        priceHint: 'Price in $',
        duration: 'Duration',
        durationHint: 'Duration in minutes',
        totalDuration: 'Total Duration',
        minutes: 'Minutes',
        system: 'System',
        save: 'Save',
        ok: 'OK',
        or: 'Or',
        color: 'Color',
        cancel: 'Cancel',
        noData: 'No Data',
        savedSuccessfully: 'Saved Successfully',
        updatedSuccessfully: 'Updated Successfully',
        deletedSussessfully: 'Deleted Successfully',
        bookedSuccessfully: 'Booked Successfully',
        copiedSuccessfully: 'Copied Successfully',
        sentSuccessfully: 'Sent Successfully',
        errorInputRequired: 'Input Required',
        errorLoadingData: 'Error Loading Data',
        errorPasswordMismatch: 'Password Not matching',
        errorWrongFormat: 'Wrong format! Please choose one of: ',
        errorInvalidPrice: 'Price is invalid. Please input number, e.g: 10',
        errorEmailInvalid: 'Email is invalid.',
        errorNumberInvalid: 'Number is invalid.',
        errorPhoneInvalid: 'Phone is invalid. Please use format: xxx-xxx-xxxx',
        askRegisterCustomer: 'Don\'t have an account? Click here to Register',
        askRegisterAdminStore: 'Create bussiness account',
        registerSuccessed: 'Register Successfully',
        deleteConfirm: 'Are you sure you want to delete this item?',
        cancelSubscriptionConfirm: 'Are you sure you want to cancel subscription?',
        systemService: 'System Service',
        pleaseSelectCategory: 'Please select category',
        myStore: 'Store',
        store: 'Store',
        selectedStore: 'Selected Store',
        avatar: 'Avatar',
        personalInformation: 'Personal Information',
        loginInformation: 'Login Information',
        profile: 'Profile',
        appointment: 'Appointment',
        appointments: 'Appointments',
        appointmentDetails: 'Appointment Details',
        addAppointment: 'Add Appointment',
        inviteAppointment: 'Invite Appointment',
        workingTime: 'Working time',
        yourWorkingTime: 'Your working time',
        changePassword: 'Change Password',
        forgotPassword: 'Forgot Password?',
        sendResetPassword: 'Send Reset Password',
        sendResetPasswordSuccessfully: 'Sent successfully! Please check your email.',
        categories: 'Categories',
        addCategory: 'Add Category',
        editCategory: 'Edit Category',
        deleteCategory: 'Delete Category',
        search: 'Search',
        searchStoreHint: 'Enter store\'s name',
        searchServiceHint: 'Enter service\'s name',
        searchCustomerHint: 'Enter customer\'s first name, last name, phone or email',
        scheduleNow: 'Schedule Now',
        schedule : 'Schedule',
        employeeCreated: 'Account created successfully. Please check email for login information. Note that email might be in spam folder.',
        addToVisit: 'Add',
        mins: 'mins',
        selectProvider: 'Select a Provider',
        select: 'Select',
        createAppointment: 'Create Appointment?',
        start: 'Start',
        end: 'End',
        pickTime: 'Your time',
        pickTimeDescription: 'Please pick time working for employee',
        errorNotChoiseDay: 'Please enable date',
        yourVisit: 'Your Appointments',
        dateAndTime: 'Date And Time',
        chooseDateAndTime: 'Choose Date And Time',
        youAlreadyAddedService: 'You aleardy added this service!',
        youAlreadyAddedService: 'You aleardy added this service!',
        confirmBook: 'Schedule Now',
        chooseTime: 'Choose Time',
        chooseTimeToVisitOn: 'Choose a time for your appointment on',
        morning: 'Morning',
        afternoon: 'Afternoon',
        evening: 'Evening',
        from : 'From',
        to : 'To',
        offline : 'Offline',
        offlineEmployee: 'Employee Offline Management',
        areYourSureOffline:"Are you sure to set up offline for",
        errorDupplicateOfflineRangeDate : "Can not pick dupplicate time range for employee offline date",
        errorNotWorkingPeriod : "Employee is not working at the time you choose, please recheck" ,
        timeUnavailablePleasePickAnother: 'Time is unavailable. Please choose another time!',
        timeUnavailableBiggerThanToday: 'Time is unavailable. Time must be bigger than current time!',
        timeMoveUnavailableBiggerThanToday: 'You cannot move the appointment to this time. The new time needs to be later than the current time now.',
        timeUnavailableOverlap: 'Time is unavailable. You already picked a service with same time!',
        pleaseSetTimes: 'Please setup time for all services!',
        about: 'About',
        staff: 'Staff',
        noTimesAvailable: 'No times available',
        provider: 'Provider',
        providers: 'Providers',
        time: 'Time',
        customer: 'Customer',
        workingTime: 'Working Time',
        editShifts: 'Edit shifts',
        location: 'Location',
        anyProviderAvailable: 'No Preference',
        autoPickProvider: 'Pick provider',
        changeProvider: 'Change Provider',
        cancelAppointment: 'Cancel Appointment?',
        cancelAppointmentConfirm: 'Are you sure you want to cancel this appointment?',
        cancelSuccessfully: 'Canceled Successfully',
        appointmentsToday: 'Appointments Today',
        noAppointmentsToday: 'No appointments today',
        update: 'Update',
        summary: 'Summary',
        totalAppointmentsToday: 'Total Appointments Today',
        totalAppointmentsThisMonth: 'Total Appointments This Month',
        viewDetail: 'View Detail',
        homeWelcome: 'Booking has never been this simple.',
        pleaseLoginToContinue: 'Please Sign In To Continute',
        inviteSubject: 'You have an invitation from',
        inviteLink: 'Invite Link',
        inviteLinkHint: 'Please include the invite link below in your message that sent to customer.',
        inviteEmailHint: 'Enter customer\'s email',
        invitePhoneHint: 'Enter customer\'s phone',
        invitePhoneHelp: 'Note: please use full Invite Link when inviting thought SMS',
        inviteMessage: 'Enter message to send to customer',
        clickToCopy: 'Click to copy',
        send: 'Send',
        subscription: 'Subscription',
        payment: "Payment",
        cancelSuccessful: "Cancel successful",
        status:'Status',
        createat: 'Create at',
        startbilling: 'Start billing',
        endbilling: 'End billing',
        currentCycle : 'Current cycle',
        history : 'History',
        activate: 'Activate',
        cannotDeleteStoreData: 'This store already have employees, services or categories data. You have to detele these data first!',
        cannotDeleteStoreAppointments: 'This store already have appointments. You can not delete it!',
        today: 'Today',
        date: 'Date',
        addTime: 'Add Time',
        mustHave1Time: 'Must have at least 1 item!',
        customers: 'Customers',
        pleaseChooseCustomer: 'Please choose customer!',
        pleaseChooseStore: 'Please choose store!',
        pleaseChooseService: 'Please choose service!',
        pleaseChooseProvider: 'Please choose provider!',
        pleaseChooseDateTime: 'Please choose date and time!',
        confirm: 'Confirm',
        msgReactiveInvalid : 'Your current plan is active util (Billing End) You can re-active plan on (Billing End)',
        addAnother: 'Add Another',
        addAnotherService: 'Add Another Service',
        day: 'Day',
        agenda: 'Agenda',
        noAvailableTime: 'Selected provider is not available during this time. Please select another provider or change date & time.',
        noAvailableProvider: 'There are no any providers are available during this time. Please select another provider or change date & time.',
        details: 'Details',
        settings: 'Settings',
        textReminders: 'Text Reminders',
        appointmentTextReminders: 'Appointment text reminders?',
        deepLink: 'Deep Link',
        selectAll: 'Select All',
        deselectAll: 'Deselect All',
        editOrder: 'Edit Order',
        selectedAppointments: 'Selected Appointments',
        dropAppointmentHint: 'Drop selected appointments here...',
        updatedAppointments: 'Updated Appointments',
        updatedAppointmentsHint: 'Updated appointments will appear here...',
        seachCustomerAddGuestHint: 'Can\'t find customer? Click button to add new one...',
        addCustomer: 'Add Customer',
        requestTechnician: 'Request Technician',
        anyTechnician: 'Any Provider',
        mustSetProviderBeforeSaving: 'You have to set Provider before saving!',
        editSchedule: 'Edit Schedule',
        editScheduleFor: 'Edit schedule for ',
        inout: 'In',
        muteAllNotifications: 'Mute All Notifications',
        yes: 'Yes',
        no: 'No',
        notificationTimeBeforeAppointment: 'Notification Time Before Appointment (in minutes):',
        notificationTimeInvalid: 'Notification Time must be bigger than 30 minutes!',
    }
})

export default Strings