import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import * as Navigator from '../../../common/Navigator'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITypography from '../../controls/ITypography'
import ICard from '../../controls/ICard'
import ISwitch from '../../controls/ISwitch'
import ICardContent from '../../controls/ICardContent'
import IDivider from '../../controls/IDivider'
import ITextField from '../../controls/ITextField'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.formMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 60,
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const [notificationTime, setNotificationTime] = useState('60')

  useEffect(() => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
    ref.once('value').then(response => {
      setLoading(props, false)
      const val = response.val()
      if (val) {
        setNotificationTime(val[Constants.NOTIFICATION_TIME] || '60')
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const postSave = async () => {
    if (!StringUtils.isNumber(notificationTime)) {
      showToast(props, TOAST_ERROR, Strings.errorNumberInvalid)
      return
    }
    const nTime = StringUtils.getNumber(notificationTime)
    if (nTime < 30) {
      showToast(props, TOAST_ERROR, Strings.notificationTimeInvalid)
      return
    }
    setLoading(props, true)
    try {
      const ref = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
      const updates = {}
      updates[Constants.NOTIFICATION_TIME] = nTime
      await ref.update(updates)
      setLoading(props, false)
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
    } catch (err) {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    }
  }

  const classes = useStyles()

  return <div>
    <ITypography className={classes.heading}>{Strings.settings}</ITypography>
    <ITypography variant='h6' style={{ marginTop: Styles.margin2Br }}>{Strings.textReminders}</ITypography>
    <IDivider />
    <div style={{ display: 'flex', alignItems: 'center', marginTop: Styles.margin1Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.notificationTimeBeforeAppointment}</ITypography>
      <ITextField className={classes.textField} value={notificationTime} onChange={(event) => { setNotificationTime(event.target.value) }}/>
    </div>
    <div style={{ textAlign: 'center', marginTop: Styles.margin4Br }}>
      <IButton onClick={postSave}>{Strings.save}</IButton>
    </div>
  </div>
}

function StoreSettings() {
  Logger.log(Constants.PAGES_STORE_SETTINGS)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default StoreSettings