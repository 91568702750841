import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as ScreenUtils from '../../../common/ScreenUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import IImage from '../../controls/IImage'
import PhoneInput from '../../widgets/PhoneInput'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import GoogleMapReact from 'google-map-react'
import ColorPicker from '../../widgets/ColorPicker'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  avatar: {
    width: 200,
    height: 200
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  },
  content: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  mapContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  },
  mapBox: {
    marginTop: 10,
    width: '100%',
    height: Styles.mapHeight
  },
  divColor: {
    width: 50,
    height: 25,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const userId = user[Constants.ID]
  const storeId = user[Constants.ID]
  const refTimer = useRef()
  const refMaps = useRef()
  const refMap = useRef()
  const refLocationLat = useRef(Constants.STORE_DEFAULT_LOCATION_LAT)
  const refLocationLng = useRef(Constants.STORE_DEFAULT_LOCATION_LNG)
  const refLocationZoom = useRef(Constants.STORE_DEFAULT_ZOOM)
  const refMarkers = useRef([])
  const refColor = useRef('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [image, setImage] = useState()
  const [src, setSrc] = useState('')
  const [color, setColor] = useState('')
  const refId = useRef()

  useEffect(() => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
    ref.once('value').then(response => {
      setLoading(props, false)
      const val = response.val()
      if (val) {
        refId.current = val[Constants.ID]
        setName(val[Constants.NAME])
        setDescription(val[Constants.DESCRIPTION])
        setCategory(val[Constants.CATEGORY])
        setAddress(val[Constants.ADDRESS])
        setPhone(val[Constants.PHONE])
        setEmail(val[Constants.EMAIL])
        setSrc(val[Constants.IMAGE])
        setColor(val[Constants.COLOR])
        refColor.current = val[Constants.COLOR]
        refLocationLat.current = val[Constants.LOCATION_LAT]
        refLocationLng.current = val[Constants.LOCATION_LNG]
        refLocationZoom.current = val[Constants.LOCATION_ZOOM]
        renderMarkers()
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })

    if (refTimer.current) {
      clearTimeout(refTimer.current)
    }
  }, [])

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (!StringUtils.isValidUploadImage(file.name)) {
        setImage(null)
        setSrc('')
        showToast(props, TOAST_ERROR, Strings.errorWrongFormat + StringUtils.arrayToString(Constants.SUPPORT_IMAGE_TYPES))
        return
      }
      setImage(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function (e) {
        setSrc(reader.result)
      }.bind(this)
    }
  }

  const postSave = async () => {
    if (StringUtils.stringEmpty(name) || StringUtils.stringEmpty(description)
      || StringUtils.stringEmpty(category) || StringUtils.stringEmpty(address)
      || StringUtils.stringEmpty(phone) || StringUtils.stringEmpty(email)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    if (!StringUtils.validateEmail(email)) {
      showToast(props, TOAST_ERROR, Strings.errorEmailInvalid)
      return
    }
    if (!StringUtils.validatePhone(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorPhoneInvalid)
      return
    }
    setLoading(props, true)    
    let updates = {}
    if (image) {
      const refStorage = firebase.storageRef(Constants.FB_STORES + '/' + userId + '/' + storeId + '/' + image.name)
      const downloadURL = await firebase.uploadFile(refStorage, image, 0, 1, (index, length, bytesTransferred, totalBytes) => {
        //updateProgress(Math.ceil(bytesTransferred / totalBytes * 100))
      })
      if (downloadURL) {
        updates[Constants.IMAGE] = downloadURL
      }
    }
    // update id if it is invalid
    if (!refId.current) {
      updates[Constants.ID] = storeId
      updates[Constants.USER_ID] = userId
    }
    updates[Constants.NAME] = name
    updates[Constants.DESCRIPTION] = description
    updates[Constants.CATEGORY] = category
    updates[Constants.ADDRESS] = address
    updates[Constants.PHONE] = phone
    updates[Constants.EMAIL] = email
    updates[Constants.COLOR] = color
    updates[Constants.LOCATION_LAT] = refLocationLat.current
    updates[Constants.LOCATION_LNG] = refLocationLng.current
    updates[Constants.LOCATION_ZOOM] = refLocationZoom.current
    updates[Constants.DATE_MODIFIED] = Date.now()
    const ref = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
    await ref.update(updates)
    if (refColor.current != color) {
      const refUser = firebase.databaseRef(Constants.FB_USERS + '/' + userId)
      updates = {}
      updates[Constants.DATE_MODIFIED] = Date.now()
      await refUser.update(updates)
    }
    setLoading(props, false)
    showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
    refTimer.current = setTimeout(() => {
      Navigator.back()
    }, 1000)
  }

  const onMarkerDragged = (e) => {
    refLocationLat.current = e.latLng.lat()
    refLocationLng.current = e.latLng.lng()
    refLocationZoom.current = refMap.current.getZoom()
  }

  const clearMarkers = () => {
    for (let i in refMarkers.current) {
      refMarkers.current[i].setMap(null)
    }
  }

  const renderMarkers = () => {
    if (refMap.current && refMaps.current) {
      clearMarkers()
      let marker = new refMaps.current.Marker({
        position: { lat: refLocationLat.current, lng: refLocationLng.current },
        draggable: true,
      })
      marker.setMap(refMap.current)
      const center = new refMaps.current.LatLng(refLocationLat.current, refLocationLng.current)
      refMap.current.setCenter(center)
      refMap.current.setZoom(refLocationZoom.current)
      refMaps.current.event.addListener(marker, 'dragend', onMarkerDragged)
      refMarkers.current.push(marker)
    }
  }

  const openColorPicker = (e, value) => {
    if (props.setColorOpen) {
      let top = 0, left = 0
      if (e.target) {
        const rect = e.target.getBoundingClientRect()
        top = rect.top + 30
        left = rect.left - 200
      }
      props.setColorOpen(value, color, null, { top, left })
    }
  }

  props.onColorClosed = (color) => {
    setColor(color)
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()
  const inputWidth = isMobile ? '70%' : Styles.inputWidth - 60

  return <>
    <div style={{ textAlign: 'center' }}>
      <ITypography className={classes.heading}>{Strings.editStore}</ITypography>
    </div>
    <div className={classes.content} style={{ marginTop: Styles.margin2Br }}>
      <div>
        <IFormControl>
          <div className={classes.textField} style={{ display: 'flex', alignItems: 'center' }} >
            <ITextField label={Strings.name} style={{ width: inputWidth }} value={name} onChange={(event) => { setName(event.target.value) }} />
            <div>
              <div className={classes.divColor} style={{ backgroundColor: color }} onClick={(e) => openColorPicker(e, true)} />
              <ColorPicker props={props} style={{ position: 'fixed' }} />
            </div>
          </div>
          <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.description} value={description} onChange={(event) => { setDescription(event.target.value) }} />
          <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.category} value={category} onChange={(event) => { setCategory(event.target.value) }} />
          <PhoneInput className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.phone} value={phone} onChange={(event) => { setPhone(event) }} />
          <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.address} value={address} onChange={(event) => { setAddress(event.target.value) }} />
          <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.email} value={email} onChange={(event) => { setEmail(event.target.value) }} />
          <div className={classes.textField} style={{ marginTop: Styles.margin2Br }} >
            <ITypography className='MuiFormLabel-root'>{Strings.image}:</ITypography>
          </div>
          <ITextField className={classes.textField} type='file' onChange={handleImageChange} />
          <IImage src={src || settings[Constants.DEFAULT_STORE_IMAGE]} style={{ width: '200px', height: '200px', marginTop: Styles.margin1Br }} />
        </IFormControl>
      </div>
      <div className={classes.mapContainer}>
        <div>
          <ITypography className='MuiFormLabel-root'>{Strings.location}:</ITypography>
        </div>
        <div className={classes.mapBox}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: Constants.GOOGLE_MAP_KEY }}
            defaultCenter={{ lat: Constants.STORE_DEFAULT_LOCATION_LAT, lng: Constants.STORE_DEFAULT_LOCATION_LNG }}
            defaultZoom={Constants.STORE_DEFAULT_ZOOM}
            onGoogleApiLoaded={({ map, maps }) => {
              refMap.current = map
              refMaps.current = maps
              renderMarkers()
            }}
            yesIWantToUseGoogleMapApiInternals
          />
        </div>
      </div>
    </div>
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <IButton onClick={() => Navigator.back()}>{Strings.cancel}</IButton>
      <IButton className={classes.rightButton} onClick={postSave}>{Strings.save}</IButton>
    </div>
  </>
}

function EditStore() {
  Logger.log(Constants.PAGES_STORE_EDIT)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default EditStore