//
// Contains all methods related to Firebase
// NOTE: Do not use firebase directly, must use this file instead
//
import firebase from 'firebase'
import * as Constants from '../common/Constants'
import * as DateUtils from '../common/DateUtils'
import * as DataUtils from '../common/DataUtils'
import * as StringUtils from '../common/StringUtils'
import moment from 'moment'

function getApiKey() {
    return Constants.DEFAULT_ENV == Constants.ENVIRONMENTS.DEV ? Constants.FB_APIKEY_DEV : Constants.FB_APIKEY_PROD
}

function getAuthDomain() {
    return Constants.DEFAULT_ENV == Constants.ENVIRONMENTS.DEV ? Constants.FB_AUTH_DOMAIN_DEV : Constants.FB_AUTH_DOMAIN_PROD
}

function getDatabaseURL() {
    return Constants.DEFAULT_ENV == Constants.ENVIRONMENTS.DEV ? Constants.FB_DATABASE_URL_DEV : Constants.FB_DATABASE_URL_PROD
}

function getProjectId() {
    return Constants.DEFAULT_ENV == Constants.ENVIRONMENTS.DEV ? Constants.FB_PROJECT_ID_DEV : Constants.FB_PROJECT_ID_PROD
}

function getStorageBucket() {
    return Constants.DEFAULT_ENV == Constants.ENVIRONMENTS.DEV ? Constants.FB_STORAGE_BUCKET_DEV : Constants.FB_STORAGE_BUCKET_PROD
}

function getMessagingSenderId() {
    return Constants.DEFAULT_ENV == Constants.ENVIRONMENTS.DEV ? Constants.FB_MESSAGING_SENDER_ID_DEV : Constants.FB_MESSAGING_SENDER_ID_PROD
}

function getAppId() {
    return Constants.DEFAULT_ENV == Constants.ENVIRONMENTS.DEV ? Constants.FB_APP_ID_DEV : Constants.FB_APP_ID_PROD
}

function getMeasurementId() {
    return Constants.DEFAULT_ENV == Constants.ENVIRONMENTS.DEV ? Constants.FB_MEASUREMENT_ID_DEV : Constants.FB_MEASUREMENT_ID_PROD
}

const config = {
    apiKey: getApiKey(),
    authDomain: getAuthDomain(),
    databaseURL: getDatabaseURL(),
    projectId: getProjectId(),
    storageBucket: getStorageBucket(),
    messagingSenderId: getMessagingSenderId(),
    appId: getAppId(),
    measurementId: getMeasurementId()
}

firebase.initializeApp(config)

export function databaseRef(path) {
    const env = Constants.DEFAULT_ENV == Constants.ENVIRONMENTS.DEV ? Constants.DATABASE_URL_DEV : Constants.DATABASE_URL_PROD
    const ref = firebase.database().ref(env + path)
    return ref
}

export function storageRef(path) {
    const env = Constants.DEFAULT_ENV == Constants.ENVIRONMENTS.DEV ? Constants.STORAGE_URL_DEV : Constants.STORAGE_URL_PROD
    const ref = firebase.storage().ref(env + path)
    return ref
}

export function functions() {
    return firebase.functions()
}

export function auth() {
    return firebase.auth()
}

export function emailAuthProvider() {
    return firebase.auth.EmailAuthProvider
}

export function uploadFile(ref, path, index, length, onUpdate) {
    return new Promise(function (resolve, reject) {
        const uploadTask = ref.put(path)
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function (snapshot) {
            onUpdate(index, length, snapshot.bytesTransferred, snapshot.totalBytes)
        }, function (error) {
            resolve(null)
        }, function () {
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                resolve(downloadURL)
            })
        })
    })
}

//
// Short data for booking, MUST also update this method in FB Functions too
//
export function storeForBooking(value) {
    var short = {}
    short[Constants.ID] = value[Constants.ID]
    short[Constants.USER_ID] = value[Constants.USER_ID]
    short[Constants.NAME] = value[Constants.NAME]
    short[Constants.EMAIL] = value[Constants.EMAIL]
    short[Constants.ADDRESS] = value[Constants.ADDRESS]
    short[Constants.PHONE] = value[Constants.PHONE]
    short[Constants.LOCATION_LAT] = value[Constants.LOCATION_LAT]
    short[Constants.LOCATION_LNG] = value[Constants.LOCATION_LNG]
    short[Constants.LOCATION_ZOOM] = value[Constants.LOCATION_ZOOM]
    short[Constants.IMAGE] = value[Constants.IMAGE]
    short[Constants.DATE_MODIFIED] = value[Constants.DATE_MODIFIED]
    return short
}

export function employeeForBooking(value) {
    var short = {}
    short[Constants.ID] = value[Constants.ID]
    short[Constants.USER_ID] = value[Constants.USER_ID]
    short[Constants.STORE_ID] = value[Constants.STORE_ID]
    short[Constants.FIRST_NAME] = value[Constants.FIRST_NAME]
    short[Constants.LAST_NAME] = value[Constants.LAST_NAME]
    short[Constants.EMAIL] = value[Constants.EMAIL]
    short[Constants.ADDRESS] = value[Constants.ADDRESS]
    short[Constants.PHONE] = value[Constants.PHONE]
    short[Constants.IMAGE] = value[Constants.IMAGE]
    short[Constants.DATE_MODIFIED] = value[Constants.DATE_MODIFIED]
    return short
}

export function serviceForBooking(value) {
    var short = {}
    short[Constants.ID] = value[Constants.ID]
    short[Constants.USER_ID] = value[Constants.USER_ID]
    short[Constants.STORE_ID] = value[Constants.STORE_ID]
    short[Constants.TITLE] = value[Constants.TITLE]
    short[Constants.DURATION] = value[Constants.DURATION]
    short[Constants.PRICE] = value[Constants.PRICE]
    short[Constants.COLOR] = value[Constants.COLOR]
    short[Constants.IMAGE] = value[Constants.IMAGE]
    short[Constants.DATE_MODIFIED] = value[Constants.DATE_MODIFIED]
    return short
}

export function userForBooking(value) {
    var short = {}
    short[Constants.ID] = value[Constants.ID]
    short[Constants.EMAIL] = value[Constants.EMAIL]
    short[Constants.FIRST_NAME] = value[Constants.FIRST_NAME]
    short[Constants.LAST_NAME] = value[Constants.LAST_NAME]
    short[Constants.ADDRESS] = value[Constants.ADDRESS]
    short[Constants.PHONE] = value[Constants.PHONE]
    short[Constants.IMAGE] = value[Constants.IMAGE]
    short[Constants.DATE_MODIFIED] = value[Constants.DATE_MODIFIED]
    return short
}

export function noPreferenceEmployee(firstName, image) {
    let employee = {}
    employee[Constants.ID] = Constants.EMPLOYEE_ANY_ONE_AVAILABLE
    employee[Constants.FIRST_NAME] = firstName
    employee[Constants.IMAGE] = image
    return employee
}

export function getEmployeeWorkingPeriods(employee, date) {
    const workingPeriods = employee[Constants.WORKING_PERIOD] || []
    if (employee[Constants.WORKING_EDIT]) {
        const key = `${date.year()}_${date.month() + 1}_${date.date()}`
        if (employee[Constants.WORKING_EDIT][key]) {
            const day = date.format('dddd').toUpperCase()
            let item = {}
            item[Constants.DATE_OF_WEEK] = DateUtils.getNumberByDay(day)
            item[Constants.IS_CHECK] = true
            let child = []
            const hours = employee[Constants.WORKING_EDIT][key][Constants.HOURS] || []
            for (let i in hours) {
                const item = hours[i]
                if (item[Constants.IS_CHECK]) {
                    child.push(item)
                }
            }
            item[Constants.HOURS] = child
            return [item]
        }
    }
    return workingPeriods
}

export function getWorkingPeriodPairs(employee, date) {
    let list = []
    // get all working hours in specific day and also filter offline hours
    const workingPeriods = getEmployeeWorkingPeriods(employee, date)
    const workingOfflines = employee[Constants.WORKING_OFFLINE] || []
    const day = date.format('dddd').toUpperCase()
    let offlinePair = [] // pair of hours [{start, end}]
    for (let i in workingOfflines) {
        const workingOffline = workingOfflines[i]
        const start = moment(workingOffline[Constants.START])
        if (date.isSame(start, 'day')) {
            const end = moment(workingOffline[Constants.END])
            let pair = {}
            pair[Constants.START] = DateUtils.getNumberFromHourMinute({ hour: start.hour(), minute: start.minute() })
            pair[Constants.END] = DateUtils.getNumberFromHourMinute({ hour: end.hour(), minute: end.minute() })
            offlinePair.push(pair)
        }
    }
    for (let i in workingPeriods) {
        const workingPeriod = workingPeriods[i]
        const dateNum = StringUtils.getNumber(workingPeriod[Constants.DATE_OF_WEEK])
        const dateStr = DateUtils.getDayByNumber(dateNum)
        if (day == dateStr && workingPeriod[Constants.IS_CHECK]) {
            const hours = workingPeriod[Constants.HOURS] || []
            for (let j in hours) {
                const item = hours[j]
                var pairs = [{ ...item }]
                // check with offline hours, create new list that contains new pairs, 
                // e.g: working 8-11am, offline 9-10am => new list [8-9, 10-11]
                for (let k in offlinePair) {
                    const offline = offlinePair[k]
                    let subList = []
                    for (let l in pairs) {
                        const pair = pairs[l]
                        if (DateUtils.isHourOverlap(pair[Constants.START], pair[Constants.END], offline[Constants.START], offline[Constants.END])) {
                            subList.push(...DateUtils.splitHourOverlap(pair[Constants.START], pair[Constants.END], offline[Constants.START], offline[Constants.END]))
                        } else {
                            subList.push(pair)
                        }
                    }
                    pairs = [...subList]
                }
                list.push(...pairs)
            }
            break
        }
    }
    DataUtils.sortBy(list, Constants.START)
    return list
}

export function calculateTimesPair(list) {
    // calculate available times for booking from working period pairs
    // using start and end of each pair, then plus with duration      
    let timesPair = [] // hold [pair]
    let pair = {} // hold {start, end}
    for (let i in list) {
        const item = list[i]
        if (i == 0) {
            pair[Constants.START] = item
            pair[Constants.END] = item
        } else {
            const previous = list[i - 1]
            if (item[Constants.START] == previous[Constants.END]) {
                pair[Constants.END] = item
            } else {
                timesPair.push(pair)
                pair = {}
                pair[Constants.START] = item
                pair[Constants.END] = item
            }
        }
    }
    timesPair.push(pair)
    return timesPair
}

export function calculateAvailableTimes(id, date, serviceDuration, employee, timesPair, storeBookings, userBookings) {
    const duration = Constants.CALENDAR_TIME_AVAILABLE_STEP / 60 // in hour
    const now = moment()
    let availableTimes = []
    for (let i in timesPair) {
        const pair = timesPair[i]
        let start = pair[Constants.START][Constants.START]
        const end = pair[Constants.END][Constants.END]
        while (start + duration <= end) {
            let available = {}
            available[Constants.ID] = id
            available[Constants.DATE] = date
            available[Constants.START] = start
            available[Constants.END] = start + serviceDuration
            available[Constants.DISPLAY] = StringUtils.getHourFromNumber(start)

            let canSet = true
            const _end = available[Constants.END]
            // Condition 0: check with current time
            const startTime = DateUtils.getHourMinuteFromNumber(start)
            const dStartTime = date.hour(startTime.hour).minute(startTime.minute)
            if (dStartTime.isBefore(now)) {
                canSet = false
            }

            // Condition 1: check with other booked services of same employee
            if (canSet) {
                for (let i in storeBookings) {
                    const item = storeBookings[i]
                    const iDate = moment(item[Constants.DATE])
                    const iStart = item[Constants.START]
                    const iEnd = item[Constants.END]
                    if (date.isSame(iDate, 'day') && employee[Constants.ID] == item[Constants.EMPLOYEE][Constants.ID]
                        && DateUtils.isHourOverlap(iStart, iEnd, start, _end)) {
                        canSet = false
                        break
                    }
                }
            }

            // Condition 2: check with other booked services of same user
            if (canSet) {
                for (let i in userBookings) {
                    const item = userBookings[i]
                    const iDate = moment(item[Constants.DATE])
                    const iStart = item[Constants.START]
                    const iEnd = item[Constants.END]
                    if (date.isSame(iDate, 'day') && employee[Constants.ID] == item[Constants.EMPLOYEE][Constants.ID]
                        && DateUtils.isHourOverlap(iStart, iEnd, start, _end)) {
                        canSet = false
                        break
                    }                    
                }
            }

            if (canSet) {
                availableTimes.push(available)
            }

            start += duration
        }
    }

    return availableTimes
}