import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as DataUtils from '../../../common/DataUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITypography from '../../controls/ITypography'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableHead from '../../controls/ITableHead'
import ITableRow from '../../controls/ITableRow'
import IPaper from '../../controls/IPaper'
import IIconButton from '../../controls/IIconButton'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogContentText from '../../controls/IDialogContentText'
import IDialogTitle from '../../controls/IDialogTitle'
import IImage from '../../controls/IImage'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { settings } = useGlobal()
  const query = Navigator.getQuery()
  const storeId = query.get(Constants.STORE_ID)
  const userId = query.get(Constants.USER_ID)
  const refItem = useRef()
  const [items, setItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setLoading(props, true)
    const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_SYSTEM_SEARCH_EMPLOYEES)
    httpsCallable({ storeId: storeId, userId: userId }).then(({ data }) => {
      setLoading(props, false)
      const list = []
      if (data.items) {
        for (let i in data.items) {
          list.push(data.items[i])
        }
      }
      DataUtils.sortBy(list, Constants.NAME)
      setItems(list)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const onAddEmployee = () => {
    Navigator.navigate(props, Constants.PAGES_SYSTEM_ADD_EMPLOYEE,
      `${Constants.USER_ID}=${userId}&${Constants.STORE_ID}=${storeId}`)
  }

  const onEditItem = (item) => {
    Navigator.navigate(props, Constants.PAGES_SYSTEM_EDIT_EMPLOYEE,
      `${Constants.USER_ID}=${userId}&${Constants.STORE_ID}=${storeId}&${Constants.ID}=${item[Constants.ID]}`)
  }

  const onDeleteItem = (item) => {
    refItem.current = item
    setOpenDialog(true)
  }

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {
      setLoading(props, true)      
      const id = refItem.current[Constants.ID]
      const ref = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId + '/' + id)
      ref.remove().then(() => {
        loadData()
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const classes = useStyles()

  return <div className={classes.root}>
    <ITypography className={classes.heading}>{Strings.employees}</ITypography>
    <div className={classes.addButton}>
      <AddButton startIcon={<AddIcon />} onClick={() => onAddEmployee()}>{Strings.addEmployee}</AddButton>
    </div>
    <ITableContainer component={IPaper} style={{ marginTop: Styles.margin1Br }}>
      <ITable className={classes.table}>
        <ITableHead>
          <ITableRow>
            <ITableCell>{Strings.avatar}</ITableCell>
            <ITableCell>{Strings.email}</ITableCell>
            <ITableCell>{Strings.firstName}</ITableCell>
            <ITableCell>{Strings.lastName}</ITableCell>
            <ITableCell>{Strings.phone}</ITableCell>
            <ITableCell>{Strings.address}</ITableCell>
            <ITableCell></ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          {items.map(row => (
            <ITableRow key={row[Constants.ID]}>
              <ITableCell component='th' scope='row'>
                <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize }} src={row[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={row[Constants.FIRST_NAME]} />
              </ITableCell>
              <ITableCell>{row[Constants.EMAIL]}</ITableCell>
              <ITableCell>{row[Constants.FIRST_NAME]}</ITableCell>
              <ITableCell>{row[Constants.LAST_NAME]}</ITableCell>
              <ITableCell>{row[Constants.PHONE]}</ITableCell>
              <ITableCell>{row[Constants.ADDRESS]}</ITableCell>
              <ITableCell>
                <IIconButton aria-label={Strings.edit} onClick={() => onEditItem(row)}><EditIcon /></IIconButton>
                <IIconButton aria-label={Strings.delete} onClick={() => onDeleteItem(row)}><DeleteIcon /></IIconButton>
              </ITableCell>
            </ITableRow>
          ))}
        </ITableBody>
      </ITable>
    </ITableContainer>
    <IDialog open={openDialog} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <IDialogTitle id='alert-dialog-title'>{Strings.delete}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText id='alert-dialog-description'>{Strings.deleteConfirm}</IDialogContentText>
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
        <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
      </IDialogActions>
    </IDialog>
  </div>
}

function ViewEmployees() {
  Logger.log(Constants.PAGES_SYSTEM_VIEW_EMPLOYEES)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default ViewEmployees