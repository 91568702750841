import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ITextField from '../controls/ITextField'
import IMenuItem from '../controls/IMenuItem'
import * as Logger from '../../common/Logger'
import * as Constants from '../../common/Constants'
import * as DateUtils from '../../common/DateUtils'
import * as StringUtils from '../../common/StringUtils'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
}))

function TimePicker({ props, item, select = 7, extra }) {
    Logger.log('TimePicker')

    const [state, setState] = useState({ hrs: [], mins: [], hr: '', min: '' })

    useEffect(() => {
        const selectHrMin = DateUtils.getHourMinuteFromNumber(select)
        let listHrs = []
        let hrIndex = 0
        for (let i = Constants.CALENDAR_START_HOUR; i <= Constants.CALENDAR_END_HOUR + 1; i++) {
            listHrs.push(`${i}`)
            if (selectHrMin.hour == i) {
                hrIndex = listHrs.length - 1
            }
        }
        let listMins = []
        let minIndex = 0
        for (let i = 0; i < 60; i += Constants.CALENDAR_TIME_STEP) {
            listMins.push(i < 10 ? `0${i}` : `${i}`)
            if (selectHrMin.minute == i) {
                minIndex = listMins.length - 1
            }
        }
        const hr = listHrs.length > 0 ? listHrs[hrIndex] : ''
        const min = listMins.length > 0 ? listMins[minIndex] : ''
        setState({ hrs: listHrs, mins: listMins, hr, min })
    }, [])

    const handleSelectHr = (event) => {
        const hr = event.target.value
        setState({ ...state, hr })
        if (props.onTimeChanged) {
            props.onTimeChanged(item, StringUtils.getNumber(hr), StringUtils.getNumber(state.min), extra)
        }
    }

    const handleSelectMin = (event) => {
        const min = event.target.value
        setState({ ...state, min })
        if (props.onTimeChanged) {
            props.onTimeChanged(item, StringUtils.getNumber(state.hr), StringUtils.getNumber(min), extra)
        }
    }

    const classes = useStyles()

    return <div className={classes.root}>
        <div>
            <ITextField select onChange={(event) => { handleSelectHr(event) }} InputLabelProps={{ shrink: true, style: { color: '#fff' } }}
                value={state.hr}> {state.hrs.map(option => (
                    <IMenuItem key={option} value={option}>{option}</IMenuItem>
                ))}
            </ITextField>
        </div>
        <span style={{ marginLeft: 5, marginRight: 5 }}>:</span>
        <div>
            <ITextField select onChange={(event) => { handleSelectMin(event) }} InputLabelProps={{ shrink: true, style: { color: '#fff' } }}
                value={state.min}> {state.mins.map(option => (
                    <IMenuItem key={option} value={option}>{option}</IMenuItem>
                ))}
            </ITextField>
        </div>
    </div>
}

export default TimePicker