import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Navigator from '../../../../common/Navigator'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as DataUtils from '../../../../common/DataUtils'
import * as ScreenUtils from '../../../../common/ScreenUtils'
import * as Constants from '../../../../common/Constants'
import * as Storage from '../../../../common/Storage'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import ITextField from '../../../controls/ITextField'
import ITypography from '../../../controls/ITypography'
import ITable from '../../../controls/ITable'
import ITableBody from '../../../controls/ITableBody'
import ITableCell from '../../../controls/ITableCell'
import ITableContainer from '../../../controls/ITableContainer'
import IPaper from '../../../controls/IPaper'
import ITableRow from '../../../controls/ITableRow'
import SearchIcon from '@material-ui/icons/Search'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import IImage from '../../../controls/IImage'

const SearchButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const ScheduleButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  searchBox: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  gridList: {
    justifyContent: 'center'
  },
  storeInfo: {
    marginLeft: 20,
    width: '100%'
  },
  card: {
    display: 'flex',
    width: '100%'
  },
  scheduleButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

function SearchView({ props }) {
  Logger.log('SearchView')

  const [keyword, setKeyword] = useState('')

  useEffect(() => {

  }, [])

  const onSearchClick = (e) => {
    e.preventDefault()
    if (props.onSearchClick) {
      props.onSearchClick(keyword.toLowerCase())
    }
  }

  const classes = useStyles()

  return <form><div className={classes.searchBox}>
    <ITextField className={classes.textField} inputProps={{ style: { fontSize: Styles.searchFontSize } }} label={Strings.searchStoreHint} value={keyword} onChange={(event) => { setKeyword(event.target.value) }} />
    <SearchButton type='submit' startIcon={<SearchIcon />} onClick={(e) => onSearchClick(e)}>{Strings.search}</SearchButton>
  </div></form>
}

function ContentView({ props }) {
  Logger.log('ContentView')

  const query = Navigator.getQuery()
  const { settings } = useGlobal()
  const [items, setItems] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = (keyword) => {
    const cacheStores = Storage.getJson(Constants.CACHE_STORES, false)
    if (cacheStores) {
      const isSearch = keyword && keyword != ''
      if (isSearch) {
        const list = []
        for (let id1 in cacheStores) {
          const child = cacheStores[id1]
          let text = `${child[Constants.NAME]}`.toLowerCase()
          if (text.indexOf(keyword) != -1) {
            list.push(child)
          }
        }
        setItems(list)
      } else {
        setItems(cacheStores)
      }
    } else {
      setLoading(props, true)
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_STORES)
      httpsCallable({ keyword }).then(({ data }) => {
        setLoading(props, false)
        const list = []
        if (data.items) {
          for (let i in data.items) {
            list.push(data.items[i])
          }
        }
        DataUtils.sortBy(list, Constants.NAME)
        setItems(list)
        Storage.setItemToMemory(Constants.CACHE_STORES, list)
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const onScheduleClick = (item) => {
    const booking = Storage.getJson(Constants.BOOKING)
    booking[Constants.STORE] = item
    booking[Constants.SERVICE] = null
    booking[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
    Storage.setJson(Constants.BOOKING, booking)
    if (props[Constants.REDIRECT]) {
      Navigator.navigate(props[Constants.REDIRECT])
    } else {
      Navigator.back()
    }
  }

  props.onSearchClick = (keyword) => {
    loadData(keyword)
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return <ITableContainer component={IPaper} className={classes.listSection} style={{ marginTop: Styles.margin1Br }}>
    <ITable>
      <ITableBody>
        {items.map(item => (
          <ITableRow key={item[Constants.ID]}>
            <ITableCell className={classes.card}>
              {isMobile && <div style={{ width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_STORE_IMAGE] || ''} alt={item[Constants.NAME]} />
                <ITypography variant='body1'><b>{item[Constants.NAME]}</b></ITypography>
                <ITypography variant='body1'>{item[Constants.DESCRIPTION]}</ITypography>
                <ITypography variant='body1'>{Strings.address}: {item[Constants.ADDRESS]}</ITypography>
                <div style={{ textAlign: 'center' }}>
                  <ScheduleButton onClick={() => onScheduleClick(item)}>{Strings.addToVisit}</ScheduleButton>
                </div>
              </div>}
              {!isMobile && <>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_STORE_IMAGE] || ''} alt={item[Constants.NAME]} />
                <div className={classes.storeInfo}>
                  <ITypography variant='body1'><b>{item[Constants.NAME]}</b></ITypography>
                  <ITypography variant='body1'>{item[Constants.DESCRIPTION]}</ITypography>
                  <ITypography variant='body1'>{Strings.address}: {item[Constants.ADDRESS]}</ITypography>
                  <div className={classes.scheduleButton} style={{ marginTop: Styles.margin1Br }}>
                    <ScheduleButton onClick={() => onScheduleClick(item)}>{Strings.addToVisit}</ScheduleButton>
                  </div>
                </div>
              </>}
            </ITableCell>
          </ITableRow>
        ))}
      </ITableBody>
    </ITable>
  </ITableContainer >
}

function ViewStores({ extra }) {
  Logger.log(Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_STORES)

  let props = createPropsWithActions()
  const classes = useStyles()

  if (extra) {
    for (let i in extra) {
      props[i] = extra[i]
    }
  }

  return <div className={classes.root}>
    <ITypography variant='h5'>{Strings.stores}</ITypography>
    <SearchView props={props} />
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </div>
}

export default ViewStores