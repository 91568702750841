const Styles = {
    contentMaxWidth: 1200,
    formMaxWidth: 500,
    inputWidth: 400,
    editorWidth: 700,
    toolbarHeight: 64,
    appBarColor: 'black',
    primaryBgColor: '#1976d2',
    primaryTextColor: 'white',
    primaryTextSize: 14,
    headingTitleSize: 28,
    searchFontSize: 14,
    rightBoxMargin: 10,
    rightButtonMargin: 50,
    footerBgColor: '#22252b',
    appointmentsHeight: 1500,
    longPressThreshold: 250,
    imageSize: 100,
    smallImageSize: 50,
    superSmallImageSize: 30,
    buttonFontSize: 14,
    summaryWidth: 400,
    addedServicesWidth: 400,
    workingTimeWidth: 400,
    sideCalendar: 360,
    mapHeight: 600,
    margin1Br: 15,
    margin2Br: 40,
    margin3Br: 60,
    margin4Br: 80,
    logoSize: 300,
}

export default Styles