import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as ScreenUtils from '../../../common/ScreenUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import IImage from '../../controls/IImage'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import IIconButton from '../../controls/IIconButton'
import ITextField from '../../controls/ITextField'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import EditIcon from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  avatar: {
    width: 200,
    height: 200
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  },
  content: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  mapContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  },
  mapBox: {
    marginTop: 10,
    width: '100%',
    height: Styles.mapHeight
  },
  edit: {
    fontSize: 20
  },
  readOnly: {
    color: '#9e9e9e'
  },
  copyLink: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  divColor: {
    width: 50,
    height: 25,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.54)'    
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const refMaps = useRef()
  const refMap = useRef()
  const refLocationLat = useRef(Constants.STORE_DEFAULT_LOCATION_LAT)
  const refLocationLng = useRef(Constants.STORE_DEFAULT_LOCATION_LNG)
  const refLocationZoom = useRef(Constants.STORE_DEFAULT_ZOOM)
  const refMarkers = useRef([])
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [src, setSrc] = useState('')
  const [canCopy, setCanCopy] = useState(false)
  const [deepLink, setDeepLink] = useState('')
  const [color, setColor] = useState('')

  useEffect(() => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
    ref.once('value').then(response => {
      setLoading(props, false)
      const val = response.val()
      if (val) {
        setName(val[Constants.NAME])
        setDescription(val[Constants.DESCRIPTION])
        setCategory(val[Constants.CATEGORY])
        setAddress(val[Constants.ADDRESS])
        setPhone(val[Constants.PHONE])
        setEmail(val[Constants.EMAIL])
        setSrc(val[Constants.IMAGE])
        setColor(val[Constants.COLOR])
        refLocationLat.current = val[Constants.LOCATION_LAT]
        refLocationLng.current = val[Constants.LOCATION_LNG]
        refLocationZoom.current = val[Constants.LOCATION_ZOOM]
        renderMarkers()

        if (window.location) {
          const pathname = window.location.hostname + Constants.PAGES_GUEST_APPOINTMENT_ABOUT_STORE
          var link = ''
          if (pathname.startsWith('http://') || pathname.startsWith('https://')) {
            link = Navigator.buildLinkString(props, pathname, `${Constants.USER_ID}=${userId}&${Constants.STORE_ID}=${storeId}`)
          } else {
            link = Navigator.buildLinkString(props, 'http://' + pathname, `${Constants.USER_ID}=${userId}&${Constants.STORE_ID}=${storeId}`)
          }
          setDeepLink(link)
        }
        if (navigator.clipboard) {
          setCanCopy(true)
        } else {
          Logger.log('Can not copy')
        }
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const clearMarkers = () => {
    for (let i in refMarkers.current) {
      refMarkers.current[i].setMap(null)
    }
  }

  const renderMarkers = () => {
    if (refMap.current && refMaps.current) {
      clearMarkers()
      let marker = new refMaps.current.Marker({
        position: { lat: refLocationLat.current, lng: refLocationLng.current },
        draggable: false,
      })
      marker.setMap(refMap.current)
      const center = new refMaps.current.LatLng(refLocationLat.current, refLocationLng.current)
      refMap.current.setCenter(center)
      refMap.current.setZoom(refLocationZoom.current)
      refMarkers.current.push(marker)
    }
  }

  const onEditStore = () => {
    Navigator.navigate(props, Constants.PAGES_STORE_EDIT)
  }

  const onCopyClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(deepLink).then(function () {
        showToast(props, TOAST_SUCCESS, Strings.copiedSuccessfully)
      }, function (err) {
        showToast(props, TOAST_SUCCESS, 'Can not copy text')
      })
    }
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()
  const inputWidth = isMobile ? '70%' : Styles.inputWidth - 60

  return <>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ITypography variant='h4'>{Strings.store}</ITypography>      
    </div>
    <div className={classes.content} style={{ marginTop: Styles.margin2Br }}>
      <div>
        <IFormControl>
          <div className={classes.textField} style={{ display: 'flex', alignItems: 'center' }}>
            <ITypography style={{ width: inputWidth }}>{Strings.name}: <b>{name}</b></ITypography>
            <div className={classes.divColor} style={{ backgroundColor: color }} />
          </div>          
          <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>{Strings.description}: <b>{description}</b></ITypography>
          <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>{Strings.category}: <b>{category}</b></ITypography>
          <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>{Strings.phone}: <b>{phone}</b></ITypography>
          <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>{Strings.address}: <b>{address}</b></ITypography>
          <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>{Strings.email}: <b>{email}</b></ITypography>
          <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>{Strings.image}:</ITypography>
          <div style={{ textAlign: 'center', marginTop: Styles.margin1Br }}>
            <IImage src={src || settings[Constants.DEFAULT_STORE_IMAGE]} style={{ width: '200px', height: '200px', margin: 'auto' }} />
          </div>
          <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>{Strings.deepLink}:</ITypography>
          <div>
            <ITextField className={classes.textField} InputProps={{ readOnly: true, className: classes.readOnly }} value={deepLink} />
            {canCopy && <ITypography variant='body2' variantMapping={{ body2: 'span' }} className={classes.copyLink} style={{ marginTop: Styles.margin1Br }}
              onClick={() => onCopyClick()}>{Strings.clickToCopy}</ITypography>}
          </div>
        </IFormControl>
      </div>
      <div className={classes.mapContainer}>
        <div>
          <ITypography className='MuiFormLabel-root'>{Strings.location}:</ITypography>
        </div>
        <div className={classes.mapBox}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: Constants.GOOGLE_MAP_KEY }}
            defaultCenter={{ lat: Constants.STORE_DEFAULT_LOCATION_LAT, lng: Constants.STORE_DEFAULT_LOCATION_LNG }}
            defaultZoom={Constants.STORE_DEFAULT_ZOOM}
            onGoogleApiLoaded={({ map, maps }) => {
              refMap.current = map
              refMaps.current = maps
              renderMarkers()
            }}
            yesIWantToUseGoogleMapApiInternals
          />
        </div>
      </div>
    </div>
    <div style={{ textAlign: 'center', marginTop: 20 }}>      
      <IButton onClick={onEditStore}>{Strings.edit}</IButton>
    </div>
  </>
}

function ViewStore() {
  Logger.log(Constants.PAGES_STORE_VIEW)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default ViewStore