import React, { useRef, useEffect, useState } from 'react'
import Context from './utils/context'
import * as firebase from './utils/firebase'
import * as Constants from './common/Constants'
import * as ScreenUtils from './common/ScreenUtils'
import Styles from './common/Styles'
import Routes from './common/Routes'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'rc-calendar/dist/rc-calendar.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './App.css'
import './pages/components/css/Appointments.css'
import './pages/components/css/ScheduleEmployee.css'
import './pages/components/css/ScheduleNow.css'
import './pages/components/css/ViewTimes.css'
import './pages/components/css/EditSubscription.css'

function App() {
  const [global, setGlobal] = useState({ loaded: false, user: null, settings: {} })
  const refUpdates = useRef({})
  const refUpdateFuncs = useRef({})

  useEffect(() => {
    // check screen size to optimze for mobile
    if (ScreenUtils.isMobile()) {      
      Styles.toolbarHeight = 50
      Styles.formMaxWidth = ScreenUtils.screenWidth() / 1.2
      Styles.inputWidth = ScreenUtils.screenWidth() / 1.3
      Styles.longPressThreshold = 1
      Styles.headingTitleSize = 22
      Styles.imageSize = 80
      Styles.smallImageSize = 50
      Styles.buttonFontSize = 12
      Styles.summaryWidth = '100%'
      Styles.addedServicesWidth = '100%'
      Styles.workingTimeWidth = '100%'
      Styles.sideCalendar = '100%'
      Styles.editorWidth = '100%'
      Styles.mapHeight = 400
      Styles.logoSize = 200
    }

    const unsubscribe = firebase.auth().onAuthStateChanged(user => {      
      if (user) {        
        const ref = firebase.databaseRef(Constants.FB_USERS + '/' + user.uid)
        refUpdateFuncs.current['user'] = async (response) => {
          const val = response.val()
          if (val) {
            // load settings
            let settings = {}
            const settingsResponse = await firebase.databaseRef(Constants.FB_SETTINGS).once('value')
            if (settingsResponse) {
              settings = settingsResponse.val() || {}
              settings[Constants.DEFAULT_SERVICE_IMAGE] = null // disable default service's image
            }
            const role = val[Constants.ROLE]
            if (role == Constants.ROLE_EMPLOYEE_STORE) {
              const userId = val[Constants.USER_ID]
              const storeId = val[Constants.STORE_ID]
              // load employee information
              const employeeCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_SYSTEM_GET_EMPLOYEE_INFO)
              const employeeResponse = await employeeCallable({ userId, storeId })
              if (employeeResponse && employeeResponse.data.user) {
                const info = { ...val, ...employeeResponse.data.user }
                setGlobal({ loaded: true, user: info, settings })
              } else {
                setGlobal({ loaded: true, user: null, settings })
              }
            } else if (role == Constants.ROLE_ADMIN_STORE) {              
              const userId = val[Constants.ID]
              const storeId = val[Constants.ID]
              // load subscription info
              const info = { ...val }
              info[Constants.HAS_SUBSCRIPTION] = false
              const refPayment = firebase.databaseRef(Constants.FB_STORE_PAYMENTS + '/' + userId + '/' + storeId)
              const responsePayment = await refPayment.once('value')
              if (responsePayment) {
                const valPayment = responsePayment.val()
                if (valPayment && valPayment.subscription && valPayment.subscription.status === 'Active') {
                  info[Constants.HAS_SUBSCRIPTION] = true
                }
              }
              setGlobal({ loaded: true, user: info, settings })
            } else {
              setGlobal({ loaded: true, user: val, settings })
            }
          } else {
            setGlobal({ loaded: true, user: null, settings: {} })
          }
        }
        ref.on('value', refUpdateFuncs.current['user'])
        refUpdates.current['user'] = ref
      } else {
        // load settings
        firebase.databaseRef(Constants.FB_SETTINGS).once('value').then(settingsResponse => {
          let settings = {}
          if (settingsResponse) {
            settings = settingsResponse.val() || {}
            settings[Constants.DEFAULT_SERVICE_IMAGE] = null // disable default service's image
          }
          setGlobal({ loaded: true, user: null, settings })
        }).catch(err => {
          setGlobal({ loaded: true, user: null, settings: {} })
        })        
      }
    })
    return () => {
      for (let id in refUpdates.current) {
        if (refUpdates.current[id]) {
          refUpdates.current[id].off('value', refUpdateFuncs.current[id])
          refUpdates.current[id] = null
        }
      }
      unsubscribe()
    }
  }, [])

  return (
    <Context.Provider value={{ user: global.user, settings: global.settings }}>
      {global.loaded && <Routes />}
      {!global.loaded && <div className='pageLoading' />}
    </Context.Provider>
  )
}

export default App
