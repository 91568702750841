import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../utils/useGlobal'
import * as firebase from '../../utils/firebase'
import * as Logger from '../../common/Logger'
import * as StringUtils from '../../common/StringUtils'
import * as Constants from '../../common/Constants'
import * as Navigator from '../../common/Navigator'
import ProgressBar from '../widgets/ProgressBar'
import Strings from '../../common/Strings'
import IButton from '../controls/IButton'
import ITextField from '../controls/ITextField'
import IFormControl from '../controls/IFormControl'
import ITypography from '../controls/ITypography'
import IAvatar from '../controls/IAvatar'
import ICard from '../controls/ICard'
import ICardContent from '../controls/ICardContent'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../common/ViewUtils'
import Styles from '../../common/Styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.formMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),   
    width: Styles.inputWidth,
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const refTimer = useRef()
  const [email, setEmail] = useState('')

  useEffect(() => {
    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const postSend = async () => {
    if (StringUtils.stringEmpty(email)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    setLoading(props, true)
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      setLoading(props, false)
      showToast(props, TOAST_SUCCESS, Strings.sendResetPasswordSuccessfully)
      refTimer.current = setTimeout(() => {
        Navigator.back()
      }, 1000)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const classes = useStyles()

  return <IFormControl style={{ maxWidth: Styles.formMaxWidth, alignItems: 'center' }}>
    <ITypography className={classes.heading}>{Strings.forgotPassword}</ITypography>
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin2Br }} label={Strings.email} value={email} onChange={(event) => { setEmail(event.target.value) }} />
    <IButton style={{ marginTop: Styles.margin3Br }} onClick={postSend}>{Strings.sendResetPassword}</IButton>
  </IFormControl>
}

function ForgotPassword() {
  Logger.log(Constants.PAGES_FORGOT_PASSWORD)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default ForgotPassword