import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Logger from '../../../common/Logger'
import * as ScreenUtils from '../../../common/ScreenUtils'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import ITypography from '../../controls/ITypography'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import * as Constants from '../../../common/Constants'
import IButton from '../../controls/IButton'
import { cancelSubscription } from './PaymentService'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableHead from '../../controls/ITableHead'
import ITableRow from '../../controls/ITableRow'
import IPaper from '../../controls/IPaper'
import IDivider from '../../controls/IDivider'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogContentText from '../../controls/IDialogContentText'
import IDialogTitle from '../../controls/IDialogTitle'
import { PricingSlot, PricingDetail } from 'react-pricing-table'
import * as Navigator from '../../../common/Navigator'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  content: {
    float: 'left',
    margin: 10,
    width: 300
  },
  planDetail: {
    width: 300,
    float: 'left'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  edit: {
    fontSize: 20
  },
  rightButton: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
    }
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user } = useGlobal()
  const userId = user[Constants.ID]
  const storeId = user[Constants.ID]
  const refTimer = useRef()
  const [subscription, setSubscription] = useState({})
  const [history, setHistory] = useState([])
  const [plans, setPlans] = React.useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    setLoading(props, true)

    const ref = firebase.databaseRef(Constants.FB_STORE_PAYMENTS + '/' + userId + '/' + storeId)
    ref
      .once('value')
      .then(response => {
        setLoading(props, false)
        const val = response.val()
        if (val) {
          if (val.subscription) {
            setSubscription(val.subscription)
            setHistory(val.subscription.statusHistory)

            let refPlan = firebase.databaseRef(Constants.FB_SYSTEM_PLANS)
            refPlan.once('value').then(response => {
              const val = response.val()
              const list = []
              if (val) {
                for (let id in val) {
                  list.push(val[id])
                }
              }
              setPlans(list)
            })
          } else {
            Navigator.navigate(props, Constants.PAGES_SELECT_PLAN)
          }
        } else {
          Navigator.navigate(props, Constants.PAGES_SELECT_PLAN)
        }
      })
      .catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })

    if (refTimer.current) {
      clearTimeout(refTimer.current)
    }
  }, [])

  const handleCloseDialog = async (agree) => {
    setOpenDialog(false)
    if (agree) {
      try {
        setLoading(props, true)
        await cancelSubscription({ subscriptionId: subscription.id })        
        // now we will wait for notify from admin
      } catch (error) {
        Logger.log(error)
        setLoading(props, false)
        showToast(props, TOAST_ERROR, error.message || Strings.errorLoadingData)
      }
    }
  }

  const cancel = () => {
    setOpenDialog(true)
  }

  const activate = () => {
    Navigator.navigate(props, Constants.PAGES_SELECT_PLAN)
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return (
    <div className={classes.root} style={{ alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <ITypography className={classes.heading}>
          {Strings.subscription}
        </ITypography>
      </div>
      <div className={classes.content} style={{ marginTop: Styles.margin3Br }}>
        <ITypography className={classes.textField}>
          {Strings.status}: <b>{subscription.status}</b>
        </ITypography>
        <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>
          {Strings.createat}: <b>{moment(subscription.createdAt).format('MMMM Do YYYY, h:mm a')}</b>
        </ITypography>
        <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>
          {Strings.startbilling}: <b>{moment(subscription.billingPeriodStartDate).format('MMMM Do YYYY, h:mm a')}</b>
        </ITypography>
        <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>
          {Strings.endbilling}: <b>{moment(subscription.billingPeriodEndDate).format('MMMM Do YYYY, h:mm a')}</b>
        </ITypography>
        <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }}>
          {Strings.currentCycle}: <b>{subscription.currentBillingCycle}</b>
        </ITypography>
      </div>

      <div className={classes.planDetail}>
        {plans
          .filter(plan => plan.id === subscription.planId)
          .map(plan => (
            <PricingSlot
              key={plan.id}
              data-plan={plan.id}
              highlighted
              shouldDisplayButton={false}
              title={plan.name}
              priceText={`\$${plan.price}/month`}
            >
              <PricingDetail>{plan.duration} {plan.duration > 1 ? 'months' : 'month'}</PricingDetail>
              {plan.features.map(feature => (
                <PricingDetail key={feature}>{feature}</PricingDetail>
              ))}
            </PricingSlot>
          ))}
      </div>

      <ITypography className={classes.textField} style={{ marginTop: Styles.margin1Br }} style={{ marginLeft: isMobile ? 10 : 0 }}>{Strings.history}:</ITypography>

      <ITableContainer component={IPaper} style={{ marginTop: Styles.margin1Br }}>
        <ITable className={classes.table} aria-label='customized table'>
          <ITableHead>
            <ITableRow>
              {isMobile && <>
                <ITableCell>{Strings.details}</ITableCell>
              </>}
              {!isMobile && <>
                <ITableCell>{'Amount'}</ITableCell>
                <ITableCell>{'Plan Id'}</ITableCell>
                <ITableCell>{'Status'}</ITableCell>
                <ITableCell>{'Time'}</ITableCell>
              </>}
            </ITableRow>
          </ITableHead>
          <ITableBody>
            {history.map((row, index) => (
              <ITableRow key={row.timestamp}>
                {isMobile && <ITableCell><div style={{ marginLeft: 10 }}>
                  <span>Amount: {row.price + ' ' + row.currencyIsoCode}</span>
                  <div>Plan Id: {row.planId}</div>
                  <div>Status: {row.status}</div>
                  <div>Time: {moment(row.timestamp).format('MMMM Do YYYY, h:mm a')}</div>
                </div>
                  <IDivider />
                </ITableCell>}
                {!isMobile && <>
                  <ITableCell component='th' scope='row'>
                    {row.price + ' ' + row.currencyIsoCode}
                  </ITableCell>
                  <ITableCell component='th' scope='row'>
                    {row.planId}
                  </ITableCell>
                  <ITableCell component='th' scope='row'>
                    {row.status}
                  </ITableCell>
                  <ITableCell component='th' scope='row'>
                    {moment(row.timestamp).format('MMMM Do YYYY, h:mm a')}
                  </ITableCell>
                </>}
              </ITableRow>
            ))}
          </ITableBody>
        </ITable>
      </ITableContainer>

      {subscription.status === 'Active' &&
        <IButton style={{ marginTop: Styles.margin1Br }} className={classes.rightButton} onClick={cancel}>
          {Strings.cancel}
        </IButton>
      }

      {(!subscription || subscription.status === 'Canceled') &&
        <>
          <IButton
            className={classes.rightButton}
            onClick={activate}     
            style={{ marginTop: Styles.margin1Br }}       
          >
            {Strings.activate}
          </IButton>
        </>
      }

      <IDialog open={openDialog} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <IDialogTitle id='alert-dialog-title'>{Strings.cancel}</IDialogTitle>
        <IDialogContent>
          <IDialogContentText id='alert-dialog-description'>{Strings.cancelSubscriptionConfirm}</IDialogContentText>
        </IDialogContent>
        <IDialogActions>
          <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
          <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
        </IDialogActions>
      </IDialog>
    </div>
  )
}

function EditSubscription() {
  Logger.log(Constants.PAGES_SUBSCRIPTION)

  let props = createPropsWithActions()
  const classes = useStyles()

  // admin will notify when cancel subscription successfully
  const { user } = useGlobal()
  const hasSubscription = user[Constants.HAS_SUBSCRIPTION] || false
  if (!hasSubscription) {
    Navigator.navigate(props, Constants.PAGES_SELECT_PLAN)
  } 

  return (
    <ICard className={classes.root}>
      <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
        <ContentView props={props} />
        <ProgressBar props={props} />
        <ToastView props={props} />
      </ICardContent>
    </ICard>
  )
}

export default EditSubscription
