import * as Constants from '../../../common/Constants'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import DropIn from 'braintree-web-drop-in-react'
import { makeStyles } from '@material-ui/core/styles'
import IButton from '../../controls/IButton'
import Label from '@material-ui/core/FormLabel'
import React from 'react'
import { Redirect } from 'react-router-dom'
import Styles from '../../../common/Styles'
import { payPlan } from './PaymentService'
import { styled } from '@material-ui/core/styles'
import * as Navigator from '../../../common/Navigator'
import ITypography from '../../controls/ITypography'
import Strings from '../../../common/Strings'
import { PricingSlot, PricingDetail } from 'react-pricing-table'
import * as Logger from '../../../common/Logger'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as StringUtils from '../../../common/StringUtils'

const Button = styled(IButton)({
  background: 'white',
  boxShadow:
    '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  content: {
    float: 'left',
    marginTop: 20,
    width: 300
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  payment: {
    width: Styles.inputWidth,
    float: 'left',
    [theme.breakpoints.up('sm')]: {
      marginTop: Styles.margin3Br,
    }
  }
}))

const CenterContainer = styled(Container)({
  textAlign: 'center'
})

function PaymentForm({ props, plan }) {
  const { user } = useGlobal()
  const userId = user[Constants.ID]

  const [loading, setLoading] = React.useState(true)
  const [paying, setPaying] = React.useState(false)
  const [error, setError] = React.useState()
  const [token, setToken] = React.useState()
  const refInstance = React.useRef()

  React.useEffect(() => {
    loadClientToken()
  }, [])

  const loadClientToken = async () => {
    try {
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_GENERATE_CLIENT_TOKEN)
      const response = await httpsCallable({ customerId: userId })
      Logger.log(response)
      if (response.data && response.data.success) {
        setToken(response.data.clientToken)
      } else {
        setLoading(false)
        setError(Strings.errorLoadingData)
      }
    } catch (error) {
      Logger.log(error)
      setError(error.message)
    }
  }

  const onInstance = React.useCallback(instance => {
    refInstance.current = instance
    setLoading(false)
  }, [])

  const onPayClick = React.useCallback(
    async instance => {
      setPaying(true)
      try {
        const { nonce } = await refInstance.current.requestPaymentMethod()
        await payPlan({ nonce, planId: plan.id })
        setPaying(false)
        setTimeout(() => {
          const query = Navigator.getQuery()
          const state = Navigator.getState()
          query.set(Constants.PAYING, 1)
          Navigator.navigate(props, Constants.PAGES_STORE_DASHBOARD, query.toString(), state)
        }, 1000)        
      } catch (error) {
        Logger.log(error)
        setPaying(false)
        showToast(props, TOAST_ERROR, error.message)
        setTimeout(() => {
          Navigator.back()
        }, 1000)
      }
    },
    [plan]
  )

  const classes = useStyles()

  return (
    <>
      <div className={classes.root} style={{ alignItems: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <ITypography className={classes.heading}>
            {Strings.payment}
          </ITypography>
        </div>
        <div className={classes.content} style={{ marginTop: Styles.margin3Br }}>
          <PricingSlot
            shouldDisplayButton={false}
            data-plan={plan.id}
            highlighted
            title={plan.name}
            priceText={`\$${plan.price}/month`}
          >
            <PricingDetail>{plan.duration} {plan.duration > 1 ? 'months' : 'month'}</PricingDetail>
            {plan.features.map(feature => (
              <PricingDetail key={feature}>{feature}</PricingDetail>
            ))}
          </PricingSlot>
        </div>

        <div className={classes.payment}>
          <div>
            This payment will cost: ${plan.price * plan.duration} for{' '}
            {plan.duration} {plan.duration > 1 ? 'months' : 'month'}
          </div>
          <div>
            Will automatically recur after: {plan.duration} {plan.duration > 1 ? 'months' : 'month'}
          </div>
          {(loading || paying ) && <Loading />}
          {token && <DropIn options={{ authorization: token }} onInstance={onInstance} />}
          {error && (
            <div style={{ marginTop: 20 }}>
              <Label>{error}</Label>
            </div>
          )}
          <Button style={{ marginTop: 20 }} disabled={loading || paying || !StringUtils.stringEmpty(error)} onClick={onPayClick}>
            Pay
          </Button>
        </div>
      </div>
      <ToastView props={props} />
    </>
  )
}

function Payment({ location }) {
  if (!location.state || !location.state.plan) {
    return <Redirect to={Navigator.buildLink({}, Constants.PAGES_STORE_DASHBOARD)} />
  }

  let props = createPropsWithActions()

  return <PaymentForm props={props} plan={location.state.plan} />
}

const Loading = () => (
  <CenterContainer fixed>
    <CircularProgress disableShrink />
  </CenterContainer>
)

export default Payment
