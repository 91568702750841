export function initWorkingPeriodCustomer() {
  return [{
    "dateOfWeek": 1,
    "hours": [{
      "end": 22,
      "id": "f4e964fd-a104-4a57-bf81-b47228202cdca",
      "order": 0,
      "start": 6
    }],
    "isCheck": true
  }, {
    "dateOfWeek": 2,
    "hours": [{
      "end": 22,
      "id": "bde439d0-b1e4-4bb3-81bc-0eb5c042a644a",
      "order": 0,
      "start": 6
    }],
    "isCheck": true
  }, {
    "dateOfWeek": 3,
    "hours": [{
      "end": 22,
      "id": "d558446b-cddd-4058-9599-b82f87cdc48ea",
      "order": 0,
      "start": 6
    }],
    "isCheck": true
  }, {
    "dateOfWeek": 4,
    "hours": [{
      "end": 22,
      "id": "2953d428-8663-46b7-bf49-094d6449b544a",
      "order": 0,
      "start": 6
    }],
    "isCheck": true
  }, {
    "dateOfWeek": 5,
    "hours": [{
      "end": 22,
      "id": "7bc4c0f2-9d17-4f36-9bbd-a84db41eed7ea",
      "order": 0,
      "start": 6
    }],
    "isCheck": true
  }, {
    "dateOfWeek": 6,
    "hours": [{
      "end": 22,
      "id": "1877cdc3-e8d9-45e2-90fc-35909d902bc0a",
      "order": 0,
      "start": 6
    }],
    "isCheck": true
  }, {
    "dateOfWeek": 0,
    "hours": [{
      "end": 22,
      "id": "9ac8cf65-8ad7-48e8-8e80-d0f826d94002a",
      "order": 0,
      "start": 6
    }],
    "isCheck": true
  }]
}

export function initWorkingPeriodEmployee() {
  return [{
    "dateOfWeek": 1,
    "hours": [{
      "end": 22,
      "id": "f4e964fd-a104-4a57-bf81-b47228202cdca1",
      "order": 0,
      "start": 6
    }],
    "isCheck": false
  }, {
    "dateOfWeek": 2,
    "hours": [{
      "end": 22,
      "id": "f4e964fd-a104-4a57-bf81-b47228202cdca2",
      "order": 0,
      "start": 6
    }],
    "isCheck": false
  }, {
    "dateOfWeek": 3,
    "hours": [{
      "end": 22,
      "id": "f4e964fd-a104-4a57-bf81-b47228202cdca3",
      "order": 0,
      "start": 6
    }],
    "isCheck": false
  }, {
    "dateOfWeek": 4,
    "hours": [{
      "end": 22,
      "id": "f4e964fd-a104-4a57-bf81-b47228202cdca4",
      "order": 0,
      "start": 6
    }],
    "isCheck": false
  }, {
    "dateOfWeek": 5,
    "hours": [{
      "end": 22,
      "id": "f4e964fd-a104-4a57-bf81-b47228202cdca5",
      "order": 0,
      "start": 6
    }],
    "isCheck": false
  }, {
    "dateOfWeek": 6,
    "hours": [{
      "end": 22,
      "id": "f4e964fd-a104-4a57-bf81-b47228202cdca6",
      "order": 0,
      "start": 6
    }],
    "isCheck": false
  }, {
    "dateOfWeek": 0,
    "hours": [{
      "end": 22,
      "id": "f4e964fd-a104-4a57-bf81-b47228202cdca7",
      "order": 0,
      "start": 6
    }],
    "isCheck": false
  }]
}