import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as DataUtils from '../../../../common/DataUtils'
import * as DateUtils from '../../../../common/DateUtils'
import * as ScreenUtils from '../../../../common/ScreenUtils'
import * as Constants from '../../../../common/Constants'
import * as Navigator from '../../../../common/Navigator'
import * as Storage from '../../../../common/Storage'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import IIconButton from '../../../controls/IIconButton'
import IDivider from '../../../controls/IDivider'
import ITypography from '../../../controls/ITypography'
import ICard from '../../../controls/ICard'
import ICardContent from '../../../controls/ICardContent'
import IGridList from '../../../controls/IGridList'
import IGridListTitle from '../../../controls/IGridListTitle'
import ISwitch from '../../../controls/ISwitch'
import IImage from '../../../controls/IImage'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import IDialog from '../../../controls/IDialog'
import IDialogActions from '../../../controls/IDialogActions'
import IDialogContent from '../../../controls/IDialogContent'
import IDialogContentText from '../../../controls/IDialogContentText'
import IDialogTitle from '../../../controls/IDialogTitle'
import IRadioGroup from '../../../controls/IRadioGroup'
import IRadio from '../../../controls/IRadio'
import ITextField from '../../../controls/ITextField'
import ICircularProgress from '../../../controls/ICircularProgress'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, isLoading, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import moment from 'moment-timezone'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const ProviderButton = styled(AddButton)({
  fontSize: 14,
  padding: '0 5px',
  width: 170,
  textTransform: 'none',
  marginRight: 20
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '95%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  text: {
    color: '#666666'
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  box: {
    borderRadius: 5,
    border: 'solid 1px #e0e0e0',
    padding: 10,
    marginBottom: 20,
  },
  boxLeft: {
    borderRadius: 5,
    border: 'solid 1px #e0e0e0',
    padding: 10,
    marginRight: 10,
    marginBottom: 20,
  },
  boxRight: {
    borderRadius: 5,
    border: 'solid 1px #e0e0e0',
    padding: 10,
    marginLeft: 10,
    marginBottom: 20,
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const refId = useRef(StringUtils.getUniqueID()) // id of default first item
  const refOldRemind = useRef(true)
  const [booking, setBooking] = useState(Storage.getJson(Constants.BOOKING))
  const [items, setItems] = useState([])
  const [remind, setRemind] = useState(false)
  const [openStoreDialog, setOpenStoreDialog] = useState(false)
  const [openServiceForDialog, setOpenServiceForDialog] = useState(false)
  const [openProviderDialog, setOpenProviderDialog] = useState(false)
  const [customerName, setCustomerName] = useState('')
  const [selectServiceFor, setSelectServiceFor] = useState(Storage.getString(Constants.CACHE_SELECT_SERVICE_FOR) || '_myself')
  const [isChecking, setIsChecking] = useState(false)
  const refPreviousCustomerNames = useRef([])
  const refTimer = useRef()
  const refItems = useRef([])

  // Store data
  const refStore = useRef();
  const refStoreBookings = useRef();
  const refUserBookings = useRef([]);
  const refEmployees = useRef();

  useEffect(() => {
    let hasChange = false
    const data = Storage.getJson(Constants.BOOKING)

    refId.current = data[Constants.ID]
    refUserBookings.current = Storage.getJson(Constants.CACHE_USER_BOOKINGS, false, [])

    // check if store is changed
    if (data[Constants.STORE]) {
      const store = Storage.getJson(Constants.CACHE_STORE_INFO, false)
      if (store && store[Constants.ID] != data[Constants.STORE][Constants.ID]) {
        Storage.clearKey(Constants.CACHE_STORE_BOOKINGS)
        Storage.clearKey(Constants.CACHE_STORE_SERVCIES)
        Storage.clearKey(Constants.CACHE_STORE_EMPLOYEES)
        data[Constants.ITEMS] = null
        hasChange = true
      }
      refStore.current = data[Constants.STORE]
      Storage.setItemToMemory(Constants.CACHE_STORE_INFO, refStore.current)
      loadStoreData()
    } else {
      setOpenStoreDialog(true)
    }

    // check if time is changed
    if (data[Constants.DATE]) {
      const date = Storage.getString(Constants.CACHE_BOOK_TIME, false)
      if (date && date != (data[Constants.DATE] + data[Constants.START])) {
        data[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
        data[Constants.ITEMS] = null
        hasChange = true
        Storage.clearKey(Constants.CACHE_STORE_BOOKINGS)
        loadStoreData()
      }
      Storage.setItemToMemory(Constants.CACHE_BOOK_TIME, data[Constants.DATE] + data[Constants.START])
    }

    // check if service is available for employee
    const service = data[Constants.SERVICE]
    const employee = data[Constants.EMPLOYEE]
    if (employee && service && employee[Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
      const employeeIds = service[Constants.EMPLOYEE_IDS] || []
      if (employeeIds.indexOf(employee[Constants.ID]) < 0) {
        data[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
        hasChange = true
      }
    }

    // populate items with each item: { id, service, employee}. 
    // items will also contains default first item, so need to filter out the default item when using
    let list = []
    const item = {}
    item[Constants.ID] = refId.current
    item[Constants.SERVICE] = data[Constants.SERVICE]
    item[Constants.EMPLOYEE] = data[Constants.EMPLOYEE]
    item[Constants.FOR_CUSTOMER] = Constants.CUSTOMER_FOR_MYSELF
    item[Constants.DATE] = data[Constants.DATE]
    item[Constants.START] = data[Constants.START]
    item[Constants.START_INIT] = data[Constants.START]
    item[Constants.APPOINTMENT_IS_SELECT_ANY_PROVIDER] = data[Constants.APPOINTMENT_IS_SELECT_ANY_PROVIDER]
    list.push(item) // add default 1 item
    if (data[Constants.ITEMS]) {
      for (let i in data[Constants.ITEMS]) {
        const item1 = data[Constants.ITEMS][i]
        const service1 = item1[Constants.SERVICE]
        const employee1 = item1[Constants.EMPLOYEE]
        if (employee1 && service1 && employee1[Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
          const employeeIds = service1[Constants.EMPLOYEE_IDS] || []
          if (employeeIds.indexOf(employee1[Constants.ID]) < 0) {
            item1[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
            hasChange = true
          }
        }
        list.push(item1)
      }
    }

    // save update
    if (hasChange) {
      setBooking({ ...data })
      Storage.setJson(Constants.BOOKING, data)
    }

    // update previous customer's names
    for (let i in list) {
      const name = list[i][Constants.FOR_CUSTOMER]
      if (name != Constants.CUSTOMER_FOR_MYSELF && refPreviousCustomerNames.current.findIndex(v => v == name) == -1) {
        refPreviousCustomerNames.current.push(name)
      }
    }

    setItems(list)
    refItems.current = list

    // load settings
    const ref = firebase.databaseRef(Constants.FB_USER_SETTINGS + '/' + user[Constants.ID])
    ref.once('value').then(response => {
      const val = response.val()
      if (val) {
        // right now, notify is applied for all types of notification, but keep separately in case we need later
        const enable = val[Constants.REMIND_24_BEFORE] || val[Constants.REMIND_CANCEL]
        refOldRemind.current = enable
        setRemind(enable)
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })

    // show select provider after selecting service
    const cacheService = Storage.getJson(Constants.CACHE_SELECT_SERVICE, false)
    if (cacheService) {
      setOpenProviderDialog(true)
    }

    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const validateLoadData = () => {
    if (!refStoreBookings.current || !refEmployees.current) {
      return
    }
    setLoading(props, false)
  }

  const loadBookings = async () => {
    const store = Storage.getJson(Constants.CACHE_STORE_INFO)
    const storeId = store[Constants.ID]
    const userId = store[Constants.USER_ID]
    const date = booking[Constants.DATE] ? moment(booking[Constants.DATE]) : moment()
    const year = date.year()
    const month = date.month() + 1
    const path = `${year}/${month}`
    const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_STORE_BOOKINGS)
    const response = await httpsCallable({ userId, storeId, path })
    const list = []
    if (response && response.data.bookings) {
      for (let i in response.data.bookings) { // day
        const item1 = response.data.bookings[i]
        for (let j in item1) { // id
          const item2 = item1[j]
          list.push(item2)
        }
      }
    }
    refStoreBookings.current = list
    Storage.setItemToMemory(Constants.CACHE_STORE_BOOKINGS, list)
    validateLoadData()
  }

  const loadEmployees = async () => {
    const store = Storage.getJson(Constants.CACHE_STORE_INFO)
    const storeId = store[Constants.ID]
    const userId = store[Constants.USER_ID]
    const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_EMPLOYEES)
    const employeeResponse = await httpsCallable({ userId, storeId })
    const val = employeeResponse.data.items
    const list = []
    if (val) {
      for (let i in val) {
        const employee = val[i]
        list.push(employee)
      }
    }
    DataUtils.sortBy(list, Constants.DATE_ADDED)
    refEmployees.current = list
    Storage.setItemToMemory(Constants.CACHE_STORE_EMPLOYEES, list)
    validateLoadData()
  }

  const loadStoreData = async () => {
    const bookings = Storage.getJson(Constants.CACHE_STORE_BOOKINGS, false)
    const employees = Storage.getJson(Constants.CACHE_STORE_EMPLOYEES, false)
    if (bookings && employees) {
      refStoreBookings.current = bookings
      refEmployees.current = employees
      validateLoadData()
    } else {
      setLoading(props, true)
      loadBookings()
      loadEmployees()
    }
  }

  const getDateTimeText = () => {
    const dateStr = moment(booking[Constants.DATE]).format('dddd, MMMM Do YYYY')
    return dateStr + ', ' + StringUtils.getHourFromNumber(booking[Constants.START])
  }

  const getDateTimeItemText = (item) => {
    return StringUtils.getHourFromNumber(item[Constants.START])
  }

  const getTotalDuration = () => {
    let total = 0
    if (booking[Constants.SERVICE]) {
      total += StringUtils.getNumber(booking[Constants.SERVICE][Constants.DURATION])
    }
    if (booking[Constants.ITEMS]) {
      for (let i in booking[Constants.ITEMS]) {
        if (booking[Constants.ITEMS][i][Constants.SERVICE]) {
          total += StringUtils.getNumber(booking[Constants.ITEMS][i][Constants.SERVICE][Constants.DURATION])
        }
      }
    }
    return total
  }

  const onEditStore = () => {
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_STORES)
  }

  const onEditService = (item) => {
    if (booking[Constants.STORE]) {
      let data = {}
      data[Constants.ID] = item[Constants.ID]
      Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_SERVICES, null, data)
    } else {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseStore)
    }
  }

  const checkAvailableTime = (id, date, qStart, duration, employee) => {
    let available = null
    // step 1: get all working hours
    const list = firebase.getWorkingPeriodPairs(employee, date)
    if (list.length == 0) {
      return available
    }
    // step 2: set start to selected time if it is available
    if (qStart && qStart > 0) {
      for (let i in list) {
        const item = list[i]
        if (qStart >= item[Constants.START] && qStart <= item[Constants.END]) {
          item[Constants.START] = qStart
          break
        }
      }
    }
    // step 3: calculate available times    
    let timesPair = firebase.calculateTimesPair(list)
    let availableTimes = firebase.calculateAvailableTimes(id, date, duration, employee, timesPair, refStoreBookings.current, refUserBookings.current)

    let last = -1
    // find the lowest available time that bigger or equal to selected time
    for (let i in availableTimes) {
      let item1 = availableTimes[i]
      if (item1[Constants.START] >= qStart) {
        if (last == -1 || item1[Constants.START] < last) {
          last = item1[Constants.START]
          available = item1
        }
      }
    }

    return available
  }

  const checkAvailableTimes = (id) => {
    if (!refStoreBookings.current || !refEmployees.current) {
      return []
    }
    let employees = []
    let service = booking[Constants.SERVICE] || {}
    let item = { ...booking }
    const extra = booking[Constants.ITEMS] || []
    const index = extra.findIndex(value => value[Constants.ID] == id)
    if (index >= 0) {
      service = extra[index][Constants.SERVICE]
      item = extra[index]
    }
    const employeeIds = service[Constants.EMPLOYEE_IDS] || []
    for (let i in refEmployees.current) {
      const employee = refEmployees.current[i]
      if (employeeIds.indexOf(employee[Constants.ID]) >= 0) {
        employees.push(employee)
      }
    }
    if (employees.length == 0) {
      return [];
    }
    item[Constants.ITEMS] = null
    const date = moment(item[Constants.DATE])
    const serviceDuration = StringUtils.getNumber(item[Constants.SERVICE][Constants.DURATION]) / 60 // in hour    
    const list = []
    for (let i in employees) {
      const startInit = item[Constants.START_INIT] || item[Constants.START]
      let qStart = startInit
      const employee = employees[i]
      // increase the time for same employee, e.g: current employee have 1 appointment at 13pm, 
      // then second appointment should start at 13:30pm with duration = 30 minutes
      if (booking[Constants.ID] != id && booking[Constants.EMPLOYEE][Constants.ID] == employee[Constants.ID]
        && booking[Constants.START] >= startInit) {
        const duration = StringUtils.getNumber(booking[Constants.SERVICE][Constants.DURATION]) / 60 // in hour  
        let next = booking[Constants.START] + duration
        qStart = next
      }
      for (let i in extra) {
        const item1 = extra[i]
        if (item1[Constants.ID] != id && item1[Constants.EMPLOYEE][Constants.ID] == employee[Constants.ID]
          && item1[Constants.START] >= startInit) {
          const duration = StringUtils.getNumber(item1[Constants.SERVICE][Constants.DURATION]) / 60 // in hour  
          let next = item1[Constants.START] + duration
          qStart = next
        }
      }
      const available = checkAvailableTime(id, date, qStart, serviceDuration, employee)
      if (available) {
        employee[Constants.START] = available[Constants.START]
        list.push(employee)
      }
    }
    return list;
  }

  const onAnyProvider = (item, needRef = false) => {
    if (!item[Constants.SERVICE]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
      return
    }
    const id = item[Constants.ID]
    const list = checkAvailableTimes(id);
    if (list.length == 0) {
      showToast(props, TOAST_ERROR, Strings.noAvailableProvider)
      return
    }
    // find the lowest available time that bigger or equal to current hour
    let employee = list[0]
    let last = -1
    for (let i in list) {
      const available = list[i];
      if (available[Constants.START] >= item[Constants.START]) {
        if (last == -1 || available[Constants.START] < last) {
          last = available[Constants.START]
          employee = available
        }
      }
    }
    // current logic will choose correct time
    if (last != item[Constants.START]) {
      showToast(props, TOAST_ERROR, Strings.noAvailableProvider)
      return
    }
    const extra = booking[Constants.ITEMS] || []
    const index = extra.findIndex(value => value[Constants.ID] == id)
    if (index >= 0) {
      extra[index][Constants.EMPLOYEE] = employee
      extra[index][Constants.EMPLOYEE_AUTO] = null
      extra[index][Constants.APPOINTMENT_IS_SELECT_ANY_PROVIDER] = true
    } else {
      booking[Constants.EMPLOYEE] = employee
      booking[Constants.EMPLOYEE_AUTO] = null
      booking[Constants.APPOINTMENT_IS_SELECT_ANY_PROVIDER] = true
    }
    Storage.setJson(Constants.BOOKING, booking)
    item[Constants.EMPLOYEE] = employee
    item[Constants.APPOINTMENT_IS_SELECT_ANY_PROVIDER] = true
    if (needRef) {
      setItems([...refItems.current])
    } else {
      setItems([...items])
    }
  }

  const onEditProvider = (item, noPreference = false) => {
    if (noPreference) {
      const booking = Storage.getJson(Constants.BOOKING)
      const id = item[Constants.ID]
      const extra = booking[Constants.ITEMS] || []
      const index = extra.findIndex(value => value[Constants.ID] == id)
      let employee = firebase.noPreferenceEmployee(Strings.anyProviderAvailable, settings[Constants.DEFAULT_AVATAR])
      if (index >= 0) {
        extra[index][Constants.EMPLOYEE] = employee
        extra[index][Constants.EMPLOYEE_AUTO] = null
      } else {
        booking[Constants.EMPLOYEE] = employee
        booking[Constants.EMPLOYEE_AUTO] = null
      }
      Storage.setJson(Constants.BOOKING, booking)
      item[Constants.EMPLOYEE] = employee
      setItems([...items])
      refItems.current = [...items]
    } else {
      if (item[Constants.SERVICE]) {
        let data = {}
        data[Constants.ID] = item[Constants.ID]
        Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_EMPLOYEES, null, data)
      } else {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
      }
    }
  }

  const onEditDate = () => {
    if (!booking[Constants.STORE]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseStore)
      return
    }
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_TIMES)
  }

  const onDeleteService = (item) => {
    const index = items.findIndex(value => value[Constants.ID] == item[Constants.ID])
    if (index >= 0) {
      items.splice(index, 1)
      const data = Storage.getJson(Constants.BOOKING)
      // filter out default first item
      data[Constants.ITEMS] = items.filter(value => value[Constants.ID] != refId.current)
      Storage.setJson(Constants.BOOKING, data)
      setItems([...items])
      refItems.current = [...items]
    }
  }

  const onAddAnother = () => {
    let employee = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
    let item = {}
    item[Constants.ID] = StringUtils.getUniqueID()
    item[Constants.EMPLOYEE] = employee
    let list = [...items, item]
    const data = Storage.getJson(Constants.BOOKING)
    // filter out default first item     
    data[Constants.ITEMS] = list.filter(value => value[Constants.ID] != refId.current)
    Storage.setJson(Constants.BOOKING, data)
    setItems(list)
    refItems.current = list
  }

  const onAddAnotherService = () => {
    if (!booking[Constants.STORE]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseStore)
      return
    }
    for (let i in items) {
      const item = items[i]
      if (!item[Constants.SERVICE]) {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
        return
      }
      if (!item[Constants.EMPLOYEE] || item[Constants.EMPLOYEE][Constants.ID] == Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseProvider)
        return
      }
    }
    if (!booking[Constants.DATE]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseDateTime)
      return
    }
    setOpenServiceForDialog(true)
  }

  const onConfirm = async () => {
    if (!booking[Constants.STORE]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseStore)
      return
    }
    for (let i in items) {
      const item = items[i]
      if (!item[Constants.SERVICE]) {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
        return
      }
      if (!item[Constants.EMPLOYEE] || item[Constants.EMPLOYEE][Constants.ID] == Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
        showToast(props, TOAST_ERROR, Strings.mustSetProviderBeforeSaving)
        return
      }
    }
    if (!booking[Constants.DATE]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseDateTime)
      return
    }

    // save settings first
    if (remind != refOldRemind.current) {
      setLoading(props, true)
      const ref = firebase.databaseRef(Constants.FB_USER_SETTINGS + '/' + user[Constants.ID])
      const updates = {}
      updates[Constants.REMIND_24_BEFORE] = remind
      updates[Constants.REMIND_CANCEL] = remind
      await ref.update(updates)
      setLoading(props, false)
      refOldRemind.current = remind
    }

    // When customer books and selects an employee or select any provider, then that appointment should have specific color
    for (let i in items) {
      const item = items[i]
      if (item[Constants.EMPLOYEE][Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
        if (item[Constants.APPOINTMENT_IS_SELECT_ANY_PROVIDER] == true) {
          item[Constants.COLOR] = Constants.APPOINTMENT_CUSTOMER_BOOKED_COLOR_ANY_PROVIDER
        } else {
          item[Constants.COLOR] = Constants.APPOINTMENT_CUSTOMER_BOOKED_COLOR
        }
      }
    }

    // const state = {}
    // state[Constants.ITEMS] = items
    // state[Constants.USER] = booking[Constants.USER]
    // state[Constants.DATE] = booking[Constants.DATE]
    // state[Constants.START] = booking[Constants.START]
    // Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_SCHEDULE_NOW, null, state)
    setLoading(props, true)
    const storeId = refStore.current[Constants.ID]
    const userId = refStore.current[Constants.USER_ID]
    let success = false
    for (let i in items) {
      const item = items[i]
      const date = moment(item[Constants.DATE])
      const year = date.year()
      const month = date.month() + 1 // month is zero index
      const day = date.date()
      const employee = item[Constants.EMPLOYEE_AUTO] || item[Constants.EMPLOYEE]
      const service = item[Constants.SERVICE]
      const duration = StringUtils.getNumber(service[Constants.DURATION]) / 60 // in hour  

      const subject = 'Appointment was created'
      const message = `Appointment was created on ${date.format('MMMM Do YYYY')}, ${getDateTimeItemText(item)}.<br/>
        Store: ${refStore.current[Constants.NAME]}.<br/>  
        Service: ${service[Constants.TITLE]}.<br/>
        Customer Name: ${user[Constants.FIRST_NAME]} ${user[Constants.LAST_NAME]}.<br/>
        Customer Email: ${user[Constants.EMAIL]}.<br/>
        Provider Name: ${employee[Constants.FIRST_NAME]} ${employee[Constants.LAST_NAME]}.<br/>
        Provider Email: ${employee[Constants.EMAIL]}.
        `
      const smsPhone = Constants.SMS_DEFAULT_PHONE
      const smsText = `Appointment was created on ${date.format('MMMM Do YYYY')}, ${getDateTimeItemText(item)}.
        Store: ${refStore.current[Constants.NAME]}.
        Service: ${service[Constants.TITLE]}.
        Customer: ${user[Constants.FIRST_NAME]} ${user[Constants.LAST_NAME]}.
        Provider: ${employee[Constants.FIRST_NAME]} ${employee[Constants.LAST_NAME]}.
        `
      const updates = {}
      updates[Constants.STORE] = firebase.storeForBooking(refStore.current)
      updates[Constants.SERVICE] = firebase.serviceForBooking(service)
      updates[Constants.EMPLOYEE] = firebase.employeeForBooking(employee)
      updates[Constants.USER] = firebase.userForBooking(user)
      updates[Constants.DATE] = date.toISOString()
      updates[Constants.START] = item[Constants.START]
      updates[Constants.END] = item[Constants.START] + duration
      updates[Constants.COLOR] = item[Constants.COLOR]
      updates[Constants.DATE_MODIFIED] = Date.now()
      updates[Constants.DATE_ADDED] = Date.now()
      updates[Constants.TIME_ZOME] = moment.tz.guess()
      try {
        const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_BOOK_SERVICE)
        await httpsCallable({ userId, storeId, year, month, day, updates, subject, message, smsPhone, smsText })
        success = true
      } catch (err) {
        Logger.log(err)
      }
    }
    setLoading(props, false)
    if (success) {
      Storage.clearKey(Constants.CACHE_STORE_BOOKINGS)
      Storage.clearKey(Constants.CACHE_STORE_EMPLOYEES)
      Storage.clearKey(Constants.CACHE_STORE_SERVCIES)
      showToast(props, TOAST_SUCCESS, Strings.bookedSuccessfully)
      refTimer.current = setTimeout(() => {
        Navigator.navigate(props, Constants.PAGES_CUSTOMER_APPOINTMENTS)
      }, 1000)
    } else {
      showToast(props, TOAST_ERROR, Strings.errorLoadingData)
    }
  }

  const handleStoreDialog = () => {
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_STORES)
  }

  const handleServiceForChange = (type) => {
    setSelectServiceFor(type)
  }

  const handleServiceForDialog = (value) => {
    if (!value) {
      setOpenServiceForDialog(false)
      return
    }
    if (selectServiceFor == '_custom') {
      if (StringUtils.stringEmpty(customerName)) {
        showToast(props, TOAST_ERROR, 'Please enter customer\'s name!')
        return
      }
      if (refPreviousCustomerNames.current.findIndex(v => v == customerName) != -1) {
        showToast(props, TOAST_ERROR, 'Please enter other customer\'s name!')
        return
      }
    }
    setOpenServiceForDialog(false)
    if (items[items.length - 1][Constants.EMPLOYEE][Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
      Storage.setItemToMemory(Constants.CACHE_CUSTOMER_NAME, customerName)
      Storage.setItemToMemory(Constants.CACHE_SELECT_SERVICE_FOR, selectServiceFor)
      Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_SERVICES, null, { isSelectService: true })
      // setOpenProviderDialog(true)
    } else {
      handleProviderDialog(false)
    }
  }

  const checkProviderAvailableTime = (item) => {
    const id = item[Constants.ID]
    const date = moment(item[Constants.DATE])
    const qStart = item[Constants.START]
    const service = item[Constants.SERVICE]
    const duration = StringUtils.getNumber(service[Constants.DURATION]) / 60 // in hour  
    const employee = item[Constants.EMPLOYEE]
    let available = null
    // step 1: get all working hours
    const list = firebase.getWorkingPeriodPairs(employee, date)
    if (list.length == 0) {
      return available
    }
    // step 2: set start to selected time if it is available
    if (qStart && qStart > 0) {
      for (let i in list) {
        const item1 = list[i]
        if (qStart >= item1[Constants.START] && qStart <= item1[Constants.END]) {
          item1[Constants.START] = qStart
          break
        }
      }
    }
    // step 3: calculate available times    
    const timesPair = firebase.calculateTimesPair(list)
    const availableTimes = firebase.calculateAvailableTimes(id, date, duration, employee, timesPair, refStoreBookings.current, refUserBookings.current)

    let last = -1
    // find the lowest available time that bigger or equal to selected time
    for (let i in availableTimes) {
      let item1 = availableTimes[i]
      if (item1[Constants.START] >= qStart) {
        if (last == -1 || item1[Constants.START] < last) {
          last = item1[Constants.START]
          available = item1
        }
      }
    }

    return available
  }

  const handleProviderDialog = async (ok, anyProvider = false) => {
    let value = ok
    const cacheService = Storage.getJson(Constants.CACHE_SELECT_SERVICE, false)
    const cacheCustomerName = Storage.getString(Constants.CACHE_CUSTOMER_NAME, customerName)
    const service = cacheService || items[items.length - 1][Constants.SERVICE]
    // check if employee can do the service
    if (ok) {
      const employee = items[items.length - 1][Constants.EMPLOYEE]
      const employeeIds = service[Constants.EMPLOYEE_IDS] || []
      if (employeeIds.indexOf(employee[Constants.ID]) == -1) {
        value = false
      }
    }
    let item = {}
    if (selectServiceFor == '_myself') {
      item[Constants.FOR_CUSTOMER] = Constants.CUSTOMER_FOR_MYSELF
    } else if (selectServiceFor == '_custom') {
      refPreviousCustomerNames.current.push(cacheCustomerName)
      item[Constants.FOR_CUSTOMER] = cacheCustomerName
    } else {
      item[Constants.FOR_CUSTOMER] = selectServiceFor
    }
    item[Constants.ID] = StringUtils.getUniqueID()
    item[Constants.SERVICE] = service
    if (value) {
      item[Constants.EMPLOYEE] = items[items.length - 1][Constants.EMPLOYEE]
      item[Constants.APPOINTMENT_IS_SELECT_ANY_PROVIDER] = items[items.length - 1][Constants.APPOINTMENT_IS_SELECT_ANY_PROVIDER]
    } else {
      item[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
    }
    item[Constants.DATE] = booking[Constants.DATE]
    item[Constants.START] = booking[Constants.START]
    // start time, which will not be changed by selecting employee mutilple times
    item[Constants.START_INIT] = booking[Constants.START]

    // increase the time for same employee
    for (let i in items) {
      const item1 = items[i]
      if (item1[Constants.EMPLOYEE][Constants.ID] == item[Constants.EMPLOYEE][Constants.ID]) {
        // next time will start with previous service's duration
        let duration = StringUtils.getNumber(item1[Constants.SERVICE][Constants.DURATION]) / 60 // in hour
        let next = item1[Constants.START] + duration
        item[Constants.START] = next
        // item[Constants.START_INIT] = next
      }
    }

    // check available time
    if (value) {
      const available = checkProviderAvailableTime(item)
      if (available) {
        item[Constants.START] = available[Constants.START]
        // item[Constants.START_INIT] = available[Constants.START]
      } else {
        showToast(props, TOAST_ERROR, Strings.noAvailableTime)
        return
      }
    }

    let list = [...items, item]
    const data = Storage.getJson(Constants.BOOKING)
    // filter out default first item     
    data[Constants.ITEMS] = list.filter(value => value[Constants.ID] != refId.current)
    Storage.setJson(Constants.BOOKING, data)
    Storage.clearKey(Constants.CACHE_SELECT_SERVICE)
    Storage.clearKey(Constants.CACHE_SELECT_SERVICE_FOR)
    Storage.clearKey(Constants.CACHE_CUSTOMER_NAME)
    setItems(list)
    refItems.current = list
    setCustomerName('')
    setOpenProviderDialog(false)
    if (!value && !ok) {
      if (anyProvider) {
        onAnyProvider(item, true)
      } else {
        let passData = {}
        passData[Constants.ID] = item[Constants.ID]
        Navigator.navigate(props, Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_EMPLOYEES, null, passData)
      }
    }
    if (!value && ok) {
      showToast(props, TOAST_ERROR, Strings.noAvailableTime)
    }
  }

  const getPreviousProvider = () => {
    if (items.length == 0) {
      return ''
    }
    const firtName = items[items.length - 1][Constants.EMPLOYEE][Constants.FIRST_NAME]
    const lastName = items[items.length - 1][Constants.EMPLOYEE][Constants.LAST_NAME]
    return `${firtName}${StringUtils.stringEmpty(lastName) ? '' : ' '}${lastName}`
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()
  const cols = isMobile ? 1 : 2

  return <div className={classes.root}>
    <ICard style={{ minHeight: 500 }}>
      <ICardContent>
        <div style={{ display: 'flex' }}>
          <ITypography variant='h5' style={{ width: '100%' }}>Create Appointment</ITypography>
        </div>
        <IDivider style={{ marginTop: 10, marginBottom: 10, backgroundColor: '#bbbbbb' }} />
        <div className={classes.container} style={{ marginTop: Styles.margin1Br }}>
          <div style={{ flex: 1 }}>
            {booking[Constants.STORE] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.store}:</b></ITypography>
              {isMobile && <>
                <div>
                  <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.STORE][Constants.IMAGE] || settings[Constants.DEFAULT_STORE_IMAGE] || ''} alt={booking[Constants.STORE][Constants.NAME]} />
                  <div style={{ width: '100%' }}>
                    <ITypography className={classes.text} variant='body1'><b>{booking[Constants.STORE][Constants.NAME]}</b></ITypography>
                    <ITypography className={classes.text} variant='body1'>{Strings.address}: <b>{booking[Constants.STORE][Constants.ADDRESS]}</b></ITypography>
                    <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{booking[Constants.STORE][Constants.PHONE]}</b></ITypography>
                    <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{booking[Constants.STORE][Constants.EMAIL]}</b></ITypography>
                  </div>
                </div>
                <IIconButton onClick={() => onEditStore()}><EditIcon /></IIconButton>
              </>}
              {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.STORE][Constants.IMAGE] || settings[Constants.DEFAULT_STORE_IMAGE] || ''} alt={booking[Constants.STORE][Constants.NAME]} />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.STORE][Constants.NAME]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.address}: <b>{booking[Constants.STORE][Constants.ADDRESS]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{booking[Constants.STORE][Constants.PHONE]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{booking[Constants.STORE][Constants.EMAIL]}</b></ITypography>
                </div>
                <div>
                  <IIconButton onClick={() => onEditStore()}><EditIcon /></IIconButton>
                </div>
              </div>}
            </div>}
            {!booking[Constants.STORE] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.store}:</b></ITypography>
              <div style={{ display: 'flex', width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={settings[Constants.DEFAULT_STORE_IMAGE] || ''} alt='store' />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseStore}</ITypography>
                </div>
                <div>
                  <IIconButton onClick={() => onEditStore()}><EditIcon /></IIconButton>
                </div>
              </div>
            </div>}
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ width: '100%' }}>
              {isMobile && <IDivider style={{ marginTop: Styles.margin1Br }} />}
              {booking[Constants.DATE] && booking[Constants.START] && <div style={{ margin: 10 }}>
                <ITypography variant='body1' style={{ marginTop: Styles.margin1Br }}><b>{Strings.dateAndTime}:</b></ITypography>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ marginLeft: 10, width: '100%' }}>
                    <ITypography className={classes.text} variant='body1' style={{ fontSize: 20 }}><b>{getDateTimeText()}</b></ITypography>
                  </div>
                  <div>
                    <IIconButton onClick={() => onEditDate()}><EditIcon /></IIconButton>
                  </div>
                </div>
              </div>}
              {(!booking[Constants.DATE] || !booking[Constants.START]) && <div style={{ margin: 10 }}>
                <ITypography variant='body1' style={{ marginTop: Styles.margin1Br }}><b>{Strings.dateAndTime}:</b></ITypography>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ marginLeft: 10, width: '100%' }}>
                    <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseDateTime}</ITypography>
                  </div>
                  <div>
                    <IIconButton onClick={() => onEditDate()}><EditIcon /></IIconButton>
                  </div>
                </div>
              </div>}
              <IDivider style={{ marginTop: Styles.margin1Br }} />
            </div>
            {booking[Constants.SERVICE] && <>
              <div style={{ margin: 10 }}>
                <ITypography variant='body1'><b>{items.length > 1 ? Strings.totalDuration : Strings.duration}:</b></ITypography>
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1' style={{ fontSize: 22 }}><b>{getTotalDuration()} {Strings.mins}</b></ITypography>
                </div>
              </div>
              <IDivider />
            </>}
            <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.textReminders}:</b></ITypography>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
                <ITypography className={classes.text}>{Strings.appointmentTextReminders}</ITypography>
                <ISwitch checked={remind} value={remind} onChange={event => setRemind(event.target.checked)} />
              </div>
            </div>
          </div>
        </div>
        <IDivider style={{ marginTop: 10, marginBottom: 10, backgroundColor: '#bbbbbb' }} />
        <ITypography variant='body1' style={{ width: '100%', marginTop: 20, marginBottom: 20, fontSize: 22 }}>{'Services & Providers'}</ITypography>
        <IGridList cellHeight='auto' cols={cols} spacing={0} className={classes.gridList}>
          {items.map((item, index) => (
            <IGridListTitle key={item[Constants.ID]}>
              <div className={isMobile ? classes.box : (index % 2 === 0 ? classes.boxLeft : classes.boxRight)}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ITypography variant='body1'><b>{Strings.customer + ' ' + (index + 1)}:</b> {item[Constants.FOR_CUSTOMER]}</ITypography>
                  <ITypography variant='body2' style={{ marginLeft: 20 }}><b>Start Time:</b></ITypography>
                  <ITypography variant='body2' style={{ marginLeft: 5 }}>{getDateTimeItemText(item)}</ITypography>
                  <div style={{ marginLeft: 'auto', width: 40, height: 40, textAlign: 'center' }}>
                    {index != 0 && index == items.length - 1 && <IIconButton onClick={() => onDeleteService(item)}><CancelIcon /></IIconButton>}
                  </div>
                </div>
                <IDivider light variant='middle' style={{ marginTop: Styles.margin1Br, marginLeft: 0, marginRight: 0, marginBottom: 10 }} />
                {item[Constants.SERVICE] && <div>
                  <ITypography variant='body1'><b>{Strings.service + ' ' + (index + 1)}:</b></ITypography>
                  {isMobile && <>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.SERVICE][Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.SERVICE][Constants.TITLE]} />
                      <div style={{ marginLeft: 10, width: '100%' }}>
                        <ITypography className={classes.text} variant='body1'><b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                        <ITypography className={classes.text} variant='body1'>{Strings.price}: <b>${item[Constants.SERVICE][Constants.PRICE]}</b></ITypography>
                        <ITypography className={classes.text} variant='body1'>{Strings.duration}: <b>{item[Constants.SERVICE][Constants.DURATION]} {Strings.mins}</b></ITypography>
                      </div>
                    </div>
                    {index == items.length - 1 && <div style={{ display: 'flex' }}>
                      <IIconButton onClick={() => onEditService(item)}><EditIcon /></IIconButton>
                    </div>}
                  </>}
                  {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                    <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.SERVICE][Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.SERVICE][Constants.TITLE]} />
                    <div style={{ marginLeft: 10, width: '100%' }}>
                      <ITypography className={classes.text} variant='body1'><b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                      <ITypography className={classes.text} variant='body1'>{Strings.price}: <b>${item[Constants.SERVICE][Constants.PRICE]}</b></ITypography>
                      <ITypography className={classes.text} variant='body1'>{Strings.duration}: <b>{item[Constants.SERVICE][Constants.DURATION]} {Strings.mins}</b></ITypography>
                    </div>
                    {index == items.length - 1 && <div style={{ textAlign: 'right' }}>
                      <IIconButton onClick={() => onEditService(item)}><EditIcon /></IIconButton>
                    </div>}
                  </div>}
                </div>}
                {!item[Constants.SERVICE] && <div>
                  <ITypography variant='body1'><b>{Strings.service + ' ' + (index + 1)}:</b></ITypography>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt='service' />
                    <div style={{ marginLeft: 10, width: '100%' }}>
                      <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseService}</ITypography>
                    </div>
                    {index == items.length - 1 && <div style={{ textAlign: 'right' }}>
                      <IIconButton onClick={() => onEditService(item)}><EditIcon /></IIconButton>
                    </div>}
                  </div>
                </div>}
                <IDivider light variant='middle' style={{ marginTop: Styles.margin1Br, marginLeft: 0, marginRight: 0, marginBottom: 10, }} />
                {item[Constants.EMPLOYEE] && <div>
                  <ITypography variant='body1'><b>{Strings.provider + ' ' + (index + 1)}:</b></ITypography>
                  {isMobile && <>
                    <div className={classes.container}>
                      <div style={{ display: 'flex', width: '100%' }}>
                        <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.EMPLOYEE][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.EMPLOYEE][Constants.FIRST_NAME]} />
                        <div style={{ marginLeft: 10, width: '100%' }}>
                          <ITypography className={classes.text} variant='body1'><b>{item[Constants.EMPLOYEE][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE][Constants.LAST_NAME]}</b></ITypography>
                          {item[Constants.EMPLOYEE][Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE && <>
                            <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{item[Constants.EMPLOYEE][Constants.EMAIL]}</b></ITypography>
                            <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{item[Constants.EMPLOYEE][Constants.PHONE]}</b></ITypography>
                          </>}
                        </div>
                      </div>
                      {index == items.length - 1 && <div style={{ textAlign: 'right', marginTop: isMobile ? Styles.margin1Br : 0 }}>
                        {/* <ProviderButton onClick={() => onEditProvider(item, true)}>{Strings.anyProviderAvailable}</ProviderButton> */}
                        <ProviderButton onClick={() => onEditProvider(item)}>{Strings.requestTechnician}</ProviderButton>
                        <ProviderButton onClick={() => onAnyProvider(item)} style={{ marginTop: Styles.margin1Br }}>{Strings.anyTechnician}</ProviderButton>
                      </div>}
                    </div>
                  </>}
                  {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                    <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.EMPLOYEE][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.EMPLOYEE][Constants.FIRST_NAME]} />
                    <div style={{ marginLeft: 10, width: '100%' }}>
                      <ITypography className={classes.text} variant='body1'><b>{item[Constants.EMPLOYEE][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE][Constants.LAST_NAME]}</b></ITypography>
                      {item[Constants.EMPLOYEE][Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE && <>
                        <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{item[Constants.EMPLOYEE][Constants.EMAIL]}</b></ITypography>
                        <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{item[Constants.EMPLOYEE][Constants.PHONE]}</b></ITypography>
                      </>}
                    </div>
                    {index == items.length - 1 && <div style={{ textAlign: 'right' }}>
                      {/* <ProviderButton onClick={() => onEditProvider(item, true)}>{Strings.anyProviderAvailable}</ProviderButton> */}
                      <ProviderButton onClick={() => onEditProvider(item)}>{Strings.requestTechnician}</ProviderButton>
                      <ProviderButton onClick={() => onAnyProvider(item)} style={{ marginTop: Styles.margin1Br }}>{Strings.anyTechnician}</ProviderButton>
                    </div>}
                  </div>}
                </div>}
                {!item[Constants.EMPLOYEE] && <div style={{ margin: 10 }}>
                  <ITypography variant='body1'><b>{Strings.provider + ' ' + (index + 1)}:</b></ITypography>
                  <div className={classes.container}>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={settings[Constants.DEFAULT_AVATAR] || ''} alt='provider' />
                      <div style={{ marginLeft: 10, width: '100%' }}>
                        <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseProvider}</ITypography>
                      </div>
                    </div>
                    {index == items.length - 1 && <div style={{ textAlign: 'right', marginTop: isMobile ? Styles.margin1Br : 0 }}>
                      {/* <ProviderButton onClick={() => onEditProvider(item, true)}>{Strings.anyProviderAvailable}</ProviderButton> */}
                      <ProviderButton onClick={() => onEditProvider(item)}>{Strings.requestTechnician}</ProviderButton>
                      <ProviderButton onClick={() => onAnyProvider(item)} style={{ marginTop: Styles.margin1Br }}>{Strings.anyTechnician}</ProviderButton>
                    </div>}
                  </div>
                </div>}
              </div>
            </IGridListTitle>
          ))}
        </IGridList>
        <div style={{ display: 'flex', height: 50, marginTop: Styles.margin1Br }}>
          <AddButton onClick={() => onAddAnotherService()} style={{ margin: 'auto' }}>{Strings.addAnotherService}</AddButton>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: Styles.margin2Br }}>
          <IButton onClick={() => onConfirm()}>{Strings.confirmBook}</IButton>
        </div>
      </ICardContent>
    </ICard>
    <IDialog open={openStoreDialog} fullWidth={true} maxWidth='sm'>
      <IDialogTitle>{Strings.appName}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText>{'Please choose a store first!'}</IDialogContentText>
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleStoreDialog()} autoFocus>{Strings.ok}</IButton>
      </IDialogActions>
    </IDialog>
    <IDialog open={openServiceForDialog} fullWidth={true} maxWidth='sm'>
      <IDialogTitle>{Strings.appName}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText>{'Who is this service for?'}</IDialogContentText>
        <IRadioGroup>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IRadio color='default' checked={selectServiceFor == '_myself'} onChange={() => handleServiceForChange('_myself')} value='_myself' />
            <ITypography className={classes.text} variant='body2'>{'Myself'}</ITypography>
          </div>
          {refPreviousCustomerNames.current.map((name, index) => (
            <div key={name} style={{ display: 'flex', alignItems: 'center' }}>
              <IRadio color='default' checked={selectServiceFor == name} onChange={() => handleServiceForChange(name)} value={name} />
              <ITypography className={classes.text} variant='body2'>{name}</ITypography>
            </div>
          ))}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IRadio color='default' checked={selectServiceFor == '_custom'} onChange={() => handleServiceForChange('_custom')} value='_custom' />
            <ITextField className={classes.textField} value={customerName} placeholder={'Enter customer\'s name'} onChange={(event) => { setCustomerName(event.target.value) }} />
          </div>
        </IRadioGroup>
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleServiceForDialog(false)}>{Strings.cancel}</IButton>
        <IButton onClick={() => handleServiceForDialog(true)} autoFocus>{Strings.selectService}</IButton>
      </IDialogActions>
    </IDialog>
    <IDialog open={openProviderDialog} fullWidth={true} maxWidth='sm'>
      <IDialogTitle>{Strings.appName}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText>{`Do you want to use the previous technician, ${getPreviousProvider()}?`}</IDialogContentText>
        {isChecking && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ICircularProgress color='primary' /></div>}
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleProviderDialog(false, true)}>{Strings.anyTechnician}</IButton>
        <IButton onClick={() => handleProviderDialog(false)}>{Strings.no}</IButton>
        <IButton onClick={() => handleProviderDialog(true)} autoFocus>{Strings.yes}</IButton>
      </IDialogActions>
    </IDialog>
  </div>
}

function StoreAppointment() {
  Logger.log(Constants.PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_APPOINTMENT)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default StoreAppointment