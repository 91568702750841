import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as DateUtils from '../../../common/DateUtils'
import * as Constants from '../../../common/Constants'
import * as Navigator from '../../../common/Navigator'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import ITypography from '../../controls/ITypography'
import IImage from '../../controls/IImage'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import IPaper from '../../controls/IPaper'
import ITableRow from '../../controls/ITableRow'
import ITableHead from '../../controls/ITableHead'
import IIconButton from '../../controls/IIconButton'
import AddIcon from '@material-ui/icons/Add'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, isLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import moment from 'moment'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.contentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  appointments: {
    width: '100%',
  },
  summary: {
    width: Styles.summaryWidth,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    }
  }
}))

function AppointmentsView({ props }) {
  Logger.log('AppointmentsView')

  const { user, settings } = useGlobal()
  const [items, setItems] = useState([])

  useEffect(() => {
    setLoading(props, true)
    const date = moment()
    const year = date.year()
    const month = date.month() + 1
    const day = date.date()
    const path = `${year}/${month}/${day}`
    const ref = firebase.databaseRef(Constants.FB_SYSTEM_BOOKINGS + '/' + path)
    ref.once('value').then(response => {
      setLoading(props, false)
      var list = []
      var mapIds = {}
      const val = response.val()
      if (val) {
        for (let i in val) {
          const item = val[i]
          const id = item[Constants.STORE][Constants.ID]
          if (mapIds[id]) {
            mapIds[id][Constants.TOTAL] = mapIds[id][Constants.TOTAL] + 1
          } else {
            const value = { ...item[Constants.STORE] }
            value[Constants.TOTAL] = 1
            mapIds[id] = value
          }
        }
      }
      for (let i in mapIds) {
        const item = mapIds[i]
        list.push(item)
      }
      DateUtils.sortBy(list, Constants.NAME)
      setItems(list)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const classes = useStyles()

  return <div className={classes.appointments}>
    <ITableContainer component={IPaper} className={classes.listSection}>
      <ITable>
        <ITableHead>
          <ITableRow>
            <ITableCell>
              <ITypography variant='h5'>{Strings.appointmentsToday}</ITypography>
            </ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          {items.map(item => (
            <ITableRow key={item[Constants.ID]}>
              <ITableCell style={{ display: 'flex', justifyItems: 'center' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.IMAGE] || settings[Constants.DEFAULT_STORE_IMAGE] || ''} alt={item[Constants.NAME]} />
                <div style={{ width: '100%', marginLeft: 20 }}>
                  <ITypography variant='body1'>{Strings.store}: <b>{item[Constants.NAME]}</b></ITypography>
                  <ITypography variant='body1'>{Strings.email}: <b>{item[Constants.EMAIL]}</b></ITypography>
                  <ITypography variant='body1'>{Strings.phone}: <b>{item[Constants.PHONE]}</b></ITypography>
                  <ITypography variant='body1'>{Strings.totalAppointmentsToday}: <b>{item[Constants.TOTAL]}</b></ITypography>
                </div>
              </ITableCell>
            </ITableRow>
          ))}
          {!isLoading(props) && items.length == 0 && <ITableRow>
            <ITableCell>
              <ITypography variant='body1'>{Strings.noAppointmentsToday}</ITypography>
            </ITableCell>
          </ITableRow>}
        </ITableBody>
      </ITable>
    </ITableContainer>
  </div>
}

function SummaryView({ props }) {
  Logger.log('SummaryView')

  const { user, settings } = useGlobal()
  const [totalMonth, setTotalMonth] = useState(-1)

  useEffect(() => {
    const date = moment()
    const year = date.year()
    const month = date.month() + 1
    const path = `${year}/${month}`
    const ref = firebase.databaseRef(Constants.FB_SYSTEM_BOOKINGS + '/' + path)
    ref.once('value').then(response => {
      var total = 0
      const val = response.val()
      if (val) {
        for (let i in val) {
          for (let j in val[i]) {
            total += 1
          }
        }
      }
      setTotalMonth(total)
    }).catch(err => {
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }, [])

  const classes = useStyles()

  return <div className={classes.summary}>
    <ITableContainer component={IPaper} className={classes.listSection}>
      <ITable>
        <ITableHead>
          <ITableRow>
            <ITableCell><ITypography variant='h5'>{Strings.summary}</ITypography></ITableCell>
          </ITableRow>
        </ITableHead>
        <ITableBody>
          <ITableRow >
            <ITableCell style={{ display: 'flex', justifyItems: 'center' }}>
              <ITypography variant='body1'>{Strings.totalAppointmentsThisMonth}: <b>{totalMonth < 0 ? '...' : totalMonth}</b></ITypography>
            </ITableCell>
          </ITableRow>
        </ITableBody>
      </ITable>
    </ITableContainer>
  </div>
}

function EmployeeDashboard() {
  Logger.log(Constants.PAGES_SYSTEM_DASHBOARD)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <>
    <div className={classes.root}>
      <AppointmentsView props={props} />
      <SummaryView props={props} />
    </div>
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default EmployeeDashboard