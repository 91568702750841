import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as DateUtils from '../../../common/DateUtils'
import * as ScreenUtils from '../../../common/ScreenUtils'
import * as Constants from '../../../common/Constants'
import * as Navigator from '../../../common/Navigator'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import IIconButton from '../../controls/IIconButton'
import ITypography from '../../controls/ITypography'
import ISwitch from '../../controls/ISwitch'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import IImage from '../../controls/IImage'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogContentText from '../../controls/IDialogContentText'
import IDialogTitle from '../../controls/IDialogTitle'
import IDivider from '../../controls/IDivider'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, isLoading, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import GoogleMapReact from 'google-map-react'
import moment from 'moment'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const ProviderButton = styled(AddButton)({
  fontSize: 14,
  padding: '0 5px',
  width: 170,
  textTransform: 'none',
  marginRight: 10
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '95%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  mapBox: {
    marginTop: 10,
    width: '100%',
    height: Styles.mapHeight
  },
  text: {
    color: '#666666'
  },
  headerBox: {
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  containerItemFlex: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  containerItem: {
    width: '100%',
    display: 'flex',
  },
  containerItemRightFlex: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 10
    }
  },
  containerItemRight: {
    width: '100%',
    marginLeft: 10
  },
  containerRight: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10
    },
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const query = Navigator.getQuery()
  const path = query.get(Constants.PATH)
  const { user, settings } = useGlobal()
  const refMaps = useRef()
  const refMap = useRef()
  const refLocationLat = useRef(Constants.STORE_DEFAULT_LOCATION_LAT)
  const refLocationLng = useRef(Constants.STORE_DEFAULT_LOCATION_LNG)
  const refLocationZoom = useRef(Constants.STORE_DEFAULT_ZOOM)
  const refLocationTitle = useRef()
  const refMarkers = useRef([])
  const refTimer = useRef()
  const refEmployee = useRef()
  const [booking, setBooking] = useState({})
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [notify, setNotify] = useState(true)

  useEffect(() => {
    setLoading(props, true)
    const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_BOOKING)
    httpsCallable({ path }).then(({ data }) => {
      setLoading(props, false)
      if (data.booking) {
        const val = data.booking
        setBooking(val)
        refLocationLat.current = val[Constants.STORE][Constants.LOCATION_LAT]
        refLocationLng.current = val[Constants.STORE][Constants.LOCATION_LNG]
        refLocationZoom.current = val[Constants.STORE][Constants.LOCATION_ZOOM]
        refLocationTitle.current = val[Constants.STORE][Constants.ADDRESS]
        renderMarkers()
      }
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })

    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const getDateTimeText = () => {
    const dateStr = moment(booking[Constants.DATE]).format('dddd, MMMM Do YYYY')
    return dateStr + ', ' + StringUtils.getHourFromNumber(booking[Constants.START])
  }

  const getDurationText = () => {
    const minutes = DateUtils.getMinutesFromNumber(booking[Constants.END] - booking[Constants.START])
    return StringUtils.formatMinutes(minutes)
  }

  const clearMarkers = () => {
    for (let i in refMarkers.current) {
      refMarkers.current[i].setMap(null)
    }
  }

  const renderMarkers = () => {
    if (refMap.current && refMaps.current) {
      clearMarkers()
      let marker = new refMaps.current.Marker({
        position: { lat: refLocationLat.current, lng: refLocationLng.current },
        draggable: false,
      })
      let infowindow = new refMaps.current.InfoWindow({
        content: refLocationTitle.current
      })
      marker.addListener('click', function () {
        infowindow.open(refMap.current, marker)
      })
      marker.setMap(refMap.current)
      const center = new refMaps.current.LatLng(refLocationLat.current, refLocationLng.current)
      refMap.current.setCenter(center)
      refMap.current.setZoom(refLocationZoom.current)
      refMarkers.current.push(marker)
    }
  }

  const onCancel = () => {
    setOpenCancelDialog(true)
  }

  const handleCancelDialog = (agree) => {
    setOpenCancelDialog(false)
    if (agree) {
      setLoading(props, true)
      const userId = booking[Constants.STORE][Constants.USER_ID]
      const storeId = booking[Constants.STORE][Constants.ID]
      const subject = 'Appointment was canceled'
      const message = `Appointment was canceled on ${moment().format('MMMM Do YYYY, h:mm a')}.<br/>
        Service: ${booking[Constants.SERVICE][Constants.TITLE]}.<br/>
        Customer Name: ${booking[Constants.USER][Constants.FIRST_NAME]} ${booking[Constants.USER][Constants.LAST_NAME]}.<br/>
        Customer Email: ${booking[Constants.USER][Constants.EMAIL]}.<br/>
        Provider Name: ${booking[Constants.EMPLOYEE][Constants.FIRST_NAME]} ${booking[Constants.EMPLOYEE][Constants.LAST_NAME]}.<br/>
        Provider Email: ${booking[Constants.EMPLOYEE][Constants.EMAIL]}.
        `
      const smsPhone = Constants.SMS_DEFAULT_PHONE
      const smsText = `Appointment was canceled on ${moment().format('MMMM Do YYYY, h:mm a')}.
        Service: ${booking[Constants.SERVICE][Constants.TITLE]}.
        Customer: ${booking[Constants.USER][Constants.FIRST_NAME]} ${booking[Constants.USER][Constants.LAST_NAME]}.
        Provider: ${booking[Constants.EMPLOYEE][Constants.FIRST_NAME]} ${booking[Constants.EMPLOYEE][Constants.LAST_NAME]}.
        `
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_CANCEL_BOOKING)
      httpsCallable({ userId, storeId, path, notify, subject, message, smsPhone, smsText }).then(({ data }) => {
        setLoading(props, false)
        if (data.success) {
          showToast(props, TOAST_SUCCESS, Strings.cancelSuccessfully)
          refTimer.current = setTimeout(() => {
            Navigator.back()
          }, 2000)
        } else {
          showToast(props, TOAST_ERROR, Strings.errorLoadingData)
        }
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const loadEmployee = async () => {
    // need to get full information of employee to check available times
    if (!refEmployee.current) {
      setLoading(props, true)
      const userId = booking[Constants.STORE][Constants.USER_ID]
      const storeId = booking[Constants.STORE][Constants.ID]
      const employeeId = booking[Constants.EMPLOYEE][Constants.ID]
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_CUSTOMER_VIEW_EMPLOYEE)
      const response = await httpsCallable({ userId, storeId, employeeId })
      if (response.data.employee) {
        refEmployee.current = response.data.employee
      }
      setLoading(props, false)
    }
  }

  const onEditService = async () => {
    await loadEmployee()
    const state = {}
    state[Constants.ID] = booking[Constants.ID]
    state[Constants.STORE] = booking[Constants.STORE]
    state[Constants.EMPLOYEE] = refEmployee.current || booking[Constants.EMPLOYEE]
    state[Constants.DATE] = booking[Constants.DATE]
    state[Constants.START] = booking[Constants.START]
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_EDIT_APPOINTMENT_VIEW_SERVICES, `${Constants.PATH}=${path}`, state)
  }

  const onEditProvider = () => {
    const state = {}
    state[Constants.ID] = booking[Constants.ID]
    state[Constants.STORE] = booking[Constants.STORE]
    state[Constants.SERVICE] = booking[Constants.SERVICE]
    state[Constants.DATE] = booking[Constants.DATE]
    state[Constants.START] = booking[Constants.START]
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_EDIT_APPOINTMENT_VIEW_SERVICES, `${Constants.PATH}=${path}`, state)
  }

  const onEditDate = async () => {
    await loadEmployee()
    const state = {}
    const item = {}
    item[Constants.ID] = StringUtils.getUniqueID()
    item[Constants.SERVICE] = booking[Constants.SERVICE]
    item[Constants.EMPLOYEE] = refEmployee.current || booking[Constants.EMPLOYEE]
    item[Constants.COLOR] = booking[Constants.COLOR]
    state.items = [item]
    state[Constants.ID] = booking[Constants.ID]
    state[Constants.STORE] = booking[Constants.STORE]
    state[Constants.DATE] = booking[Constants.DATE]
    state[Constants.START] = booking[Constants.START]
    Navigator.navigate(props, Constants.PAGES_CUSTOMER_EDIT_APPOINTMENT_SCHEDULE_NOW, `${Constants.PATH}=${path}`, state)
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return <div className={classes.root}>
    <ICard style={{ minHeight: 500 }}>
      <ICardContent>
        <div className={classes.headerBox}>
          <ITypography variant='h5' style={{ width: '100%' }}>{Strings.appointmentDetails}</ITypography>
          <div className={classes.addButton}>
            <AddButton startIcon={<CancelIcon />} onClick={() => onCancel()}>{Strings.cancel}</AddButton>
          </div>
        </div>
        <div className={classes.container} style={{ marginTop: Styles.margin1Br }}>
          <div style={{ flex: 1 }}>
            <IDivider />
            {booking[Constants.STORE] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.store}:</b></ITypography>
              <div className={classes.containerItemFlex}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }}
                  src={booking[Constants.STORE][Constants.IMAGE] || settings[Constants.DEFAULT_STORE_IMAGE] || ''} alt={booking[Constants.STORE][Constants.NAME]} />
                <div className={classes.containerItemRightFlex}>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.STORE][Constants.NAME]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.address}: <b>{booking[Constants.STORE][Constants.ADDRESS]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{booking[Constants.STORE][Constants.PHONE]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{booking[Constants.STORE][Constants.EMAIL]}</b></ITypography>
                </div>
              </div>
            </div>}
            <IDivider style={{ marginTop: Styles.margin2Br }} />
            {booking[Constants.SERVICE] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.service}:</b></ITypography>
              {isMobile && <><div style={{ display: 'flex' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.SERVICE][Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={booking[Constants.SERVICE][Constants.TITLE]} />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'><b>${booking[Constants.SERVICE][Constants.PRICE]}</b></ITypography>
                </div>
              </div>
                <IIconButton onClick={() => onEditService()}><EditIcon /></IIconButton>
              </>}
              {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.SERVICE][Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={booking[Constants.SERVICE][Constants.TITLE]} />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.price}: <b>${booking[Constants.SERVICE][Constants.PRICE]}</b></ITypography>
                </div>
                <div>
                  <IIconButton onClick={() => onEditService()}><EditIcon /></IIconButton>
                </div>
              </div>}
            </div>}
            <IDivider style={{ marginTop: Styles.margin2Br }} />
            {booking[Constants.EMPLOYEE] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.provider}:</b></ITypography>
              {isMobile && <><div style={{ display: 'flex' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.EMPLOYEE][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={booking[Constants.EMPLOYEE][Constants.FIRST_NAME]} />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.EMPLOYEE][Constants.FIRST_NAME]} {booking[Constants.EMPLOYEE][Constants.LAST_NAME]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.EMPLOYEE][Constants.EMAIL]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.EMPLOYEE][Constants.PHONE]}</b></ITypography>
                </div>
              </div>
                <ProviderButton onClick={() => onEditProvider()}>{Strings.requestTechnician}</ProviderButton>
              </>}
              {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.EMPLOYEE][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={booking[Constants.EMPLOYEE][Constants.FIRST_NAME]} />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.EMPLOYEE][Constants.FIRST_NAME]} {booking[Constants.EMPLOYEE][Constants.LAST_NAME]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{booking[Constants.EMPLOYEE][Constants.EMAIL]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{booking[Constants.EMPLOYEE][Constants.PHONE]}</b></ITypography>
                </div>
                <div>
                  <ProviderButton onClick={() => onEditProvider()}>{Strings.requestTechnician}</ProviderButton>
                </div>
              </div>}
            </div>}
          </div>
          <div className={classes.containerRight} style={{ flex: 1 }}>
            <IDivider />
            {booking[Constants.DATE] && booking[Constants.START] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.dateAndTime}:</b></ITypography>
              {isMobile && <>
                <ITypography className={classes.text} variant='body1' style={{ fontSize: 20 }}><b>{getDateTimeText()}</b></ITypography>
                <IIconButton onClick={() => onEditDate()}><EditIcon /></IIconButton>
              </>}
              {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1' style={{ fontSize: 20 }}><b>{getDateTimeText()}</b></ITypography>
                </div>
                <IIconButton onClick={() => onEditDate()}><EditIcon /></IIconButton>
              </div>}
            </div>}
            <IDivider style={{ marginTop: Styles.margin1Br }} />
            {booking[Constants.SERVICE] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.duration}:</b></ITypography>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1' style={{ fontSize: 20 }}><b>{getDurationText()} {Strings.mins}</b></ITypography>
                </div>
              </div>
            </div>}
            <IDivider style={{ marginTop: Styles.margin1Br }} />
            <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.textReminders}:</b></ITypography>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
                <ITypography className={classes.text}>{Strings.muteAllNotifications}</ITypography>
                <ISwitch checked={notify} value={notify} onChange={event => setNotify(event.target.checked)} />
              </div>
            </div>
          </div>
        </div>
        <IDivider style={{ marginTop: Styles.margin2Br }} />
        <div>
          <ITypography variant='body1'>{Strings.location}:</ITypography>
          <div className={classes.mapBox}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: Constants.GOOGLE_MAP_KEY }}
              defaultCenter={{ lat: Constants.STORE_DEFAULT_LOCATION_LAT, lng: Constants.STORE_DEFAULT_LOCATION_LNG }}
              defaultZoom={Constants.STORE_DEFAULT_ZOOM}
              onGoogleApiLoaded={({ map, maps }) => {
                refMap.current = map
                refMaps.current = maps
                renderMarkers()
              }}
              yesIWantToUseGoogleMapApiInternals
            />
          </div>
        </div>
      </ICardContent>
    </ICard>
    <IDialog open={openCancelDialog} onClose={() => handleCancelDialog(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <IDialogTitle id='alert-dialog-title'>{Strings.cancelAppointment}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText id='alert-dialog-description'>{Strings.cancelAppointmentConfirm}</IDialogContentText>
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleCancelDialog(false)}>{Strings.cancel}</IButton>
        <IButton onClick={() => handleCancelDialog(true)} autoFocus>{Strings.ok}</IButton>
      </IDialogActions>
    </IDialog>
  </div>
}

function CustomerAppointment() {
  Logger.log(Constants.PAGES_CUSTOMER_APPOINTMENT)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default CustomerAppointment