import { IS_DEBUG } from './Constants'

export function log(msg) {
    if (IS_DEBUG && msg) {
        console.log((new Date()).toLocaleString() + ': ' + JSON.stringify(msg))
    }
}

export function logData(tag, key, value = '') {
    if (IS_DEBUG && tag && key) {
        console.log((new Date()).toLocaleString() + ': ' + `${tag} ${key}: ${JSON.stringify(value)}`)
    }
}
