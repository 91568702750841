import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import IImage from '../../controls/IImage'
import PhoneInput from '../../widgets/PhoneInput'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const refTimer = useRef()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [image, setImage] = useState()
  const [src, setSrc] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')


  useEffect(() => {
    if (refTimer.current) {
      clearTimeout(refTimer.current)
    }
  }, [])

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      if (!StringUtils.isValidUploadImage(file.name)) {
        setImage(null)
        setSrc('')
        showToast(props, TOAST_ERROR, Strings.errorWrongFormat + StringUtils.arrayToString(Constants.SUPPORT_IMAGE_TYPES))
        return
      }
      setImage(file)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function (e) {
        setSrc(reader.result)
      }.bind(this)
    }
  }

  const postSave = async () => {
    if (StringUtils.stringEmpty(name) || StringUtils.stringEmpty(description)
      || StringUtils.stringEmpty(category) || StringUtils.stringEmpty(address)
      || StringUtils.stringEmpty(phone) || image == null) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    if (!StringUtils.validatePhone(phone)) {
      showToast(props, TOAST_ERROR, Strings.errorPhoneInvalid)
      return
    }
    setLoading(props, true)
    const id = StringUtils.getUniqueID()
    const ref = firebase.databaseRef(Constants.FB_STORES + '/' + user[Constants.ID] + '/' + id)
    const updates = {}    
    const refStorage = firebase.storageRef(Constants.FB_STORES + '/' + user[Constants.ID] + '/' + id + '/' + image.name)
    const downloadURL = await firebase.uploadFile(refStorage, image, 0, 1, (index, length, bytesTransferred, totalBytes) => {
      //updateProgress(Math.ceil(bytesTransferred / totalBytes * 100))
    })
    if (downloadURL) {
      updates[Constants.IMAGE] = downloadURL
    }    
    updates[Constants.ID] = id
    updates[Constants.USER_ID] = user[Constants.ID]
    updates[Constants.NAME] = name
    updates[Constants.DESCRIPTION] = description
    updates[Constants.CATEGORY] = category
    updates[Constants.ADDRESS] = address
    updates[Constants.PHONE] = phone
    updates[Constants.DATE_MODIFIED] = Date.now()
    updates[Constants.DATE_ADDED] = Date.now()
    updates[Constants.START_TIME] = startTime
    updates[Constants.END_TIME] = endTime
    ref.update(updates).then(() => {
      setLoading(props, false)      
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
      refTimer.current = setTimeout(() => {
        Navigator.navigate(props, Constants.PAGES_SYSTEM_VIEW_STORES)
      }, 1000)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const classes = useStyles()

  return <IFormControl className={classes.root}>
    <ITypography className={classes.heading}>{Strings.addStore}</ITypography>
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin2Br }} label={Strings.name} value={name} onChange={(event) => { setName(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.description} value={description} onChange={(event) => { setDescription(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.category} value={category} onChange={(event) => { setCategory(event.target.value) }} />
    <PhoneInput className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.phone} value={phone} onChange={(event) => { setPhone(event) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.address} value={address} onChange={(event) => { setAddress(event.target.value) }} />
    <div className={`${classes.textField}`} style={{ marginTop: Styles.margin1Br }}>
      <Grid container spacing={3}>
        <Grid item xs={4}><ITypography className='MuiFormLabel-root' style={{marginTop:10}}>{Strings.workingTime}</ITypography></Grid>
        <Grid item xs={4}>
          <ITextField
            type="time"
            defaultValue="00:00"
            style = {{width : 110}}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 900, // 15 min
            }}
            onChange={(event) => { setStartTime(event.target.value) }}
          />
        </Grid>       
        <Grid item xs={4}>
          <ITextField
              type="time"
              defaultValue="00:00"
              style = {{width : 110}}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 900, // 15 min
              }}
              onChange={(event) => { setEndTime(event.target.value) }}
            />
        </Grid>        
      </Grid>
    </div>
    <div className={classes.textField} style={{ marginTop: Styles.margin1Br }}>
      <ITypography className='MuiFormLabel-root'>{Strings.image}:</ITypography>      
    </div>
    <ITextField className={classes.textField} type='file' onChange={handleImageChange} />
    <IImage src={src || settings[Constants.DEFAULT_STORE_IMAGE]} style={{ width: '200px', height: '200px', marginTop: Styles.margin1Br }} />
    <div style={{ marginTop: Styles.margin2Br }}>
      <IButton onClick={() => Navigator.back()}>{Strings.cancel}</IButton>
      <IButton className={classes.rightButton} onClick={postSave}>{Strings.save}</IButton>
    </div>
  </IFormControl>
}

function AddStore() {
  Logger.log(Constants.PAGES_SYSTEM_ADD_STORE)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default AddStore