import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as Constants from '../../../common/Constants'
import * as DataUtils from '../../../common/DataUtils'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITextField from '../../controls/ITextField'
import IFormControl from '../../controls/IFormControl'
import ITypography from '../../controls/ITypography'
import ICard from '../../controls/ICard'
import ICardContent from '../../controls/ICardContent'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: Styles.formMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: Styles.inputWidth
  },
  rightButton: {
    marginLeft: Styles.rightButtonMargin
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const refTimer = useRef()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (refTimer.current) {
      clearTimeout(refTimer.current)
    }
  }, [])

  const postSave = async () => {
    if (StringUtils.stringEmpty(description) || StringUtils.stringEmpty(title)) {
      showToast(props, TOAST_ERROR, Strings.errorInputRequired)
      return
    }
    setLoading(props, true)
    const id = StringUtils.getUniqueID()
    const updates = {}
    const ref = firebase.databaseRef(Constants.FB_STORE_CATEGORIES + '/' + userId + '/' + storeId + '/' + id)
    updates[Constants.ID] = id
    updates[Constants.USER_ID] = userId
    updates[Constants.STORE_ID] = storeId
    updates[Constants.TITLE] = title
    updates[Constants.DESCRIPTION] = description
    updates[Constants.DATE_MODIFIED] = Date.now()
    updates[Constants.DATE_ADDED] = Date.now()
    ref.update(updates).then(() => {
      showToast(props, TOAST_SUCCESS, Strings.savedSuccessfully)
      setLoading(props, false)
      refTimer.current = setTimeout(() => {
        Navigator.navigate(props, Constants.PAGES_STORE_VIEW_CATEGORIES)
      }, 1000)

    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const classes = useStyles()

  return <IFormControl style={{ maxWidth: Styles.formMaxWidth, alignItems: 'center' }}>
    <ITypography className={classes.heading}>{Strings.addCategory}</ITypography>
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin2Br }} label={Strings.title} value={title} onChange={(event) => { setTitle(event.target.value) }} />
    <ITextField className={classes.textField} style={{ marginTop: Styles.margin1Br }} label={Strings.description} value={description} onChange={(event) => { setDescription(event.target.value) }} />
    <div style={{ marginTop: Styles.margin3Br }}>
      <IButton onClick={() => Navigator.back()}>{Strings.cancel}</IButton>
      <IButton className={classes.rightButton} onClick={postSave}>{Strings.save}</IButton>
    </div>
  </IFormControl>
}

function AddCategory() {
  Logger.log(Constants.PAGES_SYSTEM_ADD_CATEGORY)

  let props = createPropsWithActions()
  const classes = useStyles()

  return <ICard className={classes.root}>
    <ICardContent style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentView props={props} />
      <ProgressBar props={props} />
      <ToastView props={props} />
    </ICardContent>
  </ICard>
}

export default AddCategory