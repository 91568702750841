import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as DateUtils from '../../../../common/DateUtils'
import * as ScreenUtils from '../../../../common/ScreenUtils'
import * as Constants from '../../../../common/Constants'
import * as Navigator from '../../../../common/Navigator'
import * as Storage from '../../../../common/Storage'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import IIconButton from '../../../controls/IIconButton'
import IDivider from '../../../controls/IDivider'
import ITypography from '../../../controls/ITypography'
import ICard from '../../../controls/ICard'
import ICardContent from '../../../controls/ICardContent'
import IGridList from '../../../controls/IGridList'
import IGridListTitle from '../../../controls/IGridListTitle'
import IImage from '../../../controls/IImage'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, isLoading, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import moment from 'moment'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '95%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  text: {
    color: '#666666'
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  containerItem: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  containerItemRight: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 10
    }
  },
  containerRight: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 20
    },
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const refId = useRef(StringUtils.getUniqueID()) // id of default first item
  const [booking, setBooking] = useState(Storage.getJson(Constants.BOOKING))
  const [items, setItems] = useState([])

  useEffect(() => {
    const data = Storage.getJson(Constants.BOOKING)
    // populate items with each item: { id, service, employee}. 
    // items will also contains default first item, so need to filter out the default item when using
    let list = []
    const item = {}
    item[Constants.ID] = refId.current
    item[Constants.SERVICE] = data[Constants.SERVICE]
    item[Constants.EMPLOYEE] = user
    list.push(item) // add default 1 item
    if (booking[Constants.ITEMS]) {
      for (let i in booking[Constants.ITEMS]) {
        const item1 = booking[Constants.ITEMS][i]
        list.push(item1)
      }
    }
    setItems(list)
  }, [])

  const getDateTimeText = () => {
    const dateStr = moment(booking[Constants.DATE]).format('dddd, MMMM Do YYYY')
    return dateStr + ', ' + StringUtils.getHourFromNumber(booking[Constants.START])
  }

  const getTotalDuration = () => {
    let total = 0
    if (booking[Constants.SERVICE]) {
      total += StringUtils.getNumber(booking[Constants.SERVICE][Constants.DURATION])
    }
    if (booking[Constants.ITEMS]) {
      for (let i in booking[Constants.ITEMS]) {
        if (booking[Constants.ITEMS][i][Constants.SERVICE]) {
          total += StringUtils.getNumber(booking[Constants.ITEMS][i][Constants.SERVICE][Constants.DURATION])
        }
      }
    }
    return total
  }

  const onEditCustomer = () => {
    Navigator.navigate(props, Constants.PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_CUSTOMERS)
  }

  const onEditService = (item) => {
    let data = {}
    data[Constants.ID] = item[Constants.ID]
    Navigator.navigate(props, Constants.PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_SERVICES, null, data)
  }

  const onDeleteService = (item) => {
    const index = items.findIndex(value => value[Constants.ID] == item[Constants.ID])
    if (index >= 0) {
      items.splice(index, 1)
      const data = Storage.getJson(Constants.BOOKING)
      // filter out default first item
      data[Constants.ITEMS] = items.filter(value => value[Constants.ID] != refId.current)
      Storage.setJson(Constants.BOOKING, data)
      setItems([...items])
    }
  }

  const onAddAnother = () => {
    let item = {}
    item[Constants.ID] = StringUtils.getUniqueID()
    item[Constants.EMPLOYEE] = user
    let list = [...items, item]
    const data = Storage.getJson(Constants.BOOKING)
    // filter out default first item     
    data[Constants.ITEMS] = list.filter(value => value[Constants.ID] != refId.current)
    Storage.setJson(Constants.BOOKING, data)
    setItems(list)
  }

  const onEditDate = () => {
    if (!booking[Constants.USER]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseCustomer)
      return
    }
    for (let i in items) {
      const item = items[i]
      if (!item[Constants.SERVICE]) {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
        return
      }
      if (!item[Constants.EMPLOYEE]) {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseProvider)
        return
      }
    }
    Navigator.navigate(props, Constants.PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_TIMES)
  }

  const onConfirm = async () => {
    if (!booking[Constants.USER]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseCustomer)
      return
    }
    for (let i in items) {
      const item = items[i]
      if (!item[Constants.SERVICE]) {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
        return
      }
      if (!item[Constants.EMPLOYEE]) {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseProvider)
        return
      }
    }
    if (!booking[Constants.DATE]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseDateTime)
      return
    }
    const state = {}
    state[Constants.ITEMS] = items
    state[Constants.USER] = booking[Constants.USER]
    state[Constants.DATE] = booking[Constants.DATE]
    state[Constants.START] = booking[Constants.START]
    Navigator.navigate(props, Constants.PAGES_EMPLOYEE_ADD_APPOINTMENT_SCHEDULE_NOW, null, state)
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return <div className={classes.root}>
    <ICard style={{ minHeight: 500 }}>
      <ICardContent>
        <div style={{ display: 'flex' }}>
          <ITypography variant='h5' style={{ width: '100%' }}>{Strings.appointmentDetails}</ITypography>
        </div>
        <div className={classes.container} style={{ marginTop: Styles.margin1Br }}>
          <div style={{ flex: 1 }}>
            <IDivider />
            {booking[Constants.USER] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.customer}:</b></ITypography>
              {isMobile && <div>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.USER][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={booking[Constants.USER][Constants.FIRST_NAME]} />
                <ITypography className={classes.text} variant='body1'><b>{booking[Constants.USER][Constants.FIRST_NAME]} {booking[Constants.USER][Constants.LAST_NAME]}</b></ITypography>
                <ITypography className={classes.text} variant='body1'>{Strings.address}: <b>{booking[Constants.USER][Constants.ADDRESS]}</b></ITypography>
                <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{booking[Constants.USER][Constants.PHONE]}</b></ITypography>
                <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{booking[Constants.USER][Constants.EMAIL]}</b></ITypography>
                <IIconButton onClick={() => onEditCustomer()}><EditIcon /></IIconButton>
              </div>}
              {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.USER][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={booking[Constants.USER][Constants.FIRST_NAME]} />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.USER][Constants.FIRST_NAME]} {booking[Constants.USER][Constants.LAST_NAME]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.address}: <b>{booking[Constants.USER][Constants.ADDRESS]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{booking[Constants.USER][Constants.PHONE]}</b></ITypography>
                  <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{booking[Constants.USER][Constants.EMAIL]}</b></ITypography>
                </div>
                <div>
                  <IIconButton onClick={() => onEditCustomer()}><EditIcon /></IIconButton>
                </div>
              </div>}
            </div>}
            {!booking[Constants.USER] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.customer}:</b></ITypography>
              <div style={{ display: 'flex', width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={settings[Constants.DEFAULT_AVATAR] || ''} alt='user' />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseCustomer}</ITypography>
                </div>
                <div>
                  <IIconButton onClick={() => onEditCustomer()}><EditIcon /></IIconButton>
                </div>
              </div>
            </div>}
          </div>
          <div style={{ flex: 1 }}>
            <IDivider />
            {booking[Constants.SERVICE] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{items.length > 1 ? Strings.totalDuration : Strings.duration}:</b></ITypography>
              <div style={{ marginLeft: 10, width: '100%' }}>
                <ITypography className={classes.text} variant='body1' style={{ fontSize: 22 }}><b>{getTotalDuration()} {Strings.mins}</b></ITypography>
              </div>
            </div>}
          </div>
        </div>
        <IGridList cellHeight='auto' cols={isMobile ? 1 : 2} spacing={0} className={classes.gridList}>
          {items.map((item, index) => (
            <IGridListTitle key={item[Constants.ID]} classes={{ tile: classes.box }}>
              <IDivider style={{ marginLeft: 0, marginRight: 0, marginTop: Styles.margin1Br }} />
              {item[Constants.SERVICE] && <div style={{ margin: 10 }}>
                <ITypography variant='body1'><b>{Strings.service}:</b></ITypography>
                {isMobile && <>
                  <div style={{ display: 'flex' }}>
                    <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.SERVICE][Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.SERVICE][Constants.TITLE]} />
                    <div style={{ marginLeft: 10 }}>
                      <ITypography className={classes.text} variant='body1'><b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                      <ITypography className={classes.text} variant='body1'>{Strings.price}: <b>${item[Constants.SERVICE][Constants.PRICE]}</b></ITypography>
                      {items.length > 1 && <ITypography className={classes.text} variant='body1'>{Strings.duration}: <b>{item[Constants.SERVICE][Constants.DURATION]} {Strings.mins}</b></ITypography>}
                    </div>
                  </div>
                  {index != 0 && <IIconButton onClick={() => onDeleteService(item)}><CancelIcon /></IIconButton>}
                  <IIconButton onClick={() => onEditService(item)}><EditIcon /></IIconButton>
                </>}
                {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                  <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.SERVICE][Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.SERVICE][Constants.TITLE]} />
                  <div style={{ marginLeft: 10, width: '100%' }}>
                    <ITypography className={classes.text} variant='body1'><b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                    <ITypography className={classes.text} variant='body1'>{Strings.price}: <b>${item[Constants.SERVICE][Constants.PRICE]}</b></ITypography>
                    {items.length > 1 && <ITypography className={classes.text} variant='body1'>{Strings.duration}: <b>{item[Constants.SERVICE][Constants.DURATION]} {Strings.mins}</b></ITypography>}
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    {index != 0 && <IIconButton onClick={() => onDeleteService(item)}><CancelIcon /></IIconButton>}
                    <IIconButton onClick={() => onEditService(item)}><EditIcon /></IIconButton>
                  </div>
                </div>}
              </div>}
              {!item[Constants.SERVICE] && <div style={{ margin: 10 }}>
                <ITypography variant='body1'><b>{Strings.service}:</b></ITypography>
                <div style={{ display: 'flex', width: '100%' }}>
                  <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt='service' />
                  <div style={{ marginLeft: 10, width: '100%' }}>
                    <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseService}</ITypography>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    {index != 0 && <IIconButton onClick={() => onDeleteService(item)}><CancelIcon /></IIconButton>}
                    <IIconButton onClick={() => onEditService(item)}><EditIcon /></IIconButton>
                  </div>
                </div>
              </div>}
            </IGridListTitle>
          ))}
        </IGridList>
        <div style={{ display: 'flex', marginTop: Styles.margin1Br }}>
          <AddButton onClick={() => onAddAnother()}>{Strings.addAnother}</AddButton>
        </div>
        <div style={{ width: isMobile ? '100%' : '50%', marginTop: Styles.margin1Br }}>
          <IDivider />
          {booking[Constants.DATE] && booking[Constants.START] && <div style={{ margin: 10 }}>            
            <ITypography variant='body1' style={{ marginTop: Styles.margin1Br }}><b>{Strings.dateAndTime}:</b></ITypography>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ marginLeft: 10, width: '100%' }}>
                <ITypography className={classes.text} variant='body1' style={{ fontSize: 20 }}><b>{getDateTimeText()}</b></ITypography>
              </div>
              <div>
                <IIconButton onClick={() => onEditDate()}><EditIcon /></IIconButton>
              </div>
            </div>            
          </div>}
          {(!booking[Constants.DATE] || !booking[Constants.START]) && <div style={{ margin: 10 }}>
            <ITypography variant='body1' style={{ marginTop: Styles.margin1Br }}><b>{Strings.dateAndTime}:</b></ITypography>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ marginLeft: 10, width: '100%' }}>
                <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseDateTime}</ITypography>
              </div>
              <div>
                <IIconButton onClick={() => onEditDate()}><EditIcon /></IIconButton>
              </div>
            </div>
          </div>}
          <IDivider style={{ marginTop: Styles.margin1Br }} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: Styles.margin1Br }}>
          <IButton onClick={() => onConfirm()}>{Strings.confirm}</IButton>
        </div>
      </ICardContent>
    </ICard>
  </div>
}

function StoreAppointment() {
  Logger.log(Constants.PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_APPOINTMENT)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default StoreAppointment