import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../../utils/useGlobal'
import * as firebase from '../../../../utils/firebase'
import * as Logger from '../../../../common/Logger'
import * as StringUtils from '../../../../common/StringUtils'
import * as ScreenUtils from '../../../../common/ScreenUtils'
import * as DateUtils from '../../../../common/DateUtils'
import * as DataUtils from '../../../../common/DataUtils'
import * as Constants from '../../../../common/Constants'
import * as Navigator from '../../../../common/Navigator'
import * as Storage from '../../../../common/Storage'
import ProgressBar from '../../../widgets/ProgressBar'
import Strings from '../../../../common/Strings'
import IButton from '../../../controls/IButton'
import IIconButton from '../../../controls/IIconButton'
import IDivider from '../../../controls/IDivider'
import ITypography from '../../../controls/ITypography'
import ICard from '../../../controls/ICard'
import ICardContent from '../../../controls/ICardContent'
import IGridList from '../../../controls/IGridList'
import IGridListTitle from '../../../controls/IGridListTitle'
import IDialog from '../../../controls/IDialog'
import IDialogActions from '../../../controls/IDialogActions'
import IDialogContent from '../../../controls/IDialogContent'
import IDialogContentText from '../../../controls/IDialogContentText'
import IDialogTitle from '../../../controls/IDialogTitle'
import IRadioGroup from '../../../controls/IRadioGroup'
import IRadio from '../../../controls/IRadio'
import IImage from '../../../controls/IImage'
import ICircularProgress from '../../../controls/ICircularProgress'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../../widgets/ToastView'
import { createPropsWithActions, isLoading, setLoading, showToast } from '../../../../common/ViewUtils'
import Styles from '../../../../common/Styles'
import moment from 'moment'
import ColorPicker from '../../../widgets/ColorPicker'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const ProviderButton = styled(AddButton)({
  fontSize: 14,
  padding: '0 5px',
  width: 170,
  textTransform: 'none',
  marginRight: 20
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '95%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  text: {
    color: '#666666'
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  box: {
    borderRadius: 5,
    border: 'solid 1px #e0e0e0',
    padding: 10,
    marginBottom: 20,
  },
  boxLeft: {
    borderRadius: 5,
    border: 'solid 1px #e0e0e0',
    padding: 10,
    marginRight: 10,
    marginBottom: 20,
  },
  boxRight: {
    borderRadius: 5,
    border: 'solid 1px #e0e0e0',
    padding: 10,
    marginLeft: 10,
    marginBottom: 20,
  },
  divColor: {
    width: 50,
    height: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const refId = useRef(StringUtils.getUniqueID()) // id of default first item
  const [booking, setBooking] = useState(Storage.getJson(Constants.BOOKING))
  const [items, setItems] = useState([])
  const [openProviderDialog, setOpenProviderDialog] = useState(false)
  const [isChecking, setIsChecking] = useState(false)
  const refTimer = useRef()

  // Store data
  const refStore = useRef()
  const refStoreBookings = useRef();
  const refUserBookings = useRef([]);
  const refEmployees = useRef();

  useEffect(() => {
    let hasChange = false
    const data = Storage.getJson(Constants.BOOKING)

    refId.current = data[Constants.ID] || StringUtils.getUniqueID()
    refUserBookings.current = Storage.getJson(Constants.CACHE_USER_BOOKINGS, false, [])

    // check if time is changed
    if (data[Constants.DATE]) {
      const date = Storage.getString(Constants.CACHE_BOOK_TIME, false)
      if (date && date != (data[Constants.DATE] + data[Constants.START])) {
        data[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
        data[Constants.ITEMS] = null
        hasChange = true
        Storage.clearKey(Constants.CACHE_STORE_BOOKINGS)
        loadStoreData()
      }
      Storage.setItemToMemory(Constants.CACHE_BOOK_TIME, data[Constants.DATE] + data[Constants.START])
    }

    // check if service is available for employee
    const service = data[Constants.SERVICE]
    const employee = data[Constants.EMPLOYEE]
    if (employee && service && employee[Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
      const employeeIds = service[Constants.EMPLOYEE_IDS] || []
      if (employeeIds.indexOf(employee[Constants.ID]) < 0) {
        data[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
        hasChange = true
      }
    }

    // populate items with each item: { id, service, employee}. 
    // items will also contains default first item, so need to filter out the default item when using
    let list = []
    const item = {}
    item[Constants.ID] = refId.current
    item[Constants.SERVICE] = data[Constants.SERVICE]
    item[Constants.EMPLOYEE] = data[Constants.EMPLOYEE]
    item[Constants.DATE] = data[Constants.DATE]
    item[Constants.START] = data[Constants.START]
    item[Constants.START_INIT] = data[Constants.START]
    item[Constants.COLOR] = data[Constants.COLOR]
    list.push(item) // add default 1 item
    if (data[Constants.ITEMS]) {
      for (let i in data[Constants.ITEMS]) {
        const item1 = data[Constants.ITEMS][i]
        const service1 = item1[Constants.SERVICE]
        const employee1 = item1[Constants.EMPLOYEE]
        if (employee1 && service1 && employee1[Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
          const employeeIds = service1[Constants.EMPLOYEE_IDS] || []
          if (employeeIds.indexOf(employee1[Constants.ID]) < 0) {
            item1[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
            hasChange = true
          }
        }
        list.push(item1)
      }
    }

    // save update
    if (hasChange) {
      setBooking({ ...data })
      Storage.setJson(Constants.BOOKING, data)
    }

    setItems(list)
    loadStoreData()

    // show select provider after selecting service
    const cacheService = Storage.getJson(Constants.CACHE_SELECT_SERVICE, false)
    if (cacheService) {
      setOpenProviderDialog(true)
    }

    return () => {
      if (refTimer.current) {
        clearTimeout(refTimer.current)
      }
    }
  }, [])

  const validateLoadData = () => {
    if (!refStore.current || !refStoreBookings.current || !refEmployees.current) {
      return
    }
    setLoading(props, false)
  }

  const loadStore = async () => {
    const storeId = user[Constants.ID]
    const userId = user[Constants.ID]
    const ref = firebase.databaseRef(Constants.FB_STORES + '/' + userId + '/' + storeId)
    const responseStore = await ref.once('value')
    if (responseStore) {
      const val = responseStore.val()
      if (val) {
        refStore.current = val
        Storage.setItemToMemory(Constants.CACHE_STORE_INFO, val)
      }
    }
    validateLoadData()
  }

  const loadBookings = async () => {
    const storeId = user[Constants.ID]
    const userId = user[Constants.ID]
    const date = booking[Constants.DATE] ? moment(booking[Constants.DATE]) : moment()
    const year = date.year()
    const month = date.month() + 1
    const path = `${year}/${month}`
    const refBookings = firebase.databaseRef(Constants.FB_STORE_BOOKINGS + '/' + userId + '/' + storeId + '/' + path)
    const response = await refBookings.once('value')
    const list = []
    if (response) {
      const val = response.val()
      if (val) {
        for (let i in val) { // day
          const item1 = val[i]
          for (let j in item1) { // id
            const item2 = item1[j]
            list.push(item2)
          }
        }
      }
    }
    refStoreBookings.current = list
    Storage.setItemToMemory(Constants.CACHE_STORE_BOOKINGS, list)
    validateLoadData()
  }

  const loadEmployees = async () => {
    const storeId = user[Constants.ID]
    const userId = user[Constants.ID]
    const refEmployeesResponse = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId)
    const response = await refEmployeesResponse.once('value')
    const list = []
    if (response) {
      const val = response.val()
      if (val) {
        for (let i in val) {
          const employee = val[i]
          list.push(employee)
        }
      }
      DataUtils.sortBy(list, Constants.DATE_ADDED)
    }
    refEmployees.current = list
    Storage.setItemToMemory(Constants.CACHE_STORE_EMPLOYEES, list)
    validateLoadData()
  }

  const loadStoreData = async () => {
    const store = Storage.getJson(Constants.CACHE_STORE_INFO, false)
    const bookings = Storage.getJson(Constants.CACHE_STORE_BOOKINGS, false)
    const employees = Storage.getJson(Constants.CACHE_STORE_EMPLOYEES, false)
    if (store && bookings && employees) {
      refStore.current = store
      refStoreBookings.current = bookings
      refEmployees.current = employees
      validateLoadData()
    } else {
      setLoading(props, true)
      loadStore()
      loadBookings()
      loadEmployees()
    }
  }

  const getDateTimeText = () => {
    const dateStr = moment(booking[Constants.DATE]).format('dddd, MMMM Do YYYY')
    return dateStr + ', ' + StringUtils.getHourFromNumber(booking[Constants.START])
  }

  const getDateTimeItemText = (item) => {
    return StringUtils.getHourFromNumber(item[Constants.START])
  }

  const getTotalDuration = () => {
    let total = 0
    if (booking[Constants.SERVICE]) {
      total += StringUtils.getNumber(booking[Constants.SERVICE][Constants.DURATION])
    }
    if (booking[Constants.ITEMS]) {
      for (let i in booking[Constants.ITEMS]) {
        if (booking[Constants.ITEMS][i][Constants.SERVICE]) {
          total += StringUtils.getNumber(booking[Constants.ITEMS][i][Constants.SERVICE][Constants.DURATION])
        }
      }
    }
    return total
  }

  const onEditCustomer = () => {
    Navigator.navigate(props, Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_CUSTOMERS)
  }

  const onEditService = (item) => {
    let data = {}
    data[Constants.ID] = item[Constants.ID]
    Navigator.navigate(props, Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_SERVICES, null, data)
  }

  const onEditProvider = (item) => {
    if (item[Constants.SERVICE]) {
      let data = {}
      data[Constants.ID] = item[Constants.ID]
      Navigator.navigate(props, Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_EMPLOYEES, null, data)
    } else {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
    }
  }

  const onEditDate = () => {
    if (booking[Constants.EMPLOYEE] && booking[Constants.EMPLOYEE][Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
      Navigator.navigate(props, Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_TIMES)
    } else {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseProvider)
    }
  }

  const onDeleteService = (item) => {
    const index = items.findIndex(value => value[Constants.ID] == item[Constants.ID])
    if (index >= 0) {
      items.splice(index, 1)
      const data = Storage.getJson(Constants.BOOKING)
      // filter out default first item
      data[Constants.ITEMS] = items.filter(value => value[Constants.ID] != refId.current)
      Storage.setJson(Constants.BOOKING, data)
      setItems([...items])
    }
  }

  const onAddAnother = () => {
    let item = {}
    item[Constants.ID] = StringUtils.getUniqueID()
    let list = [...items, item]
    const data = Storage.getJson(Constants.BOOKING)
    // filter out default first item     
    data[Constants.ITEMS] = list.filter(value => value[Constants.ID] != refId.current)
    Storage.setJson(Constants.BOOKING, data)
    setItems(list)
  }

  const onAddAnotherService = () => {
    if (!booking[Constants.USER]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseCustomer)
      return
    }
    for (let i in items) {
      const item = items[i]
      if (!item[Constants.SERVICE]) {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
        return
      }
      if (!item[Constants.EMPLOYEE] || item[Constants.EMPLOYEE][Constants.ID] == Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseProvider)
        return
      }
    }
    if (!booking[Constants.DATE]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseDateTime)
      return
    }
    Navigator.navigate(props, Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_SERVICES, null, { isSelectService: true })
  }

  const onConfirm = async () => {
    if (!booking[Constants.USER]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseCustomer)
      return
    }
    for (let i in items) {
      const item = items[i]
      if (!item[Constants.SERVICE]) {
        showToast(props, TOAST_ERROR, Strings.pleaseChooseService)
        return
      }
      if (!item[Constants.EMPLOYEE] || item[Constants.EMPLOYEE][Constants.ID] == Constants.EMPLOYEE_ANY_ONE_AVAILABLE) {
        showToast(props, TOAST_ERROR, Strings.mustSetProviderBeforeSaving)
        return
      }
    }
    if (!booking[Constants.DATE]) {
      showToast(props, TOAST_ERROR, Strings.pleaseChooseDateTime)
      return
    }

    // const state = {}
    // state[Constants.ITEMS] = items
    // state[Constants.USER] = booking[Constants.USER]
    // state[Constants.DATE] = booking[Constants.DATE]
    // state[Constants.START] = booking[Constants.START]
    // Navigator.navigate(props, Constants.PAGES_STORE_ADD_APPOINTMENT_SCHEDULE_NOW, null, state)
    setLoading(props, true)
    const storeId = refStore.current[Constants.ID]
    const userId = refStore.current[Constants.USER_ID]
    let success = false
    for (let i in items) {
      const item = items[i]
      const date = moment(item[Constants.DATE])
      const year = date.year()
      const month = date.month() + 1 // month is zero index
      const day = date.date()
      const customer = booking[Constants.USER]
      const employee = item[Constants.EMPLOYEE_AUTO] || item[Constants.EMPLOYEE]
      const service = item[Constants.SERVICE]
      const duration = StringUtils.getNumber(service[Constants.DURATION]) / 60 // in hour  

      const subject = 'Appointment was created'
      const message = `Appointment was created on ${date.format('MMMM Do YYYY')}, ${getDateTimeItemText(item)}.<br/>
        Store: ${refStore.current[Constants.NAME]}.<br/>  
        Service: ${service[Constants.TITLE]}.<br/>
        Customer Name: ${customer[Constants.FIRST_NAME]} ${customer[Constants.LAST_NAME]}.<br/>
        Customer Email: ${customer[Constants.EMAIL]}.<br/>
        Provider Name: ${employee[Constants.FIRST_NAME]} ${employee[Constants.LAST_NAME]}.<br/>
        Provider Email: ${employee[Constants.EMAIL]}.
        `
      const smsPhone = Constants.SMS_DEFAULT_PHONE
      const smsText = `Appointment was created on ${date.format('MMMM Do YYYY')}, ${getDateTimeItemText(item)}.
        Store: ${refStore.current[Constants.NAME]}.  
        Service: ${service[Constants.TITLE]}.
        Customer: ${customer[Constants.FIRST_NAME]} ${customer[Constants.LAST_NAME]}.
        Provider: ${employee[Constants.FIRST_NAME]} ${employee[Constants.LAST_NAME]}.
        `
      const updates = {}
      updates[Constants.STORE] = firebase.storeForBooking(refStore.current)
      updates[Constants.SERVICE] = firebase.serviceForBooking(service)
      updates[Constants.EMPLOYEE] = firebase.employeeForBooking(employee)
      updates[Constants.USER] = firebase.userForBooking(customer)
      updates[Constants.COLOR] = item[Constants.COLOR]
      updates[Constants.DATE] = date.toISOString()
      updates[Constants.START] = item[Constants.START]
      updates[Constants.END] = item[Constants.START] + duration
      updates[Constants.DATE_MODIFIED] = Date.now()
      updates[Constants.DATE_ADDED] = Date.now()
      updates[Constants.TIME_ZOME] = moment.tz.guess()
      try {
        const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_STORE_ADD_BOOKING)
        await httpsCallable({ userId: customer[Constants.ID], storeId, year, month, day, updates, subject, message, smsPhone, smsText })
        success = true
      } catch (err) {
        Logger.log(err)
      }
    }
    setLoading(props, false)
    if (success) {
      Storage.clearKey(Constants.CACHE_STORE_INFO)
      Storage.clearKey(Constants.CACHE_STORE_BOOKINGS)
      Storage.clearKey(Constants.CACHE_STORE_EMPLOYEES)
      Storage.clearKey(Constants.CACHE_STORE_SERVCIES)
      showToast(props, TOAST_SUCCESS, Strings.bookedSuccessfully)
      refTimer.current = setTimeout(() => {
        Navigator.navigate(props, Constants.PAGES_STORE_APPOINTMENTS)
      }, 1000)
    } else {
      showToast(props, TOAST_ERROR, Strings.errorLoadingData)
    }
  }

  const checkProviderAvailableTime = (item) => {
    const id = item[Constants.ID]
    const date = moment(item[Constants.DATE])
    const qStart = item[Constants.START]
    const service = item[Constants.SERVICE]
    const duration = StringUtils.getNumber(service[Constants.DURATION]) / 60 // in hour  
    const employee = item[Constants.EMPLOYEE]
    let available = null
    // step 1: get all working hours
    const list = firebase.getWorkingPeriodPairs(employee, date)
    if (list.length == 0) {
      return available
    }
    // step 2: set start to selected time if it is available
    if (qStart && qStart > 0) {
      for (let i in list) {
        const item1 = list[i]
        if (qStart >= item1[Constants.START] && qStart <= item1[Constants.END]) {
          item1[Constants.START] = qStart
          break
        }
      }
    }
    // step 3: calculate available times    
    const timesPair = firebase.calculateTimesPair(list)
    const availableTimes = firebase.calculateAvailableTimes(id, date, duration, employee, timesPair, refStoreBookings.current, refUserBookings.current)

    let last = -1
    // find the lowest available time that bigger or equal to selected time
    for (let i in availableTimes) {
      let item1 = availableTimes[i]
      if (item1[Constants.START] >= qStart) {
        if (last == -1 || item1[Constants.START] < last) {
          last = item1[Constants.START]
          available = item1
        }
      }
    }

    return available
  }

  const handleProviderDialog = async (ok) => {
    let value = ok
    const cacheService = Storage.getJson(Constants.CACHE_SELECT_SERVICE, false)
    const service = cacheService || items[items.length - 1][Constants.SERVICE]
    // check if employee can do the service
    if (ok) {
      const employee = items[items.length - 1][Constants.EMPLOYEE]
      const employeeIds = service[Constants.EMPLOYEE_IDS] || []
      if (employeeIds.indexOf(employee[Constants.ID]) == -1) {
        value = false
      }
    }
    let item = {}
    item[Constants.ID] = StringUtils.getUniqueID()
    item[Constants.SERVICE] = service
    if (value) {
      item[Constants.EMPLOYEE] = items[items.length - 1][Constants.EMPLOYEE]
    } else {
      item[Constants.EMPLOYEE] = firebase.noPreferenceEmployee(Strings.pleaseChooseProvider, settings[Constants.DEFAULT_AVATAR])
    }
    item[Constants.DATE] = booking[Constants.DATE]
    item[Constants.START] = booking[Constants.START]
    // start time, which will not be changed by selecting employee mutilple times
    item[Constants.START_INIT] = booking[Constants.START]

    // increase the time for same employee
    for (let i in items) {
      const item1 = items[i]
      if (item1[Constants.EMPLOYEE][Constants.ID] == item[Constants.EMPLOYEE][Constants.ID]) {
        // next time will start with previous service's duration
        let duration = StringUtils.getNumber(item1[Constants.SERVICE][Constants.DURATION]) / 60 // in hour
        let next = item1[Constants.START] + duration
        item[Constants.START] = next
        // item[Constants.START_INIT] = next
      }
    }

    // check available time
    if (value) {
      const available = checkProviderAvailableTime(item)
      if (available) {
        item[Constants.START] = available[Constants.START]
        // item[Constants.START_INIT] = available[Constants.START]
      } else {
        showToast(props, TOAST_ERROR, Strings.noAvailableTime)
        return
      }
    }

    let list = [...items, item]
    const data = Storage.getJson(Constants.BOOKING)
    // filter out default first item     
    data[Constants.ITEMS] = list.filter(value => value[Constants.ID] != refId.current)
    Storage.setJson(Constants.BOOKING, data)
    Storage.clearKey(Constants.CACHE_SELECT_SERVICE)
    setItems(list)
    setOpenProviderDialog(false)
    if (!value && !ok) {
      let passData = {}
      passData[Constants.ID] = item[Constants.ID]
      Navigator.navigate(props, Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_EMPLOYEES, null, passData)
    }
    if (!value && ok) {
      showToast(props, TOAST_ERROR, Strings.noAvailableTime)
    }
  }

  const getPreviousProvider = () => {
    if (items.length == 0) {
      return ''
    }
    const firtName = items[items.length - 1][Constants.EMPLOYEE][Constants.FIRST_NAME]
    const lastName = items[items.length - 1][Constants.EMPLOYEE][Constants.LAST_NAME]
    return `${firtName}${StringUtils.stringEmpty(lastName) ? '' : ' '}${lastName}`
  }

  const openColorPicker = (e, item) => {
    if (props.setColorOpen) {
      let top = 0, left = 0
      if (e.target) {
        const rect = e.target.getBoundingClientRect()
        top = rect.top + 20
        left = rect.left - 100
      }
      props.setColorOpen(true, item[Constants.COLOR] || item[Constants.SERVICE][Constants.COLOR], item[Constants.ID], { top, left })
    }
  }

  props.onColorClosed = (color, id) => {
    const data = Storage.getJson(Constants.BOOKING)
    if (id == refId.current) {
      data[Constants.COLOR] = color
    }
    for (let i in items) {
      if (items[i][Constants.ID] == id) {
        items[i][Constants.COLOR] = color
        setItems([...items])
        // filter out default first item
        data[Constants.ITEMS] = items.filter(value => value[Constants.ID] != refId.current)
        break
      }
    }
    Storage.setJson(Constants.BOOKING, data)
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()
  const cols = isMobile ? 1 : 2

  return <div className={classes.root}>
    <ICard style={{ minHeight: 500 }}>
      <ICardContent>
        <div style={{ display: 'flex' }}>
          <ITypography variant='h5' style={{ width: '100%' }}>Create Appointment</ITypography>
        </div>
        <IDivider style={{ marginTop: 10, marginBottom: 10, backgroundColor: '#bbbbbb' }} />
        <div className={classes.container} style={{ marginTop: Styles.margin1Br }}>
          <div style={{ flex: 1 }}>
            {booking[Constants.USER] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.customer}:</b></ITypography>
              {isMobile && <div>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.USER][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={booking[Constants.USER][Constants.FIRST_NAME]} />
                <ITypography className={classes.text} variant='body1'><b>{booking[Constants.USER][Constants.FIRST_NAME]} {booking[Constants.USER][Constants.LAST_NAME]}</b></ITypography>
                {booking[Constants.USER][Constants.ADDRESS] && <ITypography className={classes.text} variant='body1'>{Strings.address}: <b>{booking[Constants.USER][Constants.ADDRESS]}</b></ITypography>}
                <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{booking[Constants.USER][Constants.PHONE]}</b></ITypography>
                {booking[Constants.USER][Constants.EMAIL] && <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{booking[Constants.USER][Constants.EMAIL]}</b></ITypography>}
                <IIconButton onClick={() => onEditCustomer()}><EditIcon /></IIconButton>
              </div>}
              {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={booking[Constants.USER][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={booking[Constants.USER][Constants.FIRST_NAME]} />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'><b>{booking[Constants.USER][Constants.FIRST_NAME]} {booking[Constants.USER][Constants.LAST_NAME]}</b></ITypography>
                  {booking[Constants.USER][Constants.ADDRESS] && <ITypography className={classes.text} variant='body1'>{Strings.address}: <b>{booking[Constants.USER][Constants.ADDRESS]}</b></ITypography>}
                  <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{booking[Constants.USER][Constants.PHONE]}</b></ITypography>
                  {booking[Constants.USER][Constants.EMAIL] && <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{booking[Constants.USER][Constants.EMAIL]}</b></ITypography>}
                </div>
                <div>
                  <IIconButton onClick={() => onEditCustomer()}><EditIcon /></IIconButton>
                </div>
              </div>}
            </div>}
            {!booking[Constants.USER] && <div style={{ margin: 10 }}>
              <ITypography variant='body1'><b>{Strings.customer}:</b></ITypography>
              <div style={{ display: 'flex', width: '100%' }}>
                <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={settings[Constants.DEFAULT_AVATAR] || ''} alt='user' />
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseCustomer}</ITypography>
                </div>
                <div>
                  <IIconButton onClick={() => onEditCustomer()}><EditIcon /></IIconButton>
                </div>
              </div>
            </div>}
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ width: '100%' }}>
              {isMobile && <IDivider style={{ marginTop: Styles.margin1Br }} />}
              {booking[Constants.DATE] && booking[Constants.START] && <div style={{ margin: 10 }}>
                <ITypography variant='body1' style={{ marginTop: Styles.margin1Br }}><b>{Strings.dateAndTime}:</b></ITypography>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ marginLeft: 10, width: '100%' }}>
                    <ITypography className={classes.text} variant='body1' style={{ fontSize: 20 }}><b>{getDateTimeText()}</b></ITypography>
                  </div>
                  <div>
                    <IIconButton onClick={() => onEditDate()}><EditIcon /></IIconButton>
                  </div>
                </div>
              </div>}
              {(!booking[Constants.DATE] || !booking[Constants.START]) && <div style={{ margin: 10 }}>
                <ITypography variant='body1' style={{ marginTop: Styles.margin1Br }}><b>{Strings.dateAndTime}:</b></ITypography>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ marginLeft: 10, width: '100%' }}>
                    <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseDateTime}</ITypography>
                  </div>
                  <div>
                    <IIconButton onClick={() => onEditDate()}><EditIcon /></IIconButton>
                  </div>
                </div>
              </div>}
            </div>
            {booking[Constants.SERVICE] && <>
              <div style={{ margin: 10 }}>
                <ITypography variant='body1'><b>{items.length > 1 ? Strings.totalDuration : Strings.duration}:</b></ITypography>
                <div style={{ marginLeft: 10, width: '100%' }}>
                  <ITypography className={classes.text} variant='body1' style={{ fontSize: 22 }}><b>{getTotalDuration()} {Strings.mins}</b></ITypography>
                </div>
              </div>
            </>}
          </div>
        </div>
        <IDivider style={{ marginTop: 10, marginBottom: 10, backgroundColor: '#bbbbbb' }} />
        <ITypography variant='body1' style={{ width: '100%', marginTop: 20, marginBottom: 10, fontSize: 22 }}>{'Services & Providers'}</ITypography>
        <IGridList cellHeight='auto' cols={cols} spacing={0} className={classes.gridList}>
          {items.map((item, index) => (
            <IGridListTitle key={item[Constants.ID]}>
              <div className={isMobile ? classes.box : (index % 2 === 0 ? classes.boxLeft : classes.boxRight)}>
                {item[Constants.SERVICE] && <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ITypography variant='body1'><b>{Strings.service + ' ' + (index + 1)}:</b></ITypography>
                    <ITypography variant='body2' style={{ marginLeft: 20 }}><b>Start Time:</b></ITypography>
                    <ITypography variant='body2' style={{ marginLeft: 5 }}>{getDateTimeItemText(item)}</ITypography>
                    <div style={{ marginLeft: 'auto', width: 40, height: 40, textAlign: 'center' }}>
                      {index != 0 && index == items.length - 1 && <IIconButton onClick={() => onDeleteService(item)}><CancelIcon /></IIconButton>}
                    </div>
                  </div>
                  {isMobile && <>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.SERVICE][Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.SERVICE][Constants.TITLE]} />
                      <div style={{ marginLeft: 10, width: '100%' }}>
                        <ITypography className={classes.text} variant='body1'><b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                        <ITypography className={classes.text} variant='body1'>{Strings.price}: <b>${item[Constants.SERVICE][Constants.PRICE]}</b></ITypography>
                        <ITypography className={classes.text} variant='body1'>{Strings.duration}: <b>{item[Constants.SERVICE][Constants.DURATION]} {Strings.mins}</b></ITypography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ITypography className={classes.text} variant='body1'>{Strings.color}:</ITypography>
                          <div className={classes.divColor} style={{ backgroundColor: item[Constants.COLOR] || item[Constants.SERVICE][Constants.COLOR], marginLeft: 10 }} onClick={(e) => openColorPicker(e, item)} />
                          <div>
                            <ColorPicker props={props} id={item[Constants.ID]} style={{ position: 'fixed' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    {index == items.length - 1 && <div style={{ display: 'flex' }}>
                      <IIconButton onClick={() => onEditService(item)}><EditIcon /></IIconButton>
                    </div>}
                  </>}
                  {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                    <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.SERVICE][Constants.IMAGE] || settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt={item[Constants.SERVICE][Constants.TITLE]} />
                    <div style={{ marginLeft: 10, width: '100%' }}>
                      <ITypography className={classes.text} variant='body1'><b>{item[Constants.SERVICE][Constants.TITLE]}</b></ITypography>
                      <ITypography className={classes.text} variant='body1'>{Strings.price}: <b>${item[Constants.SERVICE][Constants.PRICE]}</b></ITypography>
                      <ITypography className={classes.text} variant='body1'>{Strings.duration}: <b>{item[Constants.SERVICE][Constants.DURATION]} {Strings.mins}</b></ITypography>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ITypography className={classes.text} variant='body1'>{Strings.color}:</ITypography>
                        <div className={classes.divColor} style={{ backgroundColor: item[Constants.COLOR] || item[Constants.SERVICE][Constants.COLOR], marginLeft: 10 }} onClick={(e) => openColorPicker(e, item)} />
                        <div>
                          <ColorPicker props={props} id={item[Constants.ID]} style={{ position: 'fixed' }} />
                        </div>
                      </div>
                    </div>
                    {index == items.length - 1 && <div style={{ textAlign: 'right' }}>
                      <IIconButton onClick={() => onEditService(item)}><EditIcon /></IIconButton>
                    </div>}
                  </div>}
                </div>}
                {!item[Constants.SERVICE] && <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ITypography variant='body1'><b>{Strings.service + ' ' + (index + 1)}:</b></ITypography>
                    <ITypography variant='body2' style={{ marginLeft: 20 }}><b>Start Time:</b></ITypography>
                    <ITypography variant='body2' style={{ marginLeft: 5 }}>{getDateTimeItemText(item)}</ITypography>
                    <div style={{ marginLeft: 'auto', width: 40, height: 40, textAlign: 'center' }}>
                      {index != 0 && index == items.length - 1 && <IIconButton onClick={() => onDeleteService(item)}><CancelIcon /></IIconButton>}
                    </div>
                  </div>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={settings[Constants.DEFAULT_SERVICE_IMAGE] || ''} alt='service' />
                    <div style={{ marginLeft: 10, width: '100%' }}>
                      <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseService}</ITypography>
                    </div>
                    {index == items.length - 1 && <div style={{ textAlign: 'right' }}>
                      <IIconButton onClick={() => onEditService(item)}><EditIcon /></IIconButton>
                    </div>}
                  </div>
                </div>}
                <IDivider light variant='middle' style={{ marginTop: Styles.margin1Br, marginLeft: 0, marginRight: 0, marginBottom: 10, }} />
                {item[Constants.EMPLOYEE] && <div>
                  <ITypography variant='body1'><b>{Strings.provider + ' ' + (index + 1)}:</b></ITypography>
                  {isMobile && <>
                    <div className={classes.container}>
                      <div style={{ display: 'flex', width: '100%' }}>
                        <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.EMPLOYEE][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.EMPLOYEE][Constants.FIRST_NAME]} />
                        <div style={{ marginLeft: 10, width: '100%' }}>
                          <ITypography className={classes.text} variant='body1'><b>{item[Constants.EMPLOYEE][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE][Constants.LAST_NAME]}</b></ITypography>
                          {item[Constants.EMPLOYEE][Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE && <>
                            <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{item[Constants.EMPLOYEE][Constants.EMAIL]}</b></ITypography>
                            <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{item[Constants.EMPLOYEE][Constants.PHONE]}</b></ITypography>
                          </>}
                        </div>
                      </div>
                      {index == items.length - 1 && <div style={{ textAlign: 'right', marginTop: isMobile ? Styles.margin1Br : 0 }}>
                        {/* <ProviderButton onClick={() => onEditProvider(item, true)}>{Strings.anyProviderAvailable}</ProviderButton> */}
                        <ProviderButton onClick={() => onEditProvider(item)}>{Strings.requestTechnician}</ProviderButton>
                      </div>}
                    </div>
                  </>}
                  {!isMobile && <div style={{ display: 'flex', width: '100%' }}>
                    <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={item[Constants.EMPLOYEE][Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={item[Constants.EMPLOYEE][Constants.FIRST_NAME]} />
                    <div style={{ marginLeft: 10, width: '100%' }}>
                      <ITypography className={classes.text} variant='body1'><b>{item[Constants.EMPLOYEE][Constants.FIRST_NAME]} {item[Constants.EMPLOYEE][Constants.LAST_NAME]}</b></ITypography>
                      {item[Constants.EMPLOYEE][Constants.ID] != Constants.EMPLOYEE_ANY_ONE_AVAILABLE && <>
                        <ITypography className={classes.text} variant='body1'>{Strings.email}: <b>{item[Constants.EMPLOYEE][Constants.EMAIL]}</b></ITypography>
                        <ITypography className={classes.text} variant='body1'>{Strings.phone}: <b>{item[Constants.EMPLOYEE][Constants.PHONE]}</b></ITypography>
                      </>}
                    </div>
                    {index == items.length - 1 && <div style={{ textAlign: 'right' }}>
                      {/* <ProviderButton onClick={() => onEditProvider(item, true)}>{Strings.anyProviderAvailable}</ProviderButton> */}
                      <ProviderButton onClick={() => onEditProvider(item)}>{Strings.requestTechnician}</ProviderButton>
                    </div>}
                  </div>}
                </div>}
                {!item[Constants.EMPLOYEE] && <div style={{ margin: 10 }}>
                  <ITypography variant='body1'><b>{Strings.provider + ' ' + (index + 1)}:</b></ITypography>
                  <div className={classes.container}>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={settings[Constants.DEFAULT_AVATAR] || ''} alt='provider' />
                      <div style={{ marginLeft: 10, width: '100%' }}>
                        <ITypography className={classes.text} variant='body1'>{Strings.pleaseChooseProvider}</ITypography>
                      </div>
                    </div>
                    {index == items.length - 1 && <div style={{ textAlign: 'right', marginTop: isMobile ? Styles.margin1Br : 0 }}>
                      {/* <ProviderButton onClick={() => onEditProvider(item, true)}>{Strings.anyProviderAvailable}</ProviderButton> */}
                      <ProviderButton onClick={() => onEditProvider(item)}>{Strings.requestTechnician}</ProviderButton>
                    </div>}
                  </div>
                </div>}
              </div>
            </IGridListTitle>
          ))}
        </IGridList>
        <div style={{ display: 'flex', height: 50, marginTop: Styles.margin1Br }}>
          <AddButton onClick={() => onAddAnotherService()} style={{ margin: 'auto' }}>{Strings.addAnotherService}</AddButton>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: Styles.margin2Br }}>
          <IButton onClick={() => onConfirm()}>{Strings.confirmBook}</IButton>
        </div>
      </ICardContent>
    </ICard>
    <IDialog open={openProviderDialog} fullWidth={true} maxWidth='sm'>
      <IDialogTitle>{Strings.appName}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText>{`Do you want to use the previous technician, ${getPreviousProvider()}?`}</IDialogContentText>
        {isChecking && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ICircularProgress color='primary' /></div>}
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleProviderDialog(false)}>{Strings.no}</IButton>
        <IButton onClick={() => handleProviderDialog(true)} autoFocus>{Strings.yes}</IButton>
      </IDialogActions>
    </IDialog>
  </div>
}

function StoreAppointment() {
  Logger.log(Constants.PAGES_STORE_ADD_APPOINTMENT_VIEW_APPOINTMENT)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default StoreAppointment