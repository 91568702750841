import React, { useRef, useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobal } from '../../../utils/useGlobal'
import * as firebase from '../../../utils/firebase'
import * as Navigator from '../../../common/Navigator'
import * as Logger from '../../../common/Logger'
import * as StringUtils from '../../../common/StringUtils'
import * as DataUtils from '../../../common/DataUtils'
import * as ScreenUtils from '../../../common/ScreenUtils'
import * as Constants from '../../../common/Constants'
import * as Storage from '../../../common/Storage'
import ProgressBar from '../../widgets/ProgressBar'
import Strings from '../../../common/Strings'
import IButton from '../../controls/IButton'
import ITypography from '../../controls/ITypography'
import ITable from '../../controls/ITable'
import ITableBody from '../../controls/ITableBody'
import ITableCell from '../../controls/ITableCell'
import ITableContainer from '../../controls/ITableContainer'
import ITableHead from '../../controls/ITableHead'
import ITableRow from '../../controls/ITableRow'
import IPaper from '../../controls/IPaper'
import IIconButton from '../../controls/IIconButton'
import IDivider from '../../controls/IDivider'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ToastView, { TOAST_ERROR, TOAST_SUCCESS } from '../../widgets/ToastView'
import { createPropsWithActions, setLoading, showToast } from '../../../common/ViewUtils'
import Styles from '../../../common/Styles'
import IDialog from '../../controls/IDialog'
import IDialogActions from '../../controls/IDialogActions'
import IDialogContent from '../../controls/IDialogContent'
import IDialogContentText from '../../controls/IDialogContentText'
import IDialogTitle from '../../controls/IDialogTitle'
import IImage from '../../controls/IImage'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import moment from 'moment'

const AddButton = styled(IButton)({
  background: 'white',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  border: 0,
  borderRadius: 3,
  color: Styles.primaryBgColor,
  height: 35
})

const useStyles = makeStyles(theme => ({
  root: {
  },
  heading: {
    fontSize: Styles.headingTitleSize
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

function ContentView({ props }) {
  Logger.log('ContentView')

  const { user, settings } = useGlobal()
  const storeId = user[Constants.ID]
  const userId = user[Constants.ID]
  const refItem = useRef()
  const [items, setItems] = useState([])
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setLoading(props, true)
    const ref = firebase.databaseRef(Constants.FB_STORE_EMPLOYEES + '/' + userId + '/' + storeId)
    ref.once('value').then(response => {
      setLoading(props, false)
      const list = []
      const val = response.val()
      if (val) {
        for (let id in val) {
          list.push(val[id])
        }
      }
      DataUtils.sortBy(list, Constants.DATE_ADDED)
      setItems(list)
    }).catch(err => {
      setLoading(props, false)
      showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
    })
  }

  const onAddEmployee = () => {
    Navigator.navigate(props, Constants.PAGES_STORE_ADD_EMPLOYEE)
  }

  const onEditItem = (item) => {
    Navigator.navigate(props, Constants.PAGES_STORE_EDIT_EMPLOYEE, `${Constants.ID}=${item[Constants.ID]}`)
  }

  const onEditScheduleTime = (item) => {
    Navigator.navigate(props, Constants.PAGES_STORE_EMPLOYEE_SCHEDULE, `${Constants.ID}=${item[Constants.ID]}`)
  }

  const onDeleteItem = (item) => {
    refItem.current = item
    setOpenDialog(true)
  }

  const handleCloseDialog = (agree) => {
    setOpenDialog(false)
    if (agree) {
      setLoading(props, true)
      const employeeId = refItem.current[Constants.ID]
      setLoading(props, true)
      const now = moment()
      const httpsCallable = firebase.functions().httpsCallable(Constants.FB_FUNC_STORE_DELETE_EMPLOYEE)
      httpsCallable({ storeId, employeeId, year: now.year(), month: now.month() + 1, date: now.date(), hour: now.hour(), minute: now.minute() }).then(({ data }) => {
        setLoading(props, false)
        if (data.message) {
          showToast(props, TOAST_ERROR, data.message)
        } else {
          loadData()
        }
      }).catch(err => {
        setLoading(props, false)
        showToast(props, TOAST_ERROR, err.message || Strings.errorLoadingData)
      })
    }
  }

  const classes = useStyles()
  const isMobile = ScreenUtils.isMobile()

  return <div className={classes.root}>
    <ITypography className={classes.heading}>{Strings.employees}</ITypography>
    <div className={classes.addButton}>
      <AddButton startIcon={<AddIcon />} onClick={() => onAddEmployee()}>{Strings.addEmployee}</AddButton>
    </div>
    <ITableContainer component={IPaper} style={{ marginTop: Styles.margin2Br }}>
      <ITable className={classes.table}>
        <ITableHead>
          <ITableRow>
            {isMobile && <>
              <ITableCell>{Strings.details}</ITableCell>
            </>}
            {!isMobile && <>
              <ITableCell>{Strings.avatar}</ITableCell>
              <ITableCell>{Strings.email}</ITableCell>
              <ITableCell>{Strings.name}</ITableCell>
              <ITableCell>{Strings.phone}</ITableCell>
              <ITableCell>{Strings.address}</ITableCell>
              <ITableCell>{Strings.offline}</ITableCell>
              <ITableCell></ITableCell>
            </>}
          </ITableRow>
        </ITableHead>
        <ITableBody>
          {items.map(row => (
            <ITableRow key={row[Constants.ID]}>
              {isMobile && <ITableCell>
                <div style={{ display: 'flex' }}>
                  <IImage style={{ width: Styles.imageSize, height: Styles.imageSize }} src={row[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={row[Constants.FIRST_NAME]} />
                  <div style={{ width: '100%', marginLeft: 10 }}>
                    <div>{row[Constants.EMAIL]}</div>
                    <div>{row[Constants.FIRST_NAME]} {row[Constants.LAST_NAME]}</div>
                    <div>{Strings.phone}: {row[Constants.PHONE]}</div>
                    <div>{Strings.address}: {row[Constants.ADDRESS]}</div>
                    <div style={{ display: 'flex' }}>
                      <IIconButton onClick={event => onEditScheduleTime(row)}><AccessAlarmIcon /></IIconButton>
                      <IIconButton aria-label={Strings.edit} onClick={() => onEditItem(row)}><EditIcon /></IIconButton>
                      <IIconButton aria-label={Strings.delete} onClick={() => onDeleteItem(row)}><DeleteIcon /></IIconButton>
                    </div>
                  </div>
                </div>
              </ITableCell>}
              {!isMobile && <>
                <ITableCell component='th' scope='row' width={Styles.smallImageSize + 10}>
                  <IImage style={{ width: Styles.smallImageSize, height: Styles.smallImageSize }} src={row[Constants.IMAGE] || settings[Constants.DEFAULT_AVATAR] || ''} alt={row[Constants.FIRST_NAME]} />
                </ITableCell>
                <ITableCell>{row[Constants.EMAIL]}</ITableCell>
                <ITableCell>{row[Constants.FIRST_NAME]} {row[Constants.LAST_NAME]}</ITableCell>
                <ITableCell>{row[Constants.PHONE]}</ITableCell>
                <ITableCell>{row[Constants.ADDRESS]}</ITableCell>
                <ITableCell>
                  <IIconButton onClick={event => onEditScheduleTime(row)}><AccessAlarmIcon /></IIconButton>
                </ITableCell>
                <ITableCell>
                  <IIconButton aria-label={Strings.edit} onClick={() => onEditItem(row)}><EditIcon /></IIconButton>
                  <IIconButton aria-label={Strings.delete} onClick={() => onDeleteItem(row)}><DeleteIcon /></IIconButton>
                </ITableCell>
              </>}
            </ITableRow>
          ))}
        </ITableBody>
      </ITable>
    </ITableContainer>
    <IDialog open={openDialog} onClose={() => handleCloseDialog(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <IDialogTitle id='alert-dialog-title'>{Strings.delete}</IDialogTitle>
      <IDialogContent>
        <IDialogContentText id='alert-dialog-description'>{Strings.deleteConfirm}</IDialogContentText>
      </IDialogContent>
      <IDialogActions>
        <IButton onClick={() => handleCloseDialog(false)}>{Strings.cancel}</IButton>
        <IButton onClick={() => handleCloseDialog(true)} autoFocus>{Strings.ok}</IButton>
      </IDialogActions>
    </IDialog>
  </div>
}

function ViewEmployees() {
  Logger.log(Constants.PAGES_STORE_VIEW_EMPLOYEES)

  let props = createPropsWithActions()

  return <>
    <ContentView props={props} />
    <ProgressBar props={props} />
    <ToastView props={props} />
  </>
}

export default ViewEmployees